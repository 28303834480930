<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Create Operation Appointments</h1>

            <!-- <span class="mainDescription"
              >Use this page to start from scratch and put your custom
              content</span
            > -->
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-operationServiceDays' }">Back</router-link>
          </div>
          <!-- <ol class="breadcrumb">
            <li class="active">
              <span>Blank Page</span>
            </li>
          </ol> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="createOperationServiceDay" enctype="multipart/form-data">
              <div class="row">

                <div class="form-group col-md-12 mb-2">
                  <label class="text-">Hospital</label>
                  <multiselect v-model="info_service_id" :options="infoServices" :searchable="true" label="name"
                    track-by="id" placeholder="Select a Hospital" :multiple="false">
                  </multiselect>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Select operation</label>
                    <multiselect v-model="operation_id" :options="operations" :multiple="false" :searchable="true"
                      label="name_en" track-by="id" placeholder="Select operation"></multiselect>
                  </div>
                </div>

                <div class="form-group">
                  <div class="row">
                    <div class="col-md-6" v-for="(variant, index) in variants" :key="index">
                      <div class="card mb-3">
                        <div class="card-body">
                          <h5 class="card-title">{{ variant.day }}</h5>
                          <div class="form-group">
                            <label>Availability </label>
                            <!-- <span>&nbsp</span> -->
                            <input type="checkbox" v-model="variant.availability" />

                          </div>
                          <div class="form-group col-4">
                            <label>from</label>
                            <input type="time" class="form-control" v-model="variant.from" placeholder="Enter Time" />
                          </div>
                          <div class="form-group col-4">
                            <label>to</label>
                            <input type="time" class="form-control" placeholder="Enter Time" v-model="variant.to" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import Multiselect from "vue-multiselect";
export default {
  name: "CreateOperationServiceDay",
  components: {

    Multiselect,
  },
  data() {
    return {
      // variants: [],
      appointmentDays: [],
      operations: [],
      infoServices: [],
      operation_id: "",
      info_service_id: "",
      variants: [
        { appointment_day_id: 7, day: "Saturday", availability: false, from: null, to: null },
        { appointment_day_id: 1, day: "Sunday", availability: false, from: null, to: null },
        { appointment_day_id: 2, day: "Monday", availability: false, from: null, to: null },
        { appointment_day_id: 3, day: "Tuesday", availability: false, from: null, to: null },
        { appointment_day_id: 4, day: "Wednesday", availability: false, from: null, to: null },
        { appointment_day_id: 5, day: "Thursday", availability: false, from: null, to: null },
        { appointment_day_id: 6, day: "Friday", availability: false, from: null, to: null },

      ],
      // service_id: 6,
    };
  },
  async created() {
    this.fetchInfoservices();
    this.fetchOperations();
  },
  methods: {
    async fetchInfoservices() {
      try {
        // const response = await axios.get(`/admin/admin/info-service/all?service_id=${this.service_id}`);
        // const response = await axios.get(`/admin/admin/info-service/all?service_id=1`);
        const response = await axios.get(`/admin/admin/info-service/all?service_id=1`);
        this.infoServices = response.data.data;
        console.log('infoservice', response.data.data)
        this.meta = response.data.meta;
      } catch (error) {
        console.error("Error fetching infoServices:", error);
      }
    },
    async fetchOperations() {
      try {
        const response = await axios.get(`/admin/admin/operation/all`);
        this.operations = response.data.data;
        console.log(this.operations)
        console.log('operations', response.data.data)

        this.meta = response.data.meta;
      } catch (error) {
        console.error("Error fetching operation:", error);
      }
    },
    async createOperationServiceDay() {
      try {
        const formData = new FormData();
        formData.append("operation_id", this.operation_id.id);
        formData.append("info_service_id", this.info_service_id.id);
        this.variants.forEach((variant, index) => {
          formData.append(`days[${index}][appointment_day_id]`, variant.appointment_day_id);
          formData.append(`days[${index}][from]`, variant.from);
          formData.append(`days[${index}][to]`, variant.to);
          // formData.append(`days[${index}][availability]`, variant.availability);
          formData.append(`days[${index}][availability]`, variant.availability ? variant.availability = 1 : 0);


        });
        console.log(formData);
        const response = await axios.post("admin/admin/operation-service-days/create", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log(response.data);
        this.$router.push({ name: "all-operationServiceDays" });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  width: 100%;
  /* max-width: 500px; Optional: Max width for better responsiveness */
}

form>.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}
</style>
