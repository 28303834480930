<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">All Labs / Scans</h1>
          </div>
          <div class="col-sm-2">
            <router-link v-if="hasPermission('lab create')" style="margin-right: 10px" class="btn btn-primary"
              :to="{ name: 'create-lab' }">create</router-link>
            <router-link v-if="hasPermission('lab getSoftDeleted')" class="btn btn-success"
              :to="{ name: 'deleted-labs' }">Deleted
              Lab</router-link>
          </div>

        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <div v-if="labs.length > 0">
              <MyTable :columns="columns" :actions="true">
                <template v-for="(lab, index) in labs" :key="index">
                  <!-- Main Lab Row -->
                  <tr>
                    <th scope="row">{{ lab.id }}</th>
                    <td>{{ lab.name_ar }}</td>
                    <td>{{ lab.name_en }}</td>
                    <td>{{ lab.service.name ?? '' }}</td>
                    <td>Main Branch</td>
                    <td>
                      <img :src="`${lab.main_image}`" width="50" height="50" class="img-thumbnail" />
                    </td>
                    <td>
                      <router-link class="btn btn-info spacing" :to="{
                        name: 'create-appointmentLab',
                        params: { lab_id: lab.id }
                      }">
                        Create Appointments
                      </router-link>
                    </td>
                    <td>
                      <button class="me-2 btn btn-danger" @click="deleteLab(lab.id)">
                        Delete
                      </button>
                      <router-link class="btn btn-primary spacing" :to="{ name: 'edit-lab', params: { id: lab.id } }">
                        Edit
                      </router-link>
                    </td>
                  </tr>

                  <!-- Branches (Displayed as Labs) -->
                  <tr v-for="(branch, branchIndex) in lab.branches" :key="`${index}-${branchIndex}`"
                    class="nested-lab-row">
                    <th scope="row">{{ branch.id }}</th>
                    <td>{{ branch.name_ar }}</td>
                    <td>{{ branch.name_en }}</td>
                    <!-- <td>{{ branch.service?.name ?? '' }}</td> -->
                    <td>{{ branch.service_id == 17 ? 'Scans' : 'Laboratories' }}</td>
                    <td>Sub Branch</td>

                    <td>
                      <img :src="`${branch.main_image}`" width="50" height="50" class="img-thumbnail" />
                    </td>
                    <td>
                      <router-link class="btn btn-info spacing" :to="{
                        name: 'create-appointmentLab',
                        params: { lab_id: branch.id }
                      }">
                        Create Appointments
                      </router-link>
                    </td>
                    <td>
                      <button class="me-2 btn btn-danger" @click="deleteLab(branch.id)">
                        Delete
                      </button>
                      <router-link class="btn btn-primary spacing"
                        :to="{ name: 'edit-lab', params: { id: branch.id } }">
                        Edit
                      </router-link>
                    </td>
                  </tr>
                </template>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No labs found.
            </div>
          </div>
        </div>
      </div>
      <!-- existing code -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import { store } from "../../Store";
import $ from 'jquery'; // Import jQuery
import MyTable from "../MyTable//MyTable.vue"; // Import your MyTable component

export default {
  name: "AllLabs",
  components: {
    MyTable,
    // Paginate,
  },
  data() {
    return {
      dataTable: [], // Store DataTable instance
      labs: [],
      meta: {},
      searchTerm: "",
      columns: [
        { label: '#', key: 'id' },
        { label: 'Name Arabic', key: 'name_ar' },
        { label: 'Name English', key: 'name_en' },
        { label: 'Main Service', key: 'service_id' },
        { label: 'Branches', key: 'branches' },
        { label: 'Image', key: 'main_image' },
        { label: 'Create Appointments', key: 'lab_id' },

      ],
    };
  },

  async created() {
    this.fetchLabs();
  },

  computed: {
    hasPermission() {
      return (permission) => store.hasPermission(permission);
    },
    filteredLabs() {
      if (!this.searchTerm) {
        return this.labs;
      }
      const term = this.searchTerm.toLowerCase();
      return this.labs.filter(
        (lab) =>
          lab.name.toLowerCase().includes(term) ||
          lab.postal_code.toLowerCase().includes(term)
      );
    },
  },

  methods: {
    // fetch labs
    async fetchLabs() {
      try {
        const response = await axios.get(
          `/admin/admin/lab/all`
        );
        this.labs = response.data.data;
        console.log(" fetching labs:", this.labs);
        this.meta = response.data.meta;
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
      } catch (error) {
        console.error("Error fetching labs:", error);
      }
    },
    initializeDataTable() {
      this.$nextTick(() => {
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },
    deleteLab(id) {
      console.log("delete lab id: ", id);
      axios
        .delete(`/admin/admin/lab/delete?id=${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchLabs();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

};
</script>
<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}


.nested-lab-row {
  background-color: #f9f9f9;
  margin-left: 20px;
  /* Optional: Indent branches */
}
</style>