<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Create Medical Insurance</h1>

            <!-- <span class="mainDescription"
              >Use this page to start from scratch and put your custom
              content</span
            > -->
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-medicalInsurances' }">Back</router-link>
          </div>
          <!-- <ol class="breadcrumb">
            <li class="active">
              <span>Blank Page</span>
            </li>
          </ol> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="createInsurance" enctype="multipart/form-data">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">English Name</label>
                    <input type="text" class="form-control" placeholder="Enter English Name" v-model="name_en" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="labName">Arabic Name</label>
                    <input type="text" class="form-control" placeholder="Enter Arabic Name" v-model="name_ar" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">English Title</label>
                    <input type="text" class="form-control" placeholder="Enter English Title" v-model="title_en" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="labTitle">Arabic Title</label>
                    <input type="text" class="form-control" placeholder="Enter Arabic Title" v-model="title_ar" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="labName">Phone</label>
                    <input type="text" class="form-control" placeholder="Enter Phone" v-model="phone" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="labTitle">Email</label>
                    <input type="text" class="form-control" placeholder="Enter Email" v-model="email" />
                  </div>
                </div>
                <div class="form-group">
                  <label> Image Upload </label>
                  <div class="fileinput fileinput-new" data-provides="fileinput">
                    <div class="fileinput-new thumbnail">
                      <img src="/assets/images/avatar-1-xl.jpg" alt="" />
                    </div>
                    <div class="fileinput-preview fileinput-exists thumbnail"></div>
                    <div class="user-edit-image-buttons">
                      <span class="btn btn-azure btn-file"><span class="fileinput-new"><i class="fa fa-picture"></i>
                          Select image</span><span class="fileinput-exists"><i class="fa fa-picture"></i> Change</span>
                        <input type="file" name="main_image" @change="handleMainImageChange" />
                      </span>
                      <a href="#" class="btn fileinput-exists btn-red" data-dismiss="fileinput">
                        <i class="fa fa-times"></i> Remove
                      </a>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label> background Image Upload </label>
                  <div class="fileinput fileinput-new" data-provides="fileinput">
                    <div class="fileinput-new thumbnail">
                      <img src="/assets/images/avatar-1-xl.jpg" alt="" />
                    </div>
                    <div class="fileinput-preview fileinput-exists thumbnail"></div>
                    <div class="user-edit-image-buttons">
                      <span class="btn btn-azure btn-file"><span class="fileinput-new"><i class="fa fa-picture"></i>
                          Select image</span><span class="fileinput-exists"><i class="fa fa-picture"></i> Change</span>
                        <input type="file" name="background_image" @change="handleBackGroundImageChange" />
                      </span>
                      <a href="#" class="btn fileinput-exists btn-red" data-dismiss="fileinput">
                        <i class="fa fa-times"></i> Remove
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";

export default {
  name: "createMedicalInsurance",
  data() {
    return {
      name_en: "",
      name_ar: "",
      title_ar: "",
      title_en: "",
      email: "",
      phone: "",
      main_image: null,
      background_image: null,
    };
  },

  methods: {
    handleMainImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.main_image = file; // Update with the selected file
      }
      // event.target.value = null;
    },
    handleBackGroundImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.background_image = file; // Update with the selected file
      }
      // event.target.value = null;
    },
    async createInsurance() {
      try {
        const formData = new FormData();
        formData.append("name_en", this.name_en);
        formData.append("name_ar", this.name_ar);
        formData.append("title_ar", this.title_ar);
        formData.append("title_en", this.title_en);
        formData.append("email", this.email);
        formData.append("phone", this.phone);
        if (this.main_image) {
          formData.append("main_image", this.main_image); // Append the selected file
        }
        if (this.background_image) {
          formData.append("background_image", this.background_image); // Append the selected file
        }

        const response = await axios.post(
          "admin/admin/medical-insurance/create",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response.data.data);
        console.log(formData);
        this.$router.push({ name: "all-medicalInsurances" });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  width: 100%;
  /* max-width: 500px; Optional: Max width for better responsiveness */
}

form>.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}
</style>
