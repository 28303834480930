<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <!-- <div class="col-md-4">
            <input type="text" class="form-control" id="search" placeholder="Search" v-model="searchTerm" />
          </div> -->
          <div class="col-sm-10">
            <h1 class="mainTitle">Deleted Users</h1>
          </div>

          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-users' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">

            <div v-if="users.length > 0">
              <MyTable :columns="columns" :actions="true">
                <tr v-for="(user, index) in users" :key="index">
                  <td>{{ user.id }}</td>
                  <td>{{ user.name }}</td>
                  <td>{{ user.email }}</td>
                  <td>{{ user.phone }}</td>
                  <td>
                    <button class="me-2 btn btn-success" @click="restoreUsers(user.id)">
                      Restore
                    </button>
                  </td>
                </tr>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No deleted users found.
            </div>
          </div>

        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>
<script>
import MyTable from "../MyTable//MyTable.vue"; // Import your MyTable component
import axios from "../../Axios";
export default {
  name: "AllServices",
  components: {
    MyTable,
    // Paginate,
  },
  data() {
    return {
      dataTable: [],
      users: [],
      meta: {},
      columns: [
        { label: '#', key: 'id' },
        { label: 'Name', key: 'name' },
        { label: 'Email', key: 'email' },
        { label: 'Phone', key: 'phone' },
      ],
      // searchTerm: "",
    };
  },
  async created() {
    this.fetchUsers();
  },

  computed: {
    filteredUsers() {
      if (!this.searchTerm) {
        return this.users;
      }
      const term = this.searchTerm.toLowerCase();
      return this.users.filter((user) =>
        user.name.toLowerCase().includes(term)
      );
    },
  },

  methods: {
    // fetch users
    async fetchUsers() {
      try {
        const response = await axios.get(
          // `/admin/admin/user/get-delete-soft?page=${page}&is_paginate=15`
          `/admin/admin/user/get-delete-soft`
        );
        this.users = response.data.data;
        this.meta = response.data.meta;

        console.log(this.users);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    },
    restoreUsers(id) {
      console.log("restore status id: ", id);
      axios
        .post(`/admin/admin/User/restore?id=${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchStatuses();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}
</style>
