<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Emergency Details</h1>

          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-emergencies' }">Back</router-link>
          </div>

        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <div class="booking-details">
              <!-- <h1>Booking Details</h1> -->
              <div class="booking-info">
                <div class="section">
                  <h2>Patient Information</h2>
                  <p><strong>Name:</strong> {{ name || 'N/A' }}</p>
                  <p><strong>Phone:</strong> {{ phone || 'N/A' }}</p>
                  <p><strong>Patiant Name:</strong> {{ patient_name || 'N/A' }}</p>
                  <p><strong>Patiant Phone:</strong> {{ patient_phone || 'N/A' }}</p>
                  <p><strong>District:</strong> {{ district || 'N/A' }}</p>
                  <p><strong>Terms And Condition:</strong> {{ accept_terms == 1 ? "True" : "False" }}</p>
                </div>



                <!-- <div class="section status-section">
                  <h2>Status</h2>
                  <p>
                    <strong>Current Status:</strong>
                    <span class="badge" :class="'badge-' + selectedStatus.toLowerCase()">
                      {{ selectedStatus || 'N/A' }}
                    </span>
                  </p> -->

                <!-- <p><strong>Current Status:</strong> {{ selectedStatus.name || 'N/A' }}</p> -->
                <!-- <label for="status" class="form-label">Change Status:</label>
                  <select v-model="selectedStatus">
                    <option value="Pending">Pending</option>
                    <option value="Confirmed">Confirmed</option>
                    <option value="Completed">Completed</option>
                    <option value="Cancelled">Cancelled</option>
                  </select>
                  <button @click="updateStatus" class="btn btn-primary mt-3 status-btn">
                    Update Status
                  </button>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>


<script>
import axios from "../../Axios";

export default {
  name: "ShowEmergency",
  data() {
    return {
      name: "",
      phone: "",
      patient_name: "",
      patient_phone: "",
      address: "",
      district: "",
      doctor_type: "",
      accept_terms: "",
      selectedStatus: "",
    };
  },
  created() {
    this.fetchBooking();
  },
  methods: {

    async fetchBooking() {
      try {
        const response = await axios.get(`/api/admin/emergency/get?id=${this.$route.params.id}`);
        console.log(response);
        const bookingData = response.data.data;
        console.log('bookingData', bookingData);
        this.name = bookingData.name ?? null;
        this.phone = bookingData.phone ?? null;
        this.patient_name = bookingData.patient_name ?? null;
        this.patient_phone = bookingData.patient_phone ?? null;
        this.address = bookingData.address ?? null;
        this.district = bookingData.district.name ?? null;
        this.accept_terms = bookingData.accept_terms ?? null;
        this.doctor_type = bookingData.doctor_type ?? null;
        // this.selectedStatus = bookingData.status.name ?? null;
      } catch (error) {
        console.error("Error fetching bookingData:", error);
      }
    },

    async updateStatus() {
      try {

        const formData = new FormData();
        formData.append("id", this.$route.params.id);
        formData.append("status", this.selectedStatus);
        console.log(formData)
        const response = await axios.post(`/api/admin/emergency/update`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // this.booking.status = this.selectedStatus; // Update local state
        console.log(formData);
        console.log(response.data);
        this.$router.push({ name: "all-homeVisits" });
      } catch (error) {
        console.error(error);
      }
    }
  },
};
</script>
<style scoped>
.booking-details {
  padding: 20px;
}

.booking-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.section {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
}

.document {
  margin-bottom: 10px;
}

.document-image {
  max-width: 100%;
  max-height: 100px;
}

h2 {
  margin-bottom: 10px;
}

p {
  font-size: 1.1em
}

.documents-container {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}


/* Styling for the Current Status Badge */
.badge {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 1em;
  text-transform: capitalize;
}

/* Different Badge Colors Based on Status */
.badge-pending {
  background-color: #ffc107;
  color: #fff;
}

.badge-approved {
  background-color: #28a745;
  color: #fff;
}

.badge-rejected {
  background-color: #dc3545;
  color: #fff;
}

.badge-inprogress {
  background-color: #17a2b8;
  color: #fff;
}

/* Dropdown Customization */
.custom-select {
  margin-top: 10px;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 14px;
}

/* Button Styling */
.status-btn {
  display: block;
  margin-top: 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 10px 15px;
  transition: background-color 0.3s ease;
}

.status-btn:hover {
  background-color: #0056b3;
}
</style>
