<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Deleted Operations</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-operations' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">

            <!-- <div v-if="operations.length > 0"> -->
            <MyTable :columns="columns" :actions="true">
              <tr v-for="(operation, index) in operations" :key="index">
                <th scope="row">{{ operation.id }}</th>
                <td>{{ operation.name_ar }}</td>
                <td>{{ operation.name_en }}</td>
                <!-- <td>{{ operation.service.name ?? '' }}</td> -->
                <td>
                  <button class="me-2 btn btn-success" @click="restoreOperation(operation.id)">
                    Restore
                  </button>
                </td>
              </tr>
            </MyTable>
            <!-- </div>
            <div v-else class="alert alert-info">
              No operations found.
            </div> -->
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import $ from 'jquery'; // Import jQuery
import MyTable from "../MyTable//MyTable.vue"; // Import your MyTable component
import axios from "../../Axios";
export default {
  name: "DeletedOperations",
  components: {
    MyTable,
    // Paginate,
  },
  data() {
    return {
      dataTable: [],
      operations: [],
      meta: {},
      searchTerm: "",
      columns: [
        { label: '#', key: 'id' },
        { label: 'Name Arabic', key: 'name_ar' },
        { label: 'Name English', key: 'name_en' },
        // { label: 'Main Service', key: 'service_id' },
      ],
    };
  },

  async created() {
    this.fetchOperations();
  },

  computed: {
    filteredOperations() {
      if (!this.searchTerm) {
        return this.operations;
      }
      const term = this.searchTerm.toLowerCase();
      return this.operations.filter(
        (operation) =>
          operation.name.toLowerCase().includes(term)
      );
    },
  },

  methods: {
    // fetch operations
    async fetchOperations() {
      try {
        const response = await axios.get(
          `/admin/admin/operation/get-delete-soft`
        );
        this.operations = response.data.data;
        console.log(this.operations);
        this.meta = response.data.meta;
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
      } catch (error) {
        console.error("Error fetching operations:", error);
      }
    },
    initializeDataTable() {
      this.$nextTick(() => {
        // Ensure the DataTable is initialized only after the DOM is updated
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },

    restoreOperation(id) {
      console.log("restore operation id: ", id);
      axios.post(`/admin/admin/operation/restore?id=${id}`)
        .then((response) => {
          console.log(response.data.data);
          this.fetchOperations();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

};
</script>
<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}
</style>