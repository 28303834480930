<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Create Day Care Appointment</h1>

            <!-- <span class="mainDescription"
              >Use this page to start from scratch and put your custom
              content</span
            > -->
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-infoDayServices' }">Back</router-link>
          </div>
          <!-- <ol class="breadcrumb">
            <li class="active">
              <span>Blank Page</span>
            </li>
          </ol> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="createOperationServiceDay" enctype="multipart/form-data">
              <div class="row">

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Select Hospital</label>
                    <multiselect v-model="info_service_id" :options="infoServices" :multiple="false" :searchable="true"
                      label="name" track-by="id" placeholder="Select Hospital"></multiselect>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Select Day Care</label>
                    <multiselect v-model="day_service_id" :options="dayServices" :multiple="false" :searchable="true"
                      label="name_en" track-by="id" placeholder="Select dayCare"></multiselect>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label class="text-">price</label>
                  <input type="number" class="form-control" placeholder="Enter price" v-model="price" />
                </div>
                <div class="form-group col-4">
                  <label>from</label>
                  <input type="time" class="form-control" v-model="from" placeholder="Enter Time" />
                </div>
                <div class="form-group col-4">
                  <label>to</label>
                  <input type="time" class="form-control" placeholder="Enter Time" v-model="to" />
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-md-6" v-for="(variant, index) in variants" :key="index">
                      <div class="card mb-3">
                        <div class="card-body">
                          <h5 class="card-title">{{ variant.day }}</h5>
                          <div class="form-group">
                            <label>Availability </label>
                            <!-- <span>&nbsp</span> -->
                            <input type="checkbox" v-model="variant.availability" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import Multiselect from "vue-multiselect";
export default {
  name: "CreateInfoServiceDay",
  components: {
    Multiselect,
  },
  data() {
    return {
      // variants: [],
      dayServices: [],
      infoServices: [],
      appointmentDays: [],

      variants: [
        { appointment_day_id: 7, day: "Saturday", availability: false },
        { appointment_day_id: 1, day: "Sunday", availability: false },
        { appointment_day_id: 2, day: "Monday", availability: false },
        { appointment_day_id: 3, day: "Tuesday", availability: false },
        { appointment_day_id: 4, day: "Wednesday", availability: false },
        { appointment_day_id: 5, day: "Thursday", availability: false },
        { appointment_day_id: 6, day: "Friday", availability: false },

      ],

      info_service_id: "",
      appointment_day_id: null,
      day_service_id: "",
      price: "",
      from: "",
      to: "",
      availability: null,
    };
  },
  async created() {
    this.fetchAppointmentDays();
    this.fetchDayServices();
    this.fetchInfoServices();
  },
  methods: {
    async fetchDayServices() {
      try {
        const response = await axios.get(`/admin/admin/day-service/all`);
        this.dayServices = response.data.data;
        console.log(this.dayServices);
        this.meta = response.data.meta;
      } catch (error) {
        console.error("Error fetching dayService :", error);
      }
    },
    async fetchInfoServices() {
      try {
        const response = await axios.get(`/admin/admin/info-service/all?service_id=1`);
        this.infoServices = response.data.data;
        this.meta = response.data.meta;
      } catch (error) {
        console.error("Error fetching infoServices :", error);
      }
    },
    async createOperationServiceDay() {
      try {
        const formData = new FormData();
        formData.append("price", Number(this.price)); // Ensure price is a number
        formData.append("info_service_id", this.info_service_id.id);
        formData.append("day_service_id", this.day_service_id.id);
        formData.append(`from`, this.from);
        formData.append(`to`, this.to);
        this.variants.forEach((variant, index) => {
          formData.append(`days[${index}][appointment_day_id]`, variant.appointment_day_id);
          formData.append(`days[${index}][availability]`, variant.availability ? variant.availability = 1 : 0);
        });
        console.log(formData);
        const response = await axios.post("admin/admin/info-day-service/create", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log(response.data);
        this.$router.push({ name: "all-infoDayServices" });
      } catch (error) {
        console.error(error);
      }
    },
    addVariant() {
      this.variants.push({
        selectedAppointmentDay: "",
        from: "",
        to: "",
        day: "",
        availability: "",

      });
    },
    removeVariant(index) {
      this.variants.splice(index, 1);
    },

    async fetchAppointmentDays() {
      try {
        const response = await axios.get("/admin/admin/appointment-day/all");
        this.appointmentDays = response.data.data;
        console.log("appointmentDays", response.data.data);
      } catch (error) {
        console.error(error);
      }
    },

  },
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  width: 100%;
  /* max-width: 500px; Optional: Max width for better responsiveness */
}

form>.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}
</style>
