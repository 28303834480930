<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Deleted Operation Bookings</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-bookings' }">Back
            </router-link>
          </div>

        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">

            <div v-if="Bookings.length > 0">
              <MyTable :columns="columns" :actions="true">
                <tr v-for="(booking, index) in Bookings" :key="index">
                  <th scope="row">{{ booking.id }}</th>
                  <td>{{ booking.name }}</td>
                  <td>{{ booking.phone }}</td>
                  <!-- <td>{{ booking.appointments ? booking.appointments.appointment_data.lab_name : '' }}</td> -->
                  <td>{{ booking.appointments ? booking.appointments.appointment_type : '' }}</td>
                  <td>{{ booking.appointments ? booking.appointments.appointment_day.name_en : '' }}</td>
                  <td>{{ booking.appointments ? booking.appointments.appointment_hour.from + ' - ' +
                    booking.appointments.appointment_hour.to : '' }}
                  </td>
                  <td>
                    <!-- <button class="me-2 btn btn-success" @click="restoreBooking(booking.id)">
                      Delete
                    </button> -->
                    <!-- <router-link class="btn btn-primary spacing"
                      :to="{ name: 'edit-labBooking', params: { id: booking.id } }">
                      Edit
                    </router-link> -->
                  </td>
                </tr>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No Bookings found.
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import $ from 'jquery'; // Import jQuery
import MyTable from "../MyTable//MyTable.vue"; // Import your MyTable component

export default {
  name: "AllBookings",
  components: {
    MyTable,
    // Paginate,
  },
  data() {
    return {
      dataTable: [], // Store DataTable instance
      Bookings: [],
      meta: {},
      searchTerm: "",
      columns: [
        { label: '#', key: 'id' },
        { label: 'Patiant Name', key: 'name' },
        { label: 'Patiant Phone', key: 'phone' },
        // { label: 'Lab Name', key: 'appointments.appointment_data.lab_name' },
        // { label: 'Appointment Type', key: 'appointments.appointment_type' },
        { label: 'Day', key: 'appointments.appointment_day' },
        { label: 'Hour', key: 'appointments.appointment_hour' },
      ],
    };
  },

  async created() {
    this.fetchBookings();
  },

  computed: {
    filteredBookings() {
      if (!this.searchTerm) {
        return this.Bookings;
      }
      const term = this.searchTerm.toLowerCase();
      return this.Bookings.filter(
        (Booking) =>
          Booking.name.toLowerCase().includes(term)
      );
    },
  },

  methods: {
    async fetchBookings() {
      try {
        const response = await axios.get(
          `/admin/admin/booking/get-delete-soft`
        );
        this.Bookings = response.data.data;
        console.log(" fetching booking:", this.Bookings);
        this.meta = response.data.meta;
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
      } catch (error) {
        console.error("Error fetching Bookings:", error);
      }
    },
    initializeDataTable() {
      this.$nextTick(() => {
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },
    restoreBooking(id) {
      console.log("delete Bookings id: ", id);
      axios
        .delete(`/admin/admin/booking/restore?id=${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchBookings();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

};
</script>
<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}
</style>