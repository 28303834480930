<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Operation Booking Details</h1>

            <!-- <span class="mainDescription"
              >Use this page to start from scratch and put your custom
              content</span
            > -->
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-operationBookings' }">Back</router-link>
          </div>
          <!-- <ol class="breadcrumb">
            <li class="active">
              <span>Blank Page</span>
            </li>
          </ol> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <div class="booking-details">
              <!-- <h1>Booking Details</h1> -->
              <div class="booking-info">
                <div class="section">
                  <h2>Patient Information</h2>
                  <p><strong>User Name:</strong> {{ user_name || 'N/A' }}</p>
                  <p><strong>User Phone:</strong> {{ user_phone || 'N/A' }}</p>
                </div>
                <div class="section">
                  <p><strong>Date:</strong> {{ date || 'N/A' }}</p>
                  <p><strong>Operation Name:</strong> {{ operation || 'N/A' }}</p>
                  <p><strong>Hospital Name:</strong> {{ info_service || 'N/A' }}</p>
                  <p><strong>Price:</strong> {{ price || 'N/A' }}</p>
                </div>

                <div class="section status-section">
                  <h2>Status</h2>
                  <div class="form-group">
                    <br>
                    <multiselect v-model="selectedStatus" :options="statusOptions" :multiple="false"
                      placeholder="Select status" label="name_en" track-by="id"></multiselect>
                  </div>
                  <button @click="updateStatus" class="btn btn-primary mt-3 status-btn">
                    Update Status
                  </button>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import Multiselect from "vue-multiselect";
export default {
  name: "ShowOperationBooking",
  components: {
    Multiselect
  },
  data() {
    return {
      user_name: "",
      user_phone: "",
      date: "",
      user: [],

      operation: "",
      info_service: "",
      price: "",

      selectedStatus: null,
      statusOptions: [],
      // statusOptions: [
      //   { name: "Pending", value: "Pending" },
      //   { name: "Confirmed", value: "Confirmed" },
      //   { name: "Cancelled", value: "Canceled" },
      //   { name: "Done", value: "Done" },
      // ],
    };
  },
  created() {
    this.fetchBooking();
    this.fetchStatus();
  },
  methods: {
    async fetchBooking() {
      try {
        const response = await axios.get(`/admin/admin/operation/get-booking?id=${this.$route.params.id}`);
        console.log(response);
        const bookingData = response.data.data;
        this.user_name = bookingData.user_name ?? null;
        this.user_phone = bookingData.phone ?? null;
        this.price = bookingData.operation_service.price ?? null;
        this.date = bookingData.date ?? null;

        this.operation = bookingData.operation_service.operation.name_en ?? null;
        this.info_service = bookingData.operation_service.info_service.name ?? null;
        this.selectedStatus = bookingData.status ?? null;

      } catch (error) {
        console.error("Error fetching bookingData:", error);
      }
    },
    async fetchStatus() {
      try {
        const response = await axios.get(`/admin/admin/operation/all-status`);
        this.statusOptions = response.data.data;
        console.log(this.statusOptions)

        this.meta = response.data.meta;
      } catch (error) {
        console.error("Error fetching statusOptions:", error);
      }
    },
    async updateStatus() {
      try {
        const formData = new FormData();
        formData.append("booking_id", this.$route.params.id);
        formData.append("status_id", this.selectedStatus.id);

        const response = await axios.post(`/admin/admin/operation/update-booking-operation`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },

        });
        console.log(formData);
        console.log(response.data);
        this.$router.push({ name: "all-operationBookings" });
      } catch (error) {
        console.error(error);
      }
    }
  }

};
</script>
<style scoped>
.booking-details {
  padding: 20px;
}

.booking-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.section {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
}


h2 {
  margin-bottom: 10px;
}

p {
  font-size: 1.1em
}

/* Button Styling */
.status-btn {
  display: block;
  margin-top: 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 10px 15px;
  transition: background-color 0.3s ease;
}

.status-btn:hover {
  background-color: #0056b3;
}
</style>
