<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Edit Coupons</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-coupons' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="editCoupon" enctype="multipart/form-data">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">Name</label>
                    <input type="text" class="form-control" placeholder="Enter Name" v-model="name" />
                    <span class="error text-small block" v-if="errors.name">{{
                      errors.name }}</span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="specialityName">Code</label>
                    <input type="text" class="form-control" placeholder="Enter Code" v-model="code" />
                    <span class="error text-small block" v-if="errors.code">{{
                      errors.code }}</span>
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label class="text-">Discount</label>
                  <input type="number" min="0" max="100" class="form-control" placeholder="Enter Discount"
                    v-model="discount" />
                  <span class="error text-small block" v-if="errors.discount">{{
                    errors.discount }}</span>
                </div>
                <div class="checkbox clip-check check-primary">
                  <input type="checkbox" v-model="available" id="service1">
                  <label for="service1">
                    Availability
                  </label>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Notes</label>
                    <textarea rows="2" class="form-control" placeholder="Enter Notes" v-model="notes"></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Expiry Date:</label>
                    <input v-model="expires_at" type="date" />
                    <span class="error text-small block" v-if="errors.expires_at">{{
                      errors.expires_at }}</span>
                  </div>
                </div>


                <!-- <div v-if="Object.keys(errors).length > 0" class="error text-small block">
                  <ul>
                    <li v-for="(error, key) in errors" :key="key">
                      {{ error }}
                    </li>
                  </ul>
                </div> -->

              </div>
              <!-- <button class="btn btn-primary" :disabled="codeError">Submit</button> -->
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";

export default {
  name: "EditCoupon",
  data() {
    return {
      name: "",
      code: "",
      discount: "",
      available: false,
      type: "",
      notes: "",
      expires_at: "",
      errors: {}, // Store validation errors
    };
  },

  created() {
    this.fetchCoupon();
  },
  methods: {
    today() {
      const now = new Date();
      return now.toISOString().split("T")[0]; // Formats as 'YYYY-MM-DD'
    },
    isDateValid(date) {
      const selectedDate = new Date(date);
      const today = new Date(this.today());
      return selectedDate >= today;
    },
    validateForm() {
      this.errors = {};

      if (!this.expires_at) {
        this.errors.expires_at = "['Expiry Date is required.']";
      } else if (!this.isDateValid(this.expires_at)) {
        this.errors.expires_at = "['Expiry Date must be today or later.']";
      }

      return Object.keys(this.errors).length === 0;
    },
    async fetchCoupon() {
      try {
        const response = await axios.get(
          "/admin/admin/coupon/get?id=" + this.$route.params.id
        );
        const couponeData = response.data.data;

        this.name = couponeData.name;
        this.code = couponeData.code;
        this.discount = couponeData.discount;
        this.notes = couponeData.notes;
        this.available = couponeData.available === 1;
        this.expires_at = couponeData.expires_at.split(" ")[0];

        console.log('fetched', couponeData);
        console.log("data", this.data);

      } catch (error) {
        console.error(error);
      }
    },

    async editCoupon() {
      try {

        if (!this.validateForm()) {
          return; // Stop if validation fails
        }
        const formData = new FormData();
        formData.append("id", this.$route.params.id);
        formData.append('name', this.name);
        if (this.code) {
          formData.append('code', this.code);
        }
        formData.append("discount", Number(this.discount));
        formData.append("available", this.available ? 1 : 0);
        formData.append("notes", this.notes);
        formData.append("expires_at", this.expires_at);
        const response = await axios.post(
          "admin/admin/coupon/update",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log(response.data.data);
        console.log(formData);
        this.$router.push({ name: "all-coupons" });
      } catch (error) {
        console.error(error);
        if (error.response && error.response.status === 422) {
          // Handle validation errors
          this.errors = error.response.data.errors;
          console.log("Validation errors:", this.errors);
        } else {
          console.error("Error creating doctor:", error);
        }
      }
    },
  },
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
}

form>.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}

.error {
  color: #a94442;
  font-size: 0.875em;
}

/* Style for invalid inputs */
/* input:invalid,
textarea:invalid {
  color: #a94442;
} */
</style>