<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Incubator Booking Details</h1>

          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-incubators' }">Back</router-link>
          </div>

        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <div class="booking-details">
              <!-- <h1>Booking Details</h1> -->
              <div class="booking-info">
                <div class="section">
                  <h2>Patient Information</h2>
                  <br>
                  <p><strong>Name:</strong> {{ name || 'N/A' }}</p>
                  <p><strong>Phone:</strong> {{ phone || 'N/A' }}</p>
                  <p><strong>User Name:</strong> {{ user_name || 'N/A' }}</p>
                  <p><strong>User Phone:</strong> {{ user_phone || 'N/A' }}</p>
                  <p><strong>BirthDate:</strong> {{ birthdate || 'N/A' }}</p>
                  <p><strong>District:</strong> {{ district || 'N/A' }}</p>
                  <p><strong>Terms And Conditions:</strong> {{ accept_terms= 1 ? "True" : "False" }}</p>
                </div>
                <!-- <div class="section">
                  <h2>Documents</h2>
                  <div class="documents-container">
                    <div v-for="document in documents" :key="document.id" class="document">
                      <a :href="document.file" target="_blank" @click.prevent="openPdf(document.file)">
                        <img v-if="isImage(document.file)" :src="document.file" alt="Document" class="document-image" />
                        <img v-else-if="isPdf(document.file)" src="/assets/images/pdf-icon.png" alt="PDF Document"
                          class="document-image" />
                        <span v-else>{{ getFileName(document.file) }}</span>
                      </a>
                    </div>
                  </div>
                </div> -->

                <div class="section">
                  <h2>Documents</h2>
                  <div v-if="document" class="documents-container">
                    <div class="document">
                      <a :href="document" target="_blank" @click.prevent="openPdf(document)">
                        <img v-if="isImage(document)" :src="document" alt="Document" class="document-image" />
                        <img v-else-if="isPdf(document)" src="/assets/images/pdf-icon.png" alt="PDF Document"
                          class="document-image" />
                        <span v-else>{{ getFileName(document) }}</span>
                      </a>
                    </div>
                  </div>
                  <div v-else>No document available.</div>
                </div>

                <div class="section status-section">
                  <h2>Status</h2>
                  <div class="form-group">
                    <br>
                    <multiselect v-model="selectedStatus" :options="statusOptions" :multiple="false"
                      placeholder="Select status" label="name" track-by="value"></multiselect>
                  </div>
                  <button @click="updateStatus" class="btn btn-primary mt-3 status-btn">
                    Update Status
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>


<script>
import axios from "../../Axios";
import Multiselect from "vue-multiselect";
export default {
  name: "ShowIncubator",
  components: {
    Multiselect
  },
  data() {
    return {
      name: "",
      phone: "",
      user_name: "",
      user_phone: "",
      birthdate: "",
      district: "",
      document: "",
      doctor_type: "",
      accept_terms: "",
      selectedStatus: null,
      statusOptions: [
        { name: "Pending", value: "Pending" },
        { name: "Confirmed", value: "Confirmed" },
        { name: "Cancelled", value: "Canceled" },
        { name: "Done", value: "Done" },
      ],
    };
  },
  created() {
    this.fetchBooking();
  },
  methods: {

    async fetchBooking() {
      try {
        const response = await axios.get(`/api/admin/incubator/get?id=${this.$route.params.id}`);
        console.log(response);
        const bookingData = response.data.data;
        this.name = bookingData.name ?? null;
        this.phone = bookingData.phone ?? null;
        this.user_name = bookingData.user.name ?? null;
        this.user_phone = bookingData.user.phone ?? null;
        this.district = bookingData.district.name ?? null;
        this.accept_terms = bookingData.accept_terms ?? null;
        this.birthdate = bookingData.birthdate ?? null;
        this.selectedStatus = this.statusOptions.find(
          (option) => option.value === bookingData.status
        );
        this.document = bookingData.document ?? null;

      } catch (error) {
        console.error("Error fetching bookingData:", error);
      }
    },
    isImage(file) {
      const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
      const extension = file.split('.').pop().toLowerCase();
      return imageExtensions.includes(extension);
    },
    isPdf(file) {
      const pdfExtension = 'pdf';
      const extension = file.split('.').pop().toLowerCase();
      return extension === pdfExtension;
    },
    getFileName(file) {
      return file.split('/').pop();
    },
    openPdf(url) {
      window.open(url, '_blank');
    },
    async updateStatus() {
      try {
        const formData = new FormData();
        formData.append("id", this.$route.params.id);
        if (this.selectedStatus) {
          formData.append("status", this.selectedStatus.value);
        }
        const response = await axios.post(`/api/admin/incubator/update`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },

        });
        // this.booking.status = this.selectedStatus; // Update local state
        console.log(formData);
        console.log(response.data);
        this.$router.push({ name: "all-incubators" });
      } catch (error) {
        console.error(error);
      }
    }
  },
};
</script>
<style scoped>
.booking-details {
  padding: 20px;
}

.booking-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.section {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
}

.document {
  margin-bottom: 10px;
}

.document-image {
  max-width: 100%;
  max-height: 100px;
}

h2 {
  margin-bottom: 10px;
}

.documents-container {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}


p {
  font-size: 1.1em
}

/* Button Styling */
.status-btn {
  display: block;
  margin-top: 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 10px 15px;
  transition: background-color 0.3s ease;
}

.status-btn:hover {
  background-color: #0056b3;
}
</style>
