<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">All OutPatient Clinic Services</h1>
          </div>
          <div class="col-sm-2">
            <router-link style="margin-right: 10px" class="btn btn-primary"
              :to="{ name: 'create-externalClinicService' }">create</router-link>
            <!-- <router-link class="btn btn-success" :to="{ name: 'deleted-externalClinicServices' }">Deleted
              OutPatient Clinic Service</router-link> -->
          </div>

        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">

            <div v-if="externalClinics.length > 0">
              <MyTable :columns="columns" :actions="true">
                <tr v-for="(externalClinic, index) in externalClinics" :key="index">
                  <th scope="row">{{ externalClinic.id }}</th>
                  <td>{{ externalClinic.info_service.name }}</td>
                  <td>{{ externalClinic.external_clinic.name_en }}</td>
                  <td>{{ externalClinic.price }}</td>
                  <td>
                    <button class="me-2 btn btn-danger" @click="deleteExternalClinic(externalClinic.id)">
                      Delete
                    </button>
                    <router-link class="btn btn-primary spacing"
                      :to="{ name: 'edit-externalClinicService', params: { id: externalClinic.id } }">
                      Edit
                    </router-link>
                  </td>
                </tr>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No OutPatient Clinics found.
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import $ from 'jquery'; // Import jQuery
import { store } from "../../Store";
import MyTable from "../MyTable//MyTable.vue";
import axios from "../../Axios";
export default {
  name: "AllExternalClinicServices",
  components: {
    MyTable,
    // Paginate,
  },
  data() {
    return {
      dataTable: [],
      externalClinics: [],
      meta: {},
      searchTerm: "",
      columns: [
        { label: '#', key: 'id' },
        { label: 'Hospital', key: 'name' },
        { label: 'OutPatient Clinic', key: 'name_en' },
        { label: 'Price', key: 'price' },
        // { label: 'Service Name', key: 'service.name' },
      ],
    };
  },

  async created() {
    this.fetchExternalClinics();
  },
  computed: {
    hasPermission() {
      return (permission) => store.hasPermission(permission);
    },
    filteredExternalClinics() {
      if (!this.searchTerm) {
        return this.externalClinics;
      }
      const term = this.searchTerm.toLowerCase();
      return this.externalClinics.filter(
        (externalClinic) =>
          externalClinic.name.toLowerCase().includes(term)
      );
    },
  },

  methods: {
    // fetch externalClinics
    async fetchExternalClinics() {
      try {
        const response = await axios.get(
          `/admin/admin/external-clinic-service/all`
        );
        console.log(response);
        this.externalClinics = response.data.data;
        console.log(" fetching externalClinics:", this.externalClinics);
        this.meta = response.data.meta;
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
      } catch (error) {
        console.error("Error fetching externalClinics:", error);
      }
    },
    initializeDataTable() {
      this.$nextTick(() => {
        // Ensure the DataTable is initialized only after the DOM is updated
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },
    deleteExternalClinic(id) {
      console.log("delete externalClinics id: ", id);
      axios
        .delete(`/admin/admin/external-clinic-service/delete?id=${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchExternalClinics();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}
</style>