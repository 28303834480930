<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Deleted Day Cares</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-success" :to="{ name: 'all-dayServices' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">

            <div v-if="dayServices.length > 0">

              <MyTable :columns="columns" :actions="true">
                <tr v-for="(dayService, index) in dayServices" :key="index">
                  <th scope="row">{{ dayService.id }}</th>
                  <td>{{ dayService.name_ar }}</td>
                  <td>{{ dayService.name_en }}</td>
                  <td>
                    <button class="me-2 btn btn-success" @click="restoreDayService(dayService.id)">
                      Restore
                    </button>
                  </td>
                </tr>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No Deleted dayCares found.
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import $ from 'jquery'; // Import jQuery
import MyTable from "../MyTable//MyTable.vue"; // Import your MyTable component
import axios from "../../Axios";
export default {
  name: "DeletedDayServices",
  components: {
    MyTable,
    // Paginate,
  },
  data() {
    return {
      dataTable: [],
      dayServices: [],
      meta: {},
      searchTerm: "",
      columns: [
        { label: '#', key: 'id' },
        { label: 'Name Arabic', key: 'name_ar' },
        { label: 'Name English', key: 'name_en' },
      ],
    };
  },

  async created() {
    this.fetchDayServices();
  },

  computed: {
    filteredDayServices() {
      if (!this.searchTerm) {
        return this.dayServices;
      }
      const term = this.searchTerm.toLowerCase();
      return this.dayServices.filter(
        (dayService) =>
          dayService.name.toLowerCase().includes(term)
      );
    },
  },

  methods: {
    // fetch dayServices
    async fetchDayServices() {
      try {
        const response = await axios.get(
          `/admin/admin/day-service/get-delete-soft`
        );
        this.dayServices = response.data.data;
        this.meta = response.data.meta;
        console.log(response.data.data);
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
      } catch (error) {
        console.error("Error fetching dayServices:", error);
      }
    },
    initializeDataTable() {
      this.$nextTick(() => {
        // Ensure the DataTable is initialized only after the DOM is updated
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },

    restoreDayService(id) {
      console.log("restore dayService id: ", id);
      axios
        .post(`/admin/admin/day-service/restore?id=${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchDayServices();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

};
</script>
<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}
</style>