<template>
  <div class="container">
    <ErrorMessage v-if="errorMessage" :errorMessage="errorMessage" />
    <div class="form-container">
      <!-- <a class="navbar-brand" href="#">
        <img :src="`assets/images/logo.png`" alt="Clip-Two" /> -->
      <!-- <img :src="require('assets/images/logo.png')" alt="Logo"> -->
      <!-- </a> -->
      <div class="welcome-message">
        Welcome To Allo Doctor
      </div>
      <div class="form">
        <form @submit.prevent="handleLogin" class="g-3 needs-validation" novalidate>
          <div class="col-md-12">
            <label for="email" class="form-label">Email</label>
            <input type="text" class="form-control" id="email" v-model="email" required />
            <div class="valid-feedback">Looks good!</div>
          </div>

          <div class="col-md-12">
            <label for="phone" class="form-label">Phone</label>
            <input type="phone" class="form-control" id="phone" v-model="phone" required />
            <div class="valid-feedback">Looks good!</div>
          </div>

          <div class="col-12">
            <button class="btn btn-primary mt-3" type="submit" :disabled="loading">
              <span v-if="!loading">Login</span>
              <span v-else>
                <i class="fas fa-spinner fa-spin"></i> Logging in...
              </span>
            </button>
          </div>
        </form>

        <!-- <div class="col-12">
            <button class="btn btn-primary mt-3" type="submit">Login</button>
          </div> -->
        <!-- </form> -->
      </div>
    </div>
  </div>
</template>
<script>
// import axios from "axios";
import axios, { setAuthorizationHeader } from "../../Axios";
import { store } from "../../Store"; // Adjust the path as necessary
import ErrorMessage from "../Errors/ErrorMessage.vue";

export default {
  name: "LoginComponent",
  data() {
    return {
      email: "",
      phone: "",
      errorMessage: "",
      loading: false, // Add loading state
    };
  },

  components: {
    ErrorMessage,
  },
  methods: {

    async handleLogin() {
      this.loading = true; // Show loading spinner
      this.errorMessage = ""; // Clear any previous error messages

      try {
        console.log("Logging in...");

        const response = await axios.post("/admin/auth/login", {
          email: this.email,
          phone: this.phone,
        });


        console.log("Login successful", response.data.data);

        // Extract token and set it in local storage
        const token = response.data.data.token;
        if (token) {
          localStorage.setItem("access_token", token);
          setAuthorizationHeader(token);
        } else {
          console.error("Token is undefined in the response");
        }

        // Extract user data from the login response
        const userData = response.data.data;
        const role = userData.roles[0].name; // Assuming the first role is the primary role
        const permissions = userData.roles[0].permissions.map(permission => permission.name);

        console.log("Extracted role:", role);
        console.log("Extracted permissions:", permissions);

        // Set user role and permissions in the store
        store.setUserRole(role);
        store.setUserPermissions(permissions);
        store.setLoggedIn(true);
        store.setUser(userData);

        // Persist user data in local storage
        localStorage.setItem("userRole", role);
        localStorage.setItem("userPermissions", JSON.stringify(permissions));

        // window.location.reload();
        console.log("Redirecting to home...");
        this.$router.push({ name: "home" });
        // window.location.href = "/home";

      } catch (error) {

        console.error("Login failed", error.response?.data?.message || error.message);
        this.errorMessage = error.response?.data?.message || "An unexpected error occurred.";
        // console.error("Login failed", error.response.date.message);
        // this.errorMessage = error.response.date.message;

        // console.error("Login failed", error.message);
        // this.errorMessage = error.message;
        setTimeout(() => {
          this.errorMessage = "";
        }, 3500);
      } finally {
        this.loading = false; // Hide loading spinner
      }
    },
  },
};
</script>
<style scoped>
.form-container {
  height: 100vh;
  /* Full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Center vertically */
  align-items: center;
  /* Center horizontally */
  /* background-color: #f5f5f5;  */
}

.welcome-message {
  font-size: 1.5rem;
  /* Optional: Adjust font size */
  margin-bottom: 1rem;
  /* Space between the message and the form */
  text-align: center;
  /* Center text */
}

.form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  width: 50%;
  max-width: 500px;
  /* Optional: Max width for better responsiveness */
}

form button {
  width: 100%;
}
</style>
