<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Edit Hospital / Clinic</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-infoServices' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="editinfoService">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">English Name</label>
                    <input type="text" class="form-control" placeholder="Enter English Name" v-model="name_en" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="infoServiceName">Arabic Name</label>
                    <input type="text" class="form-control" placeholder="Enter Arabic Name" v-model="name_ar" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="infoServiceName">HotLine</label>
                    <input type="text" class="form-control" placeholder="Enter HotLine No" v-model="info_service_no" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="infoServiceName">English Description </label>
                    <textarea rows="3" class="form-control" placeholder="Enter English Description"
                      v-model="description_en"></textarea>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="infoServiceName">Arabic Description </label>
                    <textarea rows="3" class="form-control" placeholder="Enter Arabic Description"
                      v-model="description_ar"></textarea>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="infoServiceName">Address</label>
                    <input type="text" class="form-control" placeholder="Enter address" v-model="address" />
                  </div>
                </div>
                <GoogleMap :coords="{ lat: lat, lng: long }" @placeChanged="handlePlaceChanged" />

                <div class="form-group">
                  <label>Latitude</label>
                  <input type="text" class="form-control" placeholder="Enter Latitude" v-model="lat" />
                </div>

                <div class="form-group">
                  <label>Longitude</label>
                  <input type="text" class="form-control" placeholder="Enter Longitude" v-model="long" />
                </div>
                <div class="form-group">
                  <label>Image</label>
                  <div class="fileinput fileinput-new" data-provides="fileinput">
                    <div class="fileinput-new thumbnail">
                      <img :src="imageUrl" alt="Main Image" />
                    </div>
                    <div class="fileinput-preview fileinput-exists thumbnail"></div>
                    <div class="user-edit-image-buttons">
                      <span class="btn btn-azure btn-file"><span class="fileinput-new"><i class="fa fa-picture"></i>
                          Select image</span><span class="fileinput-exists"><i class="fa fa-picture"></i> Change</span>
                        <input type="file" name="main_image" @change="handleImageChange" />
                      </span>
                      <a href="#" class="btn fileinput-exists btn-red" data-dismiss="fileinput">
                        <i class="fa fa-times"></i> Remove
                      </a>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label> Backgroud Image Upload </label>
                  <div class="fileinput fileinput-new" data-provides="fileinput">
                    <div class="fileinput-new thumbnail">
                      <img :src="background_image_Url" alt="Background_image" />
                    </div>
                    <div class="fileinput-preview fileinput-exists thumbnail"></div>
                    <div class="user-edit-image-buttons">
                      <span class="btn btn-azure btn-file"><span class="fileinput-new"><i class="fa fa-picture"></i>
                          Select background_image</span><span class="fileinput-exists"><i class="fa fa-picture"></i>
                          Change</span>
                        <input type="file" name="background_image" @change="handleBackgroundImageChange" />
                      </span>
                      <a href="#" class="btn fileinput-exists btn-red" data-dismiss="fileinput">
                        <i class="fa fa-times"></i> Remove
                      </a>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Select services</label>
                  <select class="form-control" v-model="service_id">
                    <option value="">Select Service</option>
                    <option value="1">Hospitals</option>
                    <option value="2">Clinics</option>
                    <!-- <option value="4">OutPatient Clinics</option> -->
                  </select>
                </div>
                <div class="form-group">
                  <label>Select District</label>
                  <multiselect v-model="selectedDistrict" :options="districts" :multiple="false" :searchable="true"
                    label="name" track-by="id" placeholder="Select districts"></multiselect>
                </div>
                <div class="form-group">
                  <label>Select Branches</label>
                  <multiselect v-model="selectedBranches" :options="branches" :multiple="true" :searchable="true"
                    label="name" track-by="id" placeholder="Select branches"></multiselect>
                </div>
                <div class="form-group">
                  <label class="text-">Medical Insurances</label>
                  <multiselect v-model="selectedMedicalInsurances" :options="medicalInsurances" :searchable="true"
                    label="name_en" track-by="id" placeholder="Select Insurances" :multiple="true"></multiselect>
                </div>
                <button class="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import Multiselect from "vue-multiselect";
import GoogleMap from "../Component/GoogleMap.vue";

export default {
  name: "EditinfoService",
  components: {
    Multiselect,
    GoogleMap,
  },
  data() {
    return {
      name_en: "",
      name_ar: "",
      description_en: "",
      description_ar: "",
      address: "",
      lat: "",
      long: "",
      imageUrl: "",
      image: null,
      background_image: null,
      background_image_Url: "",
      info_service_no: null,
      service_id: "",
      services: [],
      branches: [],
      selectedBranches: [],
      district_id: "",
      districts: [],
      medicalInsurances: [],
      selectedMedicalInsurances: [],
      // existingImage: "", // URL of the existing image
      // removeImageFlag: false, // Flag to indicate if the image should be removed  

    };
  },
  // computed: {
  //   imagePreviewUrl() {
  //     return this.image ? URL.createObjectURL(this.image) : this.existingImage;
  //   },
  // },
  created() {
    this.fetchinfoService();
    this.fetchService();
    this.fetchBranches();
    this.fetchDistrict();
    this.fetchMedicalInsurances();
  },
  methods: {
    handlePlaceChanged(place) {
      this.lat = place.lat; // Update latitude
      this.long = place.lng; // Update longitude
    },
    handleImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.image = file;
      }
    },
    handleBackgroundImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.background_image = file;
      }
      // event.target.value = null;
    },
    async fetchBranches() {
      try {
        const response = await axios.get(`/admin/admin/branch/all`);
        this.branches = response.data.data;
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    },
    async fetchDistrict() {
      try {
        const response = await axios.get("/admin/admin/district/all");
        this.districts = response.data.data;
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    },

    async fetchService() {
      try {
        const response = await axios.get("/admin/admin/service/all");
        this.services = response.data.data;
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    },
    async fetchMedicalInsurances() {
      try {
        const response = await axios.get("/admin/admin/medical-insurance/all");
        console.log("medical-insurance", response.data.data);
        this.medicalInsurances = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchinfoService() {
      try {
        const response = await axios.get(
          "/admin/admin/info-service/get?id=" + this.$route.params.id
        );
        // const response = await axios.get(`admin/admin/infoService/get/${this.$route.params.id}`);
        const data = response.data.data;
        this.name_en = data.name_en;
        this.name_ar = data.name_ar;
        this.name = data.name;
        this.info_service_no = data.info_service_no;
        this.address = data.address;
        this.lat = data.lat;
        this.long = data.long;
        this.description_en = data.description_en;
        this.description_ar = data.description_ar;
        this.description = data.description;
        // this.service_id = response.data.data.service_id_original;
        this.service_id = data.service_id.id;
        this.selectedDistrict = data.district;
        this.selectedBranches = data.branches;
        this.selectedMedicalInsurances = data.medical_insurance;
        this.imageUrl = data.image;
        this.background_image_Url = data.background_image;
        this.mapCenter = { lat: data.lat, lng: data.long };
        console.log(data);
      } catch (error) {
        console.error(error);
      }
    },

    // async handleFileChange(event) {
    //   const file = event.target.files[0];
    //   if (file) {
    //     this.image = file;
    //     const reader = new FileReader();
    //     reader.onload = (e) => {
    //       this.$refs.imagePreview.src = e.target.result; // Preview the selected image
    //     };
    //     reader.readAsDataURL(file);
    //   }
    // },
    // async removeImage() {
    //   console.log("this.$refs.imagePreview.src", this.$refs.imagePreview.src);

    //   // console.log('this.image',this.image);
    //   // console.log('this.removeImageFlag',this.removeImageFlag);
    //   // const url = new URL(this.existingImage);
    //   // Send delete request to server
    //   await axios.post(`admin/admin/info-service/delete-image`, {
    //     image: this.$refs.imagePreview.src,
    //     id: this.$route.params.id, // Send filename in request body
    //   });
    //   this.image = null;
    //   this.removeImageFlag = true;
    //   this.$refs.imagePreview.src = ""; // Clear the preview image
    //   // const imageName = url.pathname.split('/').pop(); // Extract filename with extension
    // },

    async editinfoService() {
      try {
        const formData = new FormData();
        formData.append("id", this.$route.params.id);
        formData.append("name_en", this.name_en);
        formData.append("name_ar", this.name_ar);
        formData.append("description_en", this.description_en);
        formData.append("description_ar", this.description_ar);
        formData.append("info_service_no", this.info_service_no);
        formData.append("address", this.address);
        formData.append("lat", this.lat);
        formData.append("long", this.long);
        formData.append("service_id", this.service_id);
        formData.append("district_id", this.selectedDistrict.id);
        this.selectedBranches.forEach((branch, index) => {
          formData.append(`branch_id[${index}]`, branch.id);
        });
        this.selectedMedicalInsurances.forEach((medicalInsurance, index) => {
          formData.append(`medical_insurance[${index}]`, medicalInsurance.id);
        });
        if (this.image) {
          formData.append("image", this.image); // Append the selected file
        }
        if (this.background_image) {
          formData.append("background_image", this.background_image); // Append the selected file
        }
        // if (this.removeImageFlag) {
        //   formData.append("remove_image", true); // Flag to indicate removal
        // }

        const response = await axios.post(
          "admin/admin/info-service/update",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(formData)
        console.log(response.data);
        this.$router.push({ name: "all-infoServices" });
      } catch (error) {
        console.error("Error updating infoService:", error);
      }
    },
  },
};
</script>
<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  width: 100%;
  /* max-width: 500px; Optional: Max width for better responsiveness */
}

form>.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}

.multiple-image-upload {
  margin-bottom: 1.5rem;
}

.image-preview-container {
  margin-top: 1rem;
}

.image-preview-wrapper {
  position: relative;
  margin-bottom: 1rem;
}

.preview-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.remove-image {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 2px 8px;
  font-size: 12px;
}

.upload-container {
  margin-bottom: 1rem;
}
</style>
