<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">All Operation Services</h1>
          </div>
          <div class="col-sm-2">
            <!-- <router-link v-if="hasPermission('operation-service create')" style="margin-right: 10px" -->
            <router-link style="margin-right: 10px" class="btn btn-primary"
              :to="{ name: 'create-operationService' }">create</router-link>
            <!-- <router-link v-if="hasPermission('operation-service get-delete-soft')" class="btn btn-success" -->
            <!-- <router-link class="btn btn-success" :to="{ name: 'deleted-operationServices' }">Deleted
              Operations</router-link> -->
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">

            <div v-if="operations.length > 0">
              <MyTable :columns="columns" :actions="true">
                <tr v-for="(operation, index) in filteredOperations" :key="index">
                  <th scope="row">{{ operation.id }}</th>
                  <td>{{ operation.info_service.name }}</td>
                  <td>{{ operation.operation.name_en }}</td>
                  <td>{{ operation.price }}</td>
                  <td>
                    <button class="me-2 btn btn-danger" @click="deleteOperation(operation.id)">
                      Delete
                    </button>
                    <router-link class="btn btn-primary spacing"
                      :to="{ name: 'edit-operationService', params: { id: operation.id } }">
                      Edit
                    </router-link>
                  </td>

                </tr>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No operation Service found.
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import $ from 'jquery'; // Import jQuerys
import { store } from "../../Store";
import MyTable from "../MyTable//MyTable.vue"; // Import your MyTable component
import axios from "../../Axios";
export default {
  name: "AllOperationServices",
  components: {
    MyTable,
    // Paginate,
  },
  data() {
    return {
      dataTable: [],
      operations: [],
      meta: {},
      searchTerm: "",
      columns: [
        { label: '#', key: 'id' },
        { label: 'Hospital/Clinic', key: 'infoservice' },
        { label: 'Operation', key: 'operation' },
        { label: 'Price', key: 'price' },
      ],
    };
  },

  async created() {
    this.fetchOperations();
  },

  computed: {
    hasPermission() {
      return (permission) => store.hasPermission(permission);
    },
    filteredOperations() {
      if (!this.searchTerm) {
        return this.operations;
      }
      const term = this.searchTerm.toLowerCase();
      return this.operations.filter(
        (operation) =>
          operation.name.toLowerCase().includes(term)
      );
    },
  },

  methods: {
    // fetch operations
    async fetchOperations() {
      try {
        const response = await axios.get(
          `/api/admin/operation-service/all`
        );
        console.log(this.operations);
        this.operations = response.data.data;
        console.log(this.operations);
        this.meta = response.data.meta;
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
      } catch (error) {
        console.error("Error fetching operationService:", error);
      }
    },
    initializeDataTable() {
      this.$nextTick(() => {
        // Ensure the DataTable is initialized only after the DOM is updated
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },

    deleteOperation(id) {
      console.log("delete operationService id: ", id);
      axios.delete(`/api/admin/operation-service/delete?id=${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchOperations();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

};
</script>
<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}
</style>