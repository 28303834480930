<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Home Nursing Booking Details</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-nurseVisits' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <div class="booking-details">
              <!-- <h1>Booking Details</h1> -->
              <div class="booking-info">
                <div class="section">
                  <h2>Patient Information</h2>
                  <br>
                  <p><strong>Name:</strong> {{ name || 'N/A' }}</p>
                  <p><strong>Phone:</strong> {{ phone || 'N/A' }}</p>
                  <p><strong>User Name:</strong> {{ user_name || 'N/A' }}</p>
                  <p><strong>User Phone:</strong> {{ user_phone || 'N/A' }}</p>
                  <p><strong>Age:</strong> {{ age || 'N/A' }}</p>
                  <p><strong>District:</strong> {{ district || 'N/A' }}</p>
                  <p><strong>Address:</strong> {{ address || 'N/A' }}</p>
                  <p><strong>Notes:</strong> {{ notes || 'N/A' }}</p>
                </div>

                <div class="section status-section">
                  <h2>Status</h2>
                  <div class="form-group">
                    <br>
                    <multiselect v-model="selectedStatus" :options="statusOptions" :multiple="false"
                      placeholder="Select status" label="name" track-by="value"></multiselect>
                  </div>
                  <button @click="updateStatus" class="btn btn-primary mt-3 status-btn">
                    Update Status
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>


<script>
import axios from "../../Axios";
import Multiselect from "vue-multiselect";
export default {
  name: "ShowNurseVisit",
  components: {
    Multiselect,
  },
  data() {
    return {
      name: "",
      phone: "",
      user_name: "",
      user_phone: "",
      address: "",
      district_id: "",
      district: "",
      age: "",
      notes: "",
      selectedStatus: null,
      statusOptions: [
        { name: "Pending", value: "Pending" },
        { name: "Confirmed", value: "Confirmed" },
        { name: "Cancelled", value: "Canceled" },
        { name: "Done", value: "Done" },
      ],

    };
  },
  created() {
    this.fetchBooking();
  },
  methods: {

    async fetchBooking() {
      try {
        const response = await axios.get(`/admin/admin/nurse-visit/get?id=${this.$route.params.id}`);
        console.log(response);
        const bookingData = response.data.data;
        this.name = bookingData.name ?? null;
        this.phone = bookingData.phone ?? null;
        this.district = bookingData.district.name ?? null;
        this.age = bookingData.age ?? null;
        this.address = bookingData.address ?? null;
        this.user_name = bookingData.user.name ?? null;
        this.user_phone = bookingData.user.phone ?? null;
        // this.selectedStatus = bookingData.status ?? null;
        this.selectedStatus = this.statusOptions.find(
          (option) => option.value === bookingData.status
        );
        this.notes = bookingData.notes ?? null;

      } catch (error) {
        console.error("Error fetching bookingData:", error);
      }
    },

    async updateStatus() {
      try {
        const formData = new FormData();
        formData.append("id", this.$route.params.id);
        // formData.append("status", this.selectedStatus);
        if (this.selectedStatus) {
          formData.append("status", this.selectedStatus.value);
        }

        console.log(formData);
        const response = await axios.post(`/admin/admin/nurse-visit/update`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },

        });
        console.log(formData);
        console.log(response.data);
        this.$router.push({ name: "all-nurseVisits" });
      } catch (error) {
        console.error(error);
      }
    }
  },
};
</script>
<style scoped>
.booking-details {
  padding: 20px;
}

.booking-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.section {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
}

p {
  font-size: 1.1em
}

/* Button Styling */
.status-btn {
  display: block;
  margin-top: 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 10px 15px;
  transition: background-color 0.3s ease;
}

.status-btn:hover {
  background-color: #0056b3;
}
</style>
