<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Edit Banner</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-banners' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="editBanner" enctype="multipart/form-data">
              <div class="row">

                <div class="form-group col-md-6">
                  <label>Entity</label>
                  <select class="form-control" v-model="bannerable_entity" @change="fetchBannerables" disabled>
                    <option value="">Select Entity</option>
                    <option v-for="entity in ['doctor', 'pharmacy', 'lab']" :key="entity" :value="entity">
                      {{ entity }}
                    </option>
                  </select>
                </div>

                <div class="form-group">
                  <label class="text-">{{ bannerable_entity.charAt(0).toUpperCase() + bannerable_entity.slice(1)
                    }}</label>
                  <multiselect v-model="bannerable_id" :options="bannerables" :searchable="true" label="name"
                    track-by="id" placeholder="Select a bannerable_id" :multiple="false" disabled></multiselect>
                </div>

                <div class="form-group">
                  <label> Image Upload </label>
                  <div class="fileinput fileinput-new" data-provides="fileinput">
                    <div class="fileinput-new thumbnail">
                      <img v-if="imagePreview" :src="imagePreview" alt="Preview"
                        style="max-width: 200px; margin-top: 10px;" />
                    </div>
                    <div class="fileinput-preview fileinput-exists thumbnail"></div>
                    <div class="user-edit-image-buttons">
                      <span class="btn btn-azure btn-file"><span class="fileinput-new"><i class="fa fa-picture"></i>
                          Select image</span><span class="fileinput-exists"><i class="fa fa-picture"></i> Change</span>
                        <input type="file" name="image" @change="handleMainImageChange" />
                      </span>
                      <a href="#" class="btn fileinput-exists btn-red" data-dismiss="fileinput">
                        <i class="fa fa-times"></i> Remove
                      </a>
                    </div>
                    <span class="error text-small block" v-if="errors.image">{{
                      errors.image }}</span>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="checkbox clip-check check-primary">
                    <input type="checkbox" v-model="banner" id="service1">
                    <label for="service1">
                      Show Banner
                    </label>
                  </div>
                </div>

              </div>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import Multiselect from "vue-multiselect";
export default {
  name: "EditBanner",
  components: {
    Multiselect
  },
  data() {
    return {
      bannerable_entity: "",
      bannerable_id: null,
      bannerables: [],
      image: null,
      banner: false,
      imagePreview: null,
      related: {}, // Related entity details
      errors: {},
    };
  },
  // watch: {
  //   bannerable_entity(newVal) {
  //     if (newVal) {
  //       this.fetchBannerables();
  //     } else {
  //       this.bannerables = [];
  //       this.bannerable_id = null;
  //     }
  //   }
  // },

  created() {
    this.fetchBanner();
  },
  methods: {
    async fetchBannerables() {
      if (!this.bannerable_entity) return;

      try {
        const response = await axios.get(`/admin/admin/${this.bannerable_entity}/all?web=1`);
        this.bannerables = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    handleMainImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.image = file;  // Update with the selected file
        // event.target.value = null; // Reset the file input

      }
    },

    async fetchBanner() {
      try {
        const response = await axios.get(
          "/admin/admin/offer/get?id=" + this.$route.params.id
        );
        const bannerData = response.data.data;
        console.log(bannerData);
        this.bannerable_entity = bannerData.bannerable_type.toLowerCase();
        this.banner = bannerData.banner === 1;
        if (bannerData.bannerable_id) {
          this.bannerable_id = {
            id: bannerData.bannerable_id,
            name: bannerData.related.name_en || bannerData.related.name_ar || bannerData.related.name, // Use the related entity's name
          };
        }

        this.imagePreview = bannerData.image; // Show the existing image
        if (bannerData.related) {
          this.related = bannerData.related;
        }
        // Store related entity details
        console.log(response.data.data);
      } catch (error) {
        console.error(error);
      }
    },
    async editBanner() {
      try {
        const formData = new FormData();
        formData.append("id", this.$route.params.id);
        // formData.append('bannerable_entity', this.bannerable_entity);
        // formData.append('bannerable_id', this.bannerable_id.id);
        // formData.append('bannerable_id', this.bannerable_id ? this.bannerable_id.id : "");
        formData.append("banner", this.banner ? 1 : 0);
        if (this.image) {
          formData.append('image', this.image);
        }

        const response = await axios.post(
          "admin/admin/offer/update",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response.data);
        console.log(formData);

        this.$router.push({ name: "all-banners" });
      } catch (error) {
        console.error(error);
        if (error.response && error.response.status === 422) {
          // Handle validation errors
          this.errors = error.response.data.errors;
          console.log("Validation errors:", this.errors);
        } else {
          console.error("Error creating doctor:", error);
        }
      }
    },
  },
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  width: 100%;
}

.error {
  color: #a94442;
  font-size: 0.875em;
}

form>.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}
</style>
