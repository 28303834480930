<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">All Specialities</h1>
          </div>
          <div class="col-sm-2">
            <router-link v-if="hasPermission('speciality create')" style="margin-right: 10px" class="btn btn-primary"
              :to="{ name: 'create-speciality' }">create</router-link>
            <router-link v-if="hasPermission('speciality getSoftDeleted')" class="btn btn-success"
              :to="{ name: 'deleted-specialities' }">Deleted
              Speciality</router-link>
          </div>


        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">

            <div v-if="specialities.length > 0">

              <MyTable :columns="columns" :actions="true">
                <tr v-for="(speciality, index) in filteredSpecialities" :key="index">
                  <th scope="row">{{ speciality.id }}</th>
                  <td>{{ speciality.name_ar }}</td>
                  <td>{{ speciality.name_en }}</td>
                  <td>
                    <button class="me-2 btn btn-danger" @click="deleteSpeciality(speciality.id)">
                      Delete
                    </button>
                    <router-link class="btn btn-primary spacing"
                      :to="{ name: 'edit-speciality', params: { id: speciality.id } }">
                      Edit
                    </router-link>
                  </td>
                </tr>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No specialities found.
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import $ from 'jquery'; // Import jQuery
import { store } from "../../Store";
import MyTable from "../MyTable//MyTable.vue"; // Import your MyTable component
import axios from "../../Axios";
export default {
  name: "AllSpecialities",
  components: {
    MyTable,
    // Paginate,
  },
  data() {
    return {
      dataTable: [],
      specialities: [],
      meta: {},
      searchTerm: "",
      columns: [
        { label: '#', key: 'id' },
        { label: 'Name Arabic', key: 'name_ar' },
        { label: 'Name English', key: 'name_en' },
      ],
    };
  },

  async created() {
    this.fetchSpecialities();
  },

  computed: {
    hasPermission() {
      return (permission) => store.hasPermission(permission);
    },
    filteredSpecialities() {
      if (!this.searchTerm) {
        return this.specialities;
      }
      const term = this.searchTerm.toLowerCase();
      return this.specialities.filter(
        (speciality) =>
          speciality.name.toLowerCase().includes(term) ||
          speciality.postal_code.toLowerCase().includes(term)
      );
    },
  },

  methods: {
    // fetch specialities
    async fetchSpecialities() {
      try {
        const response = await axios.get(
          `/admin/admin/speciality/all`
        );
        this.specialities = response.data.data;
        this.meta = response.data.meta;
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
      } catch (error) {
        console.error("Error fetching specialities:", error);
      }
    },
    initializeDataTable() {
      this.$nextTick(() => {
        // Ensure the DataTable is initialized only after the DOM is updated
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },
    deleteSpeciality(id) {
      console.log("delete speciality id: ", id);
      axios
        .delete(`/admin/admin/speciality/delete?id=${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchSpecialities();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

};


</script>
<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}
</style>