<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">All Roles</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'create-role' }">create</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <div v-if="roles.length > 0">
              <MyTable :columns="columns" :actions="true">
                <tr v-for="(role, index) in filteredRoles" :key="index">
                  <th scope="row">{{ role.id }}</th>
                  <td>{{ role.name }}</td>
                  <td>
                    <button class="me-2 btn btn-danger" @click="deleteRole(role.id)">
                      Delete
                    </button>
                    <router-link class="btn btn-primary spacing" :to="{ name: 'edit-role', params: { id: role.id } }">
                      Edit
                    </router-link>
                  </td>
                </tr>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No Roles found.
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import $ from 'jquery'; // Import jQuery
import { store } from "../../Store";
import MyTable from "../MyTable//MyTable.vue"; // Import your MyTable component
import axios from "../../Axios";
export default {
  name: "AllRoles",
  components: {
    MyTable,
    // Paginate,
  },
  data() {
    return {
      dataTable: [],
      roles: [],
      meta: {},
      searchTerm: "",
      columns: [
        { label: '#', key: 'id' },
        { label: 'Name', key: 'name' },
      ],
    };
  },

  async created() {
    this.fetchRoles();
  },

  computed: {
    hasPermission() {
      return (permission) => store.hasPermission(permission);
    },
    filteredRoles() {

      if (!this.searchTerm) {
        return this.roles;
      }
      const term = this.searchTerm.toLowerCase();
      return this.roles.filter(
        (role) =>
          role.name.toLowerCase().includes(term) ||
          role.postal_code.toLowerCase().includes(term)
      );
    },
  },

  methods: {
    async fetchRoles() {
      try {
        const token = localStorage.getItem("access_token");
        // console.log('before token', localStorage.getItem("access_token"))
        const response = await axios.get(`/admin/admin/role/all`, {
          headers: {
            'Authorization': `Bearer ${token}`, // Add the Authorization header
            'Accept': 'application/json'
          }
        });
        // console.log('after token', localStorage.getItem("access_token"))
        this.roles = response.data.data;
        this.meta = response.data.meta;
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    },
    initializeDataTable() {
      this.$nextTick(() => {
        // Ensure the DataTable is initialized only after the DOM is updated
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },
    deleteRole(id) {
      console.log("delete role id: ", id);
      axios
        .delete(`/admin/admin/role/delete?id=${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchRoles();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

};


</script>


<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}
</style>