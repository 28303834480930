<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Deleted Appointment Hours</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-appointmentHours' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <h5 class="over-title margin-bottom-15">
              <!-- Basic <span class="text-bold">Data Table</span> -->
            </h5>
            <p>

            </p>
            <div v-if="appointmentHours.length > 0">
              <MyTable :columns="columns" :actions="true">
                <tr v-for="(appointmentHour, index) in filteredAppointmentHours" :key="index">
                  <th scope="row">{{ appointmentHour.id }}</th>
                  <td>{{ appointmentHour.from }}</td>
                  <td>{{ appointmentHour.to }}</td>
                  <td>
                    <button class="me-2 btn btn-success" @click="restoreAppointmentHour(appointmentHour.id)">
                      Restore
                    </button>
                  </td>
                </tr>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No deleted appointments found.
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import $ from 'jquery'; // Import jQuery
import MyTable from "../MyTable//MyTable.vue"; // Import your MyTable component
import axios from "../../Axios";
export default {
  name: "DeletedAppointmentHours",
  components: {
    MyTable,
    // Paginate,
  },
  data() {
    return {
      dataTable: [],
      appointmentHours: [],
      meta: {},
      searchTerm: "",
      columns: [
        { label: '#', key: 'id' },
        { label: 'From', key: 'from' },
        { label: 'To', key: 'to' },
      ],
    };
  },

  async created() {
    this.fetchAppointmentHour();
  },

  computed: {
    filteredAppointmentHours() {
      if (!this.searchTerm) {
        return this.appointmentHours;
      }
      const term = this.searchTerm.toLowerCase();
      return this.appointmentHours.filter(
        (appointmentHour) =>
          appointmentHour.name.toLowerCase().includes(term) ||
          appointmentHour.postal_code.toLowerCase().includes(term)
      );
    },
  },

  methods: {
    // fetch AppointmentHour
    async fetchAppointmentHour() {
      try {
        const response = await axios.get(
          `/admin/admin/appointment-hour/get-delete-soft`
        );
        this.appointmentHours = response.data.data;
        this.meta = response.data.meta;
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
      } catch (error) {
        console.error("Error fetching appointmentHours:", error);
      }
    },
    initializeDataTable() {
      this.$nextTick(() => {
        // Ensure the DataTable is initialized only after the DOM is updated
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },

    restoreAppointmentHour(id) {
      console.log("restore order id: ", id);
      axios.post(`/admin/admin/appointment-hour/restore?id=${id}`)
        .then((response) => {
          console.log(response.data.data);
          this.fetchAppointmentHour();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

};
</script>
<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}
</style>