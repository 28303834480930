<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Deleted Hospitals / Clinics</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-infoServices' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">

            <div v-if="infoServices.length > 0">
              <MyTable :columns="columns" :actions="true">
                <tr v-for="(infoService, index) in infoServices" :key="index">
                  <th scope="row">{{ infoService.id }}</th>
                  <td>{{ infoService.name_ar }}</td>
                  <td>{{ infoService.name_en }}</td>
                  <td>{{ infoService.service_id ? infoService.service_id.name : '' }}</td>
                  <td>
                    <img :src="infoService.image" width="50" height="50" class="img-thumbnail" />
                  </td>
                  <td>
                    <button class="me-2 btn btn-success" @click="restoreInfoService(infoService.id)">
                      Restore
                    </button>

                  </td>
                </tr>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No deleted Hospitals/Clinics found.
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import $ from 'jquery'; // Import jQuery
import MyTable from "../MyTable//MyTable.vue"; // Import your MyTable component

export default {
  name: "DeletedInfoServices",
  components: {
    MyTable,
  },
  data() {
    return {
      dataTable: [],
      // dataTable: null,
      infoServices: [],
      meta: {},
      columns: [
        { label: '#', key: 'id' },
        { label: 'Name Arabic', key: 'name_ar' },
        { label: 'Name English', key: 'name_en' },
        { label: 'Main Service', key: 'name_en' },
        { label: 'Image', key: 'image' },
      ]
      // searchTerm: "",

    };
  },

  async created() {
    await this.fetchInfoServices();
  },





  methods: {
    async fetchInfoServices() {
      try {
        const response = await axios.get(
          '/admin/admin/info-service/get-delete-soft'
        );

        this.infoServices = response.data.data;
        this.meta = response.data.meta;
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
        console.log(this.infoServices);
      } catch (error) {
        console.error("Error fetching infoServices:", error);
      }
    },
    initializeDataTable() {
      this.$nextTick(() => {
        // Ensure the DataTable is initialized only after the DOM is updated
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },
    restoreInfoService(id) {
      console.log("restore status id: ", id);
      axios
        .post(`/admin/admin/info-service/restore?id=${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchInfoServices();
        })
        .catch((error) => {
          console.error(error);
        });
    },

  },
};
</script>

<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}
</style>