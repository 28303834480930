<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Create Pre-Operation</h1>
            <!-- <span class="mainDescription"
              >Use this page to start from scratch and put your custom
              content</span
            > -->
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-preOperations' }">Back</router-link>
          </div>
          <!-- <ol class="breadcrumb">
            <li class="active">
              <span>Blank Page</span>
            </li>
          </ol> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="createPreOPeration" enctype="multipart/form-data">
              <div class="row">
                <div class="row">
                  <div class="form-group col-md-12 mb-2">
                    <label class="text-">Hospital</label>
                    <multiselect v-model="info_service_id" :options="infoServices" :searchable="true" label="name"
                      track-by="id" placeholder="Select a Hospital" :multiple="false">
                    </multiselect>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Select operation</label>
                      <multiselect v-model="operation_id" :options="operations" :multiple="false" :searchable="true"
                        label="name_en" track-by="id" placeholder="Select operation"></multiselect>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="text-">Pre-Surgery English</label>
                      <input type="text" class="form-control" placeholder="Enter  Pre-Surgery Notes"
                        v-model="pre_surgery_en" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="specialityName">Pre-Surgery Arabic</label>
                      <input type="text" class="form-control" placeholder="Enter Pre-Surgery Notes"
                        v-model="pre_surgery_ar" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="text-">Medications English</label>
                      <input type="text" class="form-control" placeholder="Enter English Medications"
                        v-model="medication_en" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="specialityName">Medications Arabic</label>
                      <input type="text" class="form-control" placeholder="Enter Arabic Medications"
                        v-model="medication_ar" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="text-">Fasting English</label>
                      <input type="text" class="form-control" placeholder="Enter English Fasting"
                        v-model="fasting_en" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="specialityName">Fasting Arabic</label>
                      <input type="text" class="form-control" placeholder="Enter Arabic Fasting" v-model="fasting_ar" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="text-">Hygeine English</label>
                      <input type="text" class="form-control" placeholder="Enter English Hygeine"
                        v-model="hygeine_en" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="specialityName">Hygeine Arabic</label>
                      <input type="text" class="form-control" placeholder="Enter Arabic Hygeine" v-model="hygeine_ar" />
                    </div>
                  </div>

                </div>
              </div>


              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>
<script>
import axios from "../../Axios";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
export default {
  name: "CreateOperation",
  components: {
    Multiselect,
  },
  data() {
    return {
      info_service_id: "",
      operation_id: "",
      pre_surgery_en: "",
      pre_surgery_ar: "",
      medication_en: "",
      medication_ar: "",
      fasting_en: "",
      fasting_ar: "",
      hygeine_en: "",
      hygeine_ar: "",
      infoServices: [],
      operations: [],

    };
  },

  async created() {
    this.fetchInfoservices();
    this.fetchOperations();
    this.fetchPreOperations();
  },
  methods: {
    async fetchPreOperations() {
      try {
        // const response = await axios.get(`/admin/admin/info-service/all?service_id=${this.service_id}`);
        // const response = await axios.get(`/admin/admin/info-service/all?service_id=1`);
        const response = await axios.get(`/admin/admin/pre-operation/get?id=` + this.$route.params.id);
        const preOperation = response.data.data;
        console.log(preOperation);
        this.info_service_id = preOperation.info_service.info_service,
          this.operation_id = preOperation.operation,
          this.pre_surgery_en = preOperation.pre_surgery_en,
          this.pre_surgery_ar = preOperation.pre_surgery_ar,
          this.medication_en = preOperation.medication_en,
          this.medication_ar = preOperation.medication_ar,
          this.fasting_en = preOperation.fasting_en,
          this.fasting_ar = preOperation.fasting_ar,
          this.hygeine_en = preOperation.hygeine_en,
          this.hygeine_ar = preOperation.hygeine_ar,
          this.meta = response.data.meta;
        console.log(this.infoServices)
      } catch (error) {
        console.error("Error fetching preoperations:", error);
      }
    },
    async createPreOPeration() {
      try {
        // Prepare form data for upload
        const formData = new FormData();
        formData.append('pre_surgery_en', this.pre_surgery_en);
        formData.append('pre_surgery_ar', this.pre_surgery_ar);
        formData.append('medication_en', this.medication_en);
        formData.append('medication_ar', this.medication_ar);
        formData.append('fasting_en', this.fasting_en);
        formData.append('fasting_ar', this.fasting_ar);
        formData.append('hygeine_en', this.hygeine_en);
        formData.append('hygeine_ar', this.hygeine_ar);
        formData.append('info_service_id', this.info_service_id.id);
        formData.append('operation_id', this.operation_id.id);

        console.log(formData);

        const response = await axios.post("admin/admin/pre-operation/create", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log(response.data);
        this.$router.push({ name: "all-preOperations" });
      } catch (error) {
        console.error(error);
      }

    },

    async fetchInfoservices() {
      try {
        // const response = await axios.get(`/admin/admin/info-service/all?service_id=${this.service_id}`);
        // const response = await axios.get(`/admin/admin/info-service/all?service_id=1`);
        const response = await axios.get(`/admin/admin/info-service/all?service_id=1`);
        this.infoServices = response.data.data;
        this.meta = response.data.meta;
        console.log(this.infoServices)
      } catch (error) {
        console.error("Error fetching infoServices:", error);
      }
    },
    async fetchOperations() {
      try {
        const response = await axios.get(`/admin/admin/operation/all`);
        this.operations = response.data.data;
        console.log(this.operations)

        this.meta = response.data.meta;
      } catch (error) {
        console.error("Error fetching operation:", error);
      }
    }

  }
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  width: 100%;
  /* max-width: 500px; Optional: Max width for better responsiveness */
}

form>.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}
</style>
