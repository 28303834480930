<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Create Category</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-categories' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="createCategory" enctype="multipart/form-data">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">English Name</label>
                    <input type="text" class="form-control" placeholder="Enter English Name" v-model="name_en" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">Arabic Name</label>
                    <input type="text" class="form-control" placeholder="Enter Arabic Name" v-model="name_ar" />
                  </div>
                </div>
                <div class="form-group">
                  <label>
                    Image Upload
                  </label>
                  <div class="fileinput fileinput-new" data-provides="fileinput">
                    <div class="fileinput-new thumbnail"><img src="/assets/images/avatar-1-xl.jpg" alt="">
                    </div>
                    <div class="fileinput-preview fileinput-exists thumbnail"></div>
                    <div class="user-edit-image-buttons">
                      <span class="btn btn-azure btn-file"><span class="fileinput-new"><i class="fa fa-picture"></i>
                          Select image</span><span class="fileinput-exists"><i class="fa fa-picture"></i> Change</span>
                        <input type="file" name="main_image" @change="handleFileChange">
                      </span>
                      <a href="#" class="btn fileinput-exists btn-red" data-dismiss="fileinput">
                        <i class="fa fa-times"></i> Remove
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";

export default {
  name: "CreateCategory",
  data() {
    return {
      name_en: "",
      name_ar: "",
      main_image: "",
      imagePreviewUrl: "",
    };
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.main_image = file;
        this.imagePreviewUrl = URL.createObjectURL(file);
        console.log("Selected file:", file); // Debugging line
      }
    },
    removeImage() {
      this.main_image = null;
      this.imagePreviewUrl = "";
    },

    // handleFileChange(event) {
    //   const file = event.target.files[0];
    //   if (file) {
    //     this.main_image = file;
    //     this.imagePreviewUrl = URL.createObjectURL(file);
    //   }
    // },
    // removeImage() {
    //   this.main_image = null;
    //   this.imagePreviewUrl = "";
    // },
    async createCategory() {
      try {
        const formData = new FormData();
        formData.append('name_en', this.name_en);
        formData.append('name_ar', this.name_ar);
        if (this.main_image) {
          formData.append('main_image', this.main_image);
        }

        const response = await axios.post("/admin/admin/category/create", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log(response.data);
        this.$router.push({ name: "all-categories" });
      } catch (error) {
        console.error("Error creating category:", error);
      }
    },
  }
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
}

form>.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}

.fileinput-preview {
  max-width: 200px;
  max-height: 200px;
}

.user-edit-image-buttons {
  margin-top: 1rem;
}
</style>
