<template>
    <div class="error-page">
        <h1>500</h1>
        <p>Oops! Something went wrong on our end. Please try again later.</p>
        <router-link to="/">Go to Home</router-link>
    </div>
</template>

<style scoped>
.error-page {
    text-align: center;
    margin-top: 100px;
}

h1 {
    font-size: 100px;
    color: #ccc;
}
</style>