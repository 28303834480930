<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Deleted Labs / Scans</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-labs' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">

            <div v-if="labs.length > 0">
              <MyTable :columns="columns" :actions="true">
                <tr v-for="(lab, index) in labs" :key="index">
                  <th scope="row">{{ lab.id }}</th>
                  <td>{{ lab.name_ar }}</td>
                  <td>{{ lab.name_en }}</td>
                  <td>{{ lab.service.name ?? '' }}</td>
                  <!-- <td>{{ lab.service_id ? lab.service_id.name : '' }}</td> -->
                  <td>
                    <img :src="`${lab.main_image}`" width="50" height="50" class="img-thumbnail" />
                  </td>
                  <td>
                    <button class="me-2 btn btn-success" @click="restoreLab(lab.id)">
                      Restore
                    </button>

                  </td>
                </tr>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No deleted labs found.
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import $ from 'jquery'; // Import jQuery
import MyTable from "../MyTable//MyTable.vue"; // Import your MyTable component

export default {
  name: "DeletedLabs",
  components: {
    MyTable,
    // Paginate,
  },
  data() {
    return {
      dataTable: [], // Store DataTable instance
      labs: [],
      meta: {},
      searchTerm: "",
      columns: [
        { label: '#', key: 'id' },
        { label: 'Name Arabic', key: 'name_ar' },
        { label: 'Name English', key: 'name_en' },
        { label: 'Main Service', key: 'service_id' },
        { label: 'Image', key: 'main_image' },
      ],
    };
  },

  async created() {
    this.fetchLabs();
  },

  computed: {
    filteredLabs() {
      if (!this.searchTerm) {
        return this.labs;
      }
      const term = this.searchTerm.toLowerCase();
      return this.labs.filter(
        (lab) =>
          lab.name.toLowerCase().includes(term) ||
          lab.postal_code.toLowerCase().includes(term)
      );
    },
  },

  methods: {
    // fetch labs
    async fetchLabs() {
      try {
        const response = await axios.get(
          `/admin/admin/lab/get-delete-soft`
        );
        this.labs = response.data.data;
        console.log(" fetching labs:", this.labs);
        this.meta = response.data.meta;
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
      } catch (error) {
        console.log("Error fetching labs:", error);
      }
    },
    initializeDataTable() {
      this.$nextTick(() => {
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },
    restoreLab(id) {
      console.log("restore Labs id: ", id);
      axios
        .post(`/admin/admin/lab/restore?id=${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchLabs();
        })
        .catch((error) => {
          console.error(error);
        });
    },

  },

};
</script>
<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}
</style>