<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- Page Title -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Edit Pharmacy</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-pharmacies' }">Back</router-link>
          </div>
        </div>
      </section>

      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="editPharmacy" enctype="multipart/form-data">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">English Name</label>
                    <input type="text" class="form-control" placeholder="Enter English Name" v-model="name_en" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="labName">Arabic Name</label>
                    <input type="text" class="form-control" placeholder="Enter Arabic Name" v-model="name_ar" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">English Title</label>
                    <input type="text" class="form-control" placeholder="Enter English Title" v-model="title_en" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="labTitle">Arabic Title</label>
                    <input type="text" class="form-control" placeholder="Enter Arabic Title" v-model="title_ar" />
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="labName">English Description </label>
                    <textarea rows="3" class="form-control" placeholder="Enter English Description"
                      v-model="description_en"></textarea>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="labName">Arabic Description </label>
                    <textarea rows="3" class="form-control" placeholder="Enter Arabic Description"
                      v-model="description_ar"></textarea>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="pharmacyName">English Address</label>
                    <input type="text" class="form-control" placeholder="Enter address" v-model="address_en" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="pharmacyName">Arabic Address</label>
                    <input type="text" class="form-control" placeholder="Enter address" v-model="address_ar" />
                  </div>
                </div>
                <GoogleMap :coords="{ lat: lat, lng: long }" @placeChanged="handlePlaceChanged" />
                <div class="form-group">
                  <label>Latitude</label>
                  <input type="text" class="form-control" placeholder="Enter Latitude" v-model="lat" />
                </div>

                <div class="form-group">
                  <label>Longitude</label>
                  <input type="text" class="form-control" placeholder="Enter Longitude" v-model="long" />
                </div>

                <div class="form-group col-4">
                  <label>from</label>
                  <input type="time" class="form-control" placeholder="Enter Time" v-model="from" />
                </div>
                <div class="form-group col-4">
                  <label>to</label>
                  <input type="time" class="form-control" placeholder="Enter Time" v-model="to" />
                </div>

                <div class="form-group col-4">
                  <label>Experience</label>
                  <input type="number" class="form-control" placeholder="Enter Experience" v-model="experience" />
                </div>
                <div class="form-group col-md-3">
                  <div class="checkbox clip-check check-primary">
                    <input type="checkbox" v-model="pickup" id="service1">
                    <label for="service1">
                      Pickup
                    </label>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <div class="checkbox clip-check check-primary">
                    <input type="checkbox" v-model="delivery" id="service2">
                    <label for="service2">
                      Delivery
                    </label>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label class="text-">Delivery Fees</label>
                  <input type="number" class="form-control" placeholder="Enter Delivery Fees" v-model="delivery_fees" />
                </div>
                <div class="form-group col-md-3">
                  <label class="text-">Delivery Time</label>
                  <input type="number" class="form-control" placeholder="Enter Delivery Time" v-model="delivery_time" />
                </div>
                <div class="form-group col-5">
                  <label>Phone</label>
                  <input type="text" class="form-control" placeholder="Enter Phone" v-model="phone" />
                </div>
                <div class="form-group col-5">
                  <label>Url</label>
                  <input type="text" class="form-control" placeholder="Enter Url" v-model="url" />
                </div>
                <!-- Image Upload -->
                <div class="form-group">
                  <label>Main Image</label>
                  <div class="fileinput fileinput-new" data-provides="fileinput">
                    <div class="fileinput-new thumbnail">
                      <img :src="mainImageUrl" alt="Main Image" />
                    </div>
                    <div class="fileinput-preview fileinput-exists thumbnail"></div>
                    <div class="user-edit-image-buttons">
                      <span class="btn btn-azure btn-file"><span class="fileinput-new"><i class="fa fa-picture"></i>
                          Select image</span><span class="fileinput-exists"><i class="fa fa-picture"></i> Change</span>
                        <input type="file" name="main_image" @change="handleMainImageChange" />
                      </span>
                      <a href="#" class="btn fileinput-exists btn-red" data-dismiss="fileinput">
                        <i class="fa fa-times"></i> Remove
                      </a>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Background Image</label>
                  <div class="fileinput fileinput-new" data-provides="fileinput">
                    <div class="fileinput-new thumbnail">
                      <img :src="backgroundImageUrl" alt="Background Image" />
                    </div>
                    <div class="fileinput-preview fileinput-exists thumbnail"></div>
                    <div class="user-edit-image-buttons">
                      <span class="btn btn-azure btn-file"><span class="fileinput-new"><i class="fa fa-picture"></i>
                          Select background_image</span><span class="fileinput-exists"><i class="fa fa-picture"></i>
                          Change</span>
                        <input type="file" name="background_image" @change="handleBackgroundImageChange" />
                      </span>
                      <a href="#" class="btn fileinput-exists btn-red" data-dismiss="fileinput">
                        <i class="fa fa-times"></i> Remove
                      </a>
                    </div>
                  </div>
                </div>
                <!-- Additional Images -->
                <div class="form-group">
                  <label>Additional Images</label>
                  <div class="multiple-image-upload">
                    <div class="upload-container">
                      <input type="file" class="form-control" accept="image/*" multiple @change="handleMultipleImages"
                        ref="multipleImages" />
                    </div>

                    <!-- Preview section -->
                    <div v-if="imagePreviewUrls.length" class="image-preview-container">
                      <h5>Selected Images:</h5>
                      <div class="row g-3">
                        <div v-for="(preview, index) in imagePreviewUrls" :key="index" class="col-md-3 col-sm-4 col-6">
                          <div class="image-preview-wrapper">
                            <img :src="preview" alt="Preview" class="img-thumbnail preview-image"
                              @click="removeImage(index)" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group ">
                  <label class="text-">District</label>
                  <multiselect v-model="selectedDistrict" :options="districts" :searchable="true" label="name"
                    track-by="id" placeholder="Select a District" :multiple="false"></multiselect>
                </div>
                <div class="form-group">
                  <label class="text-">Medical Insurances</label>
                  <multiselect v-model="selectedMedicalInsurance" :options="medicalInsurances" :searchable="true"
                    label="name_en" track-by="id" placeholder="Select Insurances" :multiple="true"></multiselect>
                </div>

              </div>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import GoogleMap from "../Component/GoogleMap.vue";
import Multiselect from "vue-multiselect";

export default {
  name: "editPharmacy",
  components: {
    Multiselect,
    GoogleMap,
  },
  data() {
    return {
      name_en: "",
      name_ar: "",
      description_en: "",
      description_ar: "",
      title_ar: "",
      title_en: "",
      experience: "",
      from: "",
      to: "",
      address: "",
      phone: "",
      url: "",
      pickup: false,
      delivery: false,
      address_ar: "",
      address_en: "",
      delivery_fees: "",
      delivery_time: "",
      lat: 30.0444, // Lat and long for input fields
      long: 31.2357, // long and long for input fields
      service_id: 24,
      user_id: "",
      districts: [],
      selectedDistrict: "",

      medicalInsurances: [],
      selectedMedicalInsurance: [],

      main_image: null,
      background_image: null,
      mainImageUrl: "",
      backgroundImageUrl: "",

      additionalImages: [],
      imagePreviewUrls: [],

    };
  },
  created() {
    this.fetchPharmacy();
    this.fetchDistrict();
    this.fetchMedicalInsurances();
  },
  methods: {
    handlePlaceChanged(place) {
      this.lat = place.lat; // Update latitude
      this.long = place.lng; // Update longitude
    },
    async fetchPharmacy() {
      try {
        const response = await axios.get(
          "/admin/admin/pharmacy/get?id=" + this.$route.params.id
        );
        const pharmacy = response.data.data;

        this.name_en = pharmacy.name_en;
        this.name_ar = pharmacy.name_ar;
        this.description_en = pharmacy.description_en;
        this.description_ar = pharmacy.description_ar;
        this.title_ar = pharmacy.title_ar;
        this.title_en = pharmacy.title_en;
        this.experience = pharmacy.experience;
        this.from = pharmacy.from;
        this.to = pharmacy.to;
        this.address_en = pharmacy.address_en;
        this.address_ar = pharmacy.address_ar;
        this.delivery_fees = pharmacy.delivery_fees;
        this.delivery_time = pharmacy.delivery_time;
        this.phone = pharmacy.phone;
        this.url = pharmacy.url;
        this.pickup = pharmacy.pickup == 1;
        this.delivery = pharmacy.delivery == 1;
        this.lat = pharmacy.latitude;
        this.long = pharmacy.longitude;
        this.selectedDistrict = pharmacy.district;
        this.mainImageUrl = pharmacy.main_image;
        this.backgroundImageUrl = pharmacy.background_image;
        this.selectedMedicalInsurance = pharmacy.medical_insurance;
        this.mapCenter = { lat: pharmacy.latitude, lng: pharmacy.latitude };

        if (pharmacy.images) {
          pharmacy.images.forEach((image) => {
            this.imagePreviewUrls.push(image.image);
          });
        }

        console.log(response.data.data);
      } catch (error) {
        console.error('Error fetching pharmacy data:', error);
      }

    },
    handleMainImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.main_image = file;
      }
    },
    handleBackgroundImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.background_image = file;
      }
    },
    handleMultipleImages(event) {
      const files = Array.from(event.target.files);
      files.forEach(file => {
        if (file.type.startsWith('image/')) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imagePreviewUrls.push(e.target.result);
          };
          reader.readAsDataURL(file);
          this.additionalImages.push(file);
        }
      });
      event.target.value = '';
    },
    removeImage(index) {
      this.additionalImages.splice(index, 1);
      this.imagePreviewUrls.splice(index, 1);
    },
    getDistrictId() {
      return this.selectedDistrict.id;
    },
    async fetchDistrict() {
      try {
        const response = await axios.get("/admin/admin/district/all");
        this.districts = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchMedicalInsurances() {
      try {
        const response = await axios.get("/admin/admin/medical-insurance/all");
        this.medicalInsurances = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async editPharmacy() {
      try {
        const district_id = this.getDistrictId();
        const formData = new FormData();
        formData.append("id", this.$route.params.id);
        formData.append("name_en", this.name_en);
        formData.append("name_ar", this.name_ar);
        formData.append("description_en", this.description_en);
        formData.append("description_ar", this.description_ar);
        formData.append("latitude", this.lat);
        formData.append("longitude", this.long);
        // formData.append("address", this.address);
        formData.append("address_en", this.address_en);
        formData.append("address_ar", this.address_ar);
        formData.append("delivery_fees", Number(this.delivery_fees));
        formData.append("delivery_time", Number(this.delivery_time));
        formData.append("experience", this.experience);
        formData.append("from", this.from);
        formData.append("to", this.to);
        formData.append("phone", this.phone);
        formData.append("url", this.url);
        formData.append("pickup", this.pickup ? 1 : 0);
        formData.append("delivery", this.delivery ? 1 : 0);
        formData.append("title_ar", this.title_ar);
        formData.append("title_en", this.title_en);
        formData.append("service_id", 24);
        formData.append("district_id", district_id);

        this.selectedMedicalInsurance.forEach((insurance, index) => {
          formData.append(`medical_insurance[${index}]`, insurance.id);
        });
        if (this.main_image) {
          formData.append("main_image", this.main_image);
        }
        if (this.background_image) {
          formData.append("background_image", this.background_image);
        }
        this.additionalImages.forEach((image) => {
          formData.append(`new_images[]`, image);
        });

        const response = await axios.post(
          "admin/admin/pharmacy/update",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(formData);
        console.log('Response:', response.data);
        this.$router.push({ name: "all-pharmacies" });
      } catch (error) {
        console.error('Error updating pharmacy:', error);
      }
    },
  },
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  width: 100%;
  /* max-width: 500px; Optional: Max width for better responsiveness */
}

form>.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}

.multiple-image-upload {
  margin-bottom: 1.5rem;
}

.image-preview-container {
  margin-top: 1rem;
}

.image-preview-wrapper {
  position: relative;
  margin-bottom: 1rem;
}

.preview-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.remove-image {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 2px 8px;
  font-size: 12px;
}

.upload-container {
  margin-bottom: 1rem;
}
</style>