<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Create OutPatient Clinic</h1>

            <!-- <span class="mainDescription"
              >Use this page to start from scratch and put your custom
              content</span
            > -->
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-externalClinics' }">Back</router-link>
          </div>
          <!-- <ol class="breadcrumb">
            <li class="active">
              <span>Blank Page</span>
            </li>
          </ol> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="createExternalClinic" enctype="multipart/form-data">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">English Name</label>
                    <input type="text" class="form-control" placeholder="Enter English Name" v-model="name_en" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="specialityName">Arabic Name</label>
                    <input type="text" class="form-control" placeholder="Enter Arabic Name" v-model="name_ar" />
                  </div>
                </div>

                <div class="form-group">
                  <button class="btn btn-success add-variant mb-3" @click="addVariant" type="button">
                    Add Variant
                  </button>
                  <div class="row">
                    <div class="col-md-6" v-for="(variant, index) in variants" :key="index">
                      <div class="card mb-3">
                        <div class="card-body">
                          <h5 class="card-title">Variant {{ index + 1 }}</h5>

                          <div class="form-group">
                            <label class="text-">Infomation Service</label>
                            <multiselect v-model="variant.info_service_id" :options="infoServices" :searchable="true"
                              label="name" track-by="id" placeholder="Select a Infomation Service" :multiple="false">
                            </multiselect>
                          </div>
                          <div class="form-group">
                            <label class="text-">English Name</label>
                            <input type="text" class="form-control" placeholder="Enter English Name"
                              v-model="variant.name_en" />
                          </div>
                          <div class="form-group">
                            <label for="specialityName">Arabic Name</label>
                            <input type="text" class="form-control" placeholder="Enter Arabic Name"
                              v-model="variant.name_ar" />
                          </div>
                          <div class="form-group">
                            <label for="labName">English Description </label>
                            <textarea rows="3" class="form-control" placeholder="Enter English Description"
                              v-model="variant.description_en"></textarea>
                          </div>

                          <div class="form-group">
                            <label for="labName">Arabic Description </label>
                            <textarea rows="3" class="form-control" placeholder="Enter Arabic Description"
                              v-model="variant.description_ar"></textarea>
                          </div>
                          <div class="form-group">
                            <label class="text-">price</label>
                            <input type="number" class="form-control" placeholder="Enter price"
                              v-model="variant.price" />
                          </div>
                          <div class="form-group ">
                            <label class="text-">District</label>
                            <multiselect v-model="variant.district_id" :options="districts" :searchable="true"
                              label="name" track-by="id" placeholder="Select a District" :multiple="false">
                            </multiselect>
                          </div>
                          <div class="form-group">
                            <label for="labName">Address</label>
                            <input type="text" class="form-control" placeholder="Enter address"
                              v-model="variant.address" />
                          </div>
                          <GoogleMap :coords="{ lat: variant.lat, lng: variant.long }"
                            @placeChanged="handlePlaceChanged" />
                          <div class="form-group">
                            <label>Latitude</label>
                            <input type="text" class="form-control" placeholder="Enter Latitude"
                              v-model="variant.lat" />
                          </div>

                          <div class="form-group">
                            <label>Longitude</label>
                            <input type="text" class="form-control" placeholder="Enter Longitude"
                              v-model="variant.long" />
                          </div>
                          <div class="form-group">
                            <label> Image Upload </label>
                            <div class="fileinput fileinput-new" data-provides="fileinput">
                              <div class="fileinput-new thumbnail">
                                <img src="/assets/images/avatar-1-xl.jpg" alt="" />
                              </div>
                              <div class="fileinput-preview fileinput-exists thumbnail"></div>
                              <div class="user-edit-image-buttons">
                                <span class="btn btn-azure btn-file"><span class="fileinput-new"><i
                                      class="fa fa-picture"></i>
                                    Select image</span><span class="fileinput-exists"><i class="fa fa-picture"></i>
                                    Change</span>
                                  <input type="file" name="main_image" @change="handleMainImageChange" />
                                </span>
                                <a href="#" class="btn fileinput-exists btn-red" data-dismiss="fileinput">
                                  <i class="fa fa-times"></i> Remove
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="form-group ">
                            <label class="text-">User</label>
                            <multiselect v-model="variant.user_id" :options="users" :searchable="true" label="name"
                              track-by="id" placeholder="Select a District" :multiple="false">
                            </multiselect>
                          </div>
                          <button class="btn btn-danger mt-3" @click="removeVariant(index)">
                            Remove Variant
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import GoogleMap from "../Component/GoogleMap.vue";
import Multiselect from "vue-multiselect";
export default {
  name: "CreateExternalClinic",
  components: {
    GoogleMap,
    Multiselect,
  },
  data() {
    return {
      name_en: "",
      name_ar: "",

      service_id: 6,

      districts: [],
      users: [],
      infoServices: [],
      variants: [],
    };
  },
  async created() {
    this.fetchInfoservices();
    this.fetchDistricts();
    this.fetchUsers();
  },
  methods: {

    handlePlaceChanged({ lat, lng }) {

      this.variants.lat = lat;
      this.variants.long = lng;
    },
    handleMainImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.main_image = file;
      }
      // event.target.value = null;
    },
    async createExternalClinic() {
      try {
        const formData = new FormData();
        formData.append('name_en', this.name_en);
        formData.append('name_ar', this.name_ar);
        formData.append("service_id", this.service_id);
        // formData.append("price", Number(this.price)); // Ensure price is a number
        this.variants.forEach((variant, index) => {
          formData.append(`info_services[${index}][info_service_id]`, variant.info_service_id.id);
          formData.append(`info_services[${index}][name_en]`, variant.name_en);
          formData.append(`info_services[${index}][name_ar]`, variant.name_ar);
          formData.append(`info_services[${index}][description_ar]`, variant.description_ar);
          formData.append(`info_services[${index}][description_en]`, variant.description_en);
          formData.append(`info_services[${index}][price]`, Number(variant.price));
          formData.append(`info_services[${index}][district_id]`, variant.district_id.id);
          formData.append(`info_services[${index}][address]`, variant.address);
          formData.append(`info_services[${index}][lat]`, variant.lat);
          formData.append(`info_services[${index}][long]`, variant.long);
          if (variant.image) {
            formData.append(`info_services[${index}][image]`, variant.image);
          }
          formData.append(`info_services[${index}][user_id]`, variant.user_id);
        });
        console.log(formData);
        const response = await axios.post("admin/admin/external-clinic/create", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log(response.data);
        this.$router.push({ name: "all-externalClinics" });
      } catch (error) {
        console.error(error);
      }
    },
    addVariant() {
      this.variants.push({
        info_service_id: "",
        name_en: "",
        name_ar: "",
        description_en: "",
        description_ar: "",
        price: "",
        district_id: "",
        address: "",
        lat: 30.0444, // Lat and long for input fields
        long: 31.2357, // long and long for input fields
        main_image: null,
        user_id: "",

      });
    },
    removeVariant(index) {
      this.variants.splice(index, 1);
    },

    async fetchInfoservices() {
      try {
        const response = await axios.get(`/admin/admin/info-service/all`);
        this.infoServices = response.data.data;
        this.meta = response.data.meta;
      } catch (error) {
        console.error("Error fetching infoServices:", error);
      }
    },
    async fetchDistricts() {
      try {
        const response = await axios.get(`/admin/admin/district/all`);
        this.districts = response.data.data;
        this.meta = response.data.meta;
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    },
    async fetchUsers() {
      try {
        const response = await axios.get(`/admin/admin/user/all`);
        this.users = response.data.data;
        this.meta = response.data.meta;
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    }
  },
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  width: 100%;
  /* max-width: 500px; Optional: Max width for better responsiveness */
}

form>.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}
</style>
