<template>
  <table class="table table-striped table-bordered table-hover table-full-width sample_1">
    <!-- Table content will be filled in from AllServices.vue -->
    <thead>
      <tr>
        <th v-for="(column, index) in columns" :key="index">{{ column.label }}</th>
        <th v-if="actions">Actions</th>
      </tr>
    </thead>
    <tbody>
      <slot></slot> <!-- Use slot to allow dynamic content -->
    </tbody>
  </table>
</template>

<script>
// import $ from 'jquery';

export default {
  name: "MyTable",
  props: {
    columns: {
      type: Array,
      required: true,
    },
    actions: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.dt-layout-row {
  display: block;
  width: 100%;
}

.dt-layout-cell {
  width: auto;
}

.dt-layout-cell.dt-layout-start {
  float: left;
}

.dt-layout-cell.dt-layout-end {
  float: right;
}

/* Clear float after layout rows */
.dt-layout-row::after {
  content: "";
  display: table;
  clear: both;
}


/* Entries Length Dropdown Styling */
.dt-length {
  display: flex;
  align-items: center;
}

.dt-length select {
  padding: 5px 10px;
  margin-right: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
  transition: border-color 0.3s ease;
}

.dt-length select:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.dt-length label {
  font-size: 12px;
  color: #666;
  margin-left: 5px;
}

/* Search Input Styling */
.dt-search {
  display: flex;
  align-items: center;
}

.dt-search label {
  margin-right: 10px;
  font-size: 12px;
  color: #666;
}

.dt-search input {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
  width: 200px;
  transition: all 0.3s ease;
}

.dt-search input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

/* Pagination Styling */
.dt-paging {
  display: flex;
  align-items: center;
}

.dt-paging-button {
  margin: 0 5px;
  padding: 6px 12px;
  border: 1px solid #ddd;
  background-color: #fff;
  color: #007bff;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.dt-paging-button:hover:not(.disabled) {
  background-color: #f8f9fa;
  border-color: #007bff;
}

.dt-paging-button.current {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.dt-paging-button.disabled {
  color: #6c757d;
  cursor: not-allowed;
  opacity: 0.65;
}

.dt-paging .ellipsis {
  margin: 0 10px;
  color: #6c757d;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .dt-layout-row {
    flex-direction: column;
    align-items: stretch;
  }

  .dt-length,
  .dt-search {
    margin-bottom: 10px;
  }

  .dt-search input {
    width: 100%;
  }

  .dt-paging-button {
    padding: 6px 8px;
  }
}

/* Info Text Styling */
.dt-info {
  font-size: 12px;
  color: #666;
}
</style>
