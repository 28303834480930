<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Create Hospital / Clinic</h1>

            <!-- <span class="mainDescription"
              >Use this page to start from scratch and put your custom
              content</span
            > -->
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-infoServices' }">Back</router-link>
          </div>
          <!-- <ol class="breadcrumb">
            <li class="active">
              <span>Blank Page</span>
            </li>
          </ol> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="createInfoService" enctype="multipart/form-data">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">English Name</label>
                    <input type="text" class="form-control" placeholder="Enter English Name" v-model="name_en" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="infoServiceName">Arabic Name</label>
                    <input type="text" class="form-control" placeholder="Enter Arabic Name" v-model="name_ar" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="infoServiceName">HotLine</label>
                    <input type="text" class="form-control" placeholder="Enter HotLine No" v-model="info_service_no" />
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="infoServiceName">English Description </label>
                    <textarea rows="3" class="form-control" placeholder="Enter English Description"
                      v-model="description_en"></textarea>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="infoServiceName">Arabic Description </label>
                    <textarea rows="3" class="form-control" placeholder="Enter Arabic Description"
                      v-model="description_ar"></textarea>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="infoServiceName">Address</label>
                    <input type="text" class="form-control" placeholder="Enter address" v-model="address" />
                  </div>
                </div>
                <GoogleMap :coords="{ lat: lat, lng: long }" @placeChanged="handlePlaceChanged" />
                <div class="form-group">
                  <label>Latitude</label>
                  <input type="text" class="form-control" placeholder="Enter Latitude" v-model="lat" />
                </div>

                <div class="form-group">
                  <label>Longitude</label>
                  <input type="text" class="form-control" placeholder="Enter Longitude" v-model="long" />
                </div>
                <div class="form-group">
                  <label> Image Upload </label>
                  <div class="fileinput fileinput-new" data-provides="fileinput">
                    <div class="fileinput-new thumbnail">
                      <img src="/assets/images/avatar-1-xl.jpg" alt="" />
                    </div>
                    <div class="fileinput-preview fileinput-exists thumbnail"></div>
                    <div class="user-edit-image-buttons">
                      <span class="btn btn-azure btn-file"><span class="fileinput-new"><i class="fa fa-picture"></i>
                          Select image</span><span class="fileinput-exists"><i class="fa fa-picture"></i> Change</span>
                        <input type="file" name="image" @change="handleImageChange" />
                      </span>
                      <a href="#" class="btn fileinput-exists btn-red" data-dismiss="fileinput">
                        <i class="fa fa-times"></i> Remove
                      </a>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label> Backgroud Image Upload </label>
                  <div class="fileinput fileinput-new" data-provides="fileinput">
                    <div class="fileinput-new thumbnail">
                      <img src="/assets/images/avatar-1-xl.jpg" alt="" />
                    </div>
                    <div class="fileinput-preview fileinput-exists thumbnail"></div>
                    <div class="user-edit-image-buttons">
                      <span class="btn btn-azure btn-file"><span class="fileinput-new"><i class="fa fa-picture"></i>
                          Select background_image</span><span class="fileinput-exists"><i class="fa fa-picture"></i>
                          Change</span>
                        <input type="file" name="background_image" @change="handleBackgroundImageChange" />
                      </span>
                      <a href="#" class="btn fileinput-exists btn-red" data-dismiss="fileinput">
                        <i class="fa fa-times"></i> Remove
                      </a>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Select services</label>
                  <select class="form-control" v-model="service_id">
                    <option value="">Select Service</option>
                    <option value="1">Hospitals</option>
                    <option value="2">Clinics</option>
                    <!-- <option value="4">OutPatient Clinics</option> -->
                  </select>
                </div>
                <div class="form-group">
                  <label>Select District</label>
                  <multiselect v-model="selectedDistrict" :options="districts" :multiple="false" :searchable="true"
                    label="name" track-by="id" placeholder="Select districts"></multiselect>
                </div>
                <div class="form-group ">
                  <label class="text-">Branch</label>
                  <multiselect v-model="selectedBranches" :options="branches" :searchable="true" label="name"
                    track-by="id" placeholder="Select a Branch" :multiple="true"></multiselect>
                </div>
                <div class="form-group">
                  <label class="text-">Medical Insurances</label>
                  <multiselect v-model="selectedMedicalInsurances" :options="medicalInsurances" :searchable="true"
                    label="name_en" track-by="id" placeholder="Select Insurances" :multiple="true"></multiselect>
                </div>
                <button class="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import { store } from "../../Store";
import Multiselect from "vue-multiselect";
import GoogleMap from "../Component/GoogleMap.vue";

export default {
  name: "CreateInfoService",
  components: {
    Multiselect,
    GoogleMap,
  },
  data() {
    return {
      name_en: "",
      name_ar: "",
      description_en: "",
      description_ar: "",
      image: null,
      background_image: null,
      info_service_no: null,
      address: "",
      lat: 30.0444, // Lat and long for input fields
      long: 31.2357, // long and long for input fields
      service_id: "",
      district_id: "",
      services: [],
      districts: [],
      selectedDistrict: [],
      selectedBranches: [],
      branches: [],
      medicalInsurances: [],
      selectedMedicalInsurances: [],
    };
  },
  created() {
    this.fetchService();
    this.fetchDistrict();
    this.fetchBranch();
    this.fetchMedicalInsurances();
  },
  methods: {
    handlePlaceChanged({ lat, lng }) {
      this.lat = lat;
      this.long = lng;
    },
    handleImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.image = file; // Update with the selected file
      }
    },
    handleBackgroundImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.background_image = file;
      }
      // event.target.value = null;
    },
    async fetchService() {
      try {
        const response = await axios.get("/admin/admin/service/all");
        console.log("Services", response.data.data);
        this.services = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchDistrict() {
      try {
        const response = await axios.get("/admin/admin/district/all");
        console.log("district", response.data.data);
        this.districts = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchBranch() {
      try {
        const response = await axios.get("/admin/admin/branch/all");
        console.log("branches", response.data.data);
        this.branches = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchMedicalInsurances() {
      try {
        const response = await axios.get("/admin/admin/medical-insurance/all");
        console.log("medical-insurance", response.data.data);
        this.medicalInsurances = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },

    async createInfoService() {
      try {
        console.log(this.image);
        // Prepare form data for upload
        const formData = new FormData();
        formData.append("name_en", this.name_en);
        formData.append("name_ar", this.name_ar);
        formData.append("description_en", this.description_en);
        formData.append("description_ar", this.description_ar);
        formData.append("info_service_no", this.info_service_no);
        formData.append("address", this.address);
        formData.append("lat", this.lat);
        formData.append("long", this.long);
        formData.append("service_id", this.service_id);
        formData.append("district_id", this.selectedDistrict.id);

        this.selectedBranches.forEach((branch, index) => {
          formData.append(`branch_id[${index}]`, branch.id);
        });
        this.selectedMedicalInsurances.forEach((medicalInsurance, index) => {
          formData.append(`medical_insurance[${index}]`, medicalInsurance.id);
        });
        if (this.image) {
          formData.append("image", this.image); // Append the selected file
        }
        if (this.background_image) {
          formData.append("background_image", this.background_image); // Append the selected file
        }
        const user_id = store.user ? store.user.id : null;
        formData.append("user_id", user_id);

        console.log(formData);
        const response = await axios.post(
          "admin/admin/info-service/create",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response.data);
        this.$router.push({ name: "all-infoServices" });
      } catch (error) {
        console.error(error);
      }
    },


  },

};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  width: 100%;
  /* max-width: 500px; Optional: Max width for better responsiveness */
}

form>.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}
</style>
