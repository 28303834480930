<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">All Procedures</h1>
          </div>
          <div class="col-sm-2">
            <router-link v-if="hasPermission('procedure create')" style="margin-right: 10px" class="btn btn-primary"
              :to="{ name: 'create-procedure' }">create</router-link>
            <!-- <router-link v-if="hasPermission('procedure get-delete-soft')" class="btn btn-success"
              :to="{ name: 'deleted-procedures' }">Deleted
              Procedures</router-link> -->
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">

            <div v-if="procedures.length > 0">

              <MyTable :columns="columns" :actions="true">
                <tr v-for="(procedure, index) in procedures" :key="index">
                  <th scope="row">{{ procedure.id }}</th>
                  <td>{{ procedure.name_ar }}</td>
                  <td>{{ procedure.name_en }}</td>
                  <td>
                    <button class="me-2 btn btn-danger" @click="deleteProcedure(procedure.id)">
                      Delete
                    </button>
                    <router-link class="btn btn-primary spacing"
                      :to="{ name: 'edit-procedure', params: { id1: procedure.id, id2: procedure.operation_service_id } }">
                      <!-- :to="{ name: 'edit-procedure', params: { id1: procedure.id, id2: procedure.operation_service_id } }"> -->
                      Edit
                    </router-link>
                  </td>
                </tr>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No procedures found.
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import $ from 'jquery'; // Import jQuery
import { store } from "../../Store";
import MyTable from "../MyTable//MyTable.vue"; // Import your MyTable component
import axios from "../../Axios";
export default {
  name: "AllProcedures",
  components: {
    MyTable,
    // Paginate,
  },
  data() {
    return {
      dataTable: [],
      procedures: [],
      meta: {},
      searchTerm: "",
      columns: [
        { label: '#', key: 'id' },
        { label: 'Name Arabic', key: 'name_ar' },
        { label: 'Name English', key: 'name_en' },
      ],
    };
  },

  async created() {
    this.fetchProcedures();
  },

  computed: {
    hasPermission() {
      return (permission) => store.hasPermission(permission);
    },
    filteredProcedures() {
      if (!this.searchTerm) {
        return this.procedures;
      }
      const term = this.searchTerm.toLowerCase();
      return this.procedures.filter(
        (procedure) =>
          procedure.name.toLowerCase().includes(term)
      );
    },
  },

  methods: {
    // fetch procedures
    async fetchProcedures() {
      try {
        const response = await axios.get(
          `/admin/admin/procedure/all`
        );
        this.procedures = response.data.data;
        this.meta = response.data.meta;
        console.log(response.data.data);
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
      } catch (error) {
        console.error("Error fetching procedures:", error);
      }
    },
    initializeDataTable() {
      this.$nextTick(() => {
        // Ensure the DataTable is initialized only after the DOM is updated
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },

    deleteProcedure(id) {
      console.log("delete procedure id: ", id);
      axios.delete(`/admin/admin/procedure/delete?id=${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchProcedures();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

};
</script>
<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}
</style>