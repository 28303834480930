<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Edit Operation Booking</h1>

            <!-- <span class="mainDescription"
              >Use this page to start from scratch and put your custom
              content</span
            > -->
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-bookings' }">Back</router-link>
          </div>
          <!-- <ol class="breadcrumb">
            <li class="active">
              <span>Blank Page</span>
            </li>
          </ol> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="updateBooking" enctype="multipart/form-data">
              <div class="row">
                <div class="form-group">
                  <label class="text-">Lab</label>
                  <select class="form-control" v-model="selectedLab">
                    <option value="">Select Lab</option>
                    <option v-for="lab in labs" :key="lab.id" :value="lab">
                      {{ lab.name }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label class="text-">Day</label>
                  <select class="form-control" v-model="selectedAppointmentDay">
                    <option value="">Select Day</option>
                    <option v-for="day in appointmentDays" :key="day.id" :value="day">
                      {{ day.name }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label class="text-">Hour</label>
                  <select class="form-control" v-model="selectedAppointmentHour">
                    <option value="">Select Hour</option>
                    <option v-for="hour in appointmentHours" :key="hour.id" :value="hour">
                      {{ hour.from }} - {{ hour.to }}
                    </option>
                  </select>
                </div>
                <div class="form-group">
                  <label>Appointment Types</label>
                  <select class="form-control" v-model="selectedType">
                    <option value="">Select Appointment Type</option>
                    <option value="home">Home</option>
                    <option value="branch">Branch</option>
                  </select>
                </div>
              </div>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";

export default {
  name: "CreateAppointmentLab",
  data() {
    return {
      labs: [],
      selectedLab: "",

      appointmentDays: [],
      selectedAppointmentDay: [],

      appointmentHours: [],
      selectedAppointmentHour: "",

      selectedType: [],
    };
  },
  created() {
    this.fetchAppointmentLab();
    this.fetchLabs();
    this.fetchAppointmentDays();
    this.fetchAppointmentHours();
  },
  methods: {
    async fetchLabs() {
      try {
        const response = await axios.get("/admin/admin/lab/all");
        console.log("labs", response.data.data);
        this.labs = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchAppointmentDays() {
      try {
        const response = await axios.get("/admin/admin/appointment-day/all");
        this.appointmentDays = response.data.data;
        console.log("appointmentDays", response.data.data);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchAppointmentHours() {
      try {
        const response = await axios.get("/admin/admin/appointment-hour/all");
        console.log("appointmentHours", response.data.data);
        this.appointmentHours = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchAppointmentLab() {
      try {
        const response = await axios.get(`/admin/admin/appointment-lab/get?id=${this.$route.params.id}`);
        console.log(response);
        const appointmentLabData = response.data.data;
        this.appointment_data = appointmentLabData.appointment_data.lab_id;
        this.appointment_day = appointmentLabData.appointment_day;
        this.appointment_hour = appointmentLabData.appointment_hour;
        this.appointment_type = appointmentLabData.appointment_type;

        console.log('appointmentLabData', appointmentLabData);
      } catch (error) {
        console.error("Error fetching appointment_data:", error);
      }
    },
    async updateAppointmentLab() {
      try {
        const formData = new FormData();
        formData.append("id", this.$route.params.id);
        formData.append("lab_id", this.selectedLab.id);
        formData.append("appointment_hour_id", this.selectedAppointmentHour.id);
        formData.append("appointment_day_id", this.selectedAppointmentDay.id);
        formData.append("appointment_type", this.selectedType);
        const response = await axios.post("/admin/admin/lab/update", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        console.log(formData);
        console.log(response.data);
        this.$router.push({ name: "all-appointmentLabs" });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  width: 100%;
  /* max-width: 500px; Optional: Max width for better responsiveness */
}

form>.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}
</style>
