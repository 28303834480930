<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Create Procedure</h1>

            <!-- <span class="mainDescription"
              >Use this page to start from scratch and put your custom
              content</span
            > -->
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-procedures' }">Back</router-link>
          </div>
          <!-- <ol class="breadcrumb">
            <li class="active">
              <span>Blank Page</span>
            </li>
          </ol> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="createProcedure" enctype="multipart/form-data">
              <div class="row">
                <!-- <div class="col-md-6">
                  <div class="form-group">
                    <label>Operation Service</label>
                    <select class="form-control" v-model="operation_service_id" required>
                      <option value="" disabled>Select Operation Service</option>
                      <option v-for="service in operationServices" :key="service.id" :value="service.id">
                        {{ service.name }}
                      </option>
                    </select>
                  </div>
                </div> -->
                <div class="form-group col-md-12 mb-2">
                  <label class="text-">Hospital</label>
                  <multiselect v-model="info_service_id" :options="infoServices" :searchable="true" label="name"
                    track-by="id" placeholder="Select a Hospital" :multiple="false">
                  </multiselect>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Select operation</label>
                    <multiselect v-model="operation_id" :options="operations" :multiple="false" :searchable="true"
                      label="name_en" track-by="id" placeholder="Select operation"></multiselect>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">English Name</label>
                    <input type="text" class="form-control" placeholder="Enter English Name" v-model="name_en" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="Name">Arabic Name</label>
                    <input type="text" class="form-control" placeholder="Enter Arabic Name" v-model="name_ar" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="labName">English Description </label>
                    <textarea rows="3" class="form-control" placeholder="Enter English Description"
                      v-model="description_en"></textarea>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="labName">Arabic Description </label>
                    <textarea rows="3" class="form-control" placeholder="Enter Arabic Description"
                      v-model="description_ar"></textarea>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">Estimated Duration English</label>
                    <input type="text" class="form-control" placeholder="Enter in English"
                      v-model="estimated_duration_en" required />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="Name">Estimated Duration Arabic</label>
                    <input type="text" class="form-control" placeholder="Enter in Arabic"
                      v-model="estimated_duration_ar" required />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">Expected Hospital Stay English</label>
                    <input type="text" class="form-control" placeholder="Enter in English"
                      v-model="expected_hospital_stay_en" required />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="Name">Expected Hospital Stay Arabic</label>
                    <input type="text" class="form-control" placeholder="Enter in Arabic"
                      v-model="expected_hospital_stay_ar" required />
                  </div>
                </div>
              </div>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import Multiselect from "vue-multiselect";
export default {
  name: "CreateProcedure",
  components: {
    Multiselect,
  },
  data() {
    return {
      info_service_id: "",
      operation_id: "",
      infoServices: [],
      operations: [],
      name_en: "",
      name_ar: "",
      description_en: "",
      description_ar: "",
      estimated_duration_en: "",
      estimated_duration_ar: "",
      expected_hospital_stay_en: "",
      expected_hospital_stay_ar: "",
    };
  },
  async created() {
    this.fetchInfoservices();
    this.fetchOperations();
  },
  methods: {
    async fetchInfoservices() {
      try {
        // const response = await axios.get(`/admin/admin/info-service/all?service_id=${this.service_id}`);
        // const response = await axios.get(`/admin/admin/info-service/all?service_id=1`);
        const response = await axios.get(`/admin/admin/info-service/all?service_id=1`);
        this.infoServices = response.data.data;
        this.meta = response.data.meta;
        console.log(this.infoServices)
      } catch (error) {
        console.error("Error fetching infoServices:", error);
      }
    },
    async fetchOperations() {
      try {
        const response = await axios.get(`/admin/admin/operation/all`);
        this.operations = response.data.data;
        console.log(this.operations)

        this.meta = response.data.meta;
      } catch (error) {
        console.error("Error fetching operation:", error);
      }
    },
    async createProcedure() {
      try {
        // Prepare form data for upload
        const formData = new FormData();
        formData.append('info_service_id', this.info_service_id.id);
        formData.append('operation_id', this.operation_id.id);
        formData.append('name_en', this.name_en);
        formData.append('name_ar', this.name_ar);
        formData.append('description_en', this.description_en);
        formData.append('description_ar', this.description_ar);
        formData.append('estimated_duration_en', this.estimated_duration_en);
        formData.append('estimated_duration_ar', this.estimated_duration_ar);
        formData.append('expected_hospital_stay_en', this.expected_hospital_stay_en);
        formData.append('expected_hospital_stay_ar', this.expected_hospital_stay_ar);
        const response = await axios.post("admin/admin/procedure/create", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log(response.data);
        this.$router.push({ name: "all-procedures" });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  width: 100%;
  /* max-width: 500px; Optional: Max width for better responsiveness */
}

form>.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}
</style>
