<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Edit Labs / Scans</h1>

            <!-- <span class="mainDescription"
              >Use this page to start from scratch and put your custom
              content</span
            > -->
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-labs' }">Back</router-link>
          </div>
          <!-- <ol class="breadcrumb">
            <li class="active">
              <span>Blank Page</span>
            </li>
          </ol> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="editLab" enctype="multipart/form-data">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">English Name</label>
                    <input type="text" class="form-control" placeholder="Enter English Name" v-model="name_en" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="labName">Arabic Name</label>
                    <input type="text" class="form-control" placeholder="Enter Arabic Name" v-model="name_ar" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">English Title</label>
                    <input type="text" class="form-control" placeholder="Enter English Title" v-model="title_en" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="labTitle">Arabic Title</label>
                    <input type="text" class="form-control" placeholder="Enter Arabic Title" v-model="title_ar" />
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="labName">English Description </label>
                    <textarea rows="3" class="form-control" placeholder="Enter English Description"
                      v-model="description_en"></textarea>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="labName">Arabic Description </label>
                    <textarea rows="3" class="form-control" placeholder="Enter Arabic Description"
                      v-model="description_ar"></textarea>
                  </div>
                </div>
                <div class="form-group ">
                  <label class="text-">City</label>
                  <multiselect v-model="selectedCity" :options="cities" :searchable="true" label="name" track-by="id"
                    placeholder="Select a City" :multiple="false"></multiselect>
                </div>
                <div class="form-group ">
                  <label class="text-">District</label>
                  <multiselect v-model="selectedDistrict" :options="districts" :searchable="true" label="name"
                    track-by="id" placeholder="Select a District" :multiple="false"></multiselect>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="labName">Address</label>
                    <input type="text" class="form-control" placeholder="Enter address" v-model="address" />
                  </div>
                </div>
                <GoogleMap :coords="{ lat: lat, lng: long }" @placeChanged="handlePlaceChanged" />
                <div class="form-group">
                  <label>Latitude</label>
                  <input type="text" class="form-control" placeholder="Enter Latitude" v-model="lat" />
                </div>

                <div class="form-group">
                  <label>Longitude</label>
                  <input type="text" class="form-control" placeholder="Enter Longitude" v-model="long" />
                </div>
                <!-- <div class="form-group col-4">
                  <label>from</label>
                  <input type="time" class="form-control" placeholder="Enter Time" v-model="from" />
                </div>
                <div class="form-group col-4">
                  <label>to</label>
                  <input type="time" class="form-control" placeholder="Enter Time" v-model="to" />
                </div> -->
                <div class="form-group col-4">
                  <label>from</label>
                  <input type="time" class="form-control" placeholder="Enter Time" v-model="from" />
                </div>
                <div class="form-group col-4">
                  <label>to</label>
                  <input type="time" class="form-control" placeholder="Enter Time" v-model="to" />
                </div>
                <div class="form-group col-4">
                  <label>Experience</label>
                  <input type="number" class="form-control" placeholder="Enter Experience" v-model="experience" />
                </div>
                <!-- Image Upload -->
                <div class="form-group">
                  <label>Main Image</label>
                  <div class="fileinput fileinput-new" data-provides="fileinput">
                    <div class="fileinput-new thumbnail">
                      <img :src="mainImageUrl" alt="Main Image" />
                    </div>
                    <div class="fileinput-preview fileinput-exists thumbnail"></div>
                    <div class="user-edit-image-buttons">
                      <span class="btn btn-azure btn-file"><span class="fileinput-new"><i class="fa fa-picture"></i>
                          Select image</span><span class="fileinput-exists"><i class="fa fa-picture"></i> Change</span>
                        <input type="file" name="main_image" @change="handleMainImageChange" />
                      </span>
                      <a href="#" class="btn fileinput-exists btn-red" data-dismiss="fileinput">
                        <i class="fa fa-times"></i> Remove
                      </a>
                    </div>
                  </div>
                </div>
                <!-- Additional Images -->
                <div class="form-group">
                  <label>Additional Images</label>
                  <div class="multiple-image-upload">
                    <div class="upload-container">
                      <input type="file" class="form-control" accept="image/*" multiple @change="handleMultipleImages"
                        ref="multipleImages" />
                    </div>
                    <!-- Preview section -->
                    <div v-if="imagePreviewUrls.length" class="image-preview-container">
                      <h5>Selected Images:</h5>
                      <div class="row g-3">
                        <div v-for="(image, index) in imagePreviewUrls" :key="index" class="col-md-3 col-sm-4 col-6">
                          <div class="image-preview-wrapper">
                            <img :src="image" alt="Preview" class="img-thumbnail preview-image"
                              @click="removeImage(index)" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label>Select services</label>
                  <select class="form-control" v-model="selectedService">
                    <option value="">Select Service</option>
                    <option value="16">Laboratories</option>
                    <option value="17">Scans</option>
                  </select>
                </div>
                <div class="form-group ">
                  <label class="text-">Main Branch</label>
                  <multiselect v-model="selectedLab" :options="labs" :searchable="true" label="name" track-by="id"
                    placeholder="Select Main Branch" :multiple="false"></multiselect>
                </div>
                <div class="form-group">
                  <label class="text-">Medical Insurances</label>
                  <multiselect v-model="selectedMedicalInsurances" :options="medicalInsurances" :searchable="true"
                    label="name_en" track-by="id" placeholder="Select Insurances" :multiple="true"></multiselect>
                </div>
                <div class="form-group" v-if="!selectedLab">
                  <button class="btn btn-success add-variant mb-4 p-2" @click="addVariant" type="button">
                    Add Investigation
                  </button>
                  <div class="row">
                    <div class="col-md-6" v-for="(variant, index) in variants" :key="index">
                      <div class="card mb-3">
                        <div class="card-body">
                          <h5 class="card-title">Investigation {{ index + 1 }}</h5>

                          <div class="form-group">
                            <label class="text-">Test</label>
                            <multiselect v-model="variant.test_id" :options="tests" :searchable="true" label="name_en"
                              track-by="id" placeholder="Select a Test" :multiple="false">
                            </multiselect>
                          </div>
                          <div class="form-group">
                            <label class="text-">price</label>
                            <input type="number" class="form-control" placeholder="Enter price"
                              v-model="variant.price" />
                          </div>
                          <div class="form-group">
                            <label class="text-">Discount Percentage</label>
                            <input type="number" min="0" max="100" class="form-control"
                              placeholder="Enter Discount Percentage" v-model="variant.discount_percentage" />
                          </div>
                          <!-- <div class="form-group">
                            <label class="text-">Price After Discount</label>
                            <input type="number" class="form-control" :value="price_after_discount" disabled />
                          </div> -->
                          <div class="form-group">
                            <label class="text-">Price After Discount</label>
                            <input type="number" class="form-control" :value="calculatePriceAfterDiscount(variant)"
                              disabled />
                          </div>
                          <button class="btn btn-danger mt-3" @click="removeVariant(index)">
                            Remove Variant
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>


<script>
import axios from "../../Axios";
import GoogleMap from "../Component/GoogleMap.vue";
import Multiselect from "vue-multiselect";


export default {
  name: "EditLab",
  components: {
    Multiselect,
    GoogleMap,
  },
  data() {
    return {
      name_en: "",
      name_ar: "",
      description_en: "",
      description_ar: "",
      title_ar: "",
      title_en: "",
      experience: "",
      from: "",
      to: "",
      address: "",
      url: "",
      lat: 30.0444, // Lat and long for input fields
      long: 31.2357, // long and long for input fields

      selectedImages: [],
      mainImageUrl: "",
      main_image: null,

      additionalImages: [], // For storing File objects
      imagePreviewUrls: [], // For storing preview URLs
      // maxImages: 10, // Maximum number of images allowed
      existingImages: [],

      services: [],
      selectedService: "",
      service_id: "",

      medicalInsurances: [],
      selectedMedicalInsurances: [],

      labs: [],
      selectedLab: "", //parent_id


      districts: [],
      selectedDistrict: "",

      cities: [],
      selectedCity: "",

      price: "",
      test_id: "",
      discount_percentage: 0,
      price_after_discount: "",
      tests: [],
      // selectedTests: [],
      variants: [],

    };
  },
  created() {
    this.fetchLab();
    this.fetchLabs();
    this.fetchService();
    this.fetchDistrict();
    this.fetchBranch();
    this.fetchMedicalInsurances();
    this.fetchTests();
  },
  methods: {
    calculatePriceAfterDiscount(variant) {
      if (!variant.price && !variant.discount_percentage) return 0;
      return variant.price - (variant.price * variant.discount_percentage) / 100;
    },

    handlePlaceChanged(place) {
      this.lat = place.lat; // Update latitude
      this.long = place.lng; // Update longitude
    },

    handleMainImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.main_image = file;
      }
    },
    handleMultipleImages(event) {
      const files = Array.from(event.target.files);

      files.forEach(file => {
        if (file.type.startsWith('image/')) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imagePreviewUrls.push(e.target.result);
          };
          reader.readAsDataURL(file);
          this.additionalImages.push(file);
        }
      });
      event.target.value = '';
    },
    removeImage(index) {
      this.additionalImages.splice(index, 1);
      this.imagePreviewUrls.splice(index, 1);
    },

    async fetchLab() {
      try {
        const response = await axios.get(
          "/admin/admin/lab/get?id=" + this.$route.params.id + "&web=1"
        );
        const labData = response.data.data;
        this.name_en = labData.name_en;
        this.name_ar = labData.name_ar;
        this.description_en = labData.description_en;
        this.description_ar = labData.description_ar;
        this.title_ar = labData.title_ar;
        this.title_en = labData.title_en;
        this.experience = labData.experience;
        this.from = labData.from;
        this.to = labData.to;
        this.address = labData.address;
        this.lat = labData.latitude;
        this.long = labData.longitude;
        this.selectedService = labData.service.id;
        this.selectedDistrict = labData.district;
        this.selectedBranches = labData.branches;
        this.selectedCity = labData.city;
        this.selectedLab = labData.main_branch;
        this.selectedMedicalInsurances = labData.medical_insurance;

        this.variants = labData.types.map((type, index) => {
          console.log(`Mapping service at index ${index}:`, type);
          return {
            test_id: type.test,
            price: type.price,
            discount_percentage: type.discount_percentage ?? 0,

          };
        });


        this.mainImageUrl = labData.main_image;
        this.mapCenter = { lat: labData.latitude, lng: labData.latitude };

        this.existingImages = labData.images.map(image => image.image);

        if (labData.images) {
          labData.images.forEach((image) => {
            this.imagePreviewUrls.push(image.image);
          });
        }
        console.log(response.data.data);
      } catch (error) {
        console.error(error);
      }
    },
    addVariant() {
      this.variants.push({
        test_id: null,
        price: "",
        discount_percentage: 0,
        price_after_discount: "",
      });
    },
    removeVariant(index) {
      this.variants.splice(index, 1);
    },
    async fetchLabs() {
      try {
        const response = await axios.get("/admin/admin/lab/all");
        console.log("lab", response.data.data);
        this.labs = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchService() {
      try {
        const response = await axios.get("/admin/admin/service/all");
        console.log("Services", response.data.data);
        this.services = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchDistrict() {
      try {
        const response = await axios.get("/admin/admin/district/all");
        console.log("district", response.data.data);
        this.districts = response.data.data;
        this.meta = response.data.meta;

      } catch (error) {
        console.error(error);
      }
    },
    async fetchBranch() {
      try {
        const response = await axios.get("/admin/admin/branch/all");
        console.log("branch", response.data.data);
        this.branches = response.data.data;
        this.meta = response.data.meta;

      } catch (error) {
        console.error(error);
      }
    },
    async fetchTests() {
      try {
        const response = await axios.get("/admin/admin/test/all");
        console.log("test", response.data.data);
        this.tests = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchMedicalInsurances() {
      try {
        const response = await axios.get("/admin/admin/medical-insurance/all");
        console.log("medical-insurance", response.data.data);
        this.medicalInsurances = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async editLab() {
      try {
        const formData = new FormData();
        formData.append("id", this.$route.params.id);
        formData.append("name_en", this.name_en);
        formData.append("name_ar", this.name_ar);
        formData.append("description_en", this.description_en);
        formData.append("description_ar", this.description_ar);
        formData.append("latitude", this.lat);
        formData.append("longitude", this.long);
        formData.append("address", this.address);
        formData.append("experience", this.experience);
        formData.append("from", this.from);
        formData.append("to", this.to);
        formData.append("title_ar", this.title_ar);
        formData.append("title_en", this.title_en);
        formData.append("service_id", this.selectedService);
        formData.append("district_id", this.selectedDistrict.id);
        this.selectedMedicalInsurances.forEach((medicalInsurance, index) => {
          formData.append(`medical_insurance[${index}]`, medicalInsurance.id);
        });
        // this.selectedBranches.forEach((branch, index) => {
        //   formData.append(`branch_id[${index}]`, branch.id);
        // });
        // this.selectedTests.forEach((test, index) => {
        //   formData.append(`test_id[${index}]`, test.id);
        // });


        formData.append("city_id", this.selectedCity.id);
        if (this.selectedLab) {
          formData.append("parent_id", this.selectedLab.id);
        }
        if (!this.selectedLab) {
          this.variants.forEach((variant, index) => {
            formData.append(`test_id[${index}]`, variant.test_id.id);
            formData.append(`price[${index}]`, Number(variant.price));
            formData.append(`discount_percentage[${index}]`, Number(variant.discount_percentage));
            //   // formData.append(`price_after_discount[${index}]`, Number(variant.price_after_discount));
          });
        }

        // this.variants.forEach((variant, index) => {
        //   formData.append(`test_id[${index}]`, variant.test_id.id);
        //   formData.append(`price[${index}]`, Number(variant.price));
        //   formData.append(`discount_percentage[${index}]`, Number(variant.discount_percentage));
        //   // formData.append(`price_after_discount[${index}]`, Number(variant.price_after_discount));
        // });
        if (this.main_image) {
          formData.append("main_image", this.main_image);
        }
        if (this.additionalImages) {
          this.additionalImages.forEach((image) => {
            formData.append(`new_images[]`, image);
          });
        }
        // this.additionalImages.forEach((image) => {
        //   formData.append(`new_images[]`, image);
        // });
        // this.existingImages.forEach((image) => {
        //   formData.append(`images[]`, image);
        // });
        // this.additionalImages.forEach((image, index) => {
        //   formData.append(`new_images[${index}]`, image);
        // });

        const response = await axios.post("admin/admin/lab/update", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log(response.data);
        console.log(formData);
        this.$router.push({ name: "all-labs" });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  width: 100%;
  /* max-width: 500px; Optional: Max width for better responsiveness */
}

form>.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}

.multiple-image-upload {
  margin-bottom: 1.5rem;
}

.image-preview-container {
  margin-top: 1rem;
}

.image-preview-wrapper {
  position: relative;
  margin-bottom: 1rem;
}

.preview-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.remove-image {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 2px 8px;
  font-size: 12px;
}

.upload-container {
  margin-bottom: 1rem;
}
</style>
