<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Deleted Pharmacies</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-pharmacies' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">

            <div v-if="pharmacies.length > 0">
              <MyTable :columns="columns" :actions="true">
                <tr v-for="(pharmacy, index) in pharmacies" :key="index">
                  <th scope="row">{{ pharmacy.id }}</th>
                  <td>{{ pharmacy.name_ar }}</td>
                  <td>{{ pharmacy.name_en }}</td>
                  <!-- <td>{{ pharmacy.service_id ? pharmacy.service_id.name : '' }}</td> Check if service_id exists -->
                  <td>
                    <img :src="`${pharmacy.main_image}`" width="50" height="50" class="img-thumbnail" />
                  </td>
                  <!-- <td><img :src="`${assets}pharmacy.image`"></td> -->

                  <td>
                    <button class="me-2 btn btn-success" @click="restorePharmacy(pharmacy.id)">
                      Restore
                    </button>

                  </td>
                </tr>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No deleted pharmacies found.
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import $ from 'jquery'; // Import jQuery
import MyTable from "../MyTable//MyTable.vue"; // Import your MyTable component
import axios from "../../Axios";
export default {
  name: "DeletedPharmacies",
  components: {
    MyTable,
    // Paginate,
  },
  data() {
    return {
      dataTable: [],
      pharmacies: [],
      // service_id:"" ,
      meta: {},
      searchTerm: "",
      columns: [
        { label: '#', key: 'id' },
        { label: 'Name Arabic', key: 'name_ar' },
        { label: 'Name English', key: 'name_en' },
        { label: 'Image', key: 'main_image' },
      ],
    };
  },

  async created() {
    this.fetchPharmacies();
  },

  // computed: {
  //   filteredPharmacies() {
  //     if (!this.searchTerm) {
  //       return this.pharmacies;
  //     }
  //     const term = this.searchTerm.toLowerCase();
  //     return this.pharmacies.filter(
  //       (pharmacy) =>
  //         pharmacy.name.toLowerCase().includes(term) ||
  //         pharmacy.postal_code.toLowerCase().includes(term)
  //     );
  //   },
  // },

  methods: {
    // fetch pharmacies
    async fetchPharmacies() {
      try {
        const response = await axios.get(
          `/admin/admin/pharmacy/get-delete-soft`
        );

        this.pharmacies = response.data.data;
        // this.service_id= response.data.data.service.id;
        this.meta = response.data.meta;
        console.log(this.pharmacies);
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
      } catch (error) {
        console.error("Error fetching pharmacies:", error);
      }
    },
    initializeDataTable() {
      this.$nextTick(() => {
        // Ensure the DataTable is initialized only after the DOM is updated
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },
    restorePharmacy(id) {
      console.log("restore pharmacy id: ", id);
      axios
        .post(`/admin/admin/pharmacy/restore?id=${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchPharmacies();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

};


</script>
<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}
</style>