<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">All Doctor Appointments</h1>
          </div>
          <div class="col-sm-2">
            <!-- <router-link style="margin-right: 10px" class="btn btn-primary"
              :to="{ name: 'create-appointmentDoctor' }">create</router-link> -->
            <!-- <router-link class="btn btn-success" :to="{ name: 'deleted-appointmentDoctors' }">Deleted
              Appointments</router-link> -->
          </div>
          <!-- <router-link v-if="hasPermission('appointment-day create')" style="margin-right: 10px"
              class="btn btn-primary" :to="{ name: 'create-appointmentDay' }">create</router-link>
            <router-link v-if="hasPermission('appointment-day getSoftDeleted')" class="btn btn-success"
              :to="{ name: 'deleted-appointmentDays' }">Deleted
              Appointments</router-link>
          </div> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <h5 class="over-title margin-bottom-15">
              <!-- Basic <span class="text-bold">Data Table</span> -->
            </h5>
            <p>

            </p>
            <div v-if="appointmentDoctors.length > 0">
              <MyTable :columns="columns" :actions="true">
                <tr v-for="(appointment, index) in filteredAppointments" :key="index">
                  <th scope="row">{{ appointment.id }}</th>
                  <td>{{ appointment.doctor_service_specialty ? appointment.doctor_service_specialty.doctor_name : 'N/A'
                    }}
                  </td>
                  <td>{{ appointment.doctor_service_specialty ? appointment.doctor_service_specialty.service_name :
                    'N/A' }}
                  </td>
                  <td>{{ appointment.doctor_service_specialty ? appointment.doctor_service_specialty.specialty_name :
                    'N/A'
                    }}</td>
                  <td>{{ appointment.appointment_day ? appointment.appointment_day.name_en : '' }}</td>
                  <td>{{ appointment.appointment_hour ? appointment.appointment_hour.from + ' - ' +
                    appointment.appointment_hour.to : '' }}</td>
                  <!-- <td>
                    <div v-for="(group, groupIndex) in appointment.appointments" :key="groupIndex">
                      <p>{{ group.day.name_en }}</p>
                      <ul>
                        <li v-for="(hour, hourIndex) in group.hour" :key="hourIndex">
                          {{ hour.from }} - {{ hour.to }}
                        </li>
                      </ul>
                    </div>
                  </td> -->
                  <td>
                    <button class="me-2 btn btn-danger" @click="deleteAppointment(appointment.id)">
                      Delete
                    </button>
                    <router-link class="btn btn-primary spacing"
                      :to="{ name: 'edit-appointmentDoctor', params: { id: appointment.id } }">
                      <!-- :to="{ name: 'edit-appointmentDoctor', params: { id1: appointment.doctor_service_specialty ? appointment.doctor_service_specialty.id : appointment.id, id2: appointment.id } }"> -->
                      <!-- :to="{ name: 'edit-appointmentDoctor', params: { id1: appointment.doctor_service_specialty, id2: appointment.id } }"> -->
                      Edit
                    </router-link>
                  </td>
                </tr>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No Appointments found.
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import { store } from "../../Store";
import axios from "../../Axios";
import $ from 'jquery'; // Import jQuery
import MyTable from "../MyTable//MyTable.vue"; // Import your MyTable component

export default {
  name: "AllAppointmentDoctors",
  components: {
    MyTable,
    // Paginate,
  },
  data() {
    return {
      dataTable: [], // Store DataTable instance
      appointmentDoctors: [],
      meta: {},
      searchTerm: "",
      columns: [
        { label: '#', key: 'id' },
        { label: 'Doctor Name', key: 'doctor_service_specialty.doctor_name' },
        { label: 'Speciality', key: 'doctor_service_specialty.speciality_name' },
        { label: 'Main Service', key: 'doctor_service_specialty.service_name' },
        // { label: 'Appointments', key: 'appointments' }, // This can be customized based on the format you need.
        { label: 'Day', key: 'appointment_day' }, // This can be customized based on the format you need.
        { label: 'Hour', key: 'appointment_hour' }, // This can be customized based on the format you need.
      ],
    };
  },

  async created() {
    this.fetchAppointment();
  },

  computed: {

    hasPermission() {
      return (permission) => store.hasPermission(permission);
    },

    filteredAppointments() {
      if (!this.searchTerm) {
        return this.appointmentDoctors;
      }
      const term = this.searchTerm.toLowerCase();
      return this.appointmentDoctors.filter((appointment) => {
        return (
          appointment.doctor_service_specialty.doctor_name.toLowerCase().includes(term) ||
          appointment.doctor_service_specialty.service_name.toLowerCase().includes(term) ||
          appointment.doctor_service_specialty.specialty_name.toLowerCase().includes(term) ||
          appointment.appointments.some((group) =>
            group.day.name_en.toLowerCase().includes(term)
          )
        );
      });
    },
  },

  methods: {

    async fetchAppointment() {
      try {
        // const params = {};
        // if (store.userRole === 'Doctor') {
        //   params.user_id = store.user ? store.user.id : null;
        // }

        // const response = await axios.get('/admin/admin/appointment-doctor/all', {
        //   params: params,
        // });

        const response = await axios.get(
          `/admin/admin/appointment-doctor/all`
        );
        console.log(response);
        this.appointmentDoctors = response.data.data;
        console.log(" fetching appointment-doctors:", this.appointmentDoctors);
        this.meta = response.data.meta;
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
      } catch (error) {
        console.error("Error fetching appointment-doctors:", error);
      }
    },
    initializeDataTable() {
      this.$nextTick(() => {
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },
    deleteAppointment(id) {
      console.log("delete appointmentDoctors id: ", id);
      axios
        .delete(`/admin/admin/appointment-doctor/delete?id=${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchAppointment();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

};
</script>
<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}
</style>