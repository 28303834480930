<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Edit Medication</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-medications' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="editMedication">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">English Name</label>
                    <input type="text" class="form-control" placeholder="Enter English Name" v-model="name_en" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="specialityName">Arabic Name</label>
                    <input type="text" class="form-control" placeholder="Enter Arabic Name" v-model="name_ar" />
                  </div>
                </div>
                <div class="form-group col-md-12">
                  <label class="text-">English Description</label>
                  <textarea class="form-control" rows="3" v-model="description_en"></textarea>
                </div>

                <div class="form-group col-md-12">
                  <label class="text-">Arabic Description</label>
                  <textarea class="form-control" rows="3" v-model="description_ar"></textarea>
                </div>
                <!-- <div class="form-group col-md-3">
                  <label class="text-">price</label>
                  <input type="number" class="form-control" placeholder="Enter price" v-model="price" />
                </div>

                <div class="form-group col-md-3">
                  <label class="text-">Price After Discount</label>
                  <input type="number" class="form-control" placeholder="Enter Price After Discount"
                    v-model="price_after_discount" />
                </div> -->
                <div class="form-group">
                  <label>Image Upload</label>
                  <div class="fileinput fileinput-new" data-provides="fileinput">
                    <div class="fileinput-new thumbnail">
                      <img :src="main_imagePreviews" alt="Main Image" />
                    </div>
                    <div class="fileinput-preview fileinput-exists thumbnail"></div>
                    <div class="user-edit-image-buttons">
                      <span class="btn btn-azure btn-file"><span class="fileinput-new"><i class="fa fa-picture"></i>
                          Select image</span><span class="fileinput-exists"><i class="fa fa-picture"></i> Change</span>
                        <input type="file" name="main_image" @change="handleFileChange" />
                      </span>
                      <a href="#" class="btn fileinput-exists btn-red" data-dismiss="fileinput">
                        <i class="fa fa-times"></i> Remove
                      </a>
                    </div>
                  </div>
                </div>
                <!-- <div class="form-group">
                  <label for="image" class="form-label">Image Upload</label>
                  <div class="fileinput-preview fileinput-exists thumbnail mt-2">
                    <img :src="main_imagePreviews" alt="Profile Preview" class="image-preview" />
                  </div>
                  <div class="upload-buttons">
                    <label class="btn btn-azure btn-file">
                      <span><i class="fa fa-picture"></i> Select Image</span>
                      <input type="file" accept="image/*" @change="handleFileChange" class="hidden" />
                    </label>
                    <button v-if="main_imagePreviews" @click.prevent="removeImage" class="btn fileinput-exists btn-red">
                      <i class="fa fa-times"></i> Remove
                    </button>
                  </div>
                </div> -->
                <div class="form-group">
                  <label>Select medication type</label>
                  <select class="form-control" v-model="selectedType">
                    <option v-for="type in types" :key="type.id" :value="type.id">
                      {{ type.name_en }}
                    </option>
                  </select>
                </div>
              </div>


              <button class="btn btn-primary submit-button">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end: DYNAMIC TABLE -->
  </div>
</template>

<script>
import axios from "../../Axios";

export default {
  name: "EditMedication",
  data() {
    return {
      name_en: "",
      name_ar: "",
      // price: "",
      // price_after_discount: "",
      description_en: "",
      description_ar: "",
      main_image: null,
      main_imagePreviews: [],
      types: [],
      selectedType: "", // This will hold the selected service id

    };
  },

  created() {
    this.fetchMedication();
    this.fetchTypes();

  },
  methods: {
    async fetchTypes() {
      try {
        const response = await axios.get("/admin/admin/type/all");
        console.log("Types", response.data.data);

        this.types = response.data.data;
        this.meta = response.data.meta;
      } catch (error) {
        console.error(error);
      }
    },

    async fetchMedication() {
      try {
        const response = await axios.get(
          "/admin/admin/medication/get?id=" + this.$route.params.id
        );
        const medicationData = response.data.data;

        this.name_en = medicationData.name_en;
        this.name_ar = medicationData.name_ar;
        this.description_en = medicationData.description_en;
        this.description_ar = medicationData.description_ar;
        // this.price = medicationData.price;
        // this.price_after_discount = medicationData.price_after_discount;
        this.selectedType = medicationData.type.id;
        if (medicationData.main_image) {
          this.main_imagePreviews = medicationData.main_image;
        }
        console.log('fetched', medicationData);
        console.log("data", this.data);
        // this.main_imagePreviews = medicationData.main_image ? [medicationData.main_image.url] : [];

      } catch (error) {
        console.error(error);
      }
    },
    async editMedication() {
      try {
        const formData = new FormData();
        formData.append("id", this.$route.params.id);
        formData.append('name_en', this.name_en);
        formData.append('name_ar', this.name_ar);
        formData.append("description_ar", this.description_ar);
        formData.append("description_en", this.description_en);
        // formData.append("price", Number(this.price));
        // formData.append("price_after_discount", Number(this.price_after_discount)); // Ensure price_after_discount is a number

        if (this.main_image) {
          formData.append("main_image", this.main_image); // Append the selected file
        }
        formData.append("type_id", this.selectedType);

        const response = await axios.post(
          "/admin/admin/medication/update",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response.data);
        console.log(formData);
        this.$router.push({ name: "all-medications" });
      } catch (error) {
        console.error(error);
      }
    },

    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.main_image = file;
        this.main_imagePreviews = URL.createObjectURL(file);
      }
      event.target.value = null;
    },
    removeImage() {
      this.main_image = null;
      this.main_imagePreviews = null;
    },

  },
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  width: 100%;
  /* max-width: 500px; Optional: Max width for better responsiveness */
}

form>.form-group {
  margin-bottom: 1.5rem;
}

.submit-button {
  width: 100%;
}

.hidden {
  display: none;
}

.upload-buttons {
  display: flex;
  gap: 1rem;
}

.btn {
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}
</style>
