<template>
    <div class="error-page">
        <h1>404</h1>
        <p>Oops! The page you are looking for does not exist.</p>
        <router-link to="/">Go to Home</router-link>
    </div>
</template>

<style scoped>
.error-page {
    text-align: center;
    margin-top: 100px;
}

h1 {
    font-size: 100px;
    color: #ccc;
}

p {
    font-size: 20px;
    color: #666;
}
</style>