<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Deleted Medication Types</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-medicationTypes' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">

            <div v-if="medicationTypes.length > 0">
              <MyTable :columns="columns" :actions="true">
                <tr v-for="(medicationType, index) in filteredMedicationTypes" :key="index">
                  <th scope="row">{{ medicationType.id }}</th>
                  <td>{{ medicationType.name_ar }}</td>
                  <td>{{ medicationType.name_en }}</td>
                  <td>
                    <button class="me-2 btn btn-success" @click="restoreMedicationType(medicationType.id)">
                      Restore
                    </button>

                  </td>
                </tr>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No deleted medication types found.
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import $ from 'jquery'; // Import jQuery
import MyTable from "../MyTable//MyTable.vue"; // Import your MyTable component
import axios from "../../Axios";
export default {
  name: "DeletedMedicationTypes",
  components: {
    MyTable,
    // Paginate,
  },
  data() {
    return {
      dataTable: [],
      medicationTypes: [],
      meta: {},
      searchTerm: "",
      columns: [
        { label: '#', key: 'id' },
        { label: 'Name Arabic', key: 'name_ar' },
        { label: 'Name English', key: 'name_en' },
      ],
    };
  },

  async created() {
    this.fetchMedicationTypes();
  },

  computed: {
    filteredMedicationTypes() {
      if (!this.searchTerm) {
        return this.medicationTypes;
      }
      const term = this.searchTerm.toLowerCase();
      return this.medicationTypes.filter(
        (medicationType) =>
          medicationType.name.toLowerCase().includes(term) ||
          medicationType.postal_code.toLowerCase().includes(term)
      );
    },
  },

  methods: {
    // fetch medicationTypes
    async fetchMedicationTypes() {
      try {
        const response = await axios.get(
          `/admin/admin/type/get-delete-soft`
        );
        this.medicationTypes = response.data.data;
        this.meta = response.data.meta;
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
      } catch (error) {
        console.error("Error fetching medicationTypes:", error);
      }
    },
    initializeDataTable() {
      this.$nextTick(() => {
        // Ensure the DataTable is initialized only after the DOM is updated
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },

    restoreMedicationType(id) {
      console.log("restore order id: ", id);
      axios.post(`/admin/admin/type/restore?id=${id}`)
        .then((response) => {
          console.log(response.data.data);
          this.fetchMedicationTypes();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

};
</script>
<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}
</style>