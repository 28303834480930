<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Deleted OutPatient Clinic Services</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-externalClinicServices' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">

            <div v-if="externalClinics.length > 0">
              <MyTable :columns="columns" :actions="true">
                <tr v-for="(externalClinic, index) in externalClinics" :key="index">
                  <th scope="row">{{ externalClinic.id }}</th>
                  <td>{{ externalClinic.info_service.name }}</td>
                  <td>{{ externalClinic.external_clinic.name_en }}</td>
                  <td>{{ externalClinic.price }}</td>
                  <td>
                    <button class="me-2 btn btn-success" @click="restoreExternalClinic(externalClinic.id)">
                      Restore
                    </button>
                  </td>
                </tr>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No deleted OutPatient clinics found.
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import $ from 'jquery'; // Import jQuery
import MyTable from "../MyTable//MyTable.vue";
import axios from "../../Axios";
export default {
  name: "DeletedExternalClinicServices",
  components: {
    MyTable,
    // Paginate,
  },
  data() {
    return {
      dataTable: [],
      externalClinics: [],
      meta: {},
      searchTerm: "",
      columns: [
        { label: '#', key: 'id' },
        { label: 'Hospital', key: 'name' },
        { label: 'ExternalClinic', key: 'name_en' },
        { label: 'Price', key: 'price' },
        // { label: 'Service Name', key: 'service.name' },
      ],
    };
  },

  async created() {
    this.fetchExternalClinics();
  },
  computed: {
    filteredExternalClinics() {
      if (!this.searchTerm) {
        return this.externalClinics;
      }
      const term = this.searchTerm.toLowerCase();
      return this.externalClinics.filter(
        (externalClinic) =>
          externalClinic.name.toLowerCase().includes(term)
      );
    },
  },

  methods: {
    // fetch externalClinic
    async fetchExternalClinics() {
      try {
        const response = await axios.get(
          `/admin/admin/external-clinic-service/get-delete-soft`
        );
        console.log(response);
        this.externalClinics = response.data.data;
        console.log(this.externalClinics);
        this.meta = response.data.meta;
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
      } catch (error) {
        console.error("Error fetching externalClinics:", error);
      }
    },
    initializeDataTable() {
      this.$nextTick(() => {
        // Ensure the DataTable is initialized only after the DOM is updated
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },
    restoreExternalClinic(id) {
      console.log("restore externalClinics id: ", id);
      axios
        .post(`/admin/admin/exteranl-clinic-service/restore?id=${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchExternalClinics();
        })
        .catch((error) => {
          console.error(error);
        });
    },

  },
};
</script>

<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}
</style>