<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Lab/Scan Booking Details</h1>

            <!-- <span class="mainDescription"
              >Use this page to start from scratch and put your custom
              content</span
            > -->
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-labBookings' }">Back</router-link>
          </div>
          <!-- <ol class="breadcrumb">
            <li class="active">
              <span>Blank Page</span>
            </li>
          </ol> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <div class="booking-details">
              <!-- <h1>Booking Details</h1> -->
              <div class="booking-info">
                <div class="section">
                  <h2>Patient Information</h2>
                  <br>
                  <!-- relative_option: 0
                
                  view_test_prerequisites: 0 -->
                  <p><strong>Name:</strong> {{ name || 'N/A' }}</p>
                  <p><strong>Phone:</strong> {{ phone || 'N/A' }}</p>
                  <p><strong>User Name:</strong> {{ user_name || 'N/A' }}</p>
                  <p><strong>User Phone:</strong> {{ user_phone || 'N/A' }}</p>
                  <p><strong>Location:</strong> {{ location || 'N/A' }}</p>
                  <p><strong>Symptoms:</strong> {{ symptoms || 'N/A' }}</p>
                </div>
                <div class="section">
                  <h2>Appointment Details</h2>
                  <br>
                  <p><strong>Day:</strong> {{ appointment_day || 'N/A' }}</p>
                  <p><strong>Hour:</strong> {{ from }} - {{ to }}</p>
                  <p><strong>Date:</strong> {{ date || 'N/A' }}</p>
                  <p><strong>Appointment Type:</strong> {{ appointment_type || 'N/A' }}</p>
                  <p><strong>Service:</strong> {{ service_name || 'N/A' }}</p>
                  <p><strong>Facility:</strong> {{ lab_name || 'N/A' }}</p>
                </div>


                <div class="section">
                  <h2>Insurance Details</h2>
                  <br>
                  <div v-if="user_insurance.length > 0">
                    <div class="row mb-5" v-for="insurance in user_insurance" :key="insurance.id">
                      <!-- <ul> -->
                      <!-- <li > -->
                      <div class="col-md-6 align-content-center">
                        <strong>Name:</strong> {{ insurance.medical_insruance_name }} <br>
                        <strong>ID Number:</strong> {{ insurance.id_number }} <br>
                        <!-- <strong>Medical Card:</strong> -->
                        <!-- <img :src="`${BASE_URL}/${insurance.photo_of_medical_card}`" alt="Medical Card"> -->
                        <!-- <img :src="`${BASE_URL}/${insurance.photo_of_medical_card}`" alt="Medical Card"
                          style="max-width: 100px;"> -->
                      </div>
                      <div class="col-md-6 align-content-center">
                        <img v-if="insurance.photo_of_medical_card" :src="insurance.photo_of_medical_card"
                          alt="main_image" style="width: 100px; height: 100px;">
                        <p v-else>No image available</p>
                      </div>
                      <!-- </li> -->
                      <!-- </ul> -->
                    </div>
                    <br>
                  </div>
                  <p v-else>No insurance details available.</p>
                </div>

                <div class="section">
                  <h2>Investigations Details</h2>
                  <br>
                  <div v-if="lab_booking_tests.length > 0">
                    <div v-for="(type, index) in lab_booking_tests" :key="type.id"
                      :class="{ 'bottom-border': index !== lab_booking_tests.length - 1 }">
                      <div class="row">
                        <p>{{ index + 1 }}</p>
                        <div class="col-md-6">
                          <p><strong>Test Name :</strong> {{ type.test.name ?? 'N/A' }}</p>
                          <p><strong>Price :</strong> <span class="highlight">{{ type.pricing.price ?? 'N/A'
                              }} EGP</span></p>
                          <p><strong>Discount Percentage :</strong> <span class="highlight">{{
                            type.pricing.discount_percentage
                            ?? 'N/A' }}%</span></p>
                          <p><strong>Price After Discount :</strong> <span class="highlight">{{
                            type.pricing.price_after_discount
                            ?? 'N/A' }} EGP</span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p v-else>No investigation details available.</p>
                </div>

                <div class="section">
                  <h2>Prescriptions</h2>
                  <br>
                  <div class="documents-container" v-if="prescriptions">
                    <div v-for="prescription in prescriptions" :key="prescription.id" class="document">
                      <a :href="prescription.file" target="_blank" @click.prevent="openPdf(prescription.file)">
                        <img v-if="isImage(prescription.file)" :src="prescription.file" alt="Document"
                          class="document-image" />
                        <img v-else-if="isPdf(prescription.file)" src="/assets/images/pdf-icon.png" alt="PDF Document"
                          class="document-image" />
                        <span v-else>{{ getFileName(prescription.file) }}</span>
                      </a>
                    </div>
                  </div>
                  <p v-else>No Prescriptions available.</p>
                </div>

                <!-- <div class="section">
                  <h2>Documents</h2>
                  <br>
                  <div class="documents-container" v-if="documents">
                    <div v-for="document in documents" :key="document.id" class="document">
                      <a :href="document.file" target="_blank" @click.prevent="openPdf(document.file)">
                        <img v-if="isImage(document.file)" :src="document.file" alt="Document" class="document-image" />
                        <img v-else-if="isPdf(document.file)" src="/assets/images/pdf-icon.png" alt="PDF Document"
                          class="document-image" />
                        <span v-else>{{ getFileName(document.file) }}</span>
                      </a>
                    </div>
                  </div>
                  <p v-else>No Documents available.</p>
                </div> -->

                <!-- <div class="section">
                  <h2>Voice Note</h2>
                  <br>
                  <p v-if="!voice_note">No voice note available.</p>
                  <audio v-else controls>
                    <source :src="voice_note" type="audio/ogg">
                    <source :src="voice_note" type="audio/mpeg">
                    <source :src="voice_note" type="audio/wav">
                    <source :src="voice_note" type="audio/opus">
                    <source :src="voice_note" type="audio/webm">
                    Your browser does not support the audio element.
                  </audio>
                </div> -->

                <div class="section status-section">
                  <h2>Status</h2>
                  <div class="form-group">
                    <br>
                    <multiselect v-model="selectedStatus" :options="statusOptions" :multiple="false"
                      placeholder="Select status" label="name" track-by="value"></multiselect>
                  </div>
                  <button @click="updateStatus" class="btn btn-primary mt-3 status-btn">
                    Update Status
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import Multiselect from "vue-multiselect";

export default {
  name: "ShowLabBooking",
  components: {
    Multiselect,
  },
  data() {
    const BASE_URL = "https://allodoctor-backend.developnetwork.net"
    return {
      name: "",
      phone: "",
      location: "",
      symptoms: "",
      user_name: "",
      user_phone: "",
      BASE_URL,

      appointment_day: "",
      from: "",
      to: "",
      appointment_type: "",
      date: "",

      relative_option: "",
      view_test_prerequisites: "",
      availability: "",

      lab_booking_tests: [],
      user_insurance: [],
      documents: [],
      prescriptions: [],
      voice_note: null,

      price: "",
      discount_percentage: "",
      total_before_discount: "",

      service_name: "",
      lab_name: "",
      selectedStatus: null,
      statusOptions: [
        { name: "Pending", value: "Pending" },
        { name: "Confirmed", value: "Confirmed" },
        { name: "Cancelled", value: "Canceled" },
        { name: "Done", value: "Done" },
      ],
    };
  },
  created() {
    this.fetchBooking();
  },
  methods: {

    async fetchBooking() {
      try {
        const response = await axios.get(`/admin/admin/lab-booking/get?id=${this.$route.params.id}`);
        console.log(response);
        const bookingData = response.data.data;
        this.name = bookingData.name ?? null;
        this.phone = bookingData.phone ?? null;
        this.user_name = bookingData.user.name ?? null;
        this.user_phone = bookingData.user.phone ?? null;
        this.location = bookingData.location ?? null;
        this.symptoms = bookingData.symptoms ?? null;

        this.relative_option = bookingData.relative_option ?? null;
        this.availability = bookingData.availability ?? null;
        this.view_test_prerequisites = bookingData.view_test_prerequisites ?? null;
        this.user_insurance = bookingData.user_insurance ?? null;
        this.price = bookingData.price ?? null;

        this.appointment_day = bookingData.appointments.appointment_day.name ?? null;
        this.from = bookingData.appointments.appointment_hour.from ?? null;
        this.to = bookingData.appointments.appointment_hour.to ?? null;
        this.appointment_type = bookingData.appointments.appointment_type ?? null;
        this.date = bookingData.date ?? null;
        this.lab_name = bookingData.appointments.appointment_data.lab_name ?? null;
        this.service_name = bookingData.appointments.appointment_data.service_name ?? null;

        this.selectedStatus = this.statusOptions.find(
          (option) => option.value === bookingData.status
        );
        this.lab_booking_tests = bookingData.lab_booking_tests ?? null;
        this.prescriptions = bookingData.prescriptions ?? null;
        this.documents = bookingData.documents ?? null;
        this.voice_note = bookingData.voice_note ?? null;


      } catch (error) {
        console.error("Error fetching bookingData:", error);
      }
    },
    isImage(file) {
      const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
      const extension = file.split('.').pop().toLowerCase();
      return imageExtensions.includes(extension);
    },
    isPdf(file) {
      const pdfExtension = 'pdf';
      const extension = file.split('.').pop().toLowerCase();
      return extension === pdfExtension;
    },
    getFileName(file) {
      return file.split('/').pop();
    },
    openPdf(url) {
      window.open(url, '_blank');
    },

    async updateStatus() {
      try {
        const formData = new FormData();
        formData.append("id", this.$route.params.id);
        if (this.selectedStatus) {
          formData.append("status", this.selectedStatus.value);
        }

        console.log(formData);
        // const response = await axios.post(`/admin/admin/lab-booking-info/status-update`, formData, {
        const response = await axios.post(`/admin/admin/lab-booking/status-update`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },

        });
        console.log(formData);
        console.log(response.data);
        this.$router.push({ name: "all-labBookings" });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
<style scoped>
.booking-details {
  padding: 20px;
}

.booking-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.section {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
}

.document {
  margin-bottom: 10px;
}

.document-image {
  max-width: 100%;
  max-height: 100px;
}

h2 {
  margin-bottom: 10px;
}

.highlight {
  color: #007bff;
  font-weight: bold;
}

.documents-container {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}

p {
  font-size: 1.1em
}


/* Button Styling */
.status-btn {
  display: block;
  margin-top: 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 10px 15px;
  transition: background-color 0.3s ease;
}

.status-btn:hover {
  background-color: #0056b3;
}
</style>
