<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">All Day Care Bookings</h1>
          </div>
          <div class="col-sm-2">
            <!-- <router-link style="margin-right: 10px" class="btn btn-primary"
              :to="{ name: 'create-booking' }">create</router-link> -->
            <!-- <router-link class="btn btn-success" :to="{ name: 'deleted-bookings' }">Deleted
              Bookings</router-link> -->
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">

            <div v-if="bookings.length > 0">
              <MyTable :columns="columns" :actions="true">
                <tr v-for="(booking, index) in bookings" :key="index">
                  <th scope="row">{{ booking.id }}</th>
                  <td>{{ booking.name ?? 'N/A' }}</td>
                  <td>{{ booking.phone ?? 'N/A' }}</td>
                  <td>{{ booking.info_day_service.day_service.name_en ?? 'N/A' }}</td>
                  <td>{{ booking.info_day_service.price ?? 'N/A' }}</td>
                  <td>{{ booking.date ?? 'N/A' }}</td>
                  <td>{{ booking.info_day_service ? booking.info_day_service.from + ' - ' +
                    booking.info_day_service.to : 'N/A' }}</td>
                  <td>
                    <span :class="statusClass(booking.status)">
                      {{ booking.status ? booking.status : 'Unknown' }}
                    </span>
                  </td>
                  <td>
                    <!-- <button class="me-2 btn btn-danger" @click="deleteBooking(booking.id)">
                      Delete
                    </button> -->
                    <!-- <router-link class="btn btn-primary spacing"
                      :to="{ name: 'edit-labBooking', params: { id: booking.id } }">
                      Edit
                    </router-link> -->
                    <router-link class="badge  bg-info spacing"
                      :to="{ name: 'show-dayServiceBooking', params: { id: booking.id } }">
                      Booking Details
                    </router-link>
                  </td>
                  <!-- <td> -->
                  <!-- <div class="status-container">
                      <div class="status-select" :class="statusClass(booking.status)"
                        @click="toggleStatusDropdown(index)">
                        {{ booking.status || 'Unknown' }}
                      </div>
                      <div v-if="booking.showStatusDropdown" class="status-dropdown">
                        <div v-for="status in ['Confirmed', 'Canceled', 'Pending']" :key="status" class="status-option"
                          :class="statusClass(status)" @click.stop="updateStatus(booking.id, status)">
                          {{ status }}
                        </div>
                      </div>
                    </div> -->
                  <!-- <select v-model="booking.status" @change="updateStatus(booking.id, booking.status)"
                      class="form-select"> -->
                  <!-- <option value="">Pending</option> -->
                  <!-- <option value="Confirmed">Confirmed</option>
                      <option value="Canceled">Canceled</option>
                    </select> -->
                  <!-- </td> -->

                </tr>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No Bookings found.
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
// import { store } from "../../Store";

import axios from "../../Axios";
import $ from 'jquery'; // Import jQuery
import MyTable from "../MyTable//MyTable.vue"; // Import your MyTable component

export default {
  name: "AllBookings",
  components: {
    MyTable,
    // Paginate,
  },
  data() {
    return {
      dataTable: [],
      bookings: [],

      meta: {},
      searchTerm: "",
      columns: [
        { label: '#', key: 'id' },
        { label: 'Patiant Name', key: 'name' },
        { label: 'Patiant Phone', key: 'phone' },
        { label: 'DayCare Name', key: 'info_day_service.day_service.name_en' },
        { label: 'Charge', key: 'info_day_service.price' },
        { label: 'Date', key: 'date' },
        { label: 'Hour', key: 'info_day_service' },
        { label: 'Status', key: 'status' },
      ],
    };
  },

  async created() {
    this.fetchBookings();
  },

  computed: {
    filteredBookings() {
      if (!this.searchTerm) {
        return this.bookings;
      }
      const term = this.searchTerm.toLowerCase();
      return this.bookings.filter((booking) => {
        return (
          (booking.name && booking.name.toLowerCase().includes(term)) ||
          (booking.phone && booking.phone.toLowerCase().includes(term))
        );
      });
    },
  },

  methods: {

    async fetchBookings() {
      try {

        // const params = {};
        // if (store.userRole === 'Doctor') {
        //   params.user_id = store.user ? store.user.id : null;
        // }

        // const response = await axios.get('/admin/admin/booking/all', {
        //   params: params,
        // });

        // try {
        //   const response = await axios.get('/admin/admin/doctor/all', {
        //     headers: {
        //       Authorization: `Bearer ${store.user.access_token}`,
        //     },
        //   });
        const response = await axios.get(
          `/api/admin/day-service/all-booking`
        );
        this.bookings = response.data.data.map(booking => ({
          ...booking,
          showStatusDropdown: false
        }));

        console.log(response);
        this.bookings = response.data.data;
        console.log(" fetching bookings:", response.data.data);
        this.meta = response.data.meta;
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
      } catch (error) {
        console.error("Error fetching bookings:", error);
      }
    },
    toggleStatusDropdown(index) {
      // Close all other dropdowns first
      this.bookings.forEach((b, i) => {
        if (i !== index) b.showStatusDropdown = false;
      });

      // Toggle the clicked booking's dropdown
      this.bookings[index].showStatusDropdown =
        !this.bookings[index].showStatusDropdown;
    },
    statusClass(status) {
      switch (status) {
        case 'Pending':
          return 'badge  bg-warning text-dark'; // Yellow
        case 'Confirmed':
          return 'badge  bg-primary'; // Blue
        case 'Done':
          return 'badge  bg-success'; // Green
        case 'Canceled':
          return 'badge  bg-danger'; // Red
        default:
          return 'badge  bg-secondary'; // Gray for unknown or missing
      }
    },
    initializeDataTable() {
      this.$nextTick(() => {
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },
    async updateStatus(id, status) {
      try {
        const formData = new FormData();
        formData.append("id", id);
        formData.append("status", status);
        console.log(formData);
        const response = await axios.post('/api/admin/day-service/update-booking-status', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log(response.data.data);

        this.bookings = this.bookings.map(booking =>
          booking.id === id ? { ...booking, status, showStatusDropdown: false } : booking
        );

        // if (response.data.data.success) {
        // Find the updated booking in the bookings array and update its status
        // this.bookings = this.bookings.map((booking) =>
        //   booking.id === id ? { ...booking, status } : booking
        // );
        //   console.log(`Status updated for booking ID ${id}:`, response.data.message);
        // } else {
        //   console.error("Failed to update status:", response.data.data);
        // }
        // console.log(`Status updated for booking ID ${id}:`, response.data);
        // // Optionally refetch bookings to sync the UI
        // this.fetchBookings();
      } catch (error) {
        console.error('Error updating status:', error);
      }
    },

    deleteBooking(id) {
      console.log("delete bookings id: ", id);
      axios
        .delete(`/admin/admin/booking/delete?id=${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchBookings();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

};
</script>
<style scoped>
/* .status-container {
  position: relative;
  display: inline-block;
} */

.status-select {
  position: relative;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 20px;
  display: inline-block;
  min-width: 100px;
  text-align: center;
}

.status-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.status-option {
  padding: 5px 10px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.status-option:hover {
  opacity: 0.8;
}


.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}


.btn {
  padding: 0.5em 1em;
  border-radius: 0.25rem;
  font-size: 0.9em;
  text-align: center;
  display: inline-block;
  min-width: 100px;
}

/* Styling for the Current Status Badge */
.badge {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.9em;
  text-transform: capitalize;
}

/* Different Badge Colors Based on Status */
.badge-pending {
  background-color: #ffc107;
  color: #fff;
}

.badge-approved {
  background-color: #28a745;
  color: #fff;
}

.badge-rejected {
  background-color: #dc3545;
  color: #fff;
}

.badge-inprogress {
  background-color: #17a2b8;
  color: #fff;
}

/* .btn-warning {
  background-color: #ffc107;
  color: #212529;
}

.btn-primary {
  background-color: #0d6efd;
  color: #fff;
}

.btn-success {
  background-color: #198754;
  color: #fff;
}

.btn-danger {
  background-color: #dc3545;
  color: #fff;
}

.btn-secondary {
  background-color: #6c757d;
  color: #fff;
} */
</style>