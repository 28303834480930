<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">All Cities</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'create-city' }">create</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <div v-if="cities.length > 0">
              <MyTable :columns="columns" :actions="true">
                <tr v-for="(city, index) in filteredCities" :key="index">
                  <th scope="row">{{ city.id }}</th>
                  <td>{{ city.name_ar }}</td>
                  <td>{{ city.name_en }}</td>
                  <td>
                    <button class="me-2 btn btn-danger" @click="deleteCity(city.id)">
                      Delete
                    </button>
                    <router-link class="btn btn-primary spacing" :to="{ name: 'edit-city', params: { id: city.id } }">
                      Edit
                    </router-link>
                  </td>
                </tr>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No Cities found.
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import $ from 'jquery'; // Import jQuery
import MyTable from "../MyTable//MyTable.vue"; // Import your MyTable component

export default {
  name: "AllCities",

  components: {
    MyTable,
    // Paginate,
  },
  data() {
    return {
      dataTable: [],
      cities: [],
      meta: {},
      searchTerm: "",
      columns: [
        { label: '#', key: 'id' },
        { label: 'Name Arabic', key: 'name_ar' },
        { label: 'Name English', key: 'name_en' },
      ],
    };
  },

  async created() {
    this.fetchCities();
  },

  computed: {
    filteredCities() {
      if (!this.searchTerm) {
        return this.cities;
      }
      const term = this.searchTerm.toLowerCase();
      return this.cities.filter(
        (city) =>
          city.name.toLowerCase().includes(term) ||
          city.postal_code.toLowerCase().includes(term)
      );
    },
  },

  methods: {
    // fetch cities
    async fetchCities() {
      try {
        const response = await axios.get(
          `/admin/admin/city/all`
        );
        this.cities = response.data.data;
        this.meta = response.data.meta;
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    },
    initializeDataTable() {
      this.$nextTick(() => {
        // Ensure the DataTable is initialized only after the DOM is updated
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },
    deleteCity(id) {
      console.log("delete city id: ", id);
      axios
        .delete(`/admin/admin/city/delete/${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchCities();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}
</style>