<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">All Emergencies</h1>
          </div>
          <div class="col-sm-2">
            <!-- <router-link style="margin-right: 10px" class="btn btn-primary"
              :to="{ name: 'create-booking' }">create</router-link> -->
            <!-- <router-link class="btn btn-success" :to="{ name: 'deleted-bookings' }">Deleted
              Bookings</router-link> -->
          </div>

        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">

            <div v-if="emergencyBookings.length > 0">
              <MyTable :columns="columns" :actions="true">
                <tr v-for="(booking, index) in emergencyBookings" :key="index">
                  <th scope="row">{{ booking.id }}</th>
                  <!-- Conditionally render name and phone -->
                  <td v-if="booking.is_me = 1">
                    {{ booking.name }}
                  </td>
                  <td v-else>
                    {{ booking.patient_name }}
                  </td>

                  <td v-if="booking.is_me = 0">
                    {{ booking.phone }}
                  </td>
                  <td v-else>
                    {{ booking.patient_phone }}
                  </td>

                  <!-- <td>{{ booking.name }}</td>
                  <td>{{ booking.phone }}</td>
                  <td>{{ booking.patient_name }}</td>
                  <td>{{ booking.patient_phone }}</td> -->
                  <td>{{ booking.district ? booking.district.name : 'N/A' }}</td>
                  <td> {{ booking.accept_terms == 1 ? "True" : "False" }}</td>
                  <!-- <td>{{ booking.accept_terms = 1 ? "True" : "False" }}</td> -->
                  <!-- <td>
                    <span :class="statusClass(booking.status ? booking.status : 'unknown')">
                      {{ booking.status ? booking.status : 'Unknown' }}
                    </span>
                  </td> -->
                  <td>
                    <!-- <button class="me-2 btn btn-danger" @click="deleteBooking(booking.id)">
                      Delete
                    </button> -->
                    <router-link class="badge  bg-info spacing"
                      :to="{ name: 'show-emergency', params: { id: booking.id } }">
                      Booking Details
                    </router-link>
                  </td>
                </tr>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No Bookings found.
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import $ from 'jquery';
import MyTable from "../MyTable//MyTable.vue"; // Import your MyTable component

export default {
  name: "AllEmergencies",
  components: {
    MyTable,
    // Paginate,
  },
  data() {
    return {
      dataTable: [], // Store DataTable instance
      emergencyBookings: [],
      meta: {},
      searchTerm: "",
      columns: [
        { label: '#', key: 'id' },
        { label: 'Name', key: 'name' },
        { label: 'Phone', key: 'phone' },
        { label: 'District', key: 'district' },
        { label: 'Terms', key: 'accept_terms' },
        // { label: 'Status', key: 'status' },
      ],
    };
  },

  async created() {
    this.fetchBookings();
  },

  computed: {
    filteredBookings() {
      if (!this.searchTerm) {
        return this.emergencyBookings;
      }
      const term = this.searchTerm.toLowerCase();
      return this.emergencyBookings.filter(
        (booking) =>
          booking.name.toLowerCase().includes(term)
      );
    },
  },

  methods: {
    async fetchBookings() {
      try {

        const response = await axios.get(
          `/admin/admin/emergency/all`
        );
        console.log(response);
        this.emergencyBookings = response.data.data;
        console.log(" fetching bookings:", response.data.data);
        this.meta = response.data.meta;
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
      } catch (error) {
        console.error("Error fetching bookings:", error);
      }
    },
    statusClass(status) {
      switch (status.toLowerCase()) {
        case 'pending':
          return 'badge  bg-warning text-dark'; // Yellow
        case 'confirmed':
          return 'badge  bg-primary'; // Blue
        case 'completed':
          return 'badge  bg-success'; // Green
        case 'cancelled':
          return 'badge  bg-danger'; // Red
        default:
          return 'badge  bg-secondary'; // Gray for unknown or missing
      }
    },

    initializeDataTable() {
      this.$nextTick(() => {
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },
    deleteBooking(id) {
      console.log("delete bookings id: ", id);
      axios
        .delete(`/admin/admin/booking/delete?id=${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchBookings();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

};
</script>
<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}


.btn {
  padding: 0.5em 1em;
  border-radius: 0.25rem;
  font-size: 0.9em;
  text-align: center;
  display: inline-block;
  min-width: 100px;
}

/* Styling for the Current Status Badge */
.badge {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.9em;
  text-transform: capitalize;
}

/* Different Badge Colors Based on Status */
.badge-pending {
  background-color: #ffc107;
  color: #fff;
}

.badge-approved {
  background-color: #28a745;
  color: #fff;
}

.badge-rejected {
  background-color: #dc3545;
  color: #fff;
}

.badge-inprogress {
  background-color: #17a2b8;
  color: #fff;
}

/* .btn-warning {
  background-color: #ffc107;
  color: #212529;
}

.btn-primary {
  background-color: #0d6efd;
  color: #fff;
}

.btn-success {
  background-color: #198754;
  color: #fff;
}

.btn-danger {
  background-color: #dc3545;
  color: #fff;
}

.btn-secondary {
  background-color: #6c757d;
  color: #fff;
} */
</style>