<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Create New User</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-users' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="createUser" enctype="multipart/form-data">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">Name</label>
                    <input type="text" class="form-control" placeholder="Enter Name" v-model="name" />
                    <span class="error text-small block" v-if="errors.name">{{ errors.name }}</span>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">Email</label>
                    <input type="email" class="form-control" placeholder="Enter Email" v-model="email" />
                    <span class="error text-small block" v-if="errors.name">{{ errors.email }}</span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="block">
                      Gender
                    </label>
                    <div class="clip-radio radio-primary">
                      <input type="radio" id="female" v-model="gender" value="female">
                      <label for="female">
                        Female
                      </label>
                      <input type="radio" id="male" v-model="gender" value="male" checked="checked">
                      <label for="male">
                        Male
                      </label>
                    </div>
                    <span class="error text-small block" v-if="errors.gender">{{ errors.gender }}</span>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="infoServiceName">Phone</label>
                    <input type="text" class="form-control" placeholder="Enter Phone" v-model="phone" required />
                    <span class="error text-small block" v-if="errors.phone">{{ errors.phone }}</span>
                  </div>
                </div>
                <div class="form-group ">
                  <label class="text-">District</label>
                  <multiselect v-model="selectedDistrict" :options="districts" :searchable="true" label="name"
                    track-by="id" placeholder="Select a District" :multiple="false"></multiselect>
                  <span class="error text-small block" v-if="errors.district_id">{{ errors.selectedDistrict }}</span>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Age</label>
                    <input type="number" class="form-control" placeholder="Enter Age" v-model="age" />
                    <span class="error text-small block" v-if="errors.age">{{ errors.age }}</span>
                  </div>
                </div>

                <div class="form-group">
                  <label> Image Upload </label>
                  <div class="fileinput fileinput-new" data-provides="fileinput">
                    <div class="fileinput-new thumbnail">
                      <img src="/assets/images/avatar-1-xl.jpg" alt="" />
                    </div>
                    <div class="fileinput-preview fileinput-exists thumbnail"></div>
                    <div class="user-edit-image-buttons">
                      <span class="btn btn-azure btn-file"><span class="fileinput-new"><i class="fa fa-picture"></i>
                          Select image</span><span class="fileinput-exists"><i class="fa fa-picture"></i> Change</span>
                        <input type="file" name="image" @change="handleFileChange" />
                      </span>
                      <a href="#" class="btn fileinput-exists btn-red" data-dismiss="fileinput">
                        <i class="fa fa-times"></i> Remove
                      </a>
                    </div>
                  </div>
                  <span class="error text-small block" v-if="errors.image">{{ errors.image }}</span>
                </div>

                <div class="form-group">
                  <label class="text-">Select Role</label>
                  <multiselect v-model="selectedRole" :options="roles" :searchable="true" label="name" track-by="id"
                    placeholder="Select Role" :multiple="false" aria-required="true"></multiselect>
                  <span class="error text-small block" v-if="errors.role_id">{{ errors.role_id }}</span>
                </div>

                <button class="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import Multiselect from "vue-multiselect";

export default {
  name: "CreateUser",
  components: {
    Multiselect,
  },
  data() {
    return {
      name: "",
      email: "",
      gender: "",
      phone: "",
      active: true,
      default_language: "en",
      selectedDistrict: "",
      districts: [],
      age: "",
      image: null,
      medicalInsurances: [],
      selectedMedicalInsurance: [],
      roles: [],
      selectedRole: "",
      errors: {}, // Store validation errors
    };
  },
  created() {
    this.fetchRoles();
    this.fetchDistricts();
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.image = file;  // Update with the selected file
      }
    },
    async fetchDistricts() {
      try {
        const response = await axios.get("/admin/admin/district/all");
        this.districts = response.data.data;
        this.meta = response.data.meta;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchRoles() {
      try {
        const token = localStorage.getItem("access_token");
        const response = await axios.get(`/admin/admin/role/all`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
          }
        });
        this.roles = response.data.data;
        this.meta = response.data.meta;
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    },
    validateForm() {
      this.errors = {};

      // Name validation
      if (this.name && this.name.length > 255) {
        this.errors.name = "Name must be less than 255 characters.";
      }

      // Gender validation
      if (this.gender && !['male', 'female'].includes(this.gender)) {
        this.errors.gender = "['Gender must be either 'male' or 'female'.']";
      }

      // Phone validation
      if (!this.phone) {
        this.errors.phone = "Phone is required.";
      } else if (!/^\d{11}$/.test(this.phone)) {
        this.errors.phone = "['Phone must be 11 digits.']";
      }

      // District validation
      if (this.selectedDistrict && !this.districts.some(district => district.id === this.selectedDistrict.id)) {
        this.errors.district_id = "['Invalid district selected.']";
      }

      // Age validation
      if (this.age && (isNaN(this.age) || this.age < 18)) {
        this.errors.age = "['Age must be a number and at least 18.']";
      }

      // Role validation
      if (!this.selectedRole) {
        this.errors.role_id = "['Role is required.']";
      }

      // Image validation
      if (this.image && !['image/jpeg', 'image/jpg', 'image/png'].includes(this.image.type)) {
        this.errors.image = "['Image must be a JPEG, JPG, or PNG file.']";
      }

      return Object.keys(this.errors).length === 0;
    },
    async createUser() {
      if (!this.validateForm()) {
        return; // Stop if validation fails
      }
      try {
        const formData = new FormData();
        formData.append("name", this.name);
        formData.append("email", this.email);
        formData.append("gender", this.gender);
        formData.append("phone", this.phone);
        formData.append("active", this.active ? 1 : 0);
        if (this.selectedDistrict) {
          formData.append("district_id", this.selectedDistrict.id);
        }
        formData.append("age", this.age);
        formData.append("default_language", this.default_language);
        formData.append("role_id", this.selectedRole.id);
        if (this.image) {
          formData.append("image", this.image); // Append the selected file
        }
        this.selectedMedicalInsurance.forEach((medicalInsurance, index) => {
          formData.append(`medical_insurance[${index}]`, medicalInsurance.id);
        });

        const response = await axios.post("admin/admin/user/create", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log(response.data);
        console.log(formData);
        this.$router.push({ name: "all-users" });
      } catch (error) {
        console.error(error);
        if (error.response && error.response.status === 422) {
          this.errors = error.response.data.errors;
          console.log("Validation errors:", this.errors);
        } else {
          console.error("Error creating doctor:", error);
        }
      }
    },
  },
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  width: 100%;
  /* max-width: 500px; Optional: Max width for better responsiveness */
}

form>.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}

.error {
  color: #a94442;
  font-size: 0.875em;
}
</style>