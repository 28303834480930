<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Create Doctor</h1>

            <!-- <span class="mainDescription"
              >Use this page to start from scratch and put your custom
              content</span
            > -->
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-doctors' }">Back</router-link>
          </div>
          <!-- <ol class="breadcrumb">
            <li class="active">
              <span>Blank Page</span>
            </li>
          </ol> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="createDoctor">
              <div class="row mb-2">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">English Name</label>
                    <input type="text" class="form-control" placeholder="Enter English Name" v-model="name_en" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="labName">Arabic Name</label>
                    <input type="text" class="form-control" placeholder="Enter Arabic Name" v-model="name_ar" />
                  </div>
                </div>
                <!-- <div class="form-group col-5">
                  <label>Phone</label>
                  <input type="text" class="form-control" placeholder="Enter Phone" v-model="phone" />
                </div> -->
                <div class="form-group col-md-4">
                  <label class="text-">English Title </label>
                  <input type="text" class="form-control" placeholder="Enter English Title " v-model="title_en" />
                </div>

                <div class="form-group col-md-4">
                  <label class="text-">Arabic Title</label>
                  <input type="text" class="form-control" placeholder="Enter Arabic Title" v-model="title_ar" />
                </div>


                <div class=" form-group col-md-12 mb-2">
                  <label>English Title Type</label>
                  <select class="form-control" v-model="title_type_en">
                    <option value="">Select Title Type</option>
                    <option value="Consultant">Consultant</option>
                    <option value="Specialist">Specialist</option>
                    <option value="Professor">Professor</option>
                    <option value="Lecturer">Lecturer</option>
                  </select>
                </div>
                <div class=" form-group col-md-12 mb-2">
                  <label>Arabic Title Type</label>
                  <select class="form-control" v-model="title_type_ar">
                    <option value="">Select Title Type</option>
                    <option value="استشاري">استشاري</option>
                    <option value="أخصائي">أخصائي</option>
                    <option value="أستاذ">أستاذ</option>
                    <option value="محاضر">محاضر</option>
                  </select>
                </div>
                <div class=" form-group col-md-12 mb-2">
                  <label>Gender</label>
                  <select class="form-control" v-model="gender">
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>
                <div class="form-group col-md-3">
                  <label class="text-">price</label>
                  <input type="number" class="form-control" placeholder="Enter price" v-model="price" />
                </div>

                <div class="form-group col-md-3">
                  <label class="text-">Price After Discount</label>
                  <input type="number" class="form-control" placeholder="Enter Price After Discount"
                    v-model="price_after_discount" />
                </div>

                <div class="form-group col-md-4">
                  <label class="text-">Experience</label>
                  <input type="number" class="form-control" placeholder="Enter Experience" v-model="experience" />
                </div>
                <div class="form-group ">
                  <label class="text-">District</label>
                  <multiselect v-model="district_id" :options="districts" :searchable="true" label="name" track-by="id"
                    placeholder="Select a District" :multiple="false"></multiselect>
                  <span class="error text-small block" v-if="errors.district_id">{{
                    errors.district_id }}</span>
                </div>
                <div class="form-group col-md-12">
                  <label class="text-">English Description</label>
                  <textarea class="form-control" rows="3" v-model="description_en"></textarea>
                </div>

                <div class="form-group col-md-12">
                  <label class="text-">Arabic Description</label>
                  <textarea class="form-control" rows="3" v-model="description_ar"></textarea>
                </div>

                <div class="form-group">
                  <label class="text-">Medical Insurances</label>
                  <multiselect v-model="selectedMedicalInsurances" :options="medicalInsurances" :searchable="true"
                    label="name_en" track-by="id" placeholder="Select Insurances" :multiple="true"></multiselect>
                  <span class="error text-small block" v-if="errors.selectedMedicalInsurances">{{
                    errors.selectedMedicalInsurances }}</span>
                </div>
                <div class="form-group">
                  <label> Image Upload </label>
                  <div class="fileinput fileinput-new" data-provides="fileinput">
                    <div class="fileinput-new thumbnail">
                      <img src="/assets/images/avatar-1-xl.jpg" alt="" />
                    </div>
                    <div class="fileinput-preview fileinput-exists thumbnail"></div>
                    <div class="user-edit-image-buttons">
                      <span class="btn btn-azure btn-file"><span class="fileinput-new"><i class="fa fa-picture"></i>
                          Select image</span><span class="fileinput-exists"><i class="fa fa-picture"></i>
                          Change</span>
                        <input type="file" name="main_image" @change="handleMainImageChange" />
                      </span>
                      <a href="#" class="btn fileinput-exists btn-red" data-dismiss="fileinput">
                        <i class="fa fa-times"></i> Remove
                      </a>
                    </div>
                  </div>
                  <span class="error text-small block" v-if="errors.main_image">{{
                    errors.main_image }}</span>
                </div>

                <div class="form-group">
                  <label>Additional Images</label>
                  <div class="multiple-image-upload">
                    <div class="upload-container">
                      <input type="file" class="form-control" accept="image/*" multiple @change="handleMultipleImages"
                        ref="multipleImages" />
                    </div>
                    <!-- Preview section -->
                    <div v-if="imagePreviewUrls.length" class="image-preview-container">
                      <h5>Selected Images:</h5>
                      <div class="row g-3">
                        <div v-for="(preview, index) in imagePreviewUrls" :key="index" class="col-md-3 col-sm-4 col-6">
                          <div class="image-preview-wrapper">
                            <img :src="preview" alt="Preview" class="img-thumbnail preview-image"
                              @click="removeImage(index)" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <span class="error text-small block" v-if="errors.imagePreviewUrls">{{
                    errors.imagePreviewUrls }}</span> -->
                </div>
                <div class="form-group">
                  <button class="btn btn-success add-variant mb-3" @click="addVariant" type="button">
                    Add Variant
                  </button>
                  <span class="error text-small block" v-if="errors.data">{{
                    errors.data }}</span>
                  <div class="row">
                    <div class="col-md-6" v-for="(variant, index) in data" :key="index">
                      <div class="card mb-3">
                        <div class="card-body">
                          <h5 class="card-title">Variant {{ index + 1 }}</h5>
                          <div class="form-group col-md-12 mb-2">
                            <label>Select services</label>
                            <select class="form-control" v-model="variant.service_id" @change="handleServiceChange(variant.service_id, variant)
                              ">
                              <option value="">Select Service</option>
                              <option value="1">Hospitals</option>
                              <option value="2">Clinics</option>
                            </select>
                            <span class="error text-small block" v-if="errors[`data.${index}.sub_service_id`]">
                              {{ errors[`data.${index}.sub_service_id`][0] }}
                            </span>
                          </div>
                          <div class="form-group col-md-12 mb-2">
                            <label class="text-">Sub service</label>
                            <multiselect v-model="variant.subService_id" :options="variant.selectSubService"
                              :searchable="true" label="name" track-by="id" placeholder="Select a Sub service"
                              :multiple="false">
                            </multiselect>
                            <span class="error text-small block" v-if="errors[`data.${index}.sub_service_id`]">
                              {{ errors[`data.${index}.sub_service_id`][0] }}
                            </span>
                          </div>
                          <div class="form-group col-md-12 mb-2">
                            <label class="text-">Speciality</label>
                            <multiselect v-model="variant.specialty_id" :options="specialities" :searchable="true"
                              label="name" track-by="name" placeholder="Select a Speciality" :multiple="false">
                            </multiselect>
                            <span class="error text-small block" v-if="errors[`data.${index}.specialty_id`]">
                              {{ errors[`data.${index}.specialty_id`][0] }}
                            </span>
                          </div>
                          <div class="form-group col-md-12 mb-2">
                            <label class="text-">Hospitals/Clinics</label>
                            <multiselect v-model="variant.info_service_id" :options="variant.infoServices"
                              :searchable="true" label="name" track-by="id" placeholder="Select a Hospital/Clinic"
                              :multiple="false" @select="handleInfoServiceChange(variant.info_service_id.id, variant)">
                            </multiselect>
                            <!-- <p>Selected Infomation: {{ variant.info_service_id.id }}</p> -->
                            <span class="error text-small block" v-if="errors[`data.${index}.info_service_id`]">
                              {{ errors[`data.${index}.info_service_id`][0] }}
                            </span>
                          </div>
                          <div class="form-group col-md-12 mb-2" v-if="variant.subService_id">
                            <label class="text-">OutPatient Clinics</label>
                            <multiselect v-model="variant.external_clinic_id" :options="variant.external_clinics"
                              :searchable="true" label="name_en" track-by="id" placeholder="Select a OutPatient Clinic"
                              :multiple="false"></multiselect>
                            <span class="error text-small block"
                              v-if="errors[`data.${index}.external_clinic_service_id`]">
                              {{ errors[`data.${index}.external_clinic_service_id`][0] }}
                            </span>
                          </div>
                          <button class=" btn btn-danger mt-3" @click="removeVariant(index)">
                            Remove Variant
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <!-- <div v-if="Object.keys(errors).length > 0" class="error text-small block">
                  <ul>
                    <li v-for="(error, key) in errors" :key="key">
                      {{ error }}
                    </li>
                  </ul>
                </div> -->
              </div>
              <button type="submit" class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import { store } from "../../Store";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
export default {
  name: "CreateDoctor",
  components: {
    Multiselect,
  },
  data() {
    return {
      name_en: "",
      name_ar: "",
      // phone: "",
      title_en: "",
      title_ar: "",
      title_type_en: "",
      title_type_ar: "",
      gender: "",
      price: "",
      price_after_discount: "",
      experience: "",
      description_en: "",
      description_ar: "",
      user_id: "",
      main_image: null,
      additionalImages: [], // For storing File objects
      imagePreviewUrls: [], // For storing preview URLs

      medicalInsurances: [],
      selectedMedicalInsurances: [],

      district_id: "",
      districts: [],

      selectService: [
        { id: 1, name: "Hospitals" },
        { id: 2, name: "Clinics" },
      ],

      // services: [],
      // subServices: [],
      // infoServices: [],
      // external_clinics: [],
      // selectSubService: [],
      specialities: [],

      data: [],
      branches: [],

      errors: {},
    };
  },

  async created() {
    // this.fetchServices(); // Corrected method name
    this.fetchDistricts(); // Corrected method name
    // this.fetchInfoServices(); // Corrected method name
    this.fetchSpecialities(); // Corrected method name
    this.fetchMedicalInsurances();
  },

  methods: {
    handleMainImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.main_image = file;
      }
      // event.target.value = null;
    },
    handleMultipleImages(event) {
      const files = Array.from(event.target.files);
      files.forEach(file => {
        if (file.type.startsWith('image/')) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imagePreviewUrls.push(e.target.result);
          };
          reader.readAsDataURL(file);
          this.additionalImages.push(file);
        }
      });
      event.target.value = '';
    },

    removeImage(index) {
      this.additionalImages.splice(index, 1);
      this.imagePreviewUrls.splice(index, 1);
    },
    validateForm() {
      this.errors = {};

      // Validate district_id
      if (!this.district_id) {
        this.errors.district_id = "District is required.";
      }

      // Validate main_image
      if (this.main_image && !['image/jpeg', 'image/jpg', 'image/png'].includes(this.main_image.type)) {
        this.errors.main_image = "Image must be a JPEG, JPG, or PNG file.";
      }

      // Validate variants
      if (this.data.length === 0) {
        this.errors.data = "At least one variant is required.";
      } else {
        this.data.forEach((variant, index) => {
          const variantErrors = {};

          if (!variant.service_id) {
            variantErrors.service_id = "Service is required.";
          }

          if (!variant.specialty_id) {
            variantErrors.specialty_id = "Specialty is required.";
          }

          if (variant.service_id == 1 && !variant.subService_id) {
            variantErrors.subService_id = "Sub Service is required for Hospitals.";
          }

          if (!variant.info_service_id && !variant.external_clinic_id) {
            variantErrors.info_service_id = "Info Service or External Clinic is required.";
          }

          if (Object.keys(variantErrors).length > 0) {
            this.errors[`data.${index}`] = variantErrors;
          }
        });
      }

      return Object.keys(this.errors).length === 0;
    },

    async createDoctor() {
      try {

        if (!this.validateForm()) {
          return;
        }
        const formData = new FormData();
        formData.append("name_en", this.name_en);
        formData.append("name_ar", this.name_ar);
        // formData.append("phone", this.phone);
        formData.append("price", Number(this.price)); // Ensure price is a number
        formData.append(
          "price_after_discount",
          Number(this.price_after_discount)
        ); // Ensure price_after_discount is a number
        formData.append("description_ar", this.description_ar);
        formData.append("description_en", this.description_en);
        formData.append("experience", this.experience);
        formData.append("gender", this.gender);
        formData.append("title_type_en", this.title_type_en);
        formData.append("title_type_ar", this.title_type_ar);
        formData.append("title_ar", this.title_ar);
        formData.append("title_en", this.title_en);
        formData.append("district_id", this.district_id.id);
        const user_id = store.user ? store.user.id : null;
        formData.append("user_id", user_id);
        if (this.main_image) {
          formData.append("main_image", this.main_image);
        }
        this.additionalImages.forEach((image) => {
          formData.append(`images[]`, image);
        });
        this.selectedMedicalInsurances.forEach((medicalInsurance, index) => {
          formData.append(`medical_insurance[${index}]`, medicalInsurance.id);
        });

        // Check if `data` is included
        this.data.map((variant, index) => {
          formData.append(
            `data[${index}][specialty_id]`,
            variant.specialty_id.id
          );

          if (variant.external_clinic_id) {
            formData.append(
              `data[${index}][external_clinic_service_id]`,
              variant.external_clinic_id.id
            );
          } else {
            formData.append(
              `data[${index}][info_service_id]`,
              variant.info_service_id.id
            );

          }
          if (variant.subService_id) {
            formData.append(
              `data[${index}][sub_service_id]`,
              variant.subService_id.id
            );
          } else {
            formData.append(
              `data[${index}][service_id]`,
              variant.service_id
            );
          }
        });
        console.log(formData); // Check if `data` is included
        // Send the form data using Axios
        const response = await axios.post(
          "/admin/admin/doctor/create",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response.data);
        this.$router.push({ name: "all-doctors" });
      } catch (error) {
        console.error(error);
        if (error.response && error.response.status === 422) {
          // Handle validation errors
          this.errors = error.response.data.errors;
          console.log("Validation errors:", this.errors);
        } else {
          console.error("Error creating doctor:", error);
        }
      }
    },
    addVariant() {
      this.data.push({
        service_id: [], // Ensure this is initialized as an array
        specialty_id: [], // Ensure this is initialized as an array
        info_service_id: [], // Ensure this is initialized as an array
        subService_id: [], // Ensure this is initialized as an array
        external_clinic_id: [], // Ensure this is initialized as an array
        selectSubService: [], // Store sub-services for this variant
        infoServices: [], // Store info services for this variant
        external_clinics: [], // Store external clinics for this variant
      });
      this.logVariants();
    },
    removeVariant(index) {
      this.data.splice(index, 1);
    },
    logVariants() {
      console.log(this.variants);
    },
    // getVariants() {
    //   return this.data.map((variant) => {
    //     return {
    //       specialty_id: variant.specialty_id.id,
    //       info_service_id: variant.info_service_id.id,
    //       subService_id: variant.subService_id.id,
    //       service_id: variant.service_id,
    //       external_clinic_id: variant.external_clinic_id.id,
    //     };
    //   });
    // },
    async fetchMedicalInsurances() {
      try {
        const response = await axios.get("/admin/admin/medical-insurance/all");
        console.log("medical-insurance", response.data.data);
        this.medicalInsurances = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchDistricts() {
      // Corrected method name
      try {
        const response = await axios.get(`/admin/admin/district/all`);
        this.districts = response.data.data;
        this.meta = response.data.meta;
        console.log("districts", this.districts);
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    },
    async fetchSubServices(serviceId) {
      try {
        const response = await axios.get(
          `/admin/admin/sub-service/all?service_id=${serviceId}`
        );
        this.subServices = response.data.data;
        this.meta = response.data.meta;
        console.log("SubServices", response.data.data);
      } catch (error) {
        console.error("Error fetching infoServices:", error);
      }
    },
    async fetchSpecialities() {
      // Corrected method name
      try {
        const response = await axios.get(`/admin/admin/speciality/all`);
        this.specialities = response.data.data;
        this.meta = response.data.meta;
        console.log("specialities", this.specialities);
      } catch (error) {
        console.error("Error fetching specialities:", error);
      }
    },

    async fetchInfoServices(serviceId, variant) {
      try {
        const response = await axios.get(
          `/admin/admin/info-service/all?service_id=${serviceId}`
        );
        variant.infoServices = response.data.data;
        console.log("infoServices for variant:", variant.infoServices);
      } catch (error) {
        console.error("Error fetching infoServices:", error);
      }
    },
    async fetchExternalClinics(infoServiceId, variant) {
      console.log("fetchExternalClinics:");
      try {
        const response = await axios.get(
          // `/admin/admin/external-clinic/all?service_id=${serviceId}`
          `/admin/admin/info-service/get?id=${infoServiceId}`
        );
        variant.external_clinics = response.data.data.external_clinic_service_ids || [];
        console.log('externalclinics', this.external_clinics);
        this.meta = response.data.meta;
      } catch (error) {
        console.error("Error fetching externalClinics:", error);
      }
    },
    handleServiceChange(selectedService, variant) {
      console.log("Selected service:", selectedService);
      console.log("Selected variant:", variant);
      variant.subService_id = ""; // Clear subService selection
      variant.info_service_id = ""; // Clear info service selection
      variant.external_clinic_id = ""; // Clear external clinic selection
      if (selectedService) {
        this.fetchInfoServices(selectedService, variant); // Pass the variant
        if (selectedService == 1) {

          variant.selectSubService = [{ id: 4, name: "OutPatient Clinics" }];
          console.log("Selected selectSubService:", variant.selectSubService)
        } else {
          variant.selectSubService = [];
        }
      } else {
        variant.infoServices = [];
        variant.selectSubService = [];
      }
    },

    handleInfoServiceChange(infoServiceId, variant) {
      console.log("Selected infoservice:", infoServiceId);

      variant.external_clinic_id = ""; // Clear external clinic selection
      if (infoServiceId) {
        this.fetchExternalClinics(infoServiceId, variant);
      } else {
        variant.external_clinics = []; // Clear clinics if no info service is selected
      }
    }
  },
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  width: 100%;
  /* max-width: 500px; Optional: Max width for better responsiveness */
}

form>.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}

.multiple-image-upload {
  margin-bottom: 1.5rem;
}

.image-preview-container {
  margin-top: 1rem;
}

.image-preview-wrapper {
  position: relative;
  margin-bottom: 1rem;
}

.preview-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.remove-image {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 2px 8px;
  font-size: 12px;
}

.upload-container {
  margin-bottom: 1rem;
}

.error {
  color: #a94442;
  font-size: 0.875em;
}
</style>