import { createRouter, createWebHistory } from "vue-router";
import MyTable from '../components/MyTable/MyTable.vue'; // Adjust the path as necessary


import LiveChat from "../components/Chat/LiveChat.vue";

import HomeView from "../views/HomeView.vue";
// import AboutView from "../views/AboutView.vue";
import LoginView from "../views/Auth/LoginView.vue";
import { store } from "@/Store"; // Import the store


//Users routes
import AllUsers from "../components/Users/AllUsers.vue";
import CreateUser from "../components/Users/CreateUser.vue";
import EditUser from "../components/Users/EditUser.vue";
import DeletedUsers from "../components/Users/DeletedUsers.vue";
//Users routes

//start roles routes
import AllRoles from "../components/Roles/AllRoles.vue";
import CreateRole from "../components/Roles/CreateRole.vue";
import EditRole from "../components/Roles/EditRole.vue";
//start roles routes

//contact Informations routes
// import AllContactInformations from "../components/ContactInformations/AllContactInformations.vue";
// import AllCountries from "@/components/Countries/AllCountries.vue";

//Start Countries routes
import AllCountries from "../components/Countries/AllCountries.vue";
import CreateCountry from "../components/Countries/CreateCountry.vue";
import EditCountry from "../components/Countries/EditCountry.vue";
//End Countries routes

//Start Cities routes
import AllCities from "../components/Cities/AllCities.vue";
import CreateCity from "../components/Cities/CreateCity.vue";
import EditCity from "../components/Cities/EditCity.vue";
//Start Cities routes

//Start Districts routes
import AllDistricts from "../components/Districts/AllDistricts.vue";
import CreateDistrict from "../components/Districts/CreateDistrict.vue";
import EditDistrict from "../components/Districts/EditDistrict.vue";
//Start Cities routes

//Start Service routes
import AllServices from "../components/Services/AllServices.vue";
import CreateService from "../components/Services/CreateService.vue";
import EditService from "../components/Services/EditServices.vue";
//End Service routes

//Start SubService routes
import AllSubServices from "../components/SubServices/AllSubServices.vue";
import CreateSubService from "../components/SubServices/CreateSubService.vue";
import EditSubService from "../components/SubServices/EditSubServices.vue";
//End SubService routes

//Start Doctors routs
import AllDoctors from "../components/Doctors/AllDoctors.vue";
import CreateDoctor from "../components/Doctors/CreateDoctor.vue";
import EditDoctor from "../components/Doctors/EditDoctor.vue";
import DeletedDoctors from "../components/Doctors/DeletedDoctors.vue";


//Start Specialty routes
import AllSpecialities from "../components/Specialities/AllSpecialities.vue";
import CreateSpeciality from "../components/Specialities/CreateSpeciality.vue";
import EditSpeciality from "../components/Specialities/EditSpeciality.vue";
import DeletedSpecialities from "../components/Specialities/DeletedSpecialities.vue";

//End Service routes

//Start InfoService routes
import AllInfoServices from "../components/InfoServices/AllInfoServices.vue";
import CreateInfoService from "../components/InfoServices/CreateInfoService.vue";
import EditInfoService from "../components/InfoServices/EditInfoService.vue";
import DeletedInfoServices from "../components/InfoServices/DeletedInfoServices.vue";

//End InfoService routes

//Start InfoService routes
import AllPharmacies from "../components/Pharmacies/AllPharmacies.vue";
import CreatePharmacy from "../components/Pharmacies/CreatePharmacy.vue";
import EditPharmacy from "../components/Pharmacies/EditPharmacy.vue";
import DeletedPharmacies from "../components/Pharmacies/DeletedPharmacies.vue";
//End Phamacy routes

//Start ConditonMedical routes
import AllConditionMedicals from "../components/ConditionMedicals/AllConditionMedicals.vue";
import CreateConditionMedical from "../components/ConditionMedicals/CreateConditionMedical.vue";
import EditConditionMedical from "../components/ConditionMedicals/EditConditionMedical.vue";
import DeletedConditionMedicals from "../components/ConditionMedicals/DeletedConditionMedicals.vue";
//End ConditonMedical routes

//End Labs routes
import AllLabs from "../components/Labs/AllLabs.vue";
import CreateLab from "../components/Labs/CreateLab.vue";
import EditLab from "../components/Labs/EditLab.vue";
import DeletedLabs from "../components/Labs/DeletedLabs.vue";
//End Labs routes

//start tests routes
import AllTests from "@/components/Tests/AllTests.vue";
import CreateTest from "../components/Tests/CreateTest.vue";
import EditTest from "../components/Tests/EditTest.vue";
import DeletedTests from "../components/Tests/DeletedTests.vue";
//start tests routes

//start branches routes
import AllBranches from "../components/Branches/AllBranches.vue";
import CreateBranche from "../components/Branches/CreateBranche.vue";
import EditBranche from "../components/Branches/EditBranche.vue";
import DeletedBranches from "../components/Branches/DeletedBranches.vue";
//End branches routes

//start OrderStatus routes
import AllOrderStatus from "../components/OrderStatus/AllOrderStatus.vue";
import CreateOrderStatus from "../components/OrderStatus/CreateOrderStatus.vue";
import EditOrderStatus from "../components/OrderStatus/EditOrderStatus.vue";
import DeletedOrderStatus from "../components/OrderStatus/DeletedOrderStatus.vue";
//End OrderStatus routes

//start PaymentTypes routes
import AllPaymentTypes from "../components/PaymentType/AllPaymentTypes.vue";
import CreatePaymentType from "@/components/PaymentType/CreatePaymentType.vue";
import EditPaymentType from "@/components/PaymentType/EditPaymentType.vue";
import DeletedPaymentTypes from "../components/PaymentType/DeletedPaymentTypes.vue";
//End PaymentTypes routes

//start PaymentTypes routes
import AllMedicationTypes from "../components/MedicationType/AllMedicationTypes.vue";
import CreateMedicationType from "@/components/MedicationType/CreateMedicationType.vue";
import EditMedicationType from "@/components/MedicationType/EditMedicationType.vue";
import DeletedMedicationTypes from "../components/MedicationType/DeletedMedicationTypes.vue";
//End PaymentTypes routes

//start PaymentTypes routes
import AllCategories from "../components/Category/AllCategories.vue";
import CreateCategory from "@/components/Category/CreateCategory.vue";
import EditCategory from "@/components/Category/EditCategory.vue";
import DeletedCategories from "../components/Category/DeletedCategories.vue";
//End PaymentTypes routes

//start Medications routes
import AllMedications from "../components/Medication/AllMedications.vue";
import CreateMedication from "@/components/Medication/CreateMedication.vue";
import EditMedication from "@/components/Medication/EditMedication.vue";
import DeletedMedications from "../components/Medication/DeletedMedications.vue";
//End Medications routes

//start BookingStatus routes
import AllBookingStatus from "../components/BookingStatus/AllBookingStatus.vue";
import CreateBookingStatus from "@/components/BookingStatus/CreateBookingStatus.vue";
import EditBookingStatus from "@/components/BookingStatus/EditBookingStatus.vue";
import DeletedBookingStatus from "../components/BookingStatus/DeletedBookingStatus.vue";
//End BookingStatus routes

//start MedicalInsurance routes
import AllMedicalInsurances from "../components/MedicalInsurance/AllMedicalInsurances.vue";
import CreateMedicalInsurance from "@/components/MedicalInsurance/CreateMedicalInsurance.vue";
import EditMedicalInsurance from "@/components/MedicalInsurance/EditMedicalInsurance.vue";
import DeletedMedicalInsurances from "../components/MedicalInsurance/DeletedMedicalInsurances.vue";
//End MedicalInsurance routes

//start MedicalInsurance routes
import AllAppointmentDays from "../components/AppointmentDays/AllAppointmentDays.vue";
import CreateAppointmentDay from "@/components/AppointmentDays/CreateAppointmentDay.vue";
import EditAppointmentDay from "@/components/AppointmentDays/EditAppointmentDay.vue";
import DeletedAppointmentDays from "../components/AppointmentDays/DeletedAppointmentDays.vue";
//End MedicalInsurance routes

//start AppointmentHours routes
import AllAppointmentHours from "../components/AppointmentHours/AllAppointmentHours.vue";
import CreateAppointmentHour from "@/components/AppointmentHours/CreateAppointmentHour.vue";
import EditAppointmentHour from "@/components/AppointmentHours/EditAppointmentHour.vue";
import DeletedAppointmentHours from "../components/AppointmentHours/DeletedAppointmentHours.vue";
//End AppointmentHours routes

//start AppointmentLabs routes
import AllAppointmentLabs from "../components/AppointmentLabs/AllAppointmentLabs.vue";
import CreateAppointmentLab from "@/components/AppointmentLabs/CreateAppointmentLab.vue";
import EditAppointmentLab from "@/components/AppointmentLabs/EditAppointmentLab.vue";
import DeletedAppointmentLabs from "../components/AppointmentLabs/DeletedAppointmentLabs.vue";
//End AppointmentHours routes

//start operations routes
import AllOperations from "../components/Operations/AllOperations.vue";
import CreateOperation from "@/components/Operations/CreateOperation.vue";
import EditOperation from "@/components/Operations/EditOperation.vue";
import DeletedOperations from "@/components/Operations/DeletedOperations.vue";
//End operations routes

//start procedures routes
import AllProcedures from "../components/Procedures/AllProcedures.vue";
import CreateProcedure from "@/components/Procedures/CreateProcedure.vue";
import EditProcedure from "@/components/Procedures/EditProcedure.vue";
import DeletedProcedures from "../components/Procedures/DeletedProcedures.vue";
//End procedures routes

//start labBooking routes
import AllLabBookings from "@/components/LabBooking/AllLabBookings.vue";
import CreateLabBooking from "@/components/LabBooking/CreateLabBooking.vue";
import EditLabBooking from "@/components/LabBooking/EditLabBooking.vue";
import DeletedLabBookings from "@/components/LabBooking/DeletedLabBookings.vue";
import ShowLabBooking from "@/components/LabBooking/ShowLabBooking.vue";
//end labBooking routes

//start appointmentDoctors
import AllAppointmentDoctors from "@/components/AppointmentDoctors/AllAppointmentDoctors.vue";
import CreateAppointmentDoctor from "@/components/AppointmentDoctors/CreateAppointmentDoctor.vue";
import EditAppointmentDoctor from "@/components/AppointmentDoctors/EditAppointmentDoctor.vue";
import DeletedAppointmentDoctors from "@/components/AppointmentDoctors/DeletedAppointmentDoctors.vue";
import ShowAppointmentDoctor from "@/components/AppointmentDoctors/ShowAppointmentDoctor.vue";
//end appointmentDoctor

//start bookings
import AllBookings from "@/components/Booking/AllBookings.vue";
import CreateBooking from "@/components/Booking/CreateBooking.vue";
import EditBooking from "@/components/Booking/EditBooking.vue";
import DeletedBookings from "@/components/Booking/DeletedBookings.vue";
import ShowBooking from "@/components/Booking/ShowBooking.vue";
//End bookings routes

//End externalClinics routes
import AllExternalClinics from "@/components/ExternalClinics/AllExternalClinics.vue";
import CreateExternalClinic from "@/components/ExternalClinics/CreateExternalClinic.vue";
import EditExternalClinic from "@/components/ExternalClinics/EditExternalClinic.vue";
import DeletedExternalClinics from "@/components/ExternalClinics/DeletedExternalClinics.vue";
//End externalClinics routes

//start incubators routes
import AllIncubators from "@/components/Incubators/AllIncubators.vue";
import CreateIncubator from "@/components/Incubators/CreateIncubator.vue";
import EditIncubator from "@/components/Incubators/EditIncubator.vue";
import DeletedIncubators from "@/components/Incubators/DeletedIncubators.vue";
import ShowIncubator from "@/components/Incubators/ShowIncubator.vue";
//End incubators routes

//start intensiveCare routes
import AllIntensiveCares from "@/components/IntensiveCare/AllIntensiveCares.vue";
import CreateIntensiveCare from "@/components/IntensiveCare/CreateIntensiveCare.vue";
import EditIntensiveCare from "@/components/IntensiveCare/EditIntensiveCare.vue";
import DeletedIntensiveCares from "@/components/IntensiveCare/DeletedIntensiveCares.vue";
import ShowIntensiveCare from "@/components/IntensiveCare/ShowIntensiveCare.vue";
//End incubators routes

//start HomeVisit routes
import AllHomeVisits from "@/components/HomeVisit/AllHomeVisits.vue";
import CreateHomeVisit from "@/components/HomeVisit/CreateHomeVisit.vue";
import EditHomeVisit from "@/components/HomeVisit/EditHomeVisit.vue";
import DeletedHomeVisits from "@/components/HomeVisit/DeletedHomeVisits.vue";
import ShowHomeVisit from "@/components/HomeVisit/ShowHomeVisit.vue";
//End HomeVisit routes

//start Emergency routes
import AllEmergencies from "@/components/Emergency/AllEmergencies.vue";
import CreateEmergency from "@/components/Emergency/CreateEmergency.vue";
import EditEmergency from "@/components/Emergency/EditEmergency.vue";
import DeletedEmergencies from "@/components/Emergency/DeletedEmergencies.vue";
import ShowEmergency from "@/components/Emergency/ShowEmergency.vue";
//End Emergency routes

//start Emergency routes
import AllOperationServiceDays from "@/components/OperationServiceDay/AllOperationServiceDays.vue";
import CreateOperationServiceDay from "@/components/OperationServiceDay/CreateOperationServiceDay.vue";
import EditOperationServiceDay from "@/components/OperationServiceDay/EditOperationServiceDay.vue";
import DeletedOperationServiceDays from "@/components/OperationServiceDay/DeletedOperationServiceDays.vue";
//End Emergency routes

//start nurse visits routes
import AllNurseVisits from "@/components/NurseVisit/AllNurseVisits.vue";
import CreateNurseVisit from "@/components/NurseVisit/CreateNurseVisit.vue";
import EditNurseVisit from "@/components/NurseVisit/EditNurseVisit.vue";
import DeletedNurseVisit from "@/components/NurseVisit/DeletedNurseVisit.vue";
import ShowNurseVisit from "@/components/NurseVisit/ShowNurseVisit.vue";
//end nurse visits routes

//start AllMedicationPharmacies  routes
import AllMedicationPharmacies from "@/components/MedicationPharmacy/AllMedicationPharmacies.vue";
import CreateMedicationPharmacy from "@/components/MedicationPharmacy/CreateMedicationPharmacy.vue";
import EditMedicationPharmacy from "@/components/MedicationPharmacy/EditMedicationPharmacy.vue";
import DeletedMedicationPharmacies from "@/components/MedicationPharmacy/DeletedMedicationPharmacies.vue";
//end AllMedicationPharmacies  routes

//start Coupon routes
import AllCoupons from "@/components/Coupon/AllCoupons.vue";
import CreateCoupon from "@/components/Coupon/CreateCoupon.vue";
import EditCoupon from "@/components/Coupon/EditCoupon.vue";
import DeletedCoupons from "@/components/Coupon/DeletedCoupons.vue";
//end Coupon  routes

//start PreOperation routes
import AllPreOperations from "@/components/PreOperation/AllPreOperations.vue";
import CreatePreOperation from "@/components/PreOperation/CreatePreOperation.vue";
import EditPreOperation from "@/components/PreOperation/EditPreOperation.vue";
import DeletedPreOperations from "@/components/PreOperation/DeletedPreOperations.vue";
//end PreOperation routes

//end ExtraServices routes
import AllExtraServices from "@/components/ExtraServices/AllExtraServices.vue";
import CreateExtraService from "@/components/ExtraServices/CreateExtraService.vue";
import EditExtraService from "@/components/ExtraServices/EditExtraService.vue";
import DeletedExtraServices from "@/components/ExtraServices/DeletedExtraServices.vue";
//end ExtraServices routes

//end DayServices routes
import AllDayServices from "@/components/DayServices/AllDayServices.vue";
import CreateDayService from "@/components/DayServices/CreateDayService.vue";
import EditDayService from "@/components/DayServices/EditDayService.vue";
import DeletedDayServices from "@/components/DayServices/DeletedDayServices.vue";
//end DayServices routes

//end InfoDayService routes
import AllInfoDayServices from "@/components/InfoDayService/AllInfoDayServices.vue";
import CreateInfoDayService from "@/components/InfoDayService/CreateInfoDayService.vue";
import EditInfoDayService from "@/components/InfoDayService/EditInfoDayService.vue";
import DeletedInfoDayServices from "@/components/InfoDayService/DeletedInfoDayServices.vue";
//end InfoDayService routes

//end DayServiceBooking routes
import AllDayServiceBookings from "@/components/DayServiceBooking/AllDayServiceBookings.vue";
import CreateDayServiceBooking from "@/components/DayServiceBooking/CreateDayServiceBooking.vue";
import EditDayServiceBooking from "@/components/DayServiceBooking/EditDayServiceBooking.vue";
import DeletedDayServiceBookings from "@/components/DayServiceBooking/DeletedDayServiceBookings.vue";
//end DayServiceBooking routes

//start order routes
import AllOrders from "@/components/Order/AllOrders.vue";
import CreateOrder from "@/components/Order/CreateOrder.vue";
import EditOrder from "@/components/Order/EditOrder.vue";
import DeletedOrders from "@/components/Order/DeletedOrders.vue";
import ShowOrder from "@/components/Order/ShowOrder.vue";
import ShowOperationBooking from "@/components/OperationBooking/ShowOperationBooking.vue";
import DeletedOperationBookings from "@/components/OperationBooking/DeletedOperationBookings.vue";
import EditOperationBooking from "@/components/OperationBooking/EditOperationBooking.vue";
import CreateOperationBooking from "@/components/OperationBooking/CreateOperationBooking.vue";
import AllOperationBookings from "@/components/OperationBooking/AllOperationBookings.vue";

import AllOperationServices from "@/components/OperationService/AllOperationServices.vue";
import CreateOperationService from "@/components/OperationService/CreateOperationService.vue";
import EditOperationService from "@/components/OperationService/EditOperationService.vue";
import DeletedOperationServices from "@/components/OperationService/DeletedOperationServices.vue";

import AllExternalClinicServices from "@/components/ExternalClinicService/AllExternalClinicServices.vue";
import CreateExternalClinicService from "@/components/ExternalClinicService/CreateExternalClinicService.vue";
import EditExternalClinicService from "@/components/ExternalClinicService/EditExternalClinicService.vue";
import DeletedExternalClinicServices from "@/components/ExternalClinicService/DeletedExternalClinicServices.vue";
import AllBanners from "@/components/Banners/AllBanners.vue";
import CreateBanner from "@/components/Banners/CreateBanner.vue";
import EditBanner from "@/components/Banners/EditBanner.vue";
import DeletedBanners from "@/components/Banners/DeletedBanners.vue";
import ShowUserInsurance from "@/components/UserInsurance/ShowUserInsurance.vue";
import DeletedUserInsurances from "@/components/UserInsurance/DeletedUserInsurances.vue";
import AllUserInsurances from "@/components/UserInsurance/AllUserInsurances.vue";
import NotFound from "@/components/Errors/NotFound.vue";
import InternalServerError from "@/components/Errors/InternalServerError.vue";
import ForbiddenPage from "@/components/Errors/ForbiddenPage.vue";
import ShowDayServiceBooking from "@/components/DayServiceBooking/ShowDayServiceBooking.vue";
import AllPrescriptionOrders from "@/components/Order/AllPrescriptionOrders.vue";

// import NotAuthorized from "@/components/NotAuthorized/NotAuthorized.vue";
// import Error404 from "@/components/Errors/Error404.vue";
// import Error500 from "@/components/Errors/ErrorPage500.vue";
// import Error403 from "@/components/Errors/Error403.vue";

//end order routes







const routes = [
  {
    path: '/liveChat',
    name: 'LiveChat',
    component: LiveChat,
    meta: { requiresAuth: true, permissions: ["chat send-message"] },
    // meta: { requiresAuth: true },
  },
  {
    path: '/mytable',
    name: 'MyTable',
    component: MyTable
  },
  // {
  //   path: "/unauthorized",
  //   name: "NotAuthorized",
  //   component: NotAuthorized,
  // },
  // {
  //   path: "/",
  //   name: "login",
  //   component: LoginView,
  //   meta: { noNav: true }, // Add a meta field to identify the login route
  // },
  // {
  //   path: "/about",
  //   name: "about",
  //   component: AboutView,
  //   meta: { requiresAuth: true },
  // },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: { noNav: true },
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
    meta: { requiresAuth: true },
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { requiresAuth: true },
  },
  // Error pages
  {
    path: '/404',
    name: 'not-found',
    component: NotFound,
  },
  {
    path: '/403',
    name: 'forbidden',
    component: ForbiddenPage,
  },
  {
    path: '/500',
    name: 'internal-server-error',
    component: InternalServerError,
  },
  {
    path: '/:pathMatch(.*)*', // Catch-all route for 404
    redirect: '/404',
  },

  //country routes
  {
    path: "/country/all",
    name: "all-countries",
    component: AllCountries,
    meta: { requiresAuth: true, permissions: ["country all"] },
  },
  {
    path: "/country/create",
    name: "create-country",
    component: CreateCountry,
    meta: { requiresAuth: true, permissions: ["country create"] },
  },
  {
    path: "/country/edit/:id",
    name: "edit-country",
    component: EditCountry,
    meta: { requiresAuth: true, permissions: ["country update"] },
  },

  //city routes
  {
    path: "/city/all",
    name: "all-cities",
    component: AllCities,
    meta: { requiresAuth: true, permissions: ["city all"] },
  },
  {
    path: "/city/create",
    name: "create-city",
    component: CreateCity,
    meta: { requiresAuth: true, permissions: ["city create"] },
  },
  {
    path: "/city/edit/:id",
    name: "edit-city",
    component: EditCity,
    meta: { requiresAuth: true, permissions: ["city update"] },
  },
  //district routes
  {
    path: "/district/all",
    name: "all-districts",
    component: AllDistricts,
    meta: { requiresAuth: true },
    // meta: { requiresAuth: true, permissions: ["district all"] },
  },
  {
    path: "/district/create",
    name: "create-district",
    component: CreateDistrict,
    meta: { requiresAuth: true },
    // meta: { requiresAuth: true, permissions: ["district create"] },
  },
  {
    path: "/district/edit/:id",
    name: "edit-district",
    component: EditDistrict,
    meta: { requiresAuth: true },
    // meta: { requiresAuth: true, permissions: ["district update"] },
  },

  //Start service routes
  {
    path: "/service/all",
    name: "all-services",
    component: AllServices,
    meta: { requiresAuth: true, permissions: ["service all"] },
  },
  {
    path: "/service/create",
    name: "create-service",
    component: CreateService,
    meta: { requiresAuth: true, permissions: ["service create"] },
  },
  {
    path: "/service/edit/:id",
    name: "edit-service",
    component: EditService,
    meta: { requiresAuth: true, permissions: ["service update"] },
  },
  //end service routes

  //Start subService routes
  {
    path: "/subService/all",
    name: "all-subServices",
    component: AllSubServices,
    meta: { requiresAuth: true, permissions: ["sub-service all"] },
  },
  {
    path: "/subService/create",
    name: "create-subService",
    component: CreateSubService,
    meta: { requiresAuth: true, permissions: ["sub-service create"] },
  },
  {
    path: "/subService/edit/:id",
    name: "edit-subService",
    component: EditSubService,
    meta: { requiresAuth: true, permissions: ["sub-service update"] },
  },
  //end subService routes

  //Start speciality routes
  {
    path: "/speciality/all",
    name: "all-specialities",
    component: AllSpecialities,
    meta: { requiresAuth: true, permissions: ["speciality all"] },
  },
  {
    path: "/speciality/create",
    name: "create-speciality",
    component: CreateSpeciality,
    meta: { requiresAuth: true, permissions: ["speciality create"] },
  },
  {
    path: "/speciality/edit/:id",
    name: "edit-speciality",
    component: EditSpeciality,
    meta: { requiresAuth: true, permissions: ["speciality update"] },
  },
  {
    path: "/speciality/all/deleted",
    name: "deleted-specialities",
    component: DeletedSpecialities,
    meta: { requiresAuth: true, permissions: ["speciality getSoftDeleted"] },
  },
  //end speciality routes

  //Start InfoService routes
  {
    path: "/infoService/all",
    name: "all-infoServices",
    component: AllInfoServices,
    meta: { requiresAuth: true, permissions: ["info-service all"] },
  },
  {
    path: "/infoService/create",
    name: "create-infoService",
    component: CreateInfoService,
    meta: { requiresAuth: true, permissions: ["info-service create"] },
  },
  {
    path: "/infoService/edit/:id",
    name: "edit-infoService",
    component: EditInfoService,
    meta: { requiresAuth: true, permissions: ["info-service update"] },
  },
  {
    path: "/infoService/all/deleted",
    name: "deleted-infoServices",
    component: DeletedInfoServices,
    meta: { requiresAuth: true, permissions: ["info-service getSoftDeleted"] },
  },
  //End InfoService

  //Start Pharmacy routes
  {
    path: "/pharmacy/all",
    name: "all-pharmacies",
    component: AllPharmacies,
    meta: { requiresAuth: true, permissions: ["pharmacy all"] },
  },
  {
    path: "/pharmacy/create",
    name: "create-pharmacy",
    component: CreatePharmacy,
    meta: { requiresAuth: true, permissions: ["pharmacy create"] },
  },
  {
    path: "/pharmacy/edit/:id",
    name: "edit-pharmacy",
    component: EditPharmacy,
    meta: { requiresAuth: true, permissions: ["pharmacy update"] },
  },
  {
    path: "/pharmacy/all/deleted",
    name: "deleted-pharmacies",
    component: DeletedPharmacies,
    meta: { requiresAuth: true, permissions: ["pharmacy getSoftDeleted"] },
  },
  //End InfoService

  //Start ConditionMedical routes
  {
    path: "/medicalCondition/all",
    name: "all-conditionMedicals",
    component: AllConditionMedicals,
    meta: { requiresAuth: true, permissions: ["condition-medical all"] },
  },
  {
    path: "/medicalCondition/create",
    name: "create-conditionMedical",
    component: CreateConditionMedical,
    meta: { requiresAuth: true, permissions: ["condition-medical create"] },
  },
  {
    path: "/medicalCondition/edit/:id",
    name: "edit-conditionMedical",
    component: EditConditionMedical,
    meta: { requiresAuth: true, permissions: ["condition-medical update"] },
  },
  {
    path: "/medicalCondition/all/deleted",
    name: "deleted-conditionMedicals",
    component: DeletedConditionMedicals,
    meta: { requiresAuth: true, permissions: ["condition-medical get-delete-soft"] },
  },
  //End ConditionMedical


  //start userInsurance routes
  {
    path: "/userInsurances/all",
    name: "all-userInsurances",
    component: AllUserInsurances,
    // meta: { requiresAuth: true, permissions: ["intensive-care all"] },
    meta: { requiresAuth: true },
  },
  {
    path: "/userInsurances/all/deleted",
    name: "deleted-userInsurances",
    component: DeletedUserInsurances,
    // meta: { requiresAuth: true, permissions: ["intensive-care getSoftDeleted"] },
    meta: { requiresAuth: true },
  },
  {
    path: "/userInsurance/:id1/get/:id2",
    name: "show-userInsurance",
    component: ShowUserInsurance,
    // meta: { requiresAuth: true, permissions: ["intensiveCare get"] },
    meta: { requiresAuth: true },
  },
  //End userInsurance routes


  //start Labs routes
  {
    path: "/lab/all",
    name: "all-labs",
    component: AllLabs,
    meta: { requiresAuth: true, permissions: ["lab all"] },
  },
  {
    path: "/lab/create",
    name: "create-lab",
    component: CreateLab,
    meta: { requiresAuth: true, permissions: ["lab create"] },
  },
  {
    path: "/lab/edit/:id",
    name: "edit-lab",
    component: EditLab,
    meta: { requiresAuth: true, permissions: ["lab update"] },
  },
  {
    path: "/lab/all/deleted",
    name: "deleted-labs",
    component: DeletedLabs,
    meta: { requiresAuth: true, permissions: ["lab getSoftDeleted"] },
  },
  //End Labs routes

  //start appointmentLabs routes
  {
    path: "/labAppointment/all",
    name: "all-appointmentLabs",
    component: AllAppointmentLabs,
    meta: { requiresAuth: true, permissions: ["appointment-lab all"] },
  },
  {
    // path: '/labs/:lab_id/appointmentLab/create',
    path: "/labAppointment/create/:lab_id",
    name: "create-appointmentLab",
    component: CreateAppointmentLab,
    meta: { requiresAuth: true, permissions: ["appointment-lab create"] },
  },
  {
    path: "/labAppointment/edit/:id",
    name: "edit-appointmentLab",
    component: EditAppointmentLab,
    meta: { requiresAuth: true, permissions: ["appointment-lab update"] },
  },
  {
    path: "/labAppointment/all/deleted",
    name: "deleted-appointmentLabs",
    component: DeletedAppointmentLabs,
    meta: { requiresAuth: true, permissions: ["appointment-lab getSoftDeleted"] },
  },
  //End appointmentLabs routes

  //start labBooking routes
  {
    path: "/labBookings/all",
    name: "all-labBookings",
    component: AllLabBookings,
    meta: { requiresAuth: true, permissions: ["lab-booking all"] },
  },
  {
    path: "/labBooking/create",
    name: "create-labBooking",
    component: CreateLabBooking,
    meta: { requiresAuth: true, permissions: ["lab-booking create"] },
  },
  {
    path: "/labBooking/edit/:id",
    name: "edit-labBooking",
    component: EditLabBooking,
    meta: { requiresAuth: true, permissions: ["lab-booking update"] },
  },
  {
    path: "/labBooking/show/:id",
    name: "show-labBooking",
    component: ShowLabBooking,
    meta: { requiresAuth: true, permissions: ["lab-booking get"] },
  },
  {
    path: "/labBookings/all/deleted",
    name: "deleted-labBookings",
    component: DeletedLabBookings,
    meta: { requiresAuth: true, permissions: ["lab-booking getSoftDeleted"] },
  },
  //End labBooking routes

  //Start Doctors routes
  {
    path: "/doctors/all",
    name: "all-doctors",
    component: AllDoctors,
    meta: { requiresAuth: true, permissions: ["doctor all"] },
  },
  {
    path: "/doctor/create",
    name: "create-doctor",
    component: CreateDoctor,
    meta: { requiresAuth: true, permissions: ["doctor create"] },
  },
  {
    path: "/doctor/edit/:id",
    name: "edit-doctor",
    component: EditDoctor,
    meta: { requiresAuth: true, permissions: ["doctor update"] },
  },
  {
    path: "/doctors/all/deleted",
    name: "deleted-doctors",
    component: DeletedDoctors,
    meta: { requiresAuth: true, permissions: ["doctor getSoftDeleted"] },
  },
  //End Doctors routes

  //start appointmentDoctors routes
  {
    path: "/doctorAppointments/all",
    name: "all-appointmentDoctors",
    component: AllAppointmentDoctors,
    meta: { requiresAuth: true, permissions: ["appointment-doctor all"] },
  },
  {
    // path: "/appointmentDoctor/create",
    path: "/doctorAppointment/create/:doctor_id",
    // path: "/appointmentDoctor/create/:doctor_service_specialty_id",
    name: "create-appointmentDoctor",
    component: CreateAppointmentDoctor,
    meta: { requiresAuth: true, props: true, permissions: ["appointment-doctor create"] },
  },
  {
    // path: "/doctorAppointment/:id1/edit/:id2",
    path: "/doctorAppointment/edit/:id",
    name: "edit-appointmentDoctor",
    component: EditAppointmentDoctor,
    meta: { requiresAuth: true, permissions: ["appointment-doctor update"] },
  },
  {
    path: "/doctorAppointment/get/:doctor_service_specialty_id",
    // path: "/doctorAppointment/get/:id",
    name: "show-appointmentDoctor",
    component: ShowAppointmentDoctor,
    meta: { requiresAuth: true, permissions: ["appointment-doctor get"] },
  },
  {
    path: "/doctorAppointments/all/deleted",
    name: "deleted-appointmentDoctors",
    component: DeletedAppointmentDoctors,
    meta: { requiresAuth: true, permissions: ["appointment-doctor getSoftDeleted"] },
  },
  //End appointmentDoctors routes

  //start bookings routes
  {
    path: "/doctorBookings/all",
    name: "all-bookings",
    component: AllBookings,
    meta: { requiresAuth: true, permissions: ["booking all"] },
  },
  {
    path: "/doctorBooking/create",
    name: "create-booking",
    component: CreateBooking,
    meta: { requiresAuth: true, permissions: ["booking create"] },
  },
  {
    path: "/doctorBooking/edit/:id",
    name: "edit-booking",
    component: EditBooking,
    meta: { requiresAuth: true, permissions: ["booking update"] },
  },
  {
    path: "/doctorBooking/show/:id",
    name: "show-booking",
    component: ShowBooking,
    meta: { requiresAuth: true, permissions: ["booking get"] },
  },
  {
    path: "/doctorBookings/all/deleted",
    name: "deleted-bookings",
    component: DeletedBookings,
    meta: { requiresAuth: true, permissions: ["booking getSoftDeleted"] },
  },
  //End bookings routes

  //branches routes
  {
    path: "/branches/all",
    name: "all-branches",
    component: AllBranches,
    meta: { requiresAuth: true, permissions: ["branch all"] },
  },
  {
    path: "/branche/branche",
    name: "create-branche",
    component: CreateBranche,
    meta: { requiresAuth: true, permissions: ["branch create"] },
  },
  {
    path: "/branche/edit/:id",
    name: "edit-branche",
    component: EditBranche,
    meta: { requiresAuth: true, permissions: ["branch update"] },
  },
  {
    path: "/branche/all/deleted",
    name: "deleted-branches",
    component: DeletedBranches,
    meta: { requiresAuth: true, permissions: ["branch get-delete-soft"] },
  },


  //start OrderStatus routes
  {
    path: "/orderStatus/all",
    name: "all-orderStatus",
    component: AllOrderStatus,
    meta: { requiresAuth: true, permissions: ["order-status all"] },
  },
  {
    path: "/orderStatus/create",
    name: "create-orderStatus",
    component: CreateOrderStatus,
    meta: { requiresAuth: true, permissions: ["order-status create"] },
  },
  {
    path: "/orderStatus/edit/:id",
    name: "edit-orderStatus",
    component: EditOrderStatus,
    meta: { requiresAuth: true, permissions: ["order-status update"] },
  },
  {
    path: "/orderStatus/all/deleted",
    name: "deleted-orderStatus",
    component: DeletedOrderStatus,
    meta: { requiresAuth: true, permissions: ["order-status get-delete-soft"] },
  },
  //End OrderStatus routes

  //start payment types routes
  {
    path: "/paymentTypes/all",
    name: "all-paymentTypes",
    component: AllPaymentTypes,
    meta: { requiresAuth: true, permissions: ["payment-type all"] },
  },
  {
    path: "/paymentType/create",
    name: "create-paymentType",
    component: CreatePaymentType,
    meta: { requiresAuth: true, permissions: ["payment-type create"] },
  },
  {
    path: "/paymentType/edit/:id",
    name: "edit-paymentType",
    component: EditPaymentType,
    meta: { requiresAuth: true, permissions: ["payment-type update"] },
  },
  {
    path: "/paymentType/all/deleted",
    name: "deleted-paymentTypes",
    component: DeletedPaymentTypes,
    meta: { requiresAuth: true, permissions: ["payment-type get-delete-soft"] },
  },
  //End payment types routes

  //start medication Type routes
  {
    path: "/medicationTypes/all",
    name: "all-medicationTypes",
    component: AllMedicationTypes,
    meta: { requiresAuth: true, permissions: ["type all"] },
  },
  {
    path: "/medicationType/create",
    name: "create-medicationType",
    component: CreateMedicationType,
    meta: { requiresAuth: true, permissions: ["type create"] },
  },
  {
    path: "/medicationType/edit/:id",
    name: "edit-medicationType",
    component: EditMedicationType,
    meta: { requiresAuth: true, permissions: ["type update"] },
  },
  {
    path: "/medicationType/all/deleted",
    name: "deleted-medicationTypes",
    component: DeletedMedicationTypes,
    meta: { requiresAuth: true, permissions: ["type getSoftDeleted"] },
  },
  //End medication Type routes

  //start medications routes
  {
    path: "/medications/all",
    name: "all-medications",
    component: AllMedications,
    meta: { requiresAuth: true, permissions: ["medication all"] },
  },
  {
    path: "/medication/create",
    name: "create-medication",
    component: CreateMedication,
    meta: { requiresAuth: true, permissions: ["medication create"] },
  },
  {
    path: "/medication/edit/:id",
    name: "edit-medication",
    component: EditMedication,
    meta: { requiresAuth: true, permissions: ["medication update"] },
  },
  {
    path: "/medication/all/deleted",
    name: "deleted-medications",
    component: DeletedMedications,
    meta: { requiresAuth: true, permissions: ["medication getSoftDeleted"] },
  },
  //End medications routes

  //start categories routes
  {
    path: "/categories/all",
    name: "all-categories",
    component: AllCategories,
    meta: { requiresAuth: true, permissions: ["category all"] },
  },
  {
    path: "/category/create",
    name: "create-category",
    component: CreateCategory,
    meta: { requiresAuth: true, permissions: ["category create"] },
  },
  {
    path: "/category/edit/:id",
    name: "edit-category",
    component: EditCategory,
    meta: { requiresAuth: true, permissions: ["category update"] },
  },
  {
    path: "/category/all/deleted",
    name: "deleted-categories",
    component: DeletedCategories,
    meta: { requiresAuth: true, permissions: ["category getSoftDeleted"] },
  },
  {
    path: "/category/all/deleted",
    name: "restore-categories",
    component: DeletedCategories,
    meta: { requiresAuth: true, permissions: ["category restore"] },
  },
  //End categories routes

  //start medicationpharmacies routes
  {
    path: "/pharmacyMedications/all",
    name: "all-pharmacyMedications",
    component: AllMedicationPharmacies,
    meta: { requiresAuth: true, permissions: ["medication-pharmacy all"] },
  },
  {
    path: "/pharmacyMedications/create",
    name: "create-pharmacyMedication",
    component: CreateMedicationPharmacy,
    meta: { requiresAuth: true, permissions: ["medication-pharmacy create"] },
  },
  {
    path: "/pharmacyMedications/edit/:id",
    name: "edit-pharmacyMedication",
    component: EditMedicationPharmacy,
    meta: { requiresAuth: true, permissions: ["medication-pharmacy update"] },
  },
  {
    path: "/pharmacyMedications/all/deleted",
    name: "deleted-pharmacyMedications",
    component: DeletedMedicationPharmacies,
    meta: { requiresAuth: true, permissions: ["medication-pharmacy getSoftDeleted"] },
  },

  //End medicationpharmacies routes

  //orders routes
  {
    path: "/orders/all",
    name: "all-orders",
    component: AllOrders,
    meta: { requiresAuth: true, permissions: ["order all"] },
  },
  {
    path: "/orders/prescription/all",
    name: "all-prescriptionOrders",
    component: AllPrescriptionOrders,
    meta: { requiresAuth: true, permissions: ["order all"] },
  },
  {
    path: "/order/create/:id",
    name: "create-order",
    component: CreateOrder,
    meta: { requiresAuth: true, permissions: ["order create"] },
  },
  {
    path: "/order/edit/:id",
    name: "edit-order",
    component: EditOrder,
    meta: { requiresAuth: true, permissions: ["order update"] },
  },
  {
    path: "/order/all/deleted",
    name: "deleted-orders",
    component: DeletedOrders,
    meta: { requiresAuth: true },
    // meta: { requiresAuth: true, permissions: ["branch get-delete-soft"] },
  },
  {
    path: "/order/show/:id",
    name: "show-order",
    component: ShowOrder,
    meta: { requiresAuth: true, permissions: ["order get"] },
  },
  //start orders routes



  //start BookingStatus routes
  {
    path: "/bookingStatus/all",
    name: "all-bookingStatus",
    component: AllBookingStatus,
    meta: { requiresAuth: true, permissions: ["status all"] },
  },
  {
    path: "/bookingStatus/create",
    name: "create-bookingStatus",
    component: CreateBookingStatus,
    meta: { requiresAuth: true, permissions: ["status create"] },
  },
  {
    path: "/bookingStatus/edit/:id",
    name: "edit-bookingStatus",
    component: EditBookingStatus,
    meta: { requiresAuth: true, permissions: ["status update"] },
  },
  {
    path: "/bookingStatus/all/deleted",
    name: "deleted-bookingStatus",
    component: DeletedBookingStatus,
    meta: { requiresAuth: true, permissions: ["status get-delete-soft"] },
  },
  {
    path: "/bookingStatus/all/deleted",
    name: "restore-bookingStatus",
    component: DeletedBookingStatus,
    meta: { requiresAuth: true, permissions: ["status restore"] },
  },
  //End BookingStatus routes

  //start test routes
  {
    path: "/tests/all",
    name: "all-tests",
    component: AllTests,
    meta: { requiresAuth: true, permissions: ["test all"] },
  },
  {
    path: "/test/create",
    name: "create-test",
    component: CreateTest,
    meta: { requiresAuth: true, permissions: ["test create"] },
  },
  {
    path: "/test/edit/:id",
    name: "edit-test",
    component: EditTest,
    meta: { requiresAuth: true, permissions: ["test update"] },
  },
  {
    path: "/test/all/deleted",
    name: "deleted-tests",
    component: DeletedTests,
    meta: { requiresAuth: true, permissions: ["test getSoftDeleted"] },
  },
  //End test routes

  //start banner routes
  {
    path: "/banners/all",
    name: "all-banners",
    component: AllBanners,
    meta: { requiresAuth: true, permissions: ["banner all"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/banner/create",
    name: "create-banner",
    component: CreateBanner,
    meta: { requiresAuth: true, permissions: ["banner create"] },
  },
  {
    path: "/banner/edit/:id",
    name: "edit-banner",
    component: EditBanner,
    meta: { requiresAuth: true, permissions: ["banner update"] },
  },
  {
    path: "/banners/all/deleted",
    name: "deleted-banners",
    component: DeletedBanners,
    meta: { requiresAuth: true, permissions: ["banner get-delete-soft"] },
  },
  //End banner routes

  //start medicalInsurances routes
  {
    path: "/medicalInsurances/all",
    name: "all-medicalInsurances",
    component: AllMedicalInsurances,
    meta: { requiresAuth: true, permissions: ["medical-insurance all"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/medicalInsurance/create",
    name: "create-medicalInsurance",
    component: CreateMedicalInsurance,
    meta: { requiresAuth: true, permissions: ["medical-insurance create"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/medicalInsurance/edit/:id",
    name: "edit-medicalInsurance",
    component: EditMedicalInsurance,
    meta: { requiresAuth: true, permissions: ["medical-insurance update"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/medicalInsurances/all/deleted",
    name: "deleted-medicalInsurances",
    component: DeletedMedicalInsurances,
    meta: { requiresAuth: true, permissions: ["medical-insurance get-delete-soft"] },
    // meta: { requiresAuth: true },
  },
  //End medicalInsurances routes

  //start AppointmentDay routes
  {
    path: "/appointmentDays/all",
    name: "all-appointmentDays",
    component: AllAppointmentDays,
    meta: { requiresAuth: true, permissions: ["appointment-day all"] },
  },
  {
    path: "/appointmentDay/create",
    name: "create-appointmentDay",
    component: CreateAppointmentDay,
    meta: { requiresAuth: true, permissions: ["appointment-day create"] },
  },
  {
    path: "/appointmentDay/edit/:id",
    name: "edit-appointmentDay",
    component: EditAppointmentDay,
    meta: { requiresAuth: true, permissions: ["appointment-day update"] },
  },
  {
    path: "/appointmentDays/all/deleted",
    name: "deleted-appointmentDays",
    component: DeletedAppointmentDays,
    meta: { requiresAuth: true, permissions: ["appointment-day getSoftDeleted"] },
  },
  //End AppointmentDay routes

  //start AppointmentHour routes
  {
    path: "/appointmentHours/all",
    name: "all-appointmentHours",
    component: AllAppointmentHours,
    meta: { requiresAuth: true, permissions: ["appointment-hour all"] },
  },
  {
    path: "/appointmentHour/create",
    name: "create-appointmentHour",
    component: CreateAppointmentHour,
    meta: { requiresAuth: true, permissions: ["appointment-hour create"] },
  },
  {
    path: "/appointmentHour/edit/:id",
    name: "edit-appointmentHour",
    component: EditAppointmentHour,
    meta: { requiresAuth: true, permissions: ["appointment-hour update"] },
  },
  {
    path: "/appointmentHours/all/deleted",
    name: "deleted-appointmentHours",
    component: DeletedAppointmentHours,
    meta: { requiresAuth: true, permissions: ["appointment-hour getSoftDeleted"] },
  },
  //End AppointmentHour routes

  //start operations routes
  {
    path: "/operations/all",
    name: "all-operations",
    component: AllOperations,
    meta: { requiresAuth: true, permissions: ["operation all"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/operation/create",
    name: "create-operation",
    component: CreateOperation,
    meta: { requiresAuth: true, permissions: ["operation create"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/operation/edit/:id",
    name: "edit-operation",
    component: EditOperation,
    meta: { requiresAuth: true, permissions: ["operation update"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/operations/all/deleted",
    name: "deleted-operations",
    component: DeletedOperations,
    meta: { requiresAuth: true, permissions: ["operation get-delete-soft"] },
    // meta: { requiresAuth: true },
  },
  //End operations routes

  //start operationService routes
  {
    path: "/operationServices/all",
    name: "all-operationServices",
    component: AllOperationServices,
    // meta: { requiresAuth: true, permissions: ["operation all"] },
    meta: { requiresAuth: true },
  },
  {
    path: "/operationService/create",
    name: "create-operationService",
    component: CreateOperationService,
    // meta: { requiresAuth: true, permissions: ["operation create"] },
    meta: { requiresAuth: true },
  },
  {
    path: "/operationService/edit/:id",
    name: "edit-operationService",
    component: EditOperationService,
    // meta: { requiresAuth: true, permissions: ["operation update"] },
    meta: { requiresAuth: true },
  },
  {
    path: "/operationServices/all/deleted",
    name: "deleted-operationServices",
    component: DeletedOperationServices,
    // meta: { requiresAuth: true, permissions: ["operation getSoftDeleted"] },
    meta: { requiresAuth: true },
  },
  //End operationService routes

  //start operationServiceDays routes
  {
    path: "/operationAppointments/all",
    name: "all-operationServiceDays",
    component: AllOperationServiceDays,
    meta: { requiresAuth: true },
    // meta: { requiresAuth: true, permissions: ["operation-dervice-days all"] },
  },
  {
    path: "/operationAppointment/create",
    name: "create-operationServiceDay",
    component: CreateOperationServiceDay,
    meta: { requiresAuth: true },
    // meta: { requiresAuth: true, permissions: ["operation-dervice-days create"] },
  },
  {
    path: "/operationAppointment/edit/:id",
    name: "edit-operationServiceDay",
    component: EditOperationServiceDay,
    meta: { requiresAuth: true },
    // meta: { requiresAuth: true, permissions: ["operation-dervice-days update"] },
  },
  {
    path: "/operationAppointments/all/deleted",
    name: "deleted-operationServiceDays",
    component: DeletedOperationServiceDays,
    meta: { requiresAuth: true },
    // meta: { requiresAuth: true, permissions: ["operation-dervice-days get-delete-soft"] },
  },
  //End operationServiceDays routes

  //start procedures routes
  {
    path: "/procedures/all",
    name: "all-procedures",
    component: AllProcedures,
    meta: { requiresAuth: true, permissions: ["procedure all"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/procedure/create",
    name: "create-procedure",
    component: CreateProcedure,
    meta: { requiresAuth: true, permissions: ["procedure create"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/procedure/:id1/edit/:id2",
    name: "edit-procedure",
    component: EditProcedure,
    meta: { requiresAuth: true, permissions: ["procedure update"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/procedures/all/deleted",
    name: "deleted-procedures",
    component: DeletedProcedures,
    meta: { requiresAuth: true, permissions: ["procedure get-delete-soft"] },
    // meta: { requiresAuth: true },
  },
  //End procedures routes

  //start preoperations routes
  {
    path: "/preOperations/all",
    name: "all-preOperations",
    component: AllPreOperations,
    meta: { requiresAuth: true, permissions: ["operation all"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/preOperation/create",
    name: "create-preOperation",
    component: CreatePreOperation,
    meta: { requiresAuth: true, permissions: ["operation create"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/preOperation/edit/:id",
    name: "edit-preOperation",
    component: EditPreOperation,
    meta: { requiresAuth: true, permissions: ["operation update"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/preOperations/all/deleted",
    name: "deleted-preOperations",
    component: DeletedPreOperations,
    meta: { requiresAuth: true, permissions: ["operation get-delete-soft"] },
    // meta: { requiresAuth: true },
  },
  //End preoperations routes

  //start operationBookings routes
  {
    path: "/operationBookings/all",
    name: "all-operationBookings",
    component: AllOperationBookings,
    // meta: { requiresAuth: true, permissions: ["operation all"] },
    meta: { requiresAuth: true },
  },
  {
    path: "/operationBooking/create",
    name: "create-operationBooking",
    component: CreateOperationBooking,
    // meta: { requiresAuth: true, permissions: ["operation create"] },
    meta: { requiresAuth: true },
  },
  {
    path: "/operationBooking/edit/:id",
    name: "edit-operationBooking",
    component: EditOperationBooking,
    // meta: { requiresAuth: true, permissions: ["operation update"] },
    meta: { requiresAuth: true },
  },
  {
    path: "/operationBookings/all/deleted",
    name: "deleted-operationBookings",
    component: DeletedOperationBookings,
    // meta: { requiresAuth: true, permissions: ["operation getSoftDeleted"] },
    meta: { requiresAuth: true },
  },
  {
    path: "/operationBooking/show/:id",
    name: "show-operationbooking",
    component: ShowOperationBooking,
    // meta: { requiresAuth: true, permissions: ["operationBookings get"] },
    meta: { requiresAuth: true },
  },
  //End operationBookings routes

  //start externalClinics routes
  {
    path: "/externalClinics/all",
    name: "all-externalClinics",
    component: AllExternalClinics,
    meta: { requiresAuth: true, permissions: ["external-clinic all"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/externalClinic/create",
    name: "create-externalClinic",
    component: CreateExternalClinic,
    meta: { requiresAuth: true, permissions: ["external-clinic create"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/externalClinic/edit/:id",
    name: "edit-externalClinic",
    component: EditExternalClinic,
    meta: { requiresAuth: true, permissions: ["external-clinic update"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/externalClinics/all/deleted",
    name: "deleted-externalClinics",
    component: DeletedExternalClinics,
    meta: { requiresAuth: true, permissions: ["external-clinic get-delete-soft"] },
    // meta: { requiresAuth: true },
  },
  //End externalClinics routes


  //start externalClinicService routes
  {
    path: "/externalClinicServices/all",
    name: "all-externalClinicServices",
    component: AllExternalClinicServices,
    // meta: { requiresAuth: true, permissions: ["external-clinic all"] },
    meta: { requiresAuth: true },
  },
  {
    path: "/externalClinicService/create",
    name: "create-externalClinicService",
    component: CreateExternalClinicService,
    // meta: { requiresAuth: true, permissions: ["external-clinic create"] },
    meta: { requiresAuth: true },
  },
  {
    path: "/externalClinicService/edit/:id",
    name: "edit-externalClinicService",
    component: EditExternalClinicService,
    // meta: { requiresAuth: true, permissions: ["external-clinic update"] },
    meta: { requiresAuth: true },
  },
  {
    path: "/externalClinicServices/all/deleted",
    name: "deleted-externalClinicServices",
    component: DeletedExternalClinicServices,
    // meta: { requiresAuth: true, permissions: ["external-clinic getSoftDeleted"] },
    meta: { requiresAuth: true },
  },
  //End externalClinicService routes

  //start oneDayService routes
  {
    path: "/dayCares/all",
    name: "all-dayServices",
    component: AllDayServices,
    meta: { requiresAuth: true, permissions: ["day-service all"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/dayCare/create",
    name: "create-dayService",
    component: CreateDayService,
    meta: { requiresAuth: true, permissions: ["day-service create"] },
    // meta: { requiresAuth: true },

  },
  {
    path: "/dayCare/edit/:id",
    name: "edit-dayService",
    component: EditDayService,
    meta: { requiresAuth: true, permissions: ["day-service update"] },
    // meta: { requiresAuth: true },

  },
  {
    path: "/dayCares/all/deleted",
    name: "deleted-dayServices",
    component: DeletedDayServices,
    meta: { requiresAuth: true, permissions: ["day-service get-delete-soft"] },
    // meta: { requiresAuth: true },

  },
  //End oneDayService routes

  //start infoDayService routes
  {
    path: "/dayCareAppointments/all",
    name: "all-infoDayServices",
    component: AllInfoDayServices,
    // meta: { requiresAuth: true, permissions: ["day-service all"] },
    meta: { requiresAuth: true },

  },
  {
    path: "/dayCareAppointment/create",
    name: "create-infoDayService",
    component: CreateInfoDayService,
    // meta: { requiresAuth: true, permissions: ["day-service create"] },
    meta: { requiresAuth: true },

  },
  {
    path: "/dayCareAppointment/edit/:id",
    name: "edit-infoDayService",
    component: EditInfoDayService,
    // meta: { requiresAuth: true, permissions: ["day-service update"] },
    meta: { requiresAuth: true },

  },
  {
    path: "/dayCareAppointments/all/deleted",
    name: "deleted-infoDayServices",
    component: DeletedInfoDayServices,
    // meta: { requiresAuth: true, permissions: ["day-service getSoftDeleted"] },
    meta: { requiresAuth: true },

  },
  //End infoDayService routes

  //start DayServiceBooking routes
  {
    path: "/dayCareBookings/all",
    name: "all-dayServiceBookings",
    component: AllDayServiceBookings,
    meta: { requiresAuth: true, permissions: ["day-service all-booking"] },
    // meta: { requiresAuth: true },

  },
  {
    path: "/dayCareBooking/create",
    name: "create-dayServiceBooking",
    component: CreateDayServiceBooking,
    // meta: { requiresAuth: true, permissions: ["day-service create"] },
    meta: { requiresAuth: true },

  },
  {
    path: "/dayCareBooking/edit/:id",
    name: "edit-dayServiceBooking",
    component: EditDayServiceBooking,
    // meta: { requiresAuth: true, permissions: ["day-service update"] },
    meta: { requiresAuth: true },

  },
  {
    path: "/dayCareBooking/get/:id",
    name: "show-dayServiceBooking",
    component: ShowDayServiceBooking,
    // meta: { requiresAuth: true, permissions: ["day-service update"] },
    meta: { requiresAuth: true },

  },
  {
    path: "/dayCareBookings/all/deleted",
    name: "deleted-dayServiceBookings",
    component: DeletedDayServiceBookings,
    // meta: { requiresAuth: true, permissions: ["day-service getSoftDeleted"] },
    meta: { requiresAuth: true },
  },
  //End dayServiceBooking routes

  //start incubators routes
  {
    path: "/incubators/all",
    name: "all-incubators",
    component: AllIncubators,
    meta: { requiresAuth: true, permissions: ["incubator all"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/incubator/create",
    name: "create-incubator",
    component: CreateIncubator,
    meta: { requiresAuth: true, permissions: ["incubator create"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/incubator/edit/:id",
    name: "edit-incubator",
    component: EditIncubator,
    meta: { requiresAuth: true, permissions: ["incubator update"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/incubators/all/deleted",
    name: "deleted-incubators",
    component: DeletedIncubators,
    meta: { requiresAuth: true, permissions: ["incubator get-delete-soft"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/incubator/get/:id",
    name: "show-incubator",
    component: ShowIncubator,
    meta: { requiresAuth: true, permissions: ["incubator get"] },
    // meta: { requiresAuth: true },
  },
  //End incubators routes

  //start intensiveCare routes
  {
    path: "/intensiveCares/all",
    name: "all-intensiveCares",
    component: AllIntensiveCares,
    meta: { requiresAuth: true, permissions: ["intensive-care all"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/intensiveCare/create",
    name: "create-intensiveCare",
    component: CreateIntensiveCare,
    meta: { requiresAuth: true, permissions: ["intensive-care create"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/intensiveCare/edit/:id",
    name: "edit-intensiveCare",
    component: EditIntensiveCare,
    meta: { requiresAuth: true, permissions: ["intensive-care update"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/intensiveCares/all/deleted",
    name: "deleted-intensiveCares",
    component: DeletedIntensiveCares,
    meta: { requiresAuth: true, permissions: ["intensive-care get-delete-soft"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/intensiveCare/get/:id",
    name: "show-intensiveCare",
    component: ShowIntensiveCare,
    meta: { requiresAuth: true, permissions: ["intensive-care get"] },
    // meta: { requiresAuth: true },
  },
  //End intensiveCare routes

  //start home-visit routes
  {
    path: "/homeVisits/all",
    name: "all-homeVisits",
    component: AllHomeVisits,
    meta: { requiresAuth: true, permissions: ["home-visit all"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/homeVisit/create",
    name: "create-homeVisit",
    component: CreateHomeVisit,
    meta: { requiresAuth: true, permissions: ["home-visit create"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/homeVisit/edit/:id",
    name: "edit-homeVisit",
    component: EditHomeVisit,
    meta: { requiresAuth: true, permissions: ["home-visit update"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/homeVisits/all/deleted",
    name: "deleted-homeVisits",
    component: DeletedHomeVisits,
    meta: { requiresAuth: true, permissions: ["home-visit get-delete-soft"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/homeVisit/get/:id",
    name: "show-homeVisit",
    component: ShowHomeVisit,
    meta: { requiresAuth: true, permissions: ["home-visit get"] },
    // meta: { requiresAuth: true },
  },
  //End home-visit routes

  //start nurse-visit routes
  {
    path: "/nurseVisits/all",
    name: "all-nurseVisits",
    component: AllNurseVisits,
    meta: { requiresAuth: true, permissions: ["nurse-visit all"] },
    // // meta: { requiresAuth: true },
  },
  {
    path: "/nurseVisit/create",
    name: "create-nurseVisit",
    component: CreateNurseVisit,
    meta: { requiresAuth: true, permissions: ["nurse-visit create"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/nurseVisit/edit/:id",
    name: "edit-nurseVisit",
    component: EditNurseVisit,
    meta: { requiresAuth: true, permissions: ["nurse-visit update"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/nurseVisits/all/deleted",
    name: "deleted-nurseVisits",
    component: DeletedNurseVisit,
    meta: { requiresAuth: true, permissions: ["nurse-visit get-delete-soft"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/nurseVisit/get/:id",
    name: "show-nurseVisit",
    component: ShowNurseVisit,
    meta: { requiresAuth: true, permissions: ["nurse-visit get"] },
    // meta: { requiresAuth: true },
  },
  //End nurse-visit routes

  //start emergency routes
  {
    path: "/emergencies/all",
    name: "all-emergencies",
    component: AllEmergencies,
    meta: { requiresAuth: true, permissions: ["emergency all"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/emergency/create",
    name: "create-emergency",
    component: CreateEmergency,
    meta: { requiresAuth: true, permissions: ["emergency create"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/emergency/edit/:id",
    name: "edit-emergency",
    component: EditEmergency,
    meta: { requiresAuth: true, permissions: ["emergency update"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/emergencies/all/deleted",
    name: "deleted-emergencies",
    component: DeletedEmergencies,
    meta: { requiresAuth: true, permissions: ["emergency get-delete-soft"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/emergency/get/:id",
    name: "show-emergency",
    component: ShowEmergency,
    meta: { requiresAuth: true, permissions: ["emergency get"] },
    // meta: { requiresAuth: true },
  },
  //End emergency routes

  //start extraServices routes
  {
    path: "/extraServices/all",
    name: "all-extraServices",
    component: AllExtraServices,
    meta: { requiresAuth: true, permissions: ["extra-service all"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/extraService/create",
    name: "create-extraService",
    component: CreateExtraService,
    meta: { requiresAuth: true, permissions: ["extra-service create"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/extraService/edit/:id",
    name: "edit-extraService",
    component: EditExtraService,
    meta: { requiresAuth: true, permissions: ["extra-service update"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/extraServices/all/deleted",
    name: "deleted-extraServices",
    component: DeletedExtraServices,
    meta: { requiresAuth: true, permissions: ["extra-service get-delete-soft"] },
    // meta: { requiresAuth: true },
  },

  //End extraServices routes

  //start coupon routes
  {
    path: "/coupons/all",
    name: "all-coupons",
    component: AllCoupons,
    meta: { requiresAuth: true, permissions: ["coupon all"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/coupon/create",
    name: "create-coupon",
    component: CreateCoupon,
    meta: { requiresAuth: true, permissions: ["coupon create"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/coupon/edit/:id",
    name: "edit-coupon",
    component: EditCoupon,
    meta: { requiresAuth: true, permissions: ["coupon update"] },
    // meta: { requiresAuth: true },
  },
  {
    path: "/coupons/all/deleted",
    name: "deleted-coupons",
    component: DeletedCoupons,
    meta: { requiresAuth: true, permissions: ["coupon get-delete-soft"] },
    // meta: { requiresAuth: true },
  },

  //End coupons routes

  //Users routes
  {
    path: "/users/all",
    name: "all-users",
    component: AllUsers,
    meta: { requiresAuth: true },
    // meta: { requiresAuth: true, permissions: ["user all"] },
  },
  {
    path: "/user/create",
    name: "create-user",
    component: CreateUser,
    meta: { requiresAuth: true },
    // meta: { requiresAuth: true, permissions: ["user create"] },
  },
  {
    path: "/user/edit/:id",
    name: "edit-user",
    component: EditUser,
    meta: { requiresAuth: true },
    // meta: { requiresAuth: true, permissions: ["user update"] },
  },
  {
    path: "/users/all/deleted",
    name: "deleted-users",
    component: DeletedUsers,
    meta: { requiresAuth: true },
    // meta: { requiresAuth: true, permissions: ["user get-delete-soft"] },
  },

  // {
  //   path: "/ContactInformations/all",
  //   name: "all-contacts",
  //   component: AllContactInformations,
  //   meta: { requiresAuth: true },
  // },
  //Users routes

  //start roles routes
  {
    path: "/role/all",
    name: "all-roles",
    component: AllRoles,
    meta: { requiresAuth: true },
    // meta: { requiresAuth: true, permissions: ["user all"] },
  },
  {
    path: "/role/create",
    name: "create-role",
    component: CreateRole,
    meta: { requiresAuth: true },
    // meta: { requiresAuth: true, permissions: ["user create"] },
  },
  {
    path: "/role/edit/:id",
    name: "edit-role",
    component: EditRole,
    meta: { requiresAuth: true },
    // meta: { requiresAuth: true, permissions: ["user update"] },
  },

  //End roles routes
];
// Function to filter routes based on user permissions
// function filterRoutes(routes) {
//   return routes.filter(route => {
//     if (!route.meta || !route.meta.permissions) return true; // No permissions required
//     return route.meta.permissions.some(permission => store.userPermissions.includes(permission));
//   });
// }

// // Filter routes before creating the router
// const filteredRoutes = filterRoutes(routes);

const router = createRouter({
  history: createWebHistory(),
  routes,
  // routes: filteredRoutes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = store.isLoggedIn;
  const userPermissions = store.userPermissions;

  console.log("Router middleware: Checking route", to.name);
  console.log("Router middleware: Logged in status", loggedIn);
  console.log("Router middleware: User permissions", userPermissions);

  if (to.name === "login" && loggedIn) {
    console.log("Router middleware: Already logged in, redirecting to home");
    next({ name: "home" });
  } else if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    console.log("Router middleware: Requires auth but not logged in, redirecting to login");
    next({ name: "login" });

  } else if (to.matched.some(record => record.meta.permissions) && !to.meta.permissions.some(permission => userPermissions.includes(permission))) {
    console.log("Router middleware: User does not have required permission, redirecting to unauthorized");
    // store.setErrorMessage("You do not have permission to access this page.");
    // next({ name: from.name || "home" });
    next({ name: 'forbidden' });
    // store.setErrorMessage("Not Authorized."); // Set the error message
  } else if (!to.matched.length) {
    // Redirect to 404 if the route does not exist
    next({ name: 'not-found' });

  } else {
    console.log("Router middleware: Proceeding to route", to.name);
    next();
  }
});

export default router;





