<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Edit Operation Appointments</h1>

            <!-- <span class="mainDescription"
              >Use this page to start from scratch and put your custom
              content</span
            > -->
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-operationServiceDays' }">Back</router-link>
          </div>
          <!-- <ol class="breadcrumb">
            <li class="active">
              <span>Blank Page</span>
            </li>
          </ol> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="updateOperationServiceDay" enctype="multipart/form-data">
              <div class="row">

                <div class="form-group col-6">
                  <label>Hospital/Clinic</label>
                  <input type="text" class="form-control" v-model="info_service" disabled />
                </div>
                <div class="form-group col-6">
                  <label>Operation</label>
                  <input type="text" class="form-control" v-model="operation" disabled />
                </div>
                <div class="form-group col-4">
                  <label>Day</label>
                  <input type="text" class="form-control" v-model="appointment_day" disabled />
                </div>



                <div class="form-group col-4">
                  <label>from</label>
                  <input type="time" class="form-control" v-model="from" placeholder="Enter Time" step="60" />
                </div>

                <div class="form-group col-4">
                  <label>to</label>
                  <input type="time" class="form-control" placeholder="Enter Time" v-model="to" step="60" />
                </div>

                <div class="col-md-3">
                  <div class="checkbox clip-check check-primary">
                    <input type="checkbox" v-model="availability" id="service1">
                    <label for="service1">
                      Availability
                    </label>
                  </div>
                </div>

              </div>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
export default {
  name: "EditOperationServiceDay",

  data() {
    return {
      operation: "",
      info_service: "",
      appointment_day: "",
      availability: "",
      from: "",
      to: "",
    };
  },
  created() {
    this.fetchOperationServiceDay();

  },
  methods: {

    async fetchOperationServiceDay() {
      try {
        const response = await axios.get(`/admin/admin/operation-service-days/get?operation_service_id=${this.$route.params.id}`);
        // const response = await axios.get(`/admin/admin/operation-service-days/get?operation_service_id=${this.$route.params.id}`);

        const operationAppointments = response.data.data[0];

        // Map the response data to the variants array
        this.operation = operationAppointments.operation_service.operation.name_en;
        this.info_service = operationAppointments.operation_service.info_service.name_en;
        this.appointment_day = operationAppointments.day.name_en;
        this.from = operationAppointments.from;
        this.to = operationAppointments.to;
        this.availability = operationAppointments.availability == 1;

        console.log('operationAppoiment', operationAppointments);
      } catch (error) {
        console.error("Error fetching operationAppoiment:", error);
      }
    },
    async updateOperationServiceDay() {
      try {
        const formData = new FormData();
        formData.append("id", this.$route.params.id);
        formData.append("availability", this.availability ? this.availability = 1 : 0);
        formData.append("from", this.from);
        formData.append("to", this.to);

        console.log(formData)
        const response = await axios.post("/admin/admin/operation-service-days/update", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        console.log(formData);
        console.log(response.data);
        this.$router.push({ name: "all-operationServiceDays" });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  width: 100%;
  /* max-width: 500px; Optional: Max width for better responsiveness */
}

form>.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}
</style>
