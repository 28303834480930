<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Edit Lab/Scan Appointment</h1>

            <!-- <span class="mainDescription"
              >Use this page to start from scratch and put your custom
              content</span
            > -->
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-appointmentLabs' }">Back</router-link>
          </div>
          <!-- <ol class="breadcrumb">
            <li class="active">
              <span>Blank Page</span>
            </li>
          </ol> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="updateAppointmentLab" enctype="multipart/form-data">
              <div class="row">
                <div class="form-group col-md-12 mb-2">
                  <label class="text-">Day</label>
                  <multiselect v-model="appointment_day" :options="appointmentDays" label="name" track-by="id"
                    placeholder="Select a Day"></multiselect>
                  <span v-if="errors.appointment_day" class="error">{{ errors.appointment_day }}</span>
                </div>
                <div class="form-group col-md-12 mb-2">
                  <label class="text-">Hour</label>
                  <multiselect v-model="appointment_hour" :options="multiselectOptions" label="label" track-by="value"
                    placeholder="Select a hour"></multiselect>
                  <span v-if="errors.appointment_hour" class="error">{{ errors.appointment_hour }}</span>
                </div>
                <div class="form-group" v-if="service_id == 16">
                  <label>Appointment Types</label>
                  <select class="form-control" v-model="appointment_type">
                    <option value="">Select Appointment Type</option>
                    <option value="home">Home</option>
                    <option value="branch">Branch</option>
                  </select>
                  <span v-if="errors.appointment_type" class="error">{{ errors.appointment_type }}</span>
                </div>
              </div>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import Multiselect from "vue-multiselect";
export default {
  components: {
    Multiselect
  },
  name: "CreateAppointmentLab",
  data() {
    return {
      appointmentDays: [],
      appointment_day: null,
      appointmentHours: [],
      appointment_hour: "",
      multiselectOptions: [],
      appointment_type: "branch",
      service_id: "",
      lab_id: "",
      errors: {},
    };
  },
  async created() {
    await this.fetchAppointmentHours();
    await this.fetchAppointmentDays();
    await this.fetchAppointmentLab();

  },
  methods: {

    async fetchAppointmentDays() {
      try {
        const response = await axios.get("/admin/admin/appointment-day/all");
        this.appointmentDays = response.data.data;
        console.log(this.appointmentDays)
      } catch (error) {
        console.error(error);
      }
    },
    async fetchAppointmentHours() {
      try {
        const response = await axios.get("/admin/admin/appointment-hour/all");
        this.appointmentHours = response.data.data;
        this.multiselectOptions = this.appointmentHours.map(hour => ({
          label: `${hour.from} - ${hour.to}`,
          value: hour.id,
        }));
        console.log(this.appointmentHours)
      } catch (error) {
        console.error(error);
      }
    },
    // setAppointmentHour() {
    //   // Set the appointment hour after the options are populated
    //   if (this.multiselectOptions.length > 0) {
    //     this.appointment_hour = this.multiselectOptions.find(
    //       hour => hour.value === this.appointmentLabData.appointment_hour.id
    //     );
    //   }
    // },
    async fetchAppointmentLab() {
      try {
        const response = await axios.get(`/admin/admin/appointment-lab/get?id=${this.$route.params.id}`);
        const appointmentLabData = response.data.data[0];
        console.log(appointmentLabData)
        this.lab_id = appointmentLabData.appointment_data.lab_id;
        this.service_id = appointmentLabData.appointment_data.service_id;
        this.appointment_type = appointmentLabData.appointment_type;
        this.appointment_day = appointmentLabData.appointment_day;
        this.appointment_hour = this.multiselectOptions.find(
          hour => hour.value === appointmentLabData.appointment_hour.id
        );
      } catch (error) {
        console.error("Error fetching appointment_data:", error);
      }
    },
    async updateAppointmentLab() {
      try {
        const formData = new FormData();
        formData.append("id", this.$route.params.id);
        formData.append("lab_id", this.lab_id);
        formData.append("appointment_hour_id", this.appointment_hour.value);
        formData.append("appointment_day_id", this.appointment_day.id);
        formData.append("appointment_type", this.appointment_type);

        const response = await axios.post("/admin/admin/appointment-lab/update", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        console.log(formData);
        console.log(response.data);
        this.$router.push({ name: "all-appointmentLabs" });
      } catch (error) {
        // console.error("Invalid Input:", error);
        if (error.response && error.response.data.errors) {
          this.errors = error.response.data.errors;
        }
      }
    },
  },
};
</script>
<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  width: 100%;
  /* max-width: 500px; Optional: Max width for better responsiveness */
}

form>.form-group {
  margin-bottom: 1.5rem;
}

.error {
  color: #a94442;
  font-size: 12px;
}

button {
  width: 100%;
}
</style>
