<template>
  <!-- start: TOP NAVBAR -->
  <header class="navbar navbar-default navbar-static-top">
    <!-- start: NAVBAR HEADER -->
    <div class="navbar-header">
      <a href="#" class="sidebar-mobile-toggler pull-left hidden-md hidden-lg" data-toggle-class="app-slide-off"
        data-toggle-target="#app" data-toggle-click-outside="#sidebar"><i class="ti-align-justify"></i> </a>

      <!-- <a class="navbar-brand" href="#"> -->
      <!-- <img src="assets/images/logo.png" alt="Clip-Two" /> -->
      <!-- <img :src="`assets/images/logo.png`" alt="Allo-Doctor" /> -->
      <!-- <img :src="`assets/images/logo.png`" alt="Allo-Doctor" /> -->
      <!-- <img :src="require('assets/images/logo.png')" alt="Logo"> -->
      <!-- </a> -->

      <a href="#" class="sidebar-toggler pull-right visible-md visible-lg" data-toggle-class="app-sidebar-closed"
        data-toggle-target="#app">
        <i class="ti-align-justify"></i>
      </a>
      <a class="pull-right menu-toggler visible-xs-block" id="menu-toggler" data-toggle="collapse"
        href=".navbar-collapse">
        <span class="sr-only">Toggle navigation</span>
        <i class="ti-view-grid"></i>
      </a>
    </div>
    <!-- end: NAVBAR HEADER -->



    <!-- start: NAVBAR COLLAPSE -->
    <div class="navbar-collapse collapse">
      <ul class="nav navbar-right">
        <!-- start: MESSAGES DROPDOWN -->
        <li class="dropdown">
          <a href class="dropdown-toggle" data-toggle="dropdown">
            <span class="dot-badge partition-red"></span> <i class="ti-comment"></i> <span>MESSAGES</span>
          </a>
          <ul class="dropdown-menu dropdown-light dropdown-messages dropdown-large">
            <li>
              <span class="dropdown-header"> Unread messages</span>
            </li>
            <li>
              <div class="drop-down-wrapper ps-container">
                <ul>
                  <li class="unread">
                    <a href="javascript:;" class="unread">
                      <div class="clearfix">
                        <div class="thread-image">
                          <img :src="`${appUrl}/assets/images/avatar-2.jpg`" alt="" />
                        </div>
                        <div class="thread-content">
                          <span class="author">Nicole Bell</span>
                          <span class="preview">Duis mollis, est non commodo luctus, nisi erat porttitor
                            ligula...</span>
                          <span class="time"> Just Now</span>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" class="unread">
                      <div class="clearfix">
                        <div class="thread-image">
                          <img :src="`${appUrl}/assets/images/avatar-3.jpg`" alt="" />
                        </div>
                        <div class="thread-content">
                          <span class="author">Steven Thompson</span>
                          <span class="preview">Duis mollis, est non commodo luctus, nisi erat porttitor
                            ligula...</span>
                          <span class="time">8 hrs</span>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;">
                      <div class="clearfix">
                        <div class="thread-image">
                          <img :src="`${appUrl}/assets/images/avatar-5.jpg`" alt="" />
                        </div>
                        <div class="thread-content">
                          <span class="author">Kenneth Ross</span>
                          <span class="preview">Duis mollis, est non commodo luctus, nisi erat porttitor
                            ligula...</span>
                          <span class="time">14 hrs</span>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li class="view-all">
              <a href="#">
                See All
              </a>
            </li>
          </ul>
        </li>
        <!-- end: MESSAGES DROPDOWN -->

        <!-- start: USER OPTIONS DROPDOWN -->
        <li class="dropdown current-user">
          <a href class="dropdown-toggle" data-toggle="dropdown">
            <img :src="`${appUrl}/assets/images/avatar-1.jpg`" alt="Peter"> <span class="username">{{ userName }}
              <i class="ti-angle-down"></i></span>
          </a>
          <ul class="dropdown-menu dropdown-dark">
            <li>
              <a href="pages_user_profile.html">
                My Profile
              </a>
            </li>

            <li>
              <a hef="pages_messages.html">
                My Messages (3)
              </a>
            </li>

            <li>
              <!-- <a href="login_signin.html"> Log Out </a> -->
              <a @click="logout"> Log Out </a>
            </li>
          </ul>
        </li>
        <!-- end: USER OPTIONS DROPDOWN -->
      </ul>


      <!-- start: MENU TOGGLER FOR MOBILE DEVICES -->
      <div class="close-handle visible-xs-block menu-toggler" data-toggle="collapse" href=".navbar-collapse">
        <div class="arrow-left"></div>
        <div class="arrow-right"></div>
      </div>
      <!-- end: MENU TOGGLER FOR MOBILE DEVICES -->
    </div>
    <!-- <a class="dropdown-off-sidebar" data-toggle-class="app-offsidebar-open" data-toggle-target="#app"
      data-toggle-click-outside="#off-sidebar">
      &nbsp;
    </a> -->
    <!-- end: NAVBAR COLLAPSE -->
  </header>
  <!-- end: TOP NAVBAR -->


</template>
<script>
import axios from "../../Axios";
import { store } from "../../Store";

export default {
  name: "NavBar",
  data() {
    return {
      appUrl: 'http://localhost:8080' // or from environment variables
      // appUrl: "https://allodoctor-backend.developnetwork.net" // or from environment variables
    };
  },
  computed: {
    userImage() {
      return store.user && store.user.image ? `${this.appUrl}/${store.user.image}` : `${this.appUrl}/assets/images/default-avatar.jpg`;
    },
    userName() {
      return store.user ? store.user.name : 'User';
    }
  },

  methods: {
    async logout() {
      try {
        await axios.post("admin/auth/logout");
      } catch (error) {
        console.error("Logout failed, token might already be expired:", error);
      } finally {
        // Always remove token data and navigate to login, regardless of errors
        localStorage.removeItem("access_token");
        localStorage.removeItem("userRole");
        localStorage.removeItem("userPermissions");
        localStorage.removeItem("user");
        localStorage.removeItem('firebase:host:allodoctor-d4515-default-rtdb.firebaseio.com');
        store.setLoggedIn(false);
        // store.setUserRole(null);
        // store.setUserPermissions([]);
        // store.setUser(null);
        this.$router.push({ name: "login" });
      }
    }

    // async logout() {
    //   try {
    //     const response = await axios.post("admin/auth/logout");
    //     console.log(response.data.data);
    //     localStorage.removeItem("access_token");
    //     localStorage.removeItem("userRole");
    //     localStorage.removeItem("userPermissions");
    //     localStorage.removeItem("tokenExpiration");
    //     store.setLoggedIn(false);
    //     store.setUserRole(null);
    //     store.setUserPermissions([]);
    //     this.$router.push({ name: "login" });
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
    // async logout() {
    //   try {
    //     const response = await axios.post("admin/auth/logout");
    //     console.log(response.data);
    //     localStorage.removeItem("access_token");
    //     store.setLoggedIn(false); // Update the reactive store's state
    //     this.$router.push({ name: "login" });
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
  },
};
</script>
<style scoped>
.collapse {
  display: none;
  visibility: hidden;
}

.collapse.in {
  display: block;
  visibility: visible;
}

.dropdown.open {
  /* margin-top: 65px; */
  margin-bottom: 1rem;
  height: 10px;
}

ul {
  margin-top: 65px;
  margin-bottom: 1rem;
  /* height: 500px; */
}
</style>