<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Deleted Pharmacy Medication</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-pharmacyMedications' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">

            <div v-if="medications.length > 0">
              <MyTable :columns="columns" :actions="true">
                <tr v-for="(medication, index) in filteredMedications" :key="index">
                  <th scope="row">{{ medication.id }}</th>
                  <td>{{ medication.pharmacy ? medication.pharmacy.name : 'N/A' }}</td>
                  <td>{{ medication.category.name ? medication.category.name : 'N/A' }}</td>
                  <td>{{ medication.medication ? medication.medication.name : 'N/A' }}</td>
                  <td>{{ medication.price }}</td>
                  <td>{{ medication.price_after_discount }}</td>
                  <td>{{ medication.quantity }}</td>
                  <td>{{ medication.availability == 1 ? 'True' : 'False' }}</td>
                  <td>
                    <button class="me-2 btn btn-success" @click="restoreMedcation(medication.id)">
                      Restore
                    </button>

                  </td>
                </tr>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No deleted Pharmacy Medications found.
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import $ from 'jquery'; // Import jQuery
import MyTable from "../MyTable//MyTable.vue"; // Import your MyTable component
import axios from "../../Axios";
export default {
  name: "DeletedMedications",
  components: {
    MyTable,
    // Paginate,
  },
  data() {
    return {
      dataTable: [],
      medications: [],
      meta: {},
      searchTerm: "",
      columns: [
        { label: '#', key: 'id' },
        { label: 'Pharmacy Name', key: 'pharmacy.name_en' },
        { label: 'Category Name', key: 'category.name_en' },
        { label: 'Medication Name', key: 'medication.name_en' },
        { label: 'Price', key: 'medication.price' },
        { label: 'Price After Discount', key: 'price_after_discount' },
        { label: 'Quantity', key: 'quantity' },
        { label: 'availability', key: 'availability' },
      ],
    };
  },

  async created() {
    this.fetchMedications();
  },

  computed: {
    filteredMedications() {
      if (!this.searchTerm) {
        return this.medications;
      }
      const term = this.searchTerm.toLowerCase();
      return this.medications.filter(
        (medication) =>
          medication.name.toLowerCase().includes(term) ||
          medication.postal_code.toLowerCase().includes(term)
      );
    },
  },

  methods: {
    // fetch orders
    async fetchMedications() {
      try {
        const response = await axios.get(
          `/admin/admin/medication-pharmacy/get-delete-soft`
        );
        this.medications = response.data.data;
        this.meta = response.data.meta;
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
      } catch (error) {
        console.error("Error fetching medications:", error);
      }
    },
    initializeDataTable() {
      this.$nextTick(() => {
        // Ensure the DataTable is initialized only after the DOM is updated
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },
    restoreMedcation(id) {
      console.log("restore Medcation id: ", id);
      axios
        .post(`/admin/admin/medication-pharmacy/restore?id=${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchMedications();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

};
</script>
<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}
</style>