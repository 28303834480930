<template>
  <!-- sidebar -->
  <div class="sidebar app-aside" id="sidebar">
    <div class="sidebar-container perfect-scrollbar">
      <nav>
        <!-- start: SEARCH FORM -->
        <div class="search-form">
          <a class="s-open" href="#">
            <i class="ti-search"></i>
          </a>
          <form class="navbar-form" role="search">
            <a class="s-remove" href="#" target=".navbar-form">
              <i class="ti-close"></i>
            </a>
            <div class="form-group">
              <input type="text" class="form-control" placeholder="Search...">
              <button class="btn search-button" type="submit">
                <i class="ti-search"></i>
              </button>
            </div>
          </form>
        </div>
        <!-- end: SEARCH FORM -->
        <!-- start: MAIN NAVIGATION MENU -->
        <div class="navbar-title">
          <span>Main Navigation</span>
        </div>
        <ul class="main-navigation-menu">
          <li class="active open">
            <router-link :to="{ name: 'home' }">
              <!-- <a href="index.html"> -->
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-home"></i>
                </div>
                <div class="item-inner">
                  <span class="title"> Dashboard </span>
                </div>
              </div>
            </router-link>

          </li>
          <li v-if="hasPermission('chat send-message')">
            <router-link :to="{ name: 'LiveChat' }">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-comments"></i>
                </div>
                <div class="item-inner">
                  <span class="title">LiveChat</span>
                </div>
              </div>
            </router-link>
          </li>
          <!-- end LiveChat -->
          <!-- Start roles -->
          <!-- <li> -->
          <li v-if="hasPermission('role all')">
            <router-link :to="{ name: 'all-roles' }">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-user"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Roles</span>
                </div>
              </div>
            </router-link>
          </li>
          <!-- end roles -->
          <!-- Start user -->
          <!-- <li> -->
          <li v-if="hasPermission('user all')">
            <router-link :to="{ name: 'all-users' }">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-user"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Users</span>
                </div>
              </div>
            </router-link>
          </li>
          <!-- end user -->
          <!-- Start Countries -->
          <li v-if="hasPermission('country all')">
            <router-link :to="{ name: 'all-countries' }">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Countries</span>
                </div>
              </div>
            </router-link>
          </li>
          <!-- End Countries -->
          <!-- Start Cities -->
          <li v-if="hasPermission('city all')">
            <router-link :to="{ name: 'all-cities' }">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Cities</span>
                </div>
              </div>
            </router-link>
          </li>
          <!-- End Cities -->
          <!-- Start districts -->
          <!-- <li v-if="hasPermission('district all')"> -->
          <li>
            <router-link :to="{ name: 'all-districts' }">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Districts</span>
                </div>
              </div>
            </router-link>
          </li>
          <!-- End districts -->
          <!-- Start Districts -->
          <li v-if="hasPermission('district all')">
            <router-link :to="{ name: 'all-districts' }">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Districts</span>
                </div>
              </div>
            </router-link>
          </li>
          <!-- End Districts -->

          <!--Start Services -->
          <li v-if="hasPermission('service all')">
            <!-- <li> -->
            <router-link :to="{ name: 'all-services' }">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Services</span>
                </div>
              </div>
            </router-link>
          </li>
          <!-- End Services -->

          <!--Start SubServices -->
          <li v-if="hasPermission('sub-service all')">
            <router-link :to="{ name: 'all-subServices' }">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Sub-Services</span>
                </div>
              </div>
            </router-link>
          </li>
          <!--End SubServices -->

          <!--Start appoitnment days -->
          <li v-if="hasPermission('appointment-day all')">
            <router-link :to="{ name: 'all-appointmentDays' }">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Appointment Days</span>
                </div>
              </div>
            </router-link>
          </li>
          <!--End appoitnment days -->

          <!--Start appoitnment hours -->
          <li v-if="hasPermission('appointment-hour all')">
            <router-link :to="{ name: 'all-appointmentHours' }">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Appointment Hours</span>
                </div>
              </div>
            </router-link>
          </li>
          <!--End appoitnment hours -->


          <!--Start Specialities -->
          <li v-if="hasPermission('speciality all')">
            <router-link :to="{ name: 'all-specialities' }">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Specialities</span>
                </div>
              </div>
            </router-link>
          </li>
          <!--End SubServices -->
          <!--Start Condition Medical -->
          <li v-if="hasPermission('condition-medical all')">
            <router-link :to="{ name: 'all-conditionMedicals' }">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Medical Condition</span>
                </div>
              </div>
            </router-link>
          </li>
          <!--End Condition Medical -->
          <!-- <li> -->
          <li v-if="hasPermission('banner all')">
            <router-link :to="{ name: 'all-banners' }">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Banners</span>
                </div>
              </div>
            </router-link>
          </li>
          <!--End coupon -->
          <!--Start coupon -->
          <!-- <li> -->
          <li v-if="hasPermission('coupon all')">
            <router-link :to="{ name: 'all-coupons' }">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Coupons</span>
                </div>
              </div>
            </router-link>
          </li>
          <!--End coupon -->
          <!--Start userInsurances -->
          <!-- <li v-if="hasPermission('user-insurance all')"> -->
          <li>
            <router-link :to="{ name: 'all-userInsurances' }">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title">User Insurances</span>
                </div>
              </div>
            </router-link>
          </li>
          <!--End userInsurances -->

          <!--Start medicalInsurances -->
          <li v-if="hasPermission('medical-insurance all')">
            <!-- <li> -->
            <router-link :to="{ name: 'all-medicalInsurances' }">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Medical Insurances</span>
                </div>
              </div>
            </router-link>
          </li>
          <!--End medicalInsurances -->
          <!--Start Branches -->
          <li v-if="hasPermission('branch all')">
            <router-link :to="{ name: 'all-branches' }">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Branches</span>
                </div>
              </div>
            </router-link>
          </li>
          <!--End Branches -->
          <!--Start Doctors -->
          <li v-if="hasPermission('doctor all')">
            <router-link :to="{ name: 'all-doctors' }">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Doctors</span>
                </div>
              </div>
            </router-link>
          </li>
          <!--End Doctors -->
          <!--Start appointmentDoctor -->
          <li v-if="hasPermission('appointment-doctor all')">
            <router-link :to="{ name: 'all-appointmentDoctors' }">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Doctor Appointments</span>
                </div>
              </div>
            </router-link>
          </li>
          <!--End appointmentDoctor -->
          <!--Start bookings -->
          <li v-if="hasPermission('booking all')">
            <router-link :to="{ name: 'all-bookings' }">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Doctor Bookings</span>
                </div>
              </div>
            </router-link>
          </li>
          <!--End bookings -->

          <!--Start bookingStatus -->
          <li v-if="hasPermission('status all')">
            <router-link :to="{ name: 'all-bookingStatus' }">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Booking Status</span>
                </div>
              </div>
            </router-link>
          </li>
          <!--End bookingStatus -->

          <!--Start InfoServices -->
          <li v-if="hasPermission('info-service all')">
            <router-link :to="{ name: 'all-infoServices' }">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Hospital/Clinic Service</span>
                </div>
              </div>
            </router-link>
          </li>
          <!--End InfoServices -->
          <!--Start ExternalClinics -->
          <li v-if="hasPermission('external-clinic all', 'external-clinic-service all')">
            <a href="javascript:void(0)">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title"> OutPatient Clinic Sub-Service </span><i class="icon-arrow"></i>
                </div>
              </div>
            </a>
            <ul v-if="hasPermission('external-clinic all')" class="sub-menu">
              <!--Start ExternalClinics -->
              <!-- <li> -->
              <li v-if="hasPermission('external-clinic all')">
                <router-link :to="{ name: 'all-externalClinics' }">
                  <!-- <a> -->
                  <span class="title">OutPatient Clinics</span>
                  <!-- </a> -->
                </router-link>
              </li>
              <!--End ExternalClinics -->
              <!--Start ExternalClinicServices -->
              <li>
                <!-- <li v-if="hasPermission('operation all')"> -->
                <router-link :to="{ name: 'all-externalClinicServices' }">
                  <!-- <a> -->
                  <span class="title">OutPatient Clinic Service</span>
                  <!-- </a> -->
                </router-link>
              </li>
              <!--End ExternalClinicServices -->
            </ul>
          </li>
          <!--End ExternalClinics -->
          <!--Start operation Sub-service -->
          <li
            v-if="hasPermission('operation all', 'pre-operation all', 'procedure all', 'operation-appointments all', 'operation-service all', 'operation allbooking')">
            <a href="javascript:void(0)">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title"> Operation Sub-Service </span><i class="icon-arrow"></i>
                </div>
              </div>
            </a>
            <ul class="sub-menu">
              <!--Start operation -->
              <!-- <li> -->
              <li v-if="hasPermission('operation all')">
                <router-link :to="{ name: 'all-operations' }">
                  <!-- <a> -->
                  <span class="title">Operations</span>
                  <!-- </a> -->
                </router-link>
              </li>
              <!--End operation -->
              <!--Start operationServices -->
              <li>
                <!-- <li v-if="hasPermission('operationServices all')"> -->
                <router-link :to="{ name: 'all-operationServices' }">
                  <!-- <a> -->
                  <span class="title">Operation Service</span>
                  <!-- </a> -->
                </router-link>
              </li>
              <!--End operationServices -->
              <!--Start preOperations -->
              <!-- <li> -->
              <li v-if="hasPermission('pre-operation all')">
                <router-link :to="{ name: 'all-preOperations' }">
                  <!-- <a> -->
                  <span class="title">Pre-Operation</span>
                  <!-- </a> -->
                </router-link>
              </li>
              <!--End preOperations -->
              <!--Start procedures -->
              <!-- <li> -->
              <li v-if="hasPermission('procedure all')">
                <router-link :to="{ name: 'all-procedures' }">
                  <!-- <a> -->
                  <span class="title">Procedures</span>
                  <!-- </a> -->
                </router-link>
              </li>
              <!--End procedures -->
              <!--Start operationServiceDays -->
              <li>
                <!-- <li v-if="hasPermission('operationServiceDays all')"> -->
                <router-link :to="{ name: 'all-operationServiceDays' }">
                  <!-- <a> -->
                  <span class="title">Operation Appointments</span>
                  <!-- </a> -->
                </router-link>
              </li>
              <!--End operationServiceDays -->
              <!--Start operationBookings -->
              <li>
                <!-- <li v-if="hasPermission('operationBookings all')"> -->
                <router-link :to="{ name: 'all-operationBookings' }">
                  <!-- <a> -->
                  <span class="title">Operation Bookings</span>
                  <!-- </a> -->
                </router-link>
              </li>
              <!--End operationBookings -->

            </ul>
          </li>
          <!--End operation Sub-service -->
          <!--Start 1 DayCare Sub-Service -->
          <li v-if="hasPermission('day-service all')">
            <a href="javascript:void(0)">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title"> 1 Day Care Sub-Service </span><i class="icon-arrow"></i>
                </div>
              </div>
            </a>
            <ul class="sub-menu">
              <!--Start dayServices -->
              <!-- <li> -->
              <li v-if="hasPermission('day-service all')">
                <router-link :to="{ name: 'all-dayServices' }">
                  <!-- <a> -->
                  <span class="title">1 Day Cares</span>
                  <!-- </a> -->
                </router-link>
              </li>
              <!--End dayServices -->
              <!--Start infoDayServices -->
              <li>
                <!-- <li v-if="hasPermission('operation all')"> -->
                <router-link :to="{ name: 'all-infoDayServices' }">
                  <!-- <a> -->
                  <span class="title">1 Day Care Appointments</span>
                  <!-- </a> -->
                </router-link>
              </li>
              <!--End infoDayServices -->
              <!--Start dayServiceBookings -->
              <li>
                <!-- <li v-if="hasPermission('operation all')"> -->
                <router-link :to="{ name: 'all-dayServiceBookings' }">
                  <!-- <a> -->
                  <span class="title">1 Day Care Bookings</span>
                  <!-- </a> -->
                </router-link>
              </li>
              <!--End dayServiceBookings -->
            </ul>
          </li>
          <!--End  1 DayCare Sub-Service -->
          <!--Start Incubators -->
          <!-- <li> -->
          <li v-if="hasPermission('incubator all')">
            <router-link :to="{ name: 'all-incubators' }">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Incubators Bookings</span>
                </div>
              </div>
            </router-link>
          </li>
          <!--End Incubators -->
          <!--Start intensiveCares -->
          <!-- <li> -->
          <li v-if="hasPermission('intensive-care all')">
            <router-link :to="{ name: 'all-intensiveCares' }">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Intensive Care Bookings</span>
                </div>
              </div>
            </router-link>
          </li>
          <!--End intensiveCares -->


          <!--Start Lab/scan service -->
          <li v-if="hasPermission('lab all', 'test all', 'appointment-lab all', 'lab-booking all')">
            <a href="javascript:void(0)">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Lab/Scan Service</span><i class="icon-arrow"></i>
                </div>
              </div>
            </a>
            <ul class="sub-menu">
              <!--Start lab -->
              <li v-if="hasPermission('lab all')">
                <router-link :to="{ name: 'all-labs' }">
                  <!-- <a> -->
                  <span class="title">Labs/Scans</span>
                  <!-- </a> -->
                </router-link>
              </li>
              <!--End lab -->
              <!--Start test -->
              <li v-if="hasPermission('test all')">
                <router-link :to="{ name: 'all-tests' }">
                  <!-- <a> -->
                  <span class="title">Investigations</span>
                  <!-- </a> -->
                </router-link>
              </li>
              <!--End test -->
              <!--Start appointmentLabs -->
              <li v-if="hasPermission('appointment-lab all')">
                <router-link :to="{ name: 'all-appointmentLabs' }">
                  <!-- <a> -->
                  <span class="title">Lab/Scan Appointments</span>
                  <!-- </a> -->
                </router-link>
              </li>
              <!--End appointmentLabs -->
              <!--Start appointmentLabs -->
              <li v-if="hasPermission('lab-booking all')">
                <router-link :to="{ name: 'all-labBookings' }">
                  <!-- <a> -->
                  <span class="title">Lab/Scan Bookings</span>
                  <!-- </a> -->
                </router-link>
              </li>
              <!--End appointmentLabs -->
            </ul>
          </li>
          <!--End Lab/scan service -->
          <!--Start pharmacy service -->
          <li
            v-if="hasPermission('pharmacy all', 'medication all', 'type all', 'category all', 'medication-pharmacy all', 'order all', 'order-status all', 'payment-type all')">
            <a href="javascript:void(0)">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title"> Pharmacy Service </span><i class="icon-arrow"></i>
                </div>
              </div>
            </a>
            <ul class="sub-menu">
              <!--Start Pharamcy -->
              <li v-if="hasPermission('pharmacy all')">
                <router-link :to="{ name: 'all-pharmacies' }">
                  <!-- <a> -->
                  <span class="title">Pharmacies</span>
                  <!-- </a> -->
                </router-link>
              </li>
              <!--End Pharamcy -->
              <!--Start Medication -->
              <li v-if="hasPermission('medication all')">
                <router-link :to="{ name: 'all-medications' }">
                  <!-- <a> -->
                  <span class="title">Medications</span>
                  <!-- </a> -->
                </router-link>
              </li>
              <!--End Medication -->
              <!--Start MedicationTypes -->
              <li v-if="hasPermission('type all')">
                <router-link :to="{ name: 'all-medicationTypes' }">
                  <!-- <a> -->
                  <span class="title">Medication Types</span>
                  <!-- </a> -->
                </router-link>
              </li>
              <!--End MedicationTypes -->
              <!--Start categories -->
              <li v-if="hasPermission('category all')">
                <router-link :to="{ name: 'all-categories' }">
                  <!-- <a> -->
                  <span class="title">Categories</span>
                  <!-- </a> -->
                </router-link>
              </li>
              <!--End categories -->
              <!--Start medicationPharmacy -->
              <li v-if="hasPermission('medication-pharmacy all')">
                <router-link :to="{ name: 'all-pharmacyMedications' }">
                  <!-- <a> -->
                  <span class="title">Pharmacy Medications</span>
                  <!-- </a> -->
                </router-link>
              </li>
              <!--End medicationPharmacy -->
              <!--Start order -->
              <!-- <li> -->
              <li v-if="hasPermission('order all')">
                <router-link :to="{ name: 'all-orders' }">
                  <!-- <a> -->
                  <span class="title">Pharamcy Orders</span>
                  <!-- </a> -->
                </router-link>
              </li>
              <!--End order -->
              <!--Start order -->
              <!-- <li> -->
              <!-- <li v-if="hasPermission('order all')">
                <router-link :to="{ name: 'all-prescriptionOrders' }"> -->
              <!-- <a> -->
              <!-- <span class="title">Prescription Orders</span> -->
              <!-- </a> -->
              <!-- </router-link>
              </li> -->
              <!--End order -->
              <!--Start OrderStatus -->
              <li v-if="hasPermission('order-status all')">
                <router-link :to="{ name: 'all-orderStatus' }">
                  <!-- <a> -->
                  <span class="title">Order Status</span>
                  <!-- </a> -->
                </router-link>
              </li>
              <!--End OrderStatus -->

              <!--Start paymentTypes -->
              <li v-if="hasPermission('payment-type all')">
                <router-link :to="{ name: 'all-paymentTypes' }">
                  <!-- <a> -->
                  <span class="title">Payment Types</span>
                  <!-- </a> -->
                </router-link>
              </li>
              <!--End paymentTypes -->

            </ul>
          </li>
          <!--End pharmacy service -->
          <!--Start homeVisit -->
          <!-- <li> -->
          <li v-if="hasPermission('home-visit all')">
            <router-link :to="{ name: 'all-homeVisits' }">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Home Visit Bookings</span>
                </div>
              </div>
            </router-link>
          </li>
          <!--End homeVisit -->
          <!--Start nurseVisit -->
          <!-- <li> -->
          <li v-if="hasPermission('nurse-visit all')">
            <router-link :to="{ name: 'all-nurseVisits' }">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Home Nursing Bookings</span>
                </div>
              </div>
            </router-link>
          </li>
          <!--End homeVisit -->
          <!--Start emergency -->
          <!-- <li> -->
          <li v-if="hasPermission('emergency all')">
            <router-link :to="{ name: 'all-emergencies' }">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Emergencies</span>
                </div>
              </div>
            </router-link>
          </li>
          <!--End emergency -->
          <!--Start ExtraServices -->
          <li v-if="hasPermission('extra-service all')">
            <router-link :to="{ name: 'all-extraServices' }">
              <div class="item-content">
                <div class="item-media">
                  <i class="ti-layers-alt"></i>
                </div>
                <div class="item-inner">
                  <span class="title">Extra-Services</span>
                </div>
              </div>
            </router-link>
          </li>
          <!--End ExtraServices -->

        </ul>
        <!-- end: MAIN NAVIGATION MENU -->
        <!-- start: CORE FEATURES -->
        <!-- <div class="navbar-title">
          <span>Core Features</span>
        </div> -->
        <!-- <ul class="folders">
          <li>
            <a href="pages_calendar.html">
              <div class="item-content">
                <div class="item-media">
                  <span class="fa-stack"> <i class="fa fa-square fa-stack-2x"></i> <i
                      class="fa fa-terminal fa-stack-1x fa-inverse"></i> </span>
                </div>
                <div class="item-inner">
                  <span class="title"> Calendar </span>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a href="pages_messages.html">
              <div class="item-content">
                <div class="item-media">
                  <span class="fa-stack"> <i class="fa fa-square fa-stack-2x"></i> <i
                      class="fa fa-folder-open-o fa-stack-1x fa-inverse"></i> </span>
                </div>
                <div class="item-inner">
                  <span class="title"> Messages </span>
                </div>
              </div>
            </a>
          </li>
        </ul> -->
        <!-- end: CORE FEATURES -->
        <!-- start: DOCUMENTATION BUTTON -->
        <!-- <div class="wrapper">
          <a href="documentation.html" class="button-o">
            <i class="ti-help"></i>
            <span>Documentation</span>
          </a>
        </div> -->
        <!-- end: DOCUMENTATION BUTTON -->
      </nav>
    </div>
  </div>
  <!-- / sidebar -->



</template>
<script>
import { store } from "../../Store";
import PerfectScrollbar from 'perfect-scrollbar';

export default {
  name: "SideBar",
  mounted() {
    const sidebar = document.querySelector('.sidebar');
    const container = document.querySelector('.sidebar-container');
    console.log('Sidebar height:', sidebar.clientHeight);
    console.log('Container height:', container.clientHeight);
    console.log('Container scrollHeight:', container.scrollHeight);

    // if (!container) {
    //   console.error("Sidebar container not found.");
    //   return;
    // }

    // // Use Vue's nextTick to ensure DOM updates are applied
    // this.$nextTick(() => {
    //   try {
    //     new PerfectScrollbar(container);
    //   } catch (error) {
    //     console.error("Error initializing Perfect Scrollbar:", error);
    //   }
    // });

    // if (container) {
    //   console.log('Initializing perfect-scrollbar for container:', container);
    //   try {
    //     new PerfectScrollbar(container);
    //   } catch (error) {
    //     console.error('Error initializing perfect-scrollbar:', error);
    //   }
    // } else {
    //   console.error('Sidebar container not found!');
    // }


    if (container) {
      new PerfectScrollbar(container);
    }
  },
  methods: {
    hasPermission(routeName) {
      return store.hasPermission(routeName);
    },
  },
};
</script>