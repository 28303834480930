<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Deleted Order Status</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-orderStatus' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">

            <div v-if="orders.length > 0">
              <MyTable :columns="columns" :actions="true">
                <tr v-for="(order, index) in filteredOrders" :key="index">
                  <th scope="row">{{ order.id }}</th>
                  <td>{{ order.name_ar }}</td>
                  <td>{{ order.name_en }}</td>
                  <td>
                    <button class="me-2 btn btn-success" @click="restoreOrder(order.id)">
                      Restore
                    </button>
                  </td>
                </tr>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No deleted order status found.
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import $ from 'jquery'; // Import jQuery
import MyTable from "../MyTable//MyTable.vue"; // Import your MyTable component
import axios from "../../Axios";
export default {
  name: "DeletedOrderStatus",
  components: {
    MyTable,
    // Paginate,
  },
  data() {
    return {
      dataTable: [],
      orders: [],
      meta: {},
      searchTerm: "",
      columns: [
        { label: '#', key: 'id' },
        { label: 'Name Arabic', key: 'name_ar' },
        { label: 'Name English', key: 'name_en' },
      ],
    };
  },

  async created() {
    this.fetchOrders();
  },

  computed: {
    filteredOrders() {
      if (!this.searchTerm) {
        return this.orders;
      }
      const term = this.searchTerm.toLowerCase();
      return this.orders.filter(
        (order) =>
          order.name.toLowerCase().includes(term) ||
          order.postal_code.toLowerCase().includes(term)
      );
    },
  },

  methods: {
    // fetch orders
    async fetchOrders() {
      try {
        const response = await axios.get(
          `/admin/admin/order-status/get-delete-soft`
        );
        this.orders = response.data.data;
        this.meta = response.data.meta;
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    },
    initializeDataTable() {
      this.$nextTick(() => {
        // Ensure the DataTable is initialized only after the DOM is updated
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },
    restoreOrder(id) {
      console.log("restore order-status id: ", id);
      axios
        .post(`/admin/admin/order-status/restore?id=${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchOrders();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

};
</script>
<style scoped>
/* Debugging styles */
.dataTables_paginate {
  border: 2px solid red;
  /* Add a red border to see if the container is styled */
}

/* Pagination container */
.dataTables_wrapper .dataTables_paginate {
  display: flex !important;
  justify-content: center !important;
  margin-top: 20px !important;
}

/* Pagination button */
.dataTables_wrapper .dataTables_paginate .paginate_button {
  display: inline-block !important;
  padding: 8px 12px !important;
  margin: 0 5px !important;
  text-decoration: none !important;
  color: #333 !important;
  background-color: #fff !important;
  border: 1px solid #ddd !important;
  border-radius: 4px !important;
  transition: background-color 0.3s, color 0.3s !important;
  cursor: pointer !important;
}

/* Active pagination button */
.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  background-color: #007bff !important;
  color: #fff !important;
  border-color: #007bff !important;
}

/* Hover state for pagination button */
.dataTables_wrapper .dataTables_paginate .paginate_button:hover:not(.current):not(.disabled) {
  background-color: #007bff !important;
  color: #fff !important;
}

/* Disabled state for pagination button */
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled {
  color: #6c757d !important;
  pointer-events: none !important;
  background-color: #fff !important;
  border-color: #ddd !important;
}
</style>
