<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">All Districts</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'create-district' }">create</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <div v-if="districts.length > 0">
              <MyTable :columns="columns" :actions="true">
                <tr v-for="(district, index) in filteredDistricts" :key="index">
                  <th scope="row">{{ district.id }}</th>
                  <td>{{ district.name_ar }}</td>
                  <td>{{ district.name_en }}</td>
                  <td>
                    <button class="me-2 btn btn-danger" @click="deleteDistrict(district.id)">
                      Delete
                    </button>
                    <router-link class="btn btn-primary spacing"
                      :to="{ name: 'edit-district', params: { id: district.id } }">
                      Edit
                    </router-link>
                  </td>
                </tr>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No Districts found.
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>
<script>
import axios from "../../Axios";
import $ from 'jquery'; // Import jQuery
import MyTable from "../MyTable//MyTable.vue"; // Import your MyTable component
export default {
  name: "AllDistricts",

  components: {
    MyTable,
    // Paginate,
  },
  data() {
    return {
      dataTable: [], // Store DataTable instance
      districts: [],
      meta: {},
      searchTerm: "",
      columns: [
        { label: '#', key: 'id' },
        { label: 'Name Arabic', key: 'name_ar' },
        { label: 'Name English', key: 'name_en' },
      ],
    };
  },

  async created() {
    this.fetchDistricts();
  },

  computed: {
    filteredDistricts() {
      if (!this.searchTerm) {
        return this.districts;
      }
      const term = this.searchTerm.toLowerCase();
      return this.districts.filter(
        (district) =>
          district.name.toLowerCase().includes(term) ||
          district.postal_code.toLowerCase().includes(term)
      );
    },
  },

  methods: {
    // fetch districts
    async fetchDistricts() {
      try {
        const response = await axios.get(
          `/admin/admin/district/all`
        );
        this.districts = response.data.data;
        this.meta = response.data.meta;
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    },
    initializeDataTable() {
      this.$nextTick(() => {
        // Ensure the DataTable is initialized only after the DOM is updated
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },
    deleteDistrict(id) {
      console.log("delete district id: ", id);
      axios
        .delete(`/admin/admin/district/delete?id=${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchDistricts();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}
</style>