<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Create Lab/Scan Appointment</h1>

            <!-- <span class="mainDescription"
              >Use this page to start from scratch and put your custom
              content</span
            > -->
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-labs' }">Back</router-link>
          </div>
          <!-- <ol class="breadcrumb">
            <li class="active">
              <span>Blank Page</span>
            </li>
          </ol> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="createAppointmentLab" enctype="multipart/form-data">
              <div class="row">

                <div class="form-group col-md-12 mb-2">
                  <label class="text-">Day</label>
                  <multiselect v-model="appointment_day" :options="appointmentDays" label="name" track-by="id"
                    placeholder="Select a Day"></multiselect>
                  <!-- <span v-if="errors.appointment_day" class="error">{{ errors.appointment_day }}</span> -->
                </div>
                <div class="form-group col-md-12 mb-2">
                  <label class="text-">Hour</label>
                  <multiselect v-model="appointment_hour" :options="multiselectOptions" label="label" track-by="value"
                    placeholder="Select a hour"></multiselect>
                  <!-- <span v-if="errors.appointment_hour" class="error">{{ errors.appointment_hour }}</span> -->
                </div>
                <div class="form-group" v-if="service == 'Laboratories'">
                  <label>Appointment Types</label>
                  <select class="form-control" v-model="selectedType">
                    <option value="">Select Appointment Type</option>
                    <option value="home">Home</option>
                    <option value="branch">Branch</option>
                  </select>
                </div>
              </div>
              <br>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import Multiselect from "vue-multiselect";
export default {
  name: "CreateAppointmentLab",
  components: {
    Multiselect,
  },
  data() {

    return {
      appointmentDays: [],
      appointment_day: null,
      appointmentHours: [],
      appointment_hour: null,
      multiselectOptions: [],
      // selectedType: [],
      selectedType: "Branch",
      service: ""
    };
  },
  created() {
    this.fetchLab();
    this.fetchAppointmentDays();
    this.fetchAppointmentHours();
  },
  methods: {
    async fetchLab() {
      try {
        const response = await axios.get(`/admin/admin/lab/get?id=${this.$route.params.lab_id}`);
        console.log("lab", response.data.data);
        this.service = response.data.data.service.name;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchAppointmentDays() {
      try {
        const response = await axios.get("/admin/admin/appointment-day/all");
        this.appointmentDays = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchAppointmentHours() {
      try {
        const response = await axios.get("/admin/admin/appointment-hour/all");
        this.appointmentHours = response.data.data;
        this.multiselectOptions = this.appointmentHours.map(hour => ({
          label: `${hour.from} - ${hour.to}`,
          value: hour.id,
        }));
      } catch (error) {
        console.error(error);
      }
    },

    async createAppointmentLab() {
      try {
        const formData = new FormData();
        // formData.append("lab_id", this.selectedLab.id);
        formData.append("lab_id", this.$route.params.lab_id);
        // formData.append("lab_id", this.$route.params.lab_id);
        formData.append("appointment_hour_id", this.appointment_hour.value);
        formData.append("appointment_day_id", this.appointment_day.id);
        // if (this.service == 'Laboratories') {
        formData.append("appointment_type", this.selectedType);
        // } else {
        //   formData.append("appointment_type", 'branch');
        // }

        console.log(formData);
        const response = await axios.post("admin/admin/appointment-lab/create", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log(formData);
        console.log(response.data);
        this.$router.push({ name: "all-labs" });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  width: 100%;
  /* max-width: 500px; Optional: Max width for better responsiveness */
}

form>.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}
</style>
