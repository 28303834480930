<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Create Role</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-roles' }">Back</router-link>
          </div>
        </div>
      </section>

      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="createRole" enctype="multipart/form-data">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Role Name</label>
                    <input type="text" class="form-control" placeholder="Enter Role Name" v-model="name" />
                  </div>
                </div>

                <!-- Multi-select for Permissions -->
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Select Permissions</label>
                    <multiselect v-model="selectedPermissions" :options="permissions" :multiple="true"
                      :searchable="true" label="name" track-by="id" :close-on-select="false" :clear-on-select="false"
                      :append-to-body="true" placeholder="Select permissions">
                    </multiselect>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->

    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
export default {
  name: "CreateRole",
  components: {
    Multiselect,
  },
  data() {
    return {
      name: "",
      permissions: [],          // Store all permissions from API here
      selectedPermissions: [],   // Selected permissions
      searchTerm: '', // For filtering permissions
    };
  },


  async created() {
    this.fetchPermissions();
  },

  methods: {
    async fetchPermissions() {
      try {
        const token = localStorage.getItem("access_token");
        const response = await axios.get("/admin/admin/permission/all", {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        this.permissions = response.data.data;
        this.meta = response.data.meta;
        console.log("permissions");
        console.log(this.permissions);
      } catch (error) {
        console.error("Error loading permissions:", error);
      }
    },
    async createRole() {
      try {
        const formData = new FormData();
        formData.append("name", this.name);
        this.selectedPermissions.forEach((permission, index) => {
          formData.append(`permissions[${index}]`, permission.id);
        });

        const token = localStorage.getItem("access_token");
        const response = await axios.post(
          "admin/admin/role/create",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              'Authorization': `Bearer ${token}`,
            },
          }
        );

        console.log(formData);
        console.log(response.data);
        this.$router.push({ name: "all-roles" });
      } catch (error) {
        console.error(error);
      }
    },
  },

};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.form-group {
  margin-bottom: 1.5rem;
}

.multiselect {
  position: relative;
  /* Ensure proper positioning */
}

.multiselect__content-wrapper {
  position: absolute;
  /* Detach dropdown from the flow */
  z-index: 1050;
  /* Ensure it appears above other elements */
  background: white;
  /* Optional: Ensures proper visibility */
  width: 100%;
  /* Match the parent width */
  max-height: 200px;
  /* Set a maximum height for the dropdown */
  overflow-y: auto;
  /* Add scrolling for long dropdowns */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  /* Optional styling */
}
</style>