<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">User Insurance Details</h1>

          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-userInsurances' }">Back</router-link>
          </div>

        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <div class="booking-details">
              <!-- <h1>Booking Details</h1> -->
              <div class="booking-info">
                <div class="section">
                  <h2>Insurance Information</h2>
                  <br>
                  <div class="col-md-6">
                    <p><strong>Name Arabic:</strong> {{ name_ar || 'N/A' }}</p>
                    <p><strong>Name English:</strong> {{ name_en || 'N/A' }}</p>
                    <p><strong>Phone:</strong> {{ phone || 'N/A' }}</p>
                    <p><strong>Email:</strong> {{ email || 'N/A' }}</p>
                  </div>
                  <div class="col-md-6">
                    <img v-if="isImage(main_image)" :src="main_image" alt="medical-card" style="max-width: 100px;">
                  </div>

                </div>
                <div class="section">
                  <h2>User Information</h2>
                  <br>
                  <div class="col-md-6">
                    <p><strong>Name:</strong> {{ user_name || 'N/A' }}</p>
                    <p><strong>Phone:</strong> {{ user_phone || 'N/A' }}</p>
                    <p><strong>Age:</strong> {{ age || 'N/A' }}</p>
                    <p><strong>Email:</strong> {{ user_email || 'N/A' }}</p>
                    <p><strong>Id Number:</strong> {{ id_number || 'N/A' }}</p>
                  </div>
                </div>
                <div class="section">
                  <h2>Medical Card</h2>
                  <div v-if="photo_of_medical_card" class="documents-container">
                    <div class="document">
                      <a :href="photo_of_medical_card" target="_blank" @click.prevent="openPdf(photo_of_medical_card)">
                        <img v-if="isImage(photo_of_medical_card)" :src="photo_of_medical_card" alt="Document"
                          class="document-image" />
                        <img v-else-if="isPdf(photo_of_medical_card)" src="/assets/images/pdf-icon.png"
                          alt="PDF Document" class="document-image" />
                        <span v-else>{{ getFileName(photo_of_medical_card) }}</span>
                      </a>
                    </div>
                  </div>
                  <div v-else>No Medical Card available.</div>
                </div>

                <!-- <div class="section">
                  <h2>Medical Card</h2>
                  <br>
                  <div class="documents-container">
                    <div class="document">
                      <a :href="`${BASE_URL}/${photo_of_medical_card}`" target="_blank"
                        @click.prevent="openPdf(`${BASE_URL}/${photo_of_medical_card}`)">
                        <img v-if="isImage(`${BASE_URL}/${photo_of_medical_card}`)"
                          :src="`${BASE_URL}/${photo_of_medical_card}`" alt="Document" class="document-image" />
                        <img v-else-if="isPdf(`${BASE_URL}/${photo_of_medical_card}`)" src="/assets/images/pdf-icon.png"
                          alt="PDF Document" class="document-image" />
                        <span v-else>{{ getFileName(`${BASE_URL}/${photo_of_medical_card}`) }}</span>
                      </a>
                    </div>
                  </div>
                </div> -->
                <div class="section status-section">
                  <h2>Status</h2>
                  <div class="form-group">
                    <br>
                    <multiselect v-model="selectedStatus" :options="statusOptions" :multiple="false"
                      placeholder="Select status" label="name" track-by="value"></multiselect>
                  </div>
                  <button @click="updateStatus" class="btn btn-primary mt-3 status-btn">
                    Update Status
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>


<script>
import axios from "../../Axios";
import Multiselect from "vue-multiselect";
export default {
  name: "ShowUserInsurance",
  components: {
    Multiselect
  },

  data() {
    const BASE_URL = `https://allodoctor-backend.developnetwork.net/storage/uploads`
    return {
      BASE_URL,

      user_phone: "",
      user_name: "",
      age: "",
      user_email: "",
      id_number: "",

      name: "",
      phone: "",
      email: "",
      main_image: "",
      name_ar: "",
      name_en: "",
      district: "",
      photo_of_medical_card: "",

      selectedStatus: null,
      statusOptions: [
        { name: "Confirmed", value: "confirmed" },
        { name: "Cancelled", value: "canceled" },
      ],

    };
  },
  created() {
    this.fetchBooking();
  },
  methods: {

    async fetchBooking() {
      try {
        const id1 = this.$route.params.id1;
        const id2 = this.$route.params.id2;

        const response = await axios.get(`/api/admin/user-insurance/get?medical_insurance_id=${id1}&user_id=${id2}`);
        const insuranceData = response.data.data;
        console.log(insuranceData);
        this.name_en = insuranceData.medical_insurance.name_en ?? null;
        this.name_ar = insuranceData.medical_insurance.name_ar ?? null;
        this.phone = insuranceData.medical_insurance.phone ?? null;
        this.email = insuranceData.medical_insurance.email ?? null;
        this.main_image = insuranceData.medical_insurance.main_image ?? null;
        this.user_name = insuranceData.user.name ?? null;
        this.user_phone = insuranceData.user.phone ?? null;
        this.user_email = insuranceData.user.email ?? null;
        this.age = insuranceData.pivot.age ?? null;
        this.id_number = insuranceData.pivot.id_number ?? null;
        this.photo_of_medical_card = insuranceData.pivot.photo_of_medical_card ?? null;
        this.selectedStatus = this.statusOptions.find(
          (option) => option.value === insuranceData.pivot.status
        );
        this.document = insuranceData.document ?? null;

      } catch (error) {
        console.error("Error fetching insuranceData:", error);
      }
    },
    isImage(file) {
      const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
      const extension = file.split('.').pop().toLowerCase();
      return imageExtensions.includes(extension);
    },
    isPdf(file) {
      const pdfExtension = 'pdf';
      const extension = file.split('.').pop().toLowerCase();
      return extension === pdfExtension;
    },
    getFileName(file) {
      return file.split('/').pop();
    },
    openPdf(url) {
      window.open(url, '_blank');
    },
    async updateStatus() {
      try {
        const formData = new FormData();
        formData.append("medical_insurance_id", this.$route.params.id1);
        formData.append("user_id", this.$route.params.id2);
        if (this.selectedStatus) {
          formData.append("status", this.selectedStatus.value);
        }
        console.log(formData)
        const response = await axios.post(`/admin/admin/user-insurance/update`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },

        });
        // this.booking.status = this.selectedStatus; // Update local state
        console.log(formData);
        console.log(response.data);
        this.$router.push({ name: "all-userInsurances" });
      } catch (error) {
        console.error(error);
      }
    }
  },
};
</script>
<style scoped>
.booking-details {
  padding: 20px;
}

.booking-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.section {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
}

.document {
  margin-bottom: 10px;
}

.document-image {
  max-width: 100%;
  max-height: 100px;
}

h2 {
  margin-bottom: 10px;
}

.documents-container {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}

p {
  font-size: 1.1em
}

/* Button Styling */
.status-btn {
  display: block;
  margin-top: 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 10px 15px;
  transition: background-color 0.3s ease;
}

.status-btn:hover {
  background-color: #0056b3;
}
</style>
