<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Doctor Booking Details</h1>

            <!-- <span class="mainDescription"
              >Use this page to start from scratch and put your custom
              content</span
            > -->
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-bookings' }">Back</router-link>
          </div>
          <!-- <ol class="breadcrumb">
            <li class="active">
              <span>Blank Page</span>
            </li>
          </ol> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <div class="booking-details">
              <!-- <h1>Booking Details</h1> -->
              <div class="booking-info">
                <div class="section">
                  <h2>Patient Information</h2>
                  <br>
                  <!-- relative_option: 0
                
                  view_test_prerequisites: 0 -->
                  <p><strong>Name:</strong> {{ name || 'N/A' }}</p>
                  <p><strong>Phone:</strong> {{ phone || 'N/A' }}</p>
                  <p><strong>User Name:</strong> {{ user_name || 'N/A' }}</p>
                  <p><strong>User Phone:</strong> {{ user_phone || 'N/A' }}</p>
                  <p><strong>Symptoms:</strong> {{ symptoms || 'N/A' }}</p>
                </div>
                <div class="section">
                  <h2>Appointment Details</h2>
                  <br>
                  <p><strong>Day:</strong> {{ appointment_day || 'N/A' }}</p>
                  <p><strong>Hour:</strong> {{ from }} - {{ to }}</p>
                  <p><strong>Date:</strong> {{ date || 'N/A' }}</p>
                  <p><strong>Doctor:</strong> {{ doctor_name || 'N/A' }}</p>
                  <p><strong>Specialty:</strong> {{ specialty_name || 'N/A' }}</p>
                  <p><strong>Service:</strong> {{ service_name || 'N/A' }}</p>
                </div>
                <div class="section">
                  <h2>Prescriptions</h2>
                  <br>
                  <div class="documents-container" v-if="prescriptions">
                    <div v-for="prescription in prescriptions" :key="prescription.id" class="document">
                      <a :href="prescription.file" target="_blank" @click.prevent="openPdf(prescription.file)">
                        <img v-if="isImage(prescription.file)" :src="prescription.file" alt="Document"
                          class="document-image" />
                        <img v-else-if="isPdf(prescription.file)" src="/assets/images/pdf-icon.png" alt="PDF Document"
                          class="document-image" />
                        <span v-else>{{ getFileName(prescription.file) }}</span>
                      </a>
                    </div>
                  </div>
                  <p v-else>No Prescriptions available.</p>
                </div>
                <div class="section">
                  <h2>Documents</h2>
                  <div class="documents-container" v-if="documents">
                    <div v-for="document in documents" :key="document.id" class="document">
                      <a :href="document.file" target="_blank" @click.prevent="openPdf(document.file)">
                        <img v-if="isImage(document.file)" :src="document.file" alt="Document" class="document-image" />
                        <img v-else-if="isPdf(document.file)" src="/assets/images/pdf-icon.png" alt="PDF Document"
                          class="document-image" />
                        <span v-else>{{ getFileName(document.file) }}</span>
                      </a>
                    </div>
                  </div>
                  <p v-else>No Documents available.</p>
                </div>
                <!-- <div class="section">
                  <h2>Documents</h2>
                  <div class="documents-container">
                    <div v-for="document in documents" :key="document.id" class="document">
                      <a :href="document.file" target="_blank">
                        <img v-if="isImage(document.file)" :src="document.file" alt="Document" class="document-image" />
                        <span v-else-if="isPdf(document.file)">{{ getFileName(document.file) }} (PDF)</span>
                        <span v-else>{{ getFileName(document.file) }}</span>
                      </a>
                    </div>
                  </div>
                </div> -->

                <!-- <div class="section">
                  <h2>Documents</h2>
                  <div class="documents-container">
                    <div v-for="document in documents" :key="document.id" class="document">
                      <a :href="document.file" target="_blank">
                        <img v-if="isImage(document.file)" :src="document.file" alt="Document" class="document-image" />
                        <span v-else>{{ getFileName(document.file) }}</span>
                      </a>
                    </div>
                  </div>
                </div> -->
                <div class="section">
                  <h2>Voice Note</h2>
                  <p v-if="!voice_note">No voice note available.</p>
                  <audio v-else controls>
                    <source :src="voice_note" type="audio/ogg">
                    <source :src="voice_note" type="audio/mpeg">
                    <source :src="voice_note" type="audio/wav">
                    <source :src="voice_note" type="audio/opus">
                    <source :src="voice_note" type="audio/webm">
                    Your browser does not support the audio element.
                  </audio>
                </div>
                <div class="section status-section">
                  <h2>Status</h2>
                  <div class="form-group">
                    <br>
                    <multiselect v-model="selectedStatus" :options="statusOptions" :multiple="false"
                      placeholder="Select status" label="name" track-by="value"></multiselect>
                  </div>
                  <button @click="updateStatus" class="btn btn-primary mt-3 status-btn">
                    Update Status
                  </button>
                </div>
                <!-- <div class="section status-section">
                  <h2>Status</h2>
                  <p>
                    <strong>Current Status:</strong>
                    <span class="badge" :class="'badge-' + selectedStatus.toLowerCase()">
                      {{ selectedStatus || 'N/A' }}
                    </span>
                  </p> -->

                <!-- <p><strong>Current Status:</strong> {{ selectedStatus.name || 'N/A' }}</p> -->
                <!-- <label for="status" class="form-label">Change Status:</label>
                  <select v-model="selectedStatus" id="status" class="form-select custom-select">
                    <option v-for="(label, key) in statuses" :key="key" :value="key">
                      {{ label.name }}
                    </option>
                  </select>
                  <button @click="updateStatus" class="btn btn-primary mt-3 status-btn">
                    Update Status
                  </button>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import Multiselect from "vue-multiselect";
export default {
  name: "ShowBooking",
  components: {
    Multiselect
  },
  data() {
    return {
      name: "",
      phone: "",
      location: "",
      symptoms: "",
      user_name: "",
      user_phone: "",

      appointment_day: "",
      from: "",
      to: "",
      appointment_type: "",
      date: "",

      relative_option: "",
      view_test_prerequisites: "",
      availability: "",

      documents: [],
      prescriptions: [],
      voice_note: null,

      doctor_name: "",
      service_name: "",
      specialty_name: "",
      price: "",
      selectedStatus: null,
      statusOptions: [
        { name: "Pending", value: "Pending" },
        { name: "Confirmed", value: "Confirmed" },
        { name: "Cancelled", value: "Canceled" },
        { name: "Done", value: "Done" },
      ],
    };
  },
  created() {
    this.fetchBooking();
  },
  methods: {

    async fetchBooking() {
      try {
        const response = await axios.get(`/admin/admin/booking/get?id=${this.$route.params.id}`);
        const bookingData = response.data.data;
        console.log(bookingData)
        this.name = bookingData.name ?? null;
        this.phone = bookingData.phone ?? null;
        this.user_name = bookingData.user.name ?? null;
        this.user_phone = bookingData.user.phone ?? null;
        this.location = bookingData.location ?? null;
        this.symptoms = bookingData.symptoms ?? null;

        this.relative_option = bookingData.relative_option ?? null;
        this.availability = bookingData.availability ?? null;
        this.view_test_prerequisites = bookingData.view_test_prerequisites ?? null;
        // this.user_insurance = bookingData.user_insurance ?? null;
        this.price = bookingData.price ?? null;

        this.appointment_day = bookingData.appointment_day_hour.appointment_day.name ?? null;
        this.from = bookingData.appointment_day_hour.appointment_hour.from ?? null;
        this.to = bookingData.appointment_day_hour.appointment_hour.to ?? null;
        this.date = bookingData.date ?? null;
        this.doctor_name = bookingData.appointment_day_hour.doctor_service_specialty.doctor_name ?? null;
        this.service_name = bookingData.appointment_day_hour.doctor_service_specialty.service_name ?? null;
        this.specialty_name = bookingData.appointment_day_hour.doctor_service_specialty.specialty_name ?? null;

        this.selectedStatus = this.statusOptions.find(
          (option) => option.value === bookingData.status
        );

        this.prescriptions = bookingData.prescriptions ?? null;
        this.documents = bookingData.documents ?? null;
        this.voice_note = bookingData.voice_note ?? null;

      } catch (error) {
        console.error("Error fetching bookingData:", error);
      }
    },
    isImage(file) {
      const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
      const extension = file.split('.').pop().toLowerCase();
      return imageExtensions.includes(extension);
    },
    isPdf(file) {
      const pdfExtension = 'pdf';
      const extension = file.split('.').pop().toLowerCase();
      return extension === pdfExtension;
    },
    getFileName(file) {
      return file.split('/').pop();
    },
    openPdf(url) {
      window.open(url, '_blank');
    },

    async updateStatus() {
      try {
        const formData = new FormData();
        formData.append("id", this.$route.params.id);
        if (this.selectedStatus) {
          formData.append("status", this.selectedStatus.value);
        }
        const response = await axios.post(`/api/admin/booking/status-update`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },

        });
        console.log(formData);
        console.log(response.data);
        this.$router.push({ name: "all-bookings" });
      } catch (error) {
        console.error(error);
      }
    }
  },
};
</script>
<style scoped>
.booking-details {
  padding: 20px;
}

.booking-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.section {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
}

.document {
  margin-bottom: 10px;
}

.document-image {
  max-width: 100%;
  max-height: 100px;
}

h2 {
  margin-bottom: 10px;
}

.documents-container {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}

p {
  font-size: 1.1em
}


/* Button Styling */
.status-btn {
  display: block;
  margin-top: 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 10px 15px;
  transition: background-color 0.3s ease;
}

.status-btn:hover {
  background-color: #0056b3;
}
</style>
