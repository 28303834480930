<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Edit Investigation</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-tests' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="editTest" enctype="multipart/form-data">
              <div class="row">

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">English Name</label>
                    <input type="text" class="form-control" placeholder="Enter English Name" v-model="name_en" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="Name">Arabic Name</label>
                    <input type="text" class="form-control" placeholder="Enter Arabic Name" v-model="name_ar" />
                  </div>
                </div>
                <div class="form-group col-md-12">
                  <label class="text-">English Description</label>
                  <textarea class="form-control" rows="3" placeholder="Enter English Description"
                    v-model="description_en"></textarea>
                </div>
                <div class="form-group col-md-12">
                  <label class="text-">Arabic Description</label>
                  <textarea class="form-control" rows="3" placeholder="Enter Arabic Description"
                    v-model="description_ar"></textarea>
                </div>
                <div class="form-group col-md-12">
                  <label class="text-">Arabic Instruction</label>
                  <textarea class="form-control" rows="3" placeholder="Enter Arabic Instruction"
                    v-model="instruction_ar"></textarea>
                </div>
                <div class="form-group col-md-12">
                  <label class="text-">English Instruction</label>
                  <textarea class="form-control" rows="3" placeholder="Enter English Instruction"
                    v-model="instruction_en"></textarea>
                </div>
                <!-- <div class="form-group col-md-3">
                  <label class="text-">price</label>
                  <input type="number" class="form-control" placeholder="Enter price" v-model="price" />
                </div> -->

              </div>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
export default {
  name: "EditTest",
  data() {
    return {
      name_en: "",
      name_ar: "",
      description_en: "",
      description_ar: "",
      instruction_ar: "",
      instruction_en: "",
      // price: "",
    };
  },

  created() {
    this.fetchTest();
  },
  methods: {
    async fetchTest() {
      try {
        const response = await axios.get(
          "/admin/admin/test/get?id=" + this.$route.params.id
        );
        const TestData = response.data.data;
        this.name_en = TestData.name_en;
        this.name_ar = TestData.name_ar;
        this.description_en = TestData.description_en;
        this.description_ar = TestData.description_ar;
        this.instruction_ar = TestData.instruction_ar;
        this.instruction_en = TestData.instruction_en;
        // this.price = TestData.price;
        console.log(TestData);
      } catch (error) {
        console.error(error);
      }
    },
    async editTest() {
      try {
        const formData = new FormData();
        formData.append("id", this.$route.params.id);
        formData.append("name_en", this.name_en);
        formData.append("name_ar", this.name_ar);
        formData.append('description_en', this.description_en);
        formData.append('description_ar', this.description_ar);
        formData.append('instruction_ar', this.instruction_ar);
        formData.append('instruction_en', this.instruction_en);
        // formData.append('price', this.price);
        const response = await axios.post(
          "admin/admin/test/update",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response.data);
        console.log(formData);

        this.$router.push({ name: "all-tests" });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  width: 100%;
}

form>.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}
</style>
