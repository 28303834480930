<template>
  <div class="main-content" v-if="access_token">
    <div>
      <div class="wrap-content container" id="container">
        <!-- start: DASHBOARD TITLE -->
        <section id="page-title" class="padding-top-15 padding-bottom-15">
          <div class="row">
            <div class="col-sm-7">
              <h1 class="mainTitle">Dashboard</h1>
              <span class="mainDescription" v-if="access_token">Hello, {{ userName }} </span>
              <!-- <h1 v-if="access_token">Hello, {{ userName }}</h1> -->
              <h3 v-if="!access_token">not logged in</h3>
            </div>

          </div>
        </section>
        <!-- end: DASHBOARD TITLE -->
        <!-- start: FEATURED BOX LINKS -->
        <div class="container-fluid container-fullw bg-white">
          <div class="row">
            <div class="col-sm-4">
              <div class=" no-radius text-center">

              </div>
            </div>

            <div class="col-sm-12">
              <div class=" no-radius text-center">
                <div class="panel-body">
                  <div class="thread-image">
                    <img :src="`${appUrl}/assets/images/undraw_medicine_b-1-ol 1.png`" alt="User Avatar"
                      class="img-fluid" />
                  </div>
                  <!-- <span class="fa-stack fa-2x"> <i class="fa fa-square fa-stack-2x text-primary"></i> <i
                      class="fa fa-terminal fa-stack-1x fa-inverse"></i> </span>
                  <h2 class="StepTitle">Manage Database</h2>
                  <p class="text-small">
                    Store, modify, and extract information from your database.
                  </p>
                  <p class="links cl-effect-1">
                    <a href>
                      view more
                    </a>
                  </p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end: FEATURED BOX LINKS -->

      </div>
    </div>
  </div>

</template>
<script>
import { store } from '@/Store';
export default {
  name: "HomeView",
  components: {},
  // props: ["orders"],

  date() {
    return {
      userName: "",
      access_token: null,

      appUrl: "" // or from environment variables
    };
  },
  async beforeCreate() {
    this.access_token = localStorage.getItem("access_token");
    this.userName = store.user ? store.user.name : 'User'; // Fallback to "User" if not found
    // this.appUrl = "https://allodoctor-backend.developnetwork.net" // or from environment variables
    this.appUrl = 'http://localhost:8080' // or from environment variables
  }
};
</script>
