<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Order Details</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-orders' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <div class="order-details">
              <div class="order-info">
                <!-- Customer Information Section -->
                <div class="section">
                  <h2>Customer Information</h2>
                  <br>
                  <p><strong>Name:</strong> {{ name || 'N/A' }}</p>
                  <p><strong>Phone:</strong> {{ phone || 'N/A' }}</p>
                  <p><strong>User Name:</strong> {{ user_name || 'N/A' }}</p>
                  <p><strong>User Phone:</strong> {{ user_phone || 'N/A' }}</p>
                  <p><strong>Created at:</strong> {{ created_at || 'N/A' }}</p>
                </div>

                <!-- Address Section -->
                <div class="section">
                  <h2>Address</h2>
                  <br>
                  <p><strong>Address:</strong> {{ address || 'N/A' }}</p>
                  <p><strong>floor:</strong> {{ floor || 'N/A' }}</p>
                  <p><strong>appartment_number:</strong> {{ appartment_number || 'N/A' }}</p>
                  <p>
                    <GoogleMap :coords="{ lat: lat, lng: long }" @placeChanged="handlePlaceChanged" />
                  </p>
                </div>

                <!-- Notes Section -->
                <div class="section">
                  <h2>Notes</h2>
                  <br>
                  <p> {{ notes ?? 'N/A' }}</p>
                </div>
                <!-- Order Details Section -->
                <div class="section">
                  <h2>Pharmacy Details</h2>
                  <br>
                  <p><strong>Code:</strong> {{ code || 'N/A' }}</p>
                  <p><strong>Pharmacy Name:</strong> {{ pharmacy_name ?? 'N/A' }}</p>
                  <p><strong>Pharmacy English Address:</strong> {{ pharmacy_address_en ?? 'N/A' }}</p>
                  <p><strong>Pharmacy Arabic Address:</strong> {{ pharmacy_address_ar ?? 'N/A' }}</p>
                  <p><strong>Pharmacy Phone:</strong> {{ pharmacy_phone ?? 'N/A' }}</p>
                  <p><strong>Delivery Time:</strong> {{ delivery_time ?? 'N/A' }} Min</p>
                </div>

                <!-- Medication Details Section -->
                <div class="section">
                  <h2>Medication Details</h2>
                  <br>
                  <div v-if="cartItems.length > 0">
                    <div v-for="(item, index) in cartItems" :key="item.id"
                      :class="{ 'bottom-border': index !== cartItems.length - 1 }">
                      <div class="row">
                        <p>{{ index + 1 }}</p>
                        <div class="col-md-6">
                          <p><strong>Medication English Name:</strong> {{ item.medication.name_en ?? 'N/A' }}</p>
                          <p><strong>Medication Arabic Name:</strong> {{ item.medication.name_ar ?? 'N/A' }}</p>
                          <p><strong>Medication Type:</strong> {{ item.medication.type.name ?? 'N/A' }}</p>
                          <p><strong>Price:</strong> <span class="highlight">{{ item.price ?? 'N/A' }}</span></p>
                          <p><strong>Quantity:</strong> <span class="highlight">{{ item.quantity ?? 'N/A' }}</span></p>
                        </div>
                        <div class="col-md-6">
                          <!-- Fix: Bind the main_image correctly -->
                          <img v-if="item.medication.main_image" :src="item.medication.main_image" alt="main_image"
                            style="max-width: 100px;">
                          <p v-else>No image available</p>
                        </div>
                      </div>
                    </div>
                    <div class="total-section">

                      <p><strong>SubTotal:</strong> <span class="highlight">{{ total_before_discount ??
                        'N/A' }} EGP</span></p>
                      <p><strong>Discount:</strong> <span class="highlight">{{
                        Math.round(((1 - (total - delivery_fees) / total_before_discount)) * 100) ??
                        'N/A' }}%</span></p>
                      <p><strong>Delievry Fees:</strong> <span class="highlight">{{ delivery_fees ??
                        'N/A' }} EGP</span></p>
                      <p><strong>Total:</strong> <span class="highlight">{{ total ?? 'N/A' }} EGP</span></p>
                      <p><strong>Payment Type:</strong> {{ payment_type ?? 'N/A' }}</p>


                    </div>

                  </div>
                  <p v-else>No Medication details available.</p>
                </div>
                <!-- Prescriptions Section -->
                <div class="section">
                  <h2>Prescriptions</h2>
                  <br>
                  <div v-if="prescription" class="documents-container">
                    <div class="document">
                      <a :href="prescription" target="_blank" @click.prevent="openPdf(prescription)">
                        <img v-if="isImage(prescription)" :src="prescription" alt="prescription"
                          class="document-image" />
                        <img v-else-if="isPdf(prescription)" src="/assets/images/pdf-icon.png" alt="PDF Document"
                          class="document-image" />
                        <span v-else>{{ getFileName(prescription) }}</span>
                      </a>
                    </div>
                    <div style="width: 100%;">
                      <router-link style="margin-right: 10px; width: 100%;" class="btn btn-success"
                        :to="{ name: 'create-order', params: { id: id } }">Create Order</router-link>
                      <!-- <router-link class="btn btn-success" :to="{ name: 'deleted-bookings' }">Deleted
              Bookings</router-link> -->
                    </div>
                  </div>

                  <div v-else>No prescription available.</div>
                </div>
                <!-- Status Section -->
                <div class="section status-section">
                  <h2>Status</h2>
                  <div class="form-group">
                    <br>
                    <multiselect v-model="selectedStatus" :options="statusOptions" :multiple="false"
                      placeholder="Select status" label="name" track-by="id"></multiselect>
                  </div>
                  <button @click="updateStatus" class="btn btn-primary mt-3 status-btn">
                    Update Status
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import axios from "../../Axios";
import GoogleMap from "../Component/GoogleMap.vue";
export default {
  name: "ShowOrder",
  components: {
    GoogleMap,
    Multiselect
  },
  data() {
    return {
      lat: 30.0444,
      long: 31.2357,
      floor: "",
      appartment_number: "",
      address: "",
      notes: "",

      name: "",
      phone: "",
      created_at: "",
      user_name: "",
      user_phone: "",

      code: "",
      id: "",

      payment_type: "",
      total_before_discount: "",
      delivery_fees: "",
      delivery_time: "",
      pharmacy_name: "",
      pharmacy_address_en: "",
      pharmacy_address_ar: "",
      pharmacy_phone: "",
      medication_en: "",
      medication_ar: "",
      price: "",
      quantity: "",
      total: "",
      type_name: "",
      prescription: "",


      user: [],
      cartItems: [],
      documents: [],
      order_status: "",
      statusOptions: [],
      selectedStatus: null,
      // statusOptions: [
      //   { name: "Pending", value: "Pending" },
      //   { name: "Confirmed", value: "Confirmed" },
      //   { name: "Cancelled", value: "Canceled" },
      //   { name: "Done", value: "Done" },
      // ],
    };
  },
  created() {
    this.fetchOrder();
    this.fetchStatuses();
  },
  methods: {
    handlePlaceChanged(place) {
      this.lat = place.lat; // Update latitude
      this.long = place.lng; // Update longitude
    },
    async fetchOrder() {
      try {
        const response = await axios.get(`/admin/admin/order/get?id=${this.$route.params.id}`);
        console.log(response.data.data);
        const orderData = response.data.data;

        this.id = orderData.id ?? null;
        this.name = orderData.created_by.name ?? null;
        this.phone = orderData.created_by.phone ?? null;
        this.user_name = orderData.user.name ?? null;
        this.user_phone = orderData.user.phone ?? null;
        this.created_at = orderData.created_at ?? null;
        this.created_by = orderData.created_by ?? null;

        this.lat = orderData.address ? orderData.address.lat : null;
        this.long = orderData.address ? orderData.address.long : null;
        this.floor = orderData.address ? orderData.address.floor : null;
        this.appartment_number = orderData.address ? orderData.address.appartment_number : null;
        this.address = orderData.address ? orderData.address.address : null;
        this.notes = orderData.note ?? null;
        this.code = orderData.code ?? null;

        // Convert and round total, total_before_discount, and delivery_fees
        this.total = orderData.total ? Math.round(parseFloat(orderData.total) * 100) / 100 : null;
        this.total_before_discount = orderData.total_before_discount ? Math.round(parseFloat(orderData.total_before_discount) * 100) / 100 : null;
        this.delivery_fees = orderData.pharmacy.delivery_fees ? Math.round(parseFloat(orderData.pharmacy.delivery_fees) * 100) / 100 : null;

        this.delivery_time = orderData.pharmacy.delivery_time ?? null; // Ensure prescription is fetched
        this.prescription = orderData.prescription ?? null; // Ensure prescription is fetched
        this.payment_type = orderData.payment_type ?? null;
        // this.total = orderData.total ?? null;
        // this.total_before_discount = orderData.total_before_discount ?? null;
        // this.delivery_fees = orderData.pharmacy.delivery_fees ?? null;
        this.cartItems = orderData.cartItems ?? null;
        this.pharmacy_name = orderData.pharmacy ? orderData.pharmacy.name : null;
        this.pharmacy_address_en = orderData.pharmacy ? orderData.pharmacy.address_en : null;
        this.pharmacy_address_ar = orderData.pharmacy ? orderData.pharmacy.address_ar : null;
        this.pharmacy_phone = orderData.pharmacy ? orderData.pharmacy.phone : null;
        this.selectedStatus = orderData.order_status ?? null;

      } catch (error) {
        console.error("Error fetching orderData:", error);
      }
    },
    isImage(file) {
      const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
      const extension = file.split('.').pop().toLowerCase();
      return imageExtensions.includes(extension);
    },
    isPdf(file) {
      const pdfExtension = 'pdf';
      const extension = file.split('.').pop().toLowerCase();
      return extension === pdfExtension;
    },
    getFileName(file) {
      return file.split('/').pop();
    },
    openPdf(url) {
      window.open(url, '_blank');
    },

    async fetchStatuses() {
      try {
        const response = await axios.get('/admin/admin/order-status/all');
        this.statusOptions = response.data.data; // Adjust based on your API response
      } catch (error) {
        console.error('Error fetching statuses:', error);
      }
    },

    async updateStatus() {
      try {
        const formData = new FormData();
        formData.append("id", this.$route.params.id);
        if (this.selectedStatus) {
          formData.append("status", this.selectedStatus.id);
        }

        const response = await axios.post(`/admin/admin/order/update`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log(formData);
        console.log(response.data);
        this.$router.push({ name: "all-orders" });
      } catch (error) {
        console.error(error);
      }
    }
  },
};
</script>
<style scoped>
.order-details {
  padding: 20px;
}

.order-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.section {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
}

.document {
  margin-bottom: 10px;
}

.document-image {
  max-width: 100%;
  max-height: 100px;
}

h2 {
  margin-bottom: 10px;
}

.documents-container {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}

p {
  font-size: 1.1em
}

.bottom-border {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.highlight {
  color: #007bff;
  font-weight: bold;
}

.total-section {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 2px solid #007bff;
}

/* Button Styling */
.status-btn {
  display: block;
  margin-top: 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 10px 15px;
  transition: background-color 0.3s ease;
}

.status-btn:hover {
  background-color: #0056b3;
}
</style>