<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">All Orders</h1>
          </div>
          <div class="col-sm-2">
            <!-- <router-link style="margin-right: 10px" class="btn btn-primary"
              :to="{ name: 'create-order' }">create</router-link> -->
            <!-- <router-link class="btn btn-success" :to="{ name: 'deleted-bookings' }">Deleted
              Bookings</router-link> -->
          </div>


        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">

            <div v-if="orders.length > 0">
              <MyTable :columns="columns" :actions="true">
                <tr v-for="(order, index) in orders" :key="index">
                  <th scope="row">{{ order.id }}</th>
                  <td>{{ order.code }}</td>
                  <td>{{ order.user.name ?? 'N/A' }}</td>
                  <td>{{ order.user.phone ?? 'N/A' }}</td>
                  <td>{{ order.payment_type ? order.payment_type.name : 'N/A' }}</td>
                  <td>{{ order.created_at ?? 'N/A' }}</td>
                  <td>{{ order.total ?? 'N/A' }} EGP</td>
                  <td>
                    <span :class="statusClass(order.order_status ? order.order_status.name : 'unknown')">
                      {{ order.order_status ? order.order_status.name : 'Unknown' }}
                    </span>
                  </td>
                  <td>
                    <router-link class="badge  bg-info spacing" :to="{ name: 'show-order', params: { id: order.id } }">
                      Order Details
                    </router-link>
                  </td>
                </tr>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No Orders found.
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
// import { store } from "../../Store";

import axios from "../../Axios";
import $ from 'jquery'; // Import jQuery
import MyTable from "../MyTable//MyTable.vue"; // Import your MyTable component

export default {
  name: "AllPrescriptionOrders",
  components: {
    MyTable,
    // Paginate,
  },
  data() {
    return {
      dataTable: [], // Store DataTable instance
      // statuses: [],
      orders: [],
      meta: {},
      searchTerm: "",
      columns: [
        { label: '#', key: 'id' },
        { label: 'Code', key: 'code' },
        { label: 'Name', key: 'user.name' },
        { label: 'Phone', key: 'user.phone' },
        { label: 'Payment Type', key: 'payment_type.name' },
        { label: 'Created at', key: 'created_at' },
        { label: 'Total', key: 'total' },
        { label: 'Status', key: 'status.name' },
      ],
    };
  },

  async created() {
    this.fetchOrders();
  },

  computed: {
    filteredBookings() {
      if (!this.searchTerm) {
        return this.bookings;
      }
      const term = this.searchTerm.toLowerCase();
      return this.bookings.filter((booking) => {
        return (
          (booking.name && booking.name.toLowerCase().includes(term)) ||
          (booking.phone && booking.phone.toLowerCase().includes(term))
        );
      });
    },
  },
  //   filteredBookings() {
  //     if (!this.searchTerm) {
  //       return this.bookings;
  //     }
  //     const term = this.searchTerm.toLowerCase();
  //     return this.bookings.filter(
  //       (booking) =>
  //         booking.name.toLowerCase().includes(term)
  //     );
  //   },

  methods: {


    async fetchOrders() {
      try {

        // const params = {};
        // if (store.userRole === 'Doctor') {
        //   params.user_id = store.user ? store.user.id : null;
        // }

        // const response = await axios.get('/admin/admin/booking/all', {
        //   params: params,
        // });

        // try {
        //   const response = await axios.get('/admin/admin/doctor/all', {
        //     headers: {
        //       Authorization: `Bearer ${store.user.access_token}`,
        //     },
        //   });

        const response = await axios.get(
          `/admin/admin/order/all?prescription`
        );
        console.log(response);
        this.orders = response.data.data;
        this.prescriptions = response.data.data;
        console.log(" fetching order:", response.data.data);
        this.meta = response.data.meta;
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
      } catch (error) {
        console.error("Error fetching bookings:", error);
      }
    },

    statusClass(status) {
      switch (status) {
        case 'Pending':
          return 'badge  bg-warning text-dark'; // Yellow
        case 'Confirmed':
          return 'badge  bg-primary'; // Blue
        case 'Completed':
          return 'badge  bg-success'; // Green
        case 'Cancelled':
          return 'badge  bg-danger'; // Red
        default:
          return 'badge  bg-secondary'; // Gray for unknown or missing
      }
    },
    initializeDataTable() {
      this.$nextTick(() => {
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },
    deleteBooking(id) {
      console.log("delete bookings id: ", id);
      axios
        .delete(`/admin/admin/booking/delete?id=${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchBookings();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

};
</script>
<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}

.btn {
  padding: 0.5em 1em;
  border-radius: 0.25rem;
  font-size: 0.9em;
  text-align: center;
  display: inline-block;
  min-width: 100px;
}


/* Styling for the Current Status Badge */
.badge {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.9em;
  text-transform: capitalize;
}

/* Different Badge Colors Based on Status */
.badge-pending {
  background-color: #ffc107;
  color: #fff;
}

.badge-approved {
  background-color: #28a745;
  color: #fff;
}

.badge-rejected {
  background-color: #dc3545;
  color: #fff;
}

.badge-inprogress {
  background-color: #17a2b8;
  color: #fff;
}
</style>