<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">All Pre-Operation</h1>
          </div>
          <div class="col-sm-2">

            <router-link class="btn btn-primary" :to="{ name: 'all-preOperations' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">

            <div v-if="preOperations.length > 0">
              <MyTable :columns="columns" :actions="true">
                <tr v-for="(preOperation, index) in filteredOperations" :key="index">
                  <th scope="row">{{ preOperation.id }}</th>
                  <td>{{ preOperation.operation ? preOperation.operation.name_en : 'N/A' }}</td>
                  <td>{{ preOperation.info_service ? preOperation.info_service.info_service.name_en : 'N/A' }}</td>
                  <td>{{ preOperation.pre_surgery_en ?? 'N/A' }}</td>
                  <td>{{ preOperation.pre_surgery_ar ?? 'N/A' }}</td>
                  <!-- <td>{{ operation.service.name ?? '' }}</td> -->
                  <td>
                    <button class="me-2 btn btn-success" @click="restoreOperation(preOperation.id)">
                      Restore
                    </button>
                  </td>

                </tr>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No pre-operations found.
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import $ from 'jquery'; // Import jQuery
import MyTable from "../MyTable//MyTable.vue"; // Import your MyTable component
import axios from "../../Axios";
export default {
  name: "AllPreOperations",
  components: {
    MyTable,
    // Paginate,
  },
  data() {
    return {
      dataTable: [],
      preOperations: [],
      meta: {},
      searchTerm: "",
      columns: [
        { label: '#', key: 'id' },
        { label: 'Operation Name', key: 'operation.name_en' },
        { label: 'Hospital Name', key: 'info_service.name_en' },
        { label: 'Pre surgery_ar', key: 'pre_surgery_ar' },
        { label: 'Pre surgery_en', key: 'pre_surgery_en' },
      ],
    };
  },

  async created() {
    this.fetchOperations();
  },

  computed: {
    filteredOperations() {
      if (!this.searchTerm) {
        return this.preOperations;
      }
      const term = this.searchTerm.toLowerCase();
      return this.preOperations.filter(
        (preOperation) =>
          preOperation.name.toLowerCase().includes(term) ||
          preOperation.postal_code.toLowerCase().includes(term)
      );
    },
  },

  methods: {
    // fetch operations
    async fetchOperations() {
      try {
        const response = await axios.get(
          `/admin/admin/pre-operation/get-delete-soft`
        );

        this.preOperations = response.data.data;
        this.meta = response.data.meta;
        console.log(response);
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
      } catch (error) {
        console.error("Error fetching preoperations:", error);
      }
    },
    initializeDataTable() {
      this.$nextTick(() => {
        // Ensure the DataTable is initialized only after the DOM is updated
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },
    restoreOperation(id) {
      console.log("restore operation id: ", id);
      axios.post(`/admin/admin/pre-operation/restore?id=${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchOperations();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

};
</script>
<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}
</style>