<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">All User Insurances</h1>
          </div>
          <div class="col-sm-2">
            <!-- <router-link style="margin-right: 10px" class="btn btn-primary"
              :to="{ name: 'create-booking' }">create</router-link> -->
            <!-- <router-link class="btn btn-success" :to="{ name: 'deleted-bookings' }">Deleted
              Bookings</router-link> -->
          </div>

        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <!-- <h5 class="over-title margin-bottom-15">
              <span class="text-bold">User Insurance Table</span>
            </h5>
            <p>
            </p> -->
            <div v-if="userInsurances.length > 0">
              <MyTable :columns="columns" :actions="true">
                <tr v-for="(insurance, index) in userInsurances" :key="index">
                  <th scope="row">{{ insurance.id }}</th>
                  <td>{{ insurance.user ? insurance.user.name : 'N/A' }}</td>
                  <td>{{ insurance.user ? insurance.user.phone : 'N/A' }}</td>
                  <td>{{ insurance.id_number }}</td>
                  <td>{{ insurance.name_en }}</td>

                  <td>
                    <span :class="statusClass(insurance.status ? insurance.status : 'unknown')">
                      {{ insurance.status ? insurance.status : 'Unknown' }}
                    </span>
                  </td>
                  <td>
                    <!-- <button class="me-2 btn btn-danger" @click="deleteBooking(booking.id)">
                      Delete
                    </button> -->
                    <router-link class="badge  bg-info spacing"
                      :to="{ name: 'show-userInsurance', params: { id1: insurance.id, id2: insurance.user_id } }">
                      <!-- :to="{ name: 'show-userInsurance', params: { id: insurance.id } }"> -->
                      Insurance Details
                    </router-link>
                  </td>
                </tr>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No User Insurances found.
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import $ from 'jquery';
import MyTable from "../MyTable//MyTable.vue"; // Import your MyTable component

export default {
  name: "AllUserInsurances",
  components: {
    MyTable,
    // Paginate,
  },
  data() {
    return {
      dataTable: [], // Store DataTable instance
      userInsurances: [],
      meta: {},
      searchTerm: "",
      columns: [
        { label: '#', key: 'id' },
        { label: 'Name', key: 'name' },
        { label: 'Phone', key: 'phone' },
        { label: 'Id Number', key: 'id_number' },
        { label: 'Insurance Name', key: 'name_en' },
        { label: 'Status', key: 'status' },
      ],
    };
  },
  computed: {
    filteredBookings() {
      if (!this.searchTerm) {
        return this.userInsurances;
      }
      const term = this.searchTerm.toLowerCase();
      return this.userInsurances.filter(
        (booking) =>
          booking.name.toLowerCase().includes(term)
      );
    },
  },
  async created() {
    this.fetchInsurances();
  },

  methods: {
    async fetchInsurances() {
      try {
        const response = await axios.get(
          `/admin/admin/user-insurance/all?all`
        );
        this.userInsurances = response.data.data;
        await this.fetchUserDetailsForInsurances();
        console.log(" fetching bookings:", response.data.data);
        this.meta = response.data.meta;
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
      } catch (error) {
        console.error("Error fetching bookings:", error);
      }
    },
    async fetchUserDetailsForInsurances() {
      for (let insurance of this.userInsurances) {
        if (insurance.user_id) {
          try {
            const userResponse = await axios.get(`/admin/admin/user/get?id=${insurance.user_id}`);
            insurance.user = userResponse.data.data; // Store user data in the insurance object
          } catch (error) {
            console.error(`Error fetching user data for user_id ${insurance.user_id}:`, error);
          }
        }
      }
    },
    statusClass(status) {
      switch (status) {
        case 'pending':
          return 'badge  bg-warning text-dark'; // Yellow
        case 'confirmed':
          return 'badge  bg-success'; // Blue
        case 'canceled':
          return 'badge  bg-danger'; // Red
        default:
          return 'badge  bg-secondary'; // Gray for unknown or missing
      }
    },

    initializeDataTable() {
      this.$nextTick(() => {
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },
    deleteBooking(id) {
      console.log("delete bookings id: ", id);
      axios
        .delete(`/admin/admin/user-insurance/delete?id=${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchBookings();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

};
</script>

<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}

.btn {
  padding: 0.5em 1em;
  border-radius: 0.25rem;
  font-size: 0.9em;
  text-align: center;
  display: inline-block;
  min-width: 100px;
}

/* Styling for the Current Status Badge */
.badge {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.9em;
  text-transform: capitalize;
}

/* Different Badge Colors Based on Status */
.badge-pending {
  background-color: #ffc107;
  color: #fff;
}

.badge-approved {
  background-color: #28a745;
  color: #fff;
}

.badge-rejected {
  background-color: #dc3545;
  color: #fff;
}

.badge-inprogress {
  background-color: #17a2b8;
  color: #fff;
}
</style>