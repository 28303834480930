<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">All Users</h1>
          </div>
          <div class="col-sm-2">
            <router-link v-if="hasPermission('user create')" style="margin-right: 10px" class="btn btn-primary"
              :to="{ name: 'create-user' }">create</router-link>
            <!-- <router-link v-if="hasPermission('user get-delete-soft')" class="btn btn-success"
              :to="{ name: 'deleted-users' }">Deleted Users</router-link> -->
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">

            <div v-if="users.length > 0">

              <MyTable :columns="columns" :actions="true">
                <tr v-for="(user, index) in users" :key="index">
                  <td>{{ user.id }}</td>
                  <td>{{ user.name }}</td>
                  <td>{{ user.phone }}</td>
                  <!-- <td>{{ user.email }}</td> -->
                  <td>{{ user.role.name ?? 'N/A' }}</td>
                  <td>{{ user.created_at }}</td>
                  <!-- <td>{{ user.active == 1 ? 'Active' : 'Inactive' }}</td> -->
                  <td>
                    <span :class="statusClass(user.active == 1 ? 'Active' : 'Inactive')">
                      {{ user.active == 1 ? 'Active' : 'Inactive' }}
                    </span>
                  </td>
                  <td>
                    <!-- <button class="btn btn-danger" @click="deleteUser(user.id)">Delete</button> -->
                    <router-link class="btn btn-primary spacing"
                      :to="{ name: 'edit-user', params: { id: user.id } }">Edit</router-link>
                  </td>
                </tr>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No users found.
            </div>
          </div>

        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>
<script>
import $ from 'jquery'; // Import jQuery
import MyTable from "../MyTable//MyTable.vue"; // Import your MyTable component
import { store } from "../../Store";
import axios from "../../Axios";
export default {
  name: "AllServices",
  components: {
    MyTable,
    // Paginate,
  },
  data() {
    return {
      dataTable: [],
      users: [],
      meta: {},
      columns: [
        { label: '#', key: 'id' },
        { label: 'Name', key: 'name' },
        { label: 'Phone', key: 'phone' },
        // { label: 'Email', key: 'email' },
        { label: 'Role', key: 'role' },

        { label: 'Date Created', key: 'date' },
        { label: 'Status', key: 'active' },
      ],
      // searchTerm: "",
    };
  },
  // mounted() {
  //   this.initializeDataTable(); // Initialize DataTable after mounting
  // },
  // beforeUnmount() {
  //   if (this.dataTable) {
  //     this.dataTable.destroy(); // Clean up DataTable on unmount
  //   }
  // },
  async created() {
    await this.fetchUsers();
  },

  computed: {
    hasPermission() {
      return (permission) => store.hasPermission(permission);
    },
  },
  // computed: {
  //   filteredUsers() {
  //     if (!this.searchTerm) {
  //       return this.users;
  //     }
  //     const term = this.searchTerm.toLowerCase();
  //     return this.users.filter((user) =>
  //       user.name.toLowerCase().includes(term)
  //     );
  //   },
  // },


  methods: {
    async fetchUsers() {
      try {
        const response = await axios.get(
          `/admin/admin/user/all`);
        this.users = response.data.data;
        this.meta = response.data.meta;
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
        console.log(this.users);

      } catch (error) {
        console.error("Error fetching users:", error);
      }
    },
    initializeDataTable() {
      this.$nextTick(() => {
        // Ensure the DataTable is initialized only after the DOM is updated
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },
    statusClass(active) {
      switch (active) {
        case 'Inactive':
          return 'badge  bg-warning text-dark'; // Yellow
        // case 'Confirmed':
        //   return 'badge  bg-primary'; // Blue
        case 'Active':
          return 'badge  bg-success'; // Green
        case 'Canceled':
          return 'badge  bg-danger'; // Red
        default:
          return 'badge  bg-secondary'; // Gray for unknown or missing
      }
    },
    // deleteUser(id) {
    //   console.log("delete user id: ", id);
    //   axios
    //     .delete(`/admin/admin/user/delete?id=${id}`)
    //     .then((response) => {
    //       console.log(response.data);
    //       this.fetchUsers();
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // },
  },
};
</script>

<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}

/* Styling for the Current Status Badge */
.badge {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.9em;
  text-transform: capitalize;
}

/* Different Badge Colors Based on Status */
.badge-pending {
  background-color: #ffc107;
  color: #fff;
}

.badge-approved {
  background-color: #28a745;
  color: #fff;
}

.badge-rejected {
  background-color: #dc3545;
  color: #fff;
}

.badge-inprogress {
  background-color: #17a2b8;
  color: #fff;
}
</style>
