<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Create Order</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'show-order', params: { id: id } }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <!-- Form to add cart items -->
            <form class="text-start mx-auto" @submit.prevent="addCartItem">
              <div class="row">
                <!-- Pharmacy is pre-filled from the fetched order -->
                <div class="form-group">
                  <label>Pharmacy</label>
                  <input type="text" class="form-control" :value="pharmacy_name" disabled />
                </div>
                <div class="form-group">
                  <label class="text-">Select Category</label>
                  <multiselect v-model="category_id" :options="categories" :searchable="true" label="name" track-by="id"
                    placeholder="Select Category" :multiple="false"></multiselect>
                </div>
                <div class="form-group">
                  <label class="text-">Select Medication</label>
                  <multiselect v-model="medication_id" :options="medications" :searchable="true" label="name"
                    track-by="id" placeholder="Select Medication" :multiple="false"></multiselect>
                </div>
                <div class="form-group col-md-3">
                  <label class="text-">Quantity</label>
                  <input type="number" class="form-control" placeholder="Enter Quantity" v-model="quantity" />
                </div>
              </div>
              <button type="submit" class="btn btn-primary">Add to Cart</button>
            </form>

            <!-- Display cart items -->
            <!-- <div v-if="cartItems.length > 0">
              <h3>Cart Items</h3>
              <ul>
                <li v-for="(item, index) in cartItems" :key="index">
                  {{ item.medication_id.name }} - {{ item.quantity }}
                </li>
              </ul>
            </div> -->
            <!-- Cart Items Table -->
            <div v-if="cartItems.length > 0" class="cart-items">
              <h3 class="mb-3">Cart Items</h3>
              <table class="table">
                <thead>
                  <tr>
                    <th>Medication</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Total</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in cartItems" :key="index">
                    <td>{{ item.medication_id.name }}</td>
                    <td>
                      <input type="number" v-model="item.quantity" min="1" @change="updateCartItem(index)" />
                    </td>
                    <td>{{ item.medication_id.price_after_discount ? item.medication_id.price_after_discount
                      : item.medication_id.price }} EGP</td>
                    <td>{{ (item.medication_id.price_after_discount ? item.medication_id.price_after_discount
                      : item.medication_id.price * item.quantity).toFixed(2) }} EGP</td>
                    <td>
                      <button @click="removeCartItem(index)" class="btn btn-danger btn-sm">
                        <i class="fas fa-trash"></i> Remove
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="total-price">
                <strong>Total Price:</strong> {{ calculateTotalPrice().toFixed(2) }} EGP
              </div>
            </div>

            <!-- Additional order information -->
            <form class="text-start mx-auto mt-5" @submit.prevent="createOrder">
              <div class="row">
                <div class="form-group col-md-6">
                  <label>Payment Type</label>
                  <select class="form-control" v-model="payment_type">
                    <!-- <option value="credit_card">Credit Card</option> -->
                    <option value="cash">Cash</option>
                  </select>
                </div>
                <div class="form-group col-md-6">
                  <label>Notes</label>
                  <textarea rows="3" class="form-control" placeholder="Enter Notes" v-model="notes"></textarea>
                </div>
              </div>
              <button type="submit" class="btn btn-success">Create Order</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import Multiselect from "vue-multiselect";

export default {
  name: "CreateOrder",
  components: {
    Multiselect,
  },
  data() {
    return {
      // Pre-filled from the fetched order
      pharmacy_id: "",
      pharmacy_name: "",

      categories: [],
      category_id: "",

      medications: [],
      medication_id: "",
      price: "",
      price_after_discount: "",
      discount_percentage: "",

      quantity: 1,
      cartItems: [],

      // Additional order information
      payment_type: "cash", // Default value
      address_id: "",
      order_status_id: 8,
      notes: "",
    };
  },
  created() {
    this.fetchOrder();
    this.fetchCategories();
  },
  watch: {
    category_id(newVal) {
      this.fetchMedications(this.pharmacy_id, newVal.id);
    },
  },
  methods: {
    async fetchCategories() {
      try {
        const response = await axios.get("/admin/admin/category/all");
        this.categories = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchMedications(pharmacyId, categoryId) {
      if (!pharmacyId || !categoryId) {
        this.medications = [];
        return;
      }
      try {
        const response = await axios.get(
          `/admin/admin/medication-pharmacy/all?pharmacyId=${pharmacyId}&categoryId=${categoryId}`
        );
        this.medications = response.data.data.map(item => ({
          ...item.medication,
          price: parseFloat(item.price).toFixed(2), // Ensure price is a number
          price_after_discount: parseFloat(item.price_after_discount).toFixed(2), // Ensure price is a number
          // discount_percentage: parseFloat(item.discount_percentage).toFixed(2), // Ensure price is a number
        }));
        console.log("Medication-Pharmacy API Response:", response.data.data);
        // this.medications = response.data.data.map(item => item.medication);
      } catch (error) {
        console.error("Error fetching medications:", error);
      }
    },
    async fetchOrder() {
      try {
        const response = await axios.get(`/admin/admin/order/get?id=${this.$route.params.id}`);
        const orderData = response.data.data;
        console.log(orderData);
        // Pre-fill the form with fetched data
        this.pharmacy_id = orderData.pharmacy.id;
        this.pharmacy_name = orderData.pharmacy.name;
        this.id = orderData.id ?? null;
        this.fetchMedications(this.pharmacy_id, this.category_id);
        this.name = orderData.created_by.name ?? null;
        this.phone = orderData.created_by.phone ?? null;
        this.user_name = orderData.user.name ?? null;
        this.user_phone = orderData.user.phone ?? null;

        this.address_id = orderData.address ? orderData.address.id : null;
        this.notes = orderData.notes ?? 'N/A';
        this.code = orderData.code ?? 'N/A';

        // this.payment_type = orderData.payment_type ?? null;
        this.order_status = orderData.order_status.name ?? null;

        // Fetch medications based on the fetched pharmacy and category
        // this.fetchMedications(this.pharmacy_id, this.category_id);
      } catch (error) {
        console.error("Error fetching orderData:", error);
      }
    },
    async addCartItem() {
      if (!this.category_id || !this.medication_id || !this.quantity) {
        alert("Please fill all fields");
        return;
      }
      const cartItem = {
        pharmacy_id: this.pharmacy_id,
        category_id: this.category_id,
        // medication_id: this.medication_id,

        medication_id: {
          ...this.medication_id, // Include all medication details
          price: parseFloat(this.medication_id.price), // Ensure price is a number
          price_after_discount: parseFloat(this.medication_id.price_after_discount), // Ensure price is a number
          // discount_percentage: parseFloat(this.medication_id.discount_percentage), // Ensure price is a number
        },
        quantity: parseInt(this.quantity),
      };
      console.log("Cart Item Added:", cartItem); // Debugging
      this.cartItems.push(cartItem);
      this.category_id = "";
      this.medication_id = "";
      this.quantity = 1;
    },

    removeCartItem(index) {
      this.cartItems.splice(index, 1);
    },
    updateCartItem(index) {
      if (this.cartItems[index].quantity < 1) {
        this.cartItems[index].quantity = 1;
      }
    },
    // calculateTotalPrice() {
    //   return this.cartItems.reduce((total, item) => {
    //     return parseFloat(total) + parseFloat(item.medication_id.price) * parseFloat(item.quantity);
    //   }, 0);
    // },
    calculateTotalPrice() {
      return this.cartItems.reduce((total, item) => {
        const itemPrice = parseFloat(item.medication_id.price_after_discount ? item.medication_id.price_after_discount : item.medication_id.price); // Ensure price is a number
        const itemQuantity = parseInt(item.quantity); // Ensure quantity is a number
        if (isNaN(itemPrice) || isNaN(itemQuantity)) {
          console.error("Invalid price or quantity in cart item:", item);
          return total;
        }
        return total + itemPrice * itemQuantity;
      }, 0);
    },
    async createOrder() {
      try {
        for (const item of this.cartItems) {
          const formData = new FormData();
          formData.append("pharmacy_id", item.pharmacy_id);
          formData.append("category_id", item.category_id.id);
          formData.append("medication_id", item.medication_id.id);
          formData.append("quantity", item.quantity);
          await axios.post("api/admin/cart/create", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        }

        const orderFormData = new FormData();
        // Append cart items
        this.cartItems.forEach((item, index) => {
          orderFormData.append(`cartItems[${index}][pharmacy_id]`, item.pharmacy_id);
          orderFormData.append(`cartItems[${index}][category_id]`, item.category_id.id);
          orderFormData.append(`cartItems[${index}][medication_id]`, item.medication_id.id);
          orderFormData.append(`cartItems[${index}][quantity]`, item.quantity);
        });

        // Append additional order information
        orderFormData.append("payment_type", this.payment_type);
        orderFormData.append("address_id", this.address_id);
        orderFormData.append("address_user_id", this.address_id);
        orderFormData.append("pharmacy_id", this.pharmacy_id);
        // orderFormData.append("order_status_id", this.order_status_id);
        orderFormData.append("order_status_id", 8);
        orderFormData.append("notes", this.notes);

        // Send the orderFormData to the API
        const orderResponse = await axios.post("admin/admin/order/create", orderFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        console.log(orderResponse);
        console.log(orderResponse.data);
        this.$router.push({ name: "all-orders" });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>


<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  width: 100%;
  /* max-width: 500px; Optional: Max width for better responsiveness */
}

form>.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}

.cart-form,
.order-form {
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin-bottom: 2rem;
}

.cart-items {
  margin-top: 2rem;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 0.75rem;
  border: 1px solid #ddd;
  text-align: left;
}

/* .table th {
  background-color: #285e97;
  color: white;
} */

.total-price {
  margin-top: 1rem;
  font-size: 1.2rem;
  /* text-align: right; */
}

.btn {
  margin-right: 0.5rem;
}

.fas {
  margin-right: 0.5rem;
}
</style>