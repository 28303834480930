<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Edit Pharmacy Medication</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-pharmacyMedications' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="editMedicationPharmacy" enctype="multipart/form-data">
              <div class="row">
                <div class="form-group">
                  <label>Select Pharmacy</label>
                  <multiselect v-model="pharmacy_id" :options="pharmacies" :multiple="false" :searchable="true"
                    label="name" track-by="id" placeholder="Select Pharmacy"></multiselect>
                  <span class="error text-small block" v-if="errors.pharmacy_id">{{
                    errors.pharmacy_id }}</span>
                </div>
                <div class="form-group ">
                  <label class="text-">Select Category</label>
                  <multiselect v-model="category_id" :options="categories" :searchable="true" label="name" track-by="id"
                    placeholder="Select Category" :multiple="false"></multiselect>
                  <span class="error text-small block" v-if="errors.category_id">{{
                    errors.category_id }}</span>
                </div>
                <div class="form-group">
                  <label class="text-">Select Medication</label>
                  <multiselect v-model="medication_id" :options="medications" :searchable="true" label="name"
                    track-by="id" placeholder="Select Medication" :multiple="false"></multiselect>
                  <span class="error text-small block" v-if="errors.medication_id">{{
                    errors.medication_id }}</span>
                </div>
                <div class="form-group col-md-3">
                  <label class="text-">Price</label>
                  <input type="number" class="form-control" placeholder="Enter price" v-model="price" />
                  <span class="error text-small block" v-if="errors.price">{{
                    errors.price }}</span>
                </div>
                <div class="form-group col-md-3">
                  <label class="text-">Discount Percentage</label>
                  <input type="number" class="form-control" placeholder="Enter price" v-model="discount_percentage" />
                  <span class="error text-small block" v-if="errors.discount_percentage">{{
                    errors.discount_percentage }}</span>
                </div>
                <!-- <div class="form-group col-md-3">
                  <label class="text-">Price After Discount</label>
                  <input type="number" class="form-control" placeholder="Enter Price After Discount"
                    v-model="price_after_discount" />
                </div> -->
                <!-- <div class="form-group col-md-3">
                  <label class="text-">Price After Discount</label>
                  <input type="number" class="form-control" :value="price_after_discount" disabled />
                </div> -->
                <div class="form-group col-md-3">
                  <label class="text-">Price After Discount</label>
                  <input type="number" class="form-control" :value="calculatePriceAfterDiscount()" disabled />
                </div>
                <div class="form-group col-md-3">
                  <label class="text-">Quantity</label>
                  <input type="number" class="form-control" placeholder="Enter Quantity" v-model="quantity" />
                </div>

                <div class="form-group col-md-3">
                  <div class="checkbox clip-check check-primary">
                    <input type="checkbox" v-model="availability" id="service1">
                    <label for="service1">
                      Availability
                    </label>
                  </div>
                </div>

              </div>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import Multiselect from "vue-multiselect";


export default {
  name: "EditPharmacyMedications",
  components: {
    Multiselect,
  },
  data() {
    return {
      pharmacy_id: "",
      medication_id: "",
      category_id: "",
      price: "",
      discount_percentage: "",
      // price_after_discount: "",
      availability: false,
      quantity: "",
      pharmacies: [],
      medications: [],
      categories: [],
      errors: {},
    };
  },

  created() {
    this.fetchMedicationPharmacy();
    this.fetchPharmacies();
    this.fetchMedications();
    this.fetchCategories();

  },

  methods: {
    calculatePriceAfterDiscount() {
      if (!this.price && !this.discount_percentage) return 0;
      return this.price - (this.price * this.discount_percentage) / 100;
    },
    async fetchPharmacies() {
      try {
        const response = await axios.get("/admin/admin/pharmacy/all");
        console.log("pharmacy", response.data.data);
        this.pharmacies = response.data.data;
        this.meta = response.data.meta;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchMedications() {
      try {
        const response = await axios.get("/admin/admin/medication/all");
        console.log("medication", response.data.data);
        this.medications = response.data.data;
        this.meta = response.data.meta;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchCategories() {
      try {
        const response = await axios.get("/admin/admin/category/all");
        console.log("category", response.data.data);
        this.categories = response.data.data;
        this.meta = response.data.meta;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchMedicationPharmacy() {
      try {
        const response = await axios.get(
          "/admin/admin/medication-pharmacy/get?id=" + this.$route.params.id
        );
        const medicationData = response.data.data;
        console.log(medicationData)
        this.category_id = medicationData.category;
        this.pharmacy_id = medicationData.pharmacy;
        this.medication_id = medicationData.medication;
        this.price = medicationData.price;
        this.quantity = medicationData.quantity;
        this.discount_percentage = medicationData.discount_percentage;
        this.availability = medicationData.availability == 1;

        console.log('fetched', medicationData);
        console.log("data", this.data);
        // this.main_imagePreviews = medicationData.main_image ? [medicationData.main_image.url] : [];

      } catch (error) {
        console.error(error);
      }
    },
    async editMedicationPharmacy() {
      try {
        const formData = new FormData();
        formData.append("id", this.$route.params.id);
        formData.append('pharmacy_id', this.pharmacy_id.id);
        formData.append('category_id', this.category_id.id);
        formData.append("medication_id", this.medication_id.id);
        formData.append("price", Number(this.price));
        formData.append("discount_percentage", Number(this.discount_percentage));
        // formData.append(
        //   "price_after_discount",
        //   Number(this.price_after_discount)
        // );

        formData.append("quantity", Number(this.quantity));
        formData.append("availability", this.availability ? 1 : 0);
        console.log(formData);
        const response = await axios.post(
          "/admin/admin/medication-pharmacy/update",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response.data);
        console.log(formData);
        this.$router.push({ name: "all-pharmacyMedications" });
      } catch (error) {
        // console.error(error);
        if (error.response && error.response.data.errors) {
          // Map backend errors to the errors object
          this.errors = error.response.data.errors;
        }
      }
    },
  },
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  width: 100%;
}

.error {
  color: #a94442;
  font-size: 0.875em;
}

form>.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}
</style>
