<!-- <template>
  <div class="app-content">
     <div class="main-content" style="padding: 0 !important; margin: 30px !important;"> 

<div :class="{ 'main-content': !isLoginPage }" style="padding: 0 !important; margin: 30px !important;">
      <NavBar v-if="store.isLoggedIn" />
      <SideBar v-if="store.isLoggedIn" />
      <router-view />
      <ErrorMessage v-if="store.errorMessage" :errorMessage="store.errorMessage" />
    </div>
</div>
</template> -->
<!-- <template>
  <NavBar v-if="store.isLoggedIn" />
  <SideBar v-if="store.isLoggedIn" />
  <div>
    <div :class="{ 'main-content': !isLoginPage }" style="padding: 0 !important; margin: 30px !important;">
      <router-view />
      <ErrorMessage v-if="store.errorMessage" :errorMessage="store.errorMessage" />
    </div>
  </div>
</template> -->
<!-- <template>
  <SideBar v-if="store.isLoggedIn" />
  <div class="app-content">
    <NavBar v-if="store.isLoggedIn" />
    <div :class="{ 'main-content': !isLoginPage }" style="padding: 0 !important; margin: 30px !important;">
      <router-view /> -->
<!-- <ErrorMessage v-if="store.errorMessage" :errorMessage="store.errorMessage" /> -->
<!-- <ErrorMessage v-if="store.errorMessage" />
    </div>
  </div>
</template> -->
<template>
  <SideBar v-if="store.isLoggedIn" />
  <div class="app-content">
    <NavBar v-if="store.isLoggedIn" />
    <div :class="{ 'main-content': !isLoginPage }" style="padding: 0 !important; margin: 30px !important;">
      <router-view />
      <ErrorMessage v-if="store.errorMessage" />
    </div>
  </div>
  <!-- <OffSideBar v-if="store.isLoggedIn" /> -->
</template>
<script>
import { store } from "./Store"; // Adjust the path as necessary
import NavBar from "./components/Navigation/NavBar.vue";
import SideBar from "./components/Navigation/SideBar.vue";
// import OffSideBar from "./components/Navigation/OffSideBar.vue";

import ErrorMessage from "./components/Errors/ErrorMessage.vue";

import { useRoute } from "vue-router";
import { onMounted, ref, watch } from "vue";

export default {
  name: "App",
  components: {
    NavBar,
    SideBar,
    // OffSideBar,
    ErrorMessage
  },

  setup() {
    const route = useRoute();
    const isLoginPage = ref(route.meta.noNav || false);

    // Watch the route for changes
    watch(route, (newRoute) => {
      isLoginPage.value = newRoute.meta.noNav || false;
    });
    // Watch for error messages
    watch(
      () => store.errorMessage,
      (newMessage) => {
        if (newMessage) {
          console.log("Error message detected:", newMessage); // Debugging
          setTimeout(() => {
            console.log("Clearing error message..."); // Debugging
            store.clearErrorMessage();  // Clear the error message after 10 seconds
          }, 10000); // 10 seconds
        }
      }
    );
    // watch(
    //   () => store.errorMessage,
    //   (newMessage) => {
    //     if (newMessage) {
    //       setTimeout(() => {
    //         store.clearErrorMessage();  // Clear the error message after a few seconds
    //       }); // Adjust the timeout as needed
    //     }
    //   }
    // );
    onMounted(() => {
      if (localStorage.getItem("access_token")) {
        store.setLoggedIn(true);
      }
    });

    return { store, isLoginPage };
  },
  created() {
    if (localStorage.getItem("access_token")) {
      store.setLoggedIn(true);
    }
  },
};
</script>
<!-- <template>
  <div class="app">
     <div class="main-content" style="padding: 0 !important; margin: 30px !important;"> 

<div :class="{ 'main-content': !isLoginPage }" style="padding: 0 !important; margin: 30px !important;">
      <NavBar v-if="store.isLoggedIn" />
      <SideBar v-if="store.isLoggedIn" />
      <router-view />
      <ErrorMessage v-if="store.errorMessage" :errorMessage="store.errorMessage" />
    </div>
</div>
</template> -->