<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Deleted Payment Types</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-paymentTypes' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">

            <div v-if="paymentTypes.length > 0">
              <MyTable :columns="columns" :actions="true">
                <tr v-for="(paymentType, index) in filteredPaymentTypes" :key="index">
                  <th scope="row">{{ paymentType.id }}</th>
                  <td>{{ paymentType.name_ar }}</td>
                  <td>{{ paymentType.name_en }}</td>
                  <td>
                    <button class="me-2 btn btn-success" @click="restorePaymentType(paymentType.id)">
                      Restore
                    </button>
                  </td>
                </tr>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No deleted intensive care found.
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import $ from 'jquery'; // Import jQuery
import MyTable from "../MyTable//MyTable.vue";
import axios from "../../Axios";
export default {
  name: "DeletedPaymentTypes",
  components: {
    MyTable,
    // Paginate,
  },
  data() {
    return {
      dataTable: [],
      paymentTypes: [],
      meta: {},
      searchTerm: "",
      columns: [
        { label: '#', key: 'id' },
        { label: 'Name Arabic', key: 'name_ar' },
        { label: 'Name English', key: 'name_en' },
      ],
    };
  },

  async created() {
    this.fetchPaymentTypes();
  },
  computed: {
    filteredPaymentTypes() {
      if (!this.searchTerm) {
        return this.paymentTypes;
      }
      const term = this.searchTerm.toLowerCase();
      return this.paymentTypes.filter(
        (paymentType) =>
          paymentType.name.toLowerCase().includes(term) ||
          paymentType.postal_code.toLowerCase().includes(term)
      );
    },
  },

  methods: {
    // fetch orders
    async fetchPaymentTypes() {
      try {
        const response = await axios.get(
          `/admin/admin/payment-type/get-delete-soft`
        );
        this.paymentTypes = response.data.data;
        this.meta = response.data.meta;
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
      } catch (error) {
        console.error("Error fetching paymentTypes:", error);
      }
    },
    initializeDataTable() {
      this.$nextTick(() => {
        // Ensure the DataTable is initialized only after the DOM is updated
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },
    restorePaymentType(id) {
      console.log("restore payment-type id: ", id);
      axios
        .post(`/admin/admin/payment-type/restore?id=${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchPaymentTypes();
        })
        .catch((error) => {
          console.error(error);
        });
    },

  },
};
</script>

<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}
</style>