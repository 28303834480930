<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <!-- <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">All Category</h1>
          </div>
        </div>
      </section> -->
      <!-- end: PAGE TITLE -->
      <div class="container-fluid container-fullw bg-white">
        <!-- <div class="row"> -->
        <!-- <div class="col-md-12"> -->
        <div class="d-flex" style="height: 500px;">

          <!-- Chat List (Left Sidebar) -->
          <div class="bg-white border-end " style="width: 300px; height: 500px;">
            <div class="p-3 border-bottom">
              <input v-model="searchQuery" type="text" class="form-control" placeholder="Search chats" />
            </div>
            <div class="overflow-auto h-100">
              <div v-for="chat in sortedAndFilteredChats" :key="chat.id" @click="selectChat(chat)"
                class="p-3 border-bottom chat-item position-relative"
                :class="{ 'active': selectedChat?.id === chat.id }">
                <div class="d-flex justify-content-between align-items-start">
                  <div class="flex-grow-1">
                    <div class="d-flex align-items-center">
                      <h6 class="mb-1" :class="{ 'fw-bold': chat.unreadCount > 0 }">
                        User #{{ chat.sender_id }} and chat_id #{{ chat.id }}
                      </h6>
                      <!-- Unread messages badge -->
                      <div v-if="chat.unreadCount > 0" class="unread-badge ms-2">
                        {{ chat.unreadCount }}
                      </div>
                    </div>
                    <p class="text-muted small mb-0" :class="{ 'fw-bold': chat.unreadCount > 0 }">
                      {{ chat.lastMessage?.message || 'No messages' }}
                    </p>
                  </div>
                  <small class="text-muted">
                    {{ formatTimestamp(chat.lastMessage?.timestamp) }}
                  </small>
                </div>
              </div>
            </div>
          </div>

          <!-- Chat Window -->
          <div class="flex-grow-1 d-flex flex-column " style="height: 500px;">
            <!-- Chat Header -->
            <div v-if="selectedChat" class="p-3 border-bottom bg-white">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="mb-0">Chat with User #{{ selectedChat.sender_id }}</h5>
                <span class="text-muted small">
                  {{ messages.length }} messages
                </span>
              </div>
            </div>


            <!-- Messages Area -->
            <!-- <div v-if="selectedChat" ref="messagesContainer" class="flex-grow-1 p-3 overflow-auto bg-light">
                <div v-for="message in sortedMessages" :key="message.id" class="mb-3"> -->
            <div v-if="selectedChat" ref="messagesContainer" class="flex-grow-1 p-3 overflow-auto h-100">
              <template v-for="(group, date) in groupedMessages" :key="date">
                <!-- Date separator -->
                <div class="text-center my-3">
                  <span class="date-divider">{{ formatDateDivider(date) }}</span>
                </div>

                <!-- Messages for this date -->
                <div v-for="message in group" :key="message.id" class="mb-3">
                  <div
                    :class="['d-flex', message.sender_id === userId ? 'justify-content-end' : 'justify-content-start']">
                    <div :class="['message-bubble', message.sender_id === userId ? 'sent' : 'received']">
                      <div class="message-box">{{ message.message }}</div>
                      <div class="message-timestamp small">
                        {{ formatMessageTime(message.timestamp) }}
                        <!-- <span v-if="message.seen" class="ms-1">✓</span> -->
                      </div>
                    </div>
                  </div>
                </div>


              </template>
            </div>

            <!-- Message Input -->
            <div v-if="selectedChat" class="p-3 bg-white border-top flex-grow-1 p-3">
              <div class="chat-input">
                <input v-model="newMessage" @keyup.enter="sendMessage" placeholder="Type your message..." />
                <button @click="sendMessage" class="btn btn-primary" :disabled="!newMessage.trim()">
                  Send
                </button>
              </div>
            </div>

            <!-- No Chat Selected Placeholder -->
            <div v-else class="flex-grow-1 d-flex align-items-center justify-content-center bg-light">
              <h5 class="text-muted">Select a chat to start messaging</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
</template>

<script>
import database from "../../firebase";
import { ref, onValue, update, get } from "firebase/database";
import axios from "axios";
import { store } from "../../Store";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import calendar from 'dayjs/plugin/calendar';

dayjs.extend(calendar);
dayjs.extend(relativeTime);

export default {
  data() {
    return {
      userId: store.user?.id,
      receiver_id: "",
      searchQuery: "",
      chats: [],
      selectedChat: null,
      messages: [],
      newMessage: "",

    };
  },

  computed: {
    sortedAndFilteredChats() {
      // First filter the chats
      let filteredChats = this.chats;
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        filteredChats = filteredChats.filter(chat =>
          chat.sender_id.toString().includes(query) ||
          chat.lastMessage?.message.toLowerCase().includes(query)
        );
      }

      // Then sort them by last message timestamp and unread count
      return filteredChats.sort((a, b) => {
        // If one chat has unread messages and the other doesn't, prioritize the unread one
        // if (a.unreadCount > 0 && b.unreadCount === 0) return -1;
        // if (b.unreadCount > 0 && a.unreadCount === 0) return 1;

        // If both have or don't have unread messages, sort by timestamp
        const timeA = a.lastMessage?.timestamp ? dayjs(a.lastMessage.timestamp).valueOf() : 0;
        const timeB = b.lastMessage?.timestamp ? dayjs(b.lastMessage.timestamp).valueOf() : 0;
        return timeB - timeA;
      });
    },
    sortedMessages() {
      return [...this.messages].sort((a, b) =>
        dayjs(b.timestamp).valueOf() - dayjs(a.timestamp).valueOf() // Reverse order
      );
    },
    groupedMessages() {
      const groups = {};

      this.messages.forEach(message => {
        const date = dayjs(message.timestamp).format('YYYY-MM-DD');
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(message);
      });

      // Sort dates in descending order
      const sortedGroups = {};
      Object.keys(groups)
        .sort((a, b) => dayjs(a).valueOf() - dayjs(b).valueOf()) // Ascending order
        .forEach(date => {
          // Sort messages within each group by timestamp (oldest first)
          sortedGroups[date] = groups[date].sort(
            (a, b) => dayjs(a.timestamp).valueOf() - dayjs(b.timestamp).valueOf()
          );
        });
      return sortedGroups;
    }

  },

  created() {
    this.listenForChats();
    // this.fetchChats();
  },

  methods: {

    listenForRealTimeUpdates(chatId) {
      const messagesRef = ref(database, `chats/${chatId}/messages`);
      onValue(messagesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const newMessages = Object.entries(data).map(([id, message]) => ({
            id,
            ...message
          }));

          // Merge new messages with existing messages
          this.messages = [...this.messages, ...newMessages.filter(newMsg =>
            !this.messages.some(existingMsg => existingMsg.id === newMsg.id)
          )];

          this.$nextTick(() => {
            this.scrollToBottom();
          });
        }
      });
    },

    listenForChats() {
      const chatsRef = ref(database, "chats");
      onValue(chatsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const chatArray = Object.entries(data).map(([id, chatData]) => {
            const messages = chatData.messages ? Object.entries(chatData.messages) : [];
            const lastMessage = messages.length > 0
              ? { id: messages[messages.length - 1][0], ...messages[messages.length - 1][1] }
              : null;

            const unreadCount = messages.reduce((count, messageEntry) => {
              const msg = messageEntry[1]; // Access the message object directly
              const isUnread = msg.sender_id !== this.userId && !msg.seen;
              return count + (isUnread ? 1 : 0);
            }, 0);

            return {
              id,
              sender_id: lastMessage?.sender_id,
              lastMessage,
              unreadCount,
              messages: messages.map(([msgId, msg]) => ({ id: msgId, ...msg }))
            };
          });

          this.chats = chatArray.filter(chat => {
            const hasMessages = chat.messages && chat.messages.length > 0;
            if (!hasMessages) return false;

            return chat.messages.some(msg =>
              msg.sender_id === this.userId || msg.receiver_id === this.userId
            );
          });

          if (this.selectedChat) {
            const updatedChat = this.chats.find(c => c.id === this.selectedChat.id);
            if (updatedChat) {
              this.selectedChat = updatedChat;
            }
          }
        }
      });
    },
    // async fetchChats() {
    //   try {
    //     const response = await axios.get('/api/admin/chat/get-all-chats'); // Replace with your API endpoint
    //     const chatsData = response.data.chats;

    //     this.chats = chatsData.map(chat => {
    //       const messages = chat.messages ? Object.entries(chat.messages).map(([id, message]) => ({
    //         id,
    //         ...message
    //       })) : [];

    //       const lastMessage = messages.length > 0 ? messages[messages.length - 1] : null;
    //       const firstMessage = messages.length > 0 ? messages[0] : null;

    //       const unreadCount = messages.reduce((count, message) => {
    //         const isUnread = message.sender_id !== this.userId && !message.seen;
    //         return count + (isUnread ? 1 : 0);
    //       }, 0);

    //       return {
    //         id: chat.chat_id,
    //         sender_id: firstMessage?.sender_id || lastMessage?.sender_id,
    //         lastMessage,
    //         unreadCount,
    //         messages
    //       };
    //     });

    //     if (this.selectedChat) {
    //       const updatedChat = this.chats.find(c => c.id === this.selectedChat.id);
    //       if (updatedChat) {
    //         this.selectedChat = updatedChat;
    //       }
    //     }
    //   } catch (error) {
    //     console.error('Error fetching chats:', error);
    //   }
    // },

    // selectChat(chat) {
    //   this.selectedChat = chat;
    //   this.messages = [];
    //   this.listenForMessages(chat.id);
    //   this.markChatAsSeen(chat.id);
    // },

    // async selectChat(chat) {
    selectChat(chat) {
      this.selectedChat = chat;
      this.messages = [];
      // Listen for real-time updates from Firebase
      this.listenForRealTimeUpdates(chat.id);

      // Mark messages as seen
      this.markChatAsSeen(chat.id);
      // Update the unread count for the selected chat
      const updatedChatIndex = this.chats.findIndex(c => c.id === chat.id);
      if (updatedChatIndex !== -1) {
        this.chats[updatedChatIndex].unreadCount = 0; // Reset unread count
      }
    },

    async markChatAsSeen(chatId) {
      const messagesRef = ref(database, `chats/${chatId}/messages`);
      const snapshot = await get(messagesRef);
      const messages = snapshot.val();

      if (messages) {
        const updates = {};
        Object.entries(messages).forEach(([messageId, message]) => {
          if (message.sender_id !== this.userId && !message.seen) {
            updates[`chats/${chatId}/messages/${messageId}/seen`] = true;
          }
        });

        if (Object.keys(updates).length > 0) {
          await update(ref(database), updates);
        }
      }
    },

    listenForMessages(chatId) {
      const messagesRef = ref(database, `chats/${chatId}/messages`);
      onValue(messagesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          this.messages = Object.entries(data).map(([id, message]) => ({
            id,
            ...message
          }));
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        }
      });
    },

    async sendMessage() {
      if (!this.newMessage.trim() || !this.selectedChat) return;

      try {
        const formData = new FormData();
        formData.append("message", this.newMessage);
        formData.append("chat_id", this.selectedChat.id);
        formData.append("receiver_id", this.selectedChat.sender_id); // Add receiver_id
        const response = await axios.post("/admin/admin/chat/send-message", formData);

        if (response.data.status === "success") {
          this.newMessage = "";
        }
      } catch (error) {
        console.error("Error sending message:", error);
      }
    },
    formatDateDivider(date) {
      const messageDate = dayjs(date);
      const today = dayjs();
      const yesterday = dayjs().subtract(1, 'day');

      if (messageDate.isSame(today, 'day')) {
        return 'Today';
      } else if (messageDate.isSame(yesterday, 'day')) {
        return 'Yesterday';
      } else if (messageDate.isSame(today, 'year')) {
        return messageDate.format('MMMM D'); // e.g., "March 25"
      } else {
        return messageDate.format('MMMM D, YYYY'); // e.g., "March 25, 2023"
      }
    },

    formatMessageTime(timestamp) {
      return dayjs(timestamp).format('hh:mm A'); // This will format time as "04:00 PM"
    },

    formatTimestamp(timestamp) {
      if (!timestamp) return '';
      //   return dayjs(timestamp).fromNow();
      const date = dayjs(timestamp);
      const today = dayjs();
      const yesterday = dayjs().subtract(1, 'day');

      if (date.isSame(today, 'day')) {
        return date.format('hh:mm A');
      } else if (date.isSame(yesterday, 'day')) {
        return 'Yesterday';
      } else if (date.isSame(today, 'year')) {
        return date.format('MMM D');
      } else {
        return date.format('MM/DD/YY');
      }
    },

    scrollToBottom() {
      if (this.$refs.messagesContainer) {
        this.$refs.messagesContainer.scrollTop = this.$refs.messagesContainer.scrollHeight;
      }
    }

  },

  watch: {
    messages() {
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    }
  }
};
</script>

<style scoped>
.chat-item {
  cursor: pointer;
  transition: background-color 0.2s;
}

.chat-item:hover {
  background-color: #f8f9fa;
}

.chat-item.active {
  background-color: #e9ecef;
}

.chat-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-right: 10px;
}

.chat-input button {
  padding: 10px 15px;
  background-color: #1E7DC0;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.date-divider {
  background-color: #e9ecef;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 0.875rem;
  color: #6c757d;
}

.message-timestamp {
  font-size: 0.7rem;
  opacity: 0.7;
  margin-top: 2px;
  text-align: right;
}

.chat-input button:hover {
  background-color: #1E7DC0;
}

.unread-badge {
  background-color: #1E7DC0;
  color: white;
  border-radius: 50%;
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  padding: 0 6px;
}

.message-bubble {
  max-width: 70%;
  padding: 3px 11px;
  border-radius: 8px;
  margin-bottom: 2px;
}

.message-box {
  padding: 8px 10px;
}

.message-bubble.sent {
  background-color: #1E7DC0;
  color: rgb(255, 255, 255);
}

.message-bubble.received {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}

.message-timestamp {
  font-size: 0.75rem;
  opacity: 0.7;
  margin-top: 4px;
}

.message {
  word-break: break-word;
}
</style>