import { reactive } from "vue";
// import axios from "axios";

export const store = reactive({
  isLoggedIn: !!localStorage.getItem("access_token"),
  userRole: localStorage.getItem("user_role") || null,
  userPermissions: JSON.parse(localStorage.getItem("user_permissions")) || [],
  errorMessage: null, // Add error message property
  user: JSON.parse(localStorage.getItem("user")) || null, // Add user object

  setLoggedIn(status) {
    this.isLoggedIn = status;
  },
  setUserRole(role) {
    console.log("Setting user role:", role);
    this.userRole = role;
    localStorage.setItem("userRole", role); // Persist role in local storage

  },
  setUserPermissions(permissions) {
    console.log("Setting user permissions:", permissions);
    this.userPermissions = permissions;
    localStorage.setItem("userPermissions", JSON.stringify(permissions)); // Persist permissions in local storage
  },
  setUser(user) {
    console.log("Setting user data:", user);
    this.user = user;
    localStorage.setItem("user", JSON.stringify(user));
  },
  hasPermission(routeName) {
    return this.userPermissions.includes(routeName);
  },
  setErrorMessage(message) { // Add method to set error messages
    console.log("Setting error message:", message); // Debugging
    this.errorMessage = message;
  },
  clearErrorMessage() { // Method to clear the error message after a timeout or fade-out
    console.log("Clearing error message..."); // Debugging
    this.errorMessage = null;
  },
  // logout() {
  //   this.isLoggedIn = false;
  //   this.userRole = null;
  //   this.userPermissions = [];
  //   this.user = null;
  //   localStorage.removeItem("access_token");
  //   localStorage.removeItem("userRole");
  //   localStorage.removeItem("userPermissions");
  //   localStorage.removeItem("user");
  // },
});