<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Edit Appointment Hour</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-appointmentHours' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="editAppointmentHour">
              <div class="row">

                <div class="form-group col-4">
                  <label>from</label>
                  <input type="time" class="form-control" placeholder="Enter Time" v-model="from" />
                </div>
                <div class="form-group col-4">
                  <label>to</label>
                  <input type="time" class="form-control" placeholder="Enter Time" v-model="to" />
                </div>


              </div>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";

export default {
  name: "EditAppointmentHour",
  data() {
    return {
      from: "",
      to: "",
    };
  },

  created() {
    this.fetchAppointmentHour();
  },
  methods: {
    async fetchAppointmentHour() {
      try {
        const response = await axios.get(
          "/admin/admin/appointment-hour/get?id=" + this.$route.params.id
        );
        // const response = await axios.get(`admin/admin/speciality/get/${this.$route.params.id}`);
        console.log(response.data.data);
        this.from = response.data.data.from_display;
        this.to = response.data.data.to_display;

      } catch (error) {
        console.error(error);
      }
    },
    async editAppointmentHour() {
      try {
        const formData = new FormData();
        formData.append("id", this.$route.params.id);
        formData.append("from", this.from);
        formData.append("to", this.to);

        const response = await axios.post(
          "admin/admin/appointment-hour/update",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response.data);
        console.log(formData);

        this.$router.push({ name: "all-appointmentHours" });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  width: 100%;
}

form>.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}
</style>
