<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">All SubServices</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'create-subService' }">create</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <div v-if="subServices.length > 0">
              <MyTable :columns="columns" :actions="true">
                <tr v-for="(subService, index) in filteredSubServices" :key="index">
                  <th scope="row">{{ subService.id }}</th>
                  <td>{{ subService.name_ar }}</td>
                  <td>{{ subService.name_en }}</td>
                  <td>{{ subService.service.name }}</td>
                  <td>
                    <img :src="`${subService.image}`" width="50" height="50" class="img-thumbnail" />
                  </td>
                  <!-- <td><img :src="`${assets}subService.image`"></td> -->

                  <td>
                    <button class="me-2 btn btn-danger" @click="deleteSubService(subService.id)">
                      Delete
                    </button>
                    <router-link class="btn btn-primary spacing"
                      :to="{ name: 'edit-subService', params: { id: subService.id } }">
                      Edit
                    </router-link>
                  </td>
                </tr>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No Sub-Services found.
            </div>

          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import $ from 'jquery'; // Import jQuery
import MyTable from "../MyTable//MyTable.vue"; // Import your MyTable component
import axios from "../../Axios";
export default {
  name: "AllSubServices",
  components: {
    MyTable,
    // Paginate,
  },
  data() {
    return {
      dataTable: [],
      subServices: [],
      meta: {},
      searchTerm: "",
      columns: [
        { label: '#', key: 'id' },
        { label: 'Name Arabic', key: 'name_ar' },
        { label: 'Name English', key: 'name_en' },
        { label: 'Main Service', key: 'service' },
        { label: 'Image', key: 'image' },
      ],
    };
  },
  async created() {
    this.fetchSubServices();
  },

  computed: {

    filteredSubServices() {

      if (!this.searchTerm) {
        return this.subServices;
      }
      const term = this.searchTerm.toLowerCase();
      return this.subServices.filter(
        (subService) =>
          subService.name.toLowerCase().includes(term) ||
          subService.postal_code.toLowerCase().includes(term)
      );
    },
  },

  methods: {
    // fetch subServices
    async fetchSubServices() {
      try {
        const response = await axios.get(
          `/admin/admin/sub-service/all`
        );
        this.subServices = response.data.data;
        this.meta = response.data.meta;
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
      } catch (error) {
        console.error("Error fetching subServices:", error);
      }
    },
    initializeDataTable() {
      this.$nextTick(() => {
        // Ensure the DataTable is initialized only after the DOM is updated
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },
    deleteSubService(id) {
      console.log("delete subService id: ", id);
      axios
        .delete(`/admin/admin/sub-service/delete?id=${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchSubServices();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

};
</script>
<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}
</style>