<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Edit OutPatient Clinic</h1>

            <!-- <span class="mainDescription"
              >Use this page to start from scratch and put your custom
              content</span
            > -->
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-externalClinics' }">Back</router-link>
          </div>
          <!-- <ol class="breadcrumb">
            <li class="active">
              <span>Blank Page</span>
            </li>
          </ol> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="editExternalClinic" enctype="multipart/form-data">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">English Name</label>
                    <input type="text" class="form-control" placeholder="Enter English Name" v-model="name_en" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="specialityName">Arabic Name</label>
                    <input type="text" class="form-control" placeholder="Enter Arabic Name" v-model="name_ar" />
                  </div>
                </div>

              </div>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>


<script>
import axios from "../../Axios";
export default {
  name: "EditExternalClinic",
  data() {
    return {
      name_en: "",
      name_ar: "",

      service_id: "",

    };
  },
  async created() {
    this.fetchExternalClinic();
  },
  methods: {
    async fetchExternalClinic() {
      try {
        const response = await axios.get(
          "/admin/admin/external-clinic/get?id=" + this.$route.params.id
        );

        const ExternalClinic = response.data.data;
        console.log(ExternalClinic);
        this.name_en = ExternalClinic.name_en;
        this.name_ar = ExternalClinic.name_ar;

        console.log(this.services);
        console.log('fetched', ExternalClinic);
        console.log("data", this.data);
        // Populate `data` with initial variant data for each service
      } catch (error) {
        console.error("Error fetching doctor:", error);
      }
    },
    async editExternalClinic() {
      try {
        const formData = new FormData();
        formData.append("id", this.$route.params.id);
        formData.append('name_en', this.name_en);
        formData.append('name_ar', this.name_ar);
        formData.append("service_id", this.service_id);

        // const user_id = store.user ? store.user.id : null;

        // formData.append(`info_services[${index}][user_id]`, user_id);

        console.log(formData);
        const response = await axios.post("admin/admin/external-clinic/update", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log(response.data);
        this.$router.push({ name: "all-externalClinics" });
      } catch (error) {
        console.error(error);
      }
    },

  },
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  width: 100%;
  /* max-width: 500px; Optional: Max width for better responsiveness */
}

form>.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}
</style>
