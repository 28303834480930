<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">All Category</h1>
          </div>
          <div class="col-sm-2">
            <router-link v-if="hasPermission('category create')" style="margin-right: 10px" class="btn btn-primary"
              :to="{ name: 'create-category' }">create</router-link>
            <router-link v-if="hasPermission('category getSoftDeleted')" class="btn btn-success"
              :to="{ name: 'deleted-categories' }">Deleted
              Category</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">

            <div v-if="categories.length > 0">
              <MyTable :columns="columns" :actions="true">
                <tr v-for="(category, index) in filteredCategories" :key="index">
                  <th scope="row">{{ category.id }}</th>
                  <td>{{ category.name_ar }}</td>
                  <td>{{ category.name_en }}</td>
                  <td>
                    <img :src="`${category.main_image}`" width="50" height="50" class="img-thumbnail" />
                  </td>

                  <!-- <td><img :src="`${assets}service.image`"></td> -->
                  <td>
                    <button class="me-2 btn btn-danger" @click="deleteCategory(category.id)">
                      Delete
                    </button>
                    <router-link class="btn btn-primary spacing"
                      :to="{ name: 'edit-category', params: { id: category.id } }">
                      Edit
                    </router-link>
                  </td>
                </tr>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No categories found.
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import { store } from "../../Store";
import $ from 'jquery'; // Import jQuery
import MyTable from "../MyTable//MyTable.vue"; // Import your MyTable component
export default {
  name: "AllCategories",
  components: {
    MyTable,
    // Paginate,
  },
  data() {
    return {
      dataTable: [], // Store DataTable instance
      categories: [],
      meta: {},
      searchTerm: "",
      columns: [
        { label: '#', key: 'id' },
        { label: 'Name Arabic', key: 'name_ar' },
        { label: 'Name English', key: 'name_en' },
        { label: 'Image', key: 'main_image' },
      ],
    };
  },

  async created() {
    this.fetchCategories();
  },

  computed: {
    hasPermission() {
      return (permission) => store.hasPermission(permission);
    },
    filteredCategories() {
      if (!this.searchTerm) {
        return this.categories;
      }
      const term = this.searchTerm.toLowerCase();
      return this.categories.filter(
        (category) =>
          category.name.toLowerCase().includes(term) ||
          category.postal_code.toLowerCase().includes(term)
      );
    },
  },

  methods: {
    // fetch orders
    async fetchCategories() {
      try {
        const response = await axios.get(
          `/admin/admin/category/all`
        );
        this.categories = response.data.data;
        this.meta = response.data.meta;
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
        console.log('response', response.data)
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },
    initializeDataTable() {
      this.$nextTick(() => {
        // Ensure the DataTable is initialized only after the DOM is updated
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },
    deleteCategory(id) {
      console.log("delete category id: ", id);
      axios
        .delete(`/admin/admin/category/delete?id=${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchCategories();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

};
</script>
<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}
</style>
