import $ from 'jquery';
import 'datatables.net';

export default {
  install(app) {
    app.config.globalProperties.$dataTable = (selector) => {
      return $(selector).DataTable();
    };
  }
};
