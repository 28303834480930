<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Edit Role</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-roles' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="editRole" enctype="multipart/form-data">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Role Name</label>
                    <input type="text" class="form-control" placeholder="Enter Role Name" v-model="name" />
                  </div>
                </div>

                <!-- Multi-select for Permissions -->
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Select Permissions</label>
                    <multiselect v-model="selectedPermissions" :options="permissions" :multiple="true"
                      :searchable="true" label="name" track-by="id" :close-on-select="false" :clear-on-select="false"
                      :append-to-body="true" placeholder="Select permissions">
                    </multiselect>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>

      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import Multiselect from "vue-multiselect";

export default {
  name: "EditRole",
  components: {
    Multiselect,
  },
  data() {
    return {
      name: "",
      permissions: [],
      selectedPermissions: []   // Selected permissions
    };
  },
  created() {
    this.fetchRole();
    this.fetchPermissions();
  },
  methods: {

    async fetchPermissions() {
      try {
        const token = localStorage.getItem("access_token");
        const response = await axios.get("/admin/admin/permission/all", {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        console.log('permissions', response.data.data);
        this.permissions = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },

    async fetchRole() {
      try {
        const token = localStorage.getItem("access_token");
        console.log('fetching the permissions')
        const response = await axios.get(
          "/admin/admin/role/get?id=" + this.$route.params.id
          , {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
        console.log('role', response.data.data);

        this.name = response.data.data.name;
        // this.permissions = response.data.data.permissions.id;
        // this.permission_id = response.data.data.permissions.id;
        this.selectedPermissions = response.data.data.permissions;

        console.log(response.data.data);
      } catch (error) {
        console.error(error);
      }
    },
    async editRole() {
      try {
        const formData = new FormData();
        formData.append("id", this.$route.params.id);
        formData.append("name", this.name);
        this.selectedPermissions.forEach((permission, index) => {
          formData.append(`permissions[${index}]`, permission.id);
        });
        const token = localStorage.getItem("access_token");
        const response = await axios.post(
          "admin/admin/role/update",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              'Authorization': `Bearer ${token}`,
            },
          }
        );

        console.log(formData);
        console.log(response.data);
        this.$router.push({ name: "all-roles" });
      } catch (error) {
        console.error(error);
      }
    },
  },
};

</script>
<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}
</style>
