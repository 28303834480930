<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Edit Day Care</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-dayServices' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="editDayService" enctype="multipart/form-data">
              <div class="row">

                <!-- <div class="col-md-6">
                  <div class="form-group">
                    <label>Select Service</label>
                    <multiselect v-model="service_id" :options="services" :multiple="false" :searchable="true"
                      label="name" track-by="id" placeholder="Select services"></multiselect>
                  </div>
                </div> -->
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">English Name</label>
                    <input type="text" class="form-control" placeholder="Enter English Name" v-model="name_en" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="Name">Arabic Name</label>
                    <input type="text" class="form-control" placeholder="Enter Arabic Name" v-model="name_ar" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="labName">English Description </label>
                    <textarea rows="3" class="form-control" placeholder="Enter English Description"
                      v-model="description_en"></textarea>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="labName">Arabic Description </label>
                    <textarea rows="3" class="form-control" placeholder="Enter Arabic Description"
                      v-model="description_ar"></textarea>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
// import Multiselect from "vue-multiselect";
export default {
  name: "CreateDayService",
  components: {
    // Multiselect,
  },
  data() {
    return {
      service_id: "",
      name_en: "",
      name_ar: "",
      description_en: "",
      description_ar: "",
      services: [],
    };
  },

  async created() {
    this.fetchServices();
    this.fetchDayServices();
  },
  methods: {
    async fetchServices() {
      try {

        const response = await axios.get(`/admin/admin/service/all`);
        this.services = response.data.data;
        this.meta = response.data.meta;
      } catch (error) {
        console.error("Error fetching service :", error);
      }
    },
    async fetchDayServices() {
      try {
        const response = await axios.get(
          "/api/admin/day-service/get?id=" + this.$route.params.id
        );
        // this.service_id = response.data.data.service_id.id;
        this.name_en = response.data.data.name_en;
        this.name_ar = response.data.data.name_ar;
        this.description_en = response.data.data.description_en;
        this.description_ar = response.data.data.description_ar;

      } catch (error) {
        console.error(error);
      }
    },
    async editDayService() {
      try {
        const formData = new FormData();
        formData.append("id", this.$route.params.id);
        // formData.append('service_id', this.service_id.id);
        formData.append('service_id', this.service_id);
        formData.append('name_en', this.name_en);
        formData.append('name_ar', this.name_ar);
        formData.append('description_en', this.description_en);
        formData.append('description_ar', this.description_ar);
        const response = await axios.post(
          "api/admin/day-service/update",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response.data);
        console.log(formData);

        this.$router.push({ name: "all-dayServices" });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  width: 100%;
}

form>.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}
</style>
