import axios from "axios";
import router from './router';
import { store } from "./Store";

// axios.defaults.baseURL = "https://coma-backend.developnetwork.net";
axios.defaults.baseURL = "https://allodoctor-backend.developnetwork.net";

// axios.defaults.baseURL = "http://192.168.98.131:8080";
// axios.defaults.baseURL = "http://192.168.1.172:8000";
// axios.defaults.baseURL = "http://127.0.0.1:8000";


const token = localStorage.getItem("access_token");
console.log("access_token: ", token);

if (token) {
  console.log("Setting Authorization header with token:", token);
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

export const setAuthorizationHeader = (token) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};
// axios.interceptors.response.use(
//   response => response,
//   error => {
//     if (error.response && error.response.status === 500 && error.response.data.exception === 'Tymon\\JWTAuth\\Exceptions\\TokenExpiredException') {
//       // Clear local storage or cookies holding the token
//       localStorage.removeItem('token');  // Adjust to your storage method
//       // Redirect to login page
//       window.location.href = '/login';
//     }
//     return Promise.reject(error);
//   }
// );
// // Add a response interceptor
// axios.interceptors.response.use(
//   response => response,
//   error => {
//     if (error.response && error.response.status === 401) {
//       console.error("Token expired or invalid");
//       localStorage.removeItem("access_token");
//       localStorage.removeItem("userRole");
//       localStorage.removeItem("userPermissions");
//       localStorage.removeItem("tokenExpiration");
//       router.push({ name: "login" });
//     } else if (error.response && error.response.status === 500) {
//       console.error("Server error:", error.response.data.message);
//       localStorage.removeItem('access_token');
//     }
//     return Promise.reject(error);
//   }
// );

// Add a response interceptor
// axios.interceptors.response.use(
//   response => response,
//   error => {
//     if (error.response && error.response.status === 401) {
//       console.error("Token expired or invalid");
//       localStorage.removeItem("access_token");
//       localStorage.removeItem("userRole");
//       localStorage.removeItem("userPermissions");
//       localStorage.removeItem("tokenExpiration");
//       router.push({ name: "login" });
//     }
//     return Promise.reject(error);
//   }
// );

// Add a response interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : null;

    if (status === 403) {
      // Redirect to 403 page
      store.setErrorMessage("You do not have permission to access this resource.");
      router.push({ name: 'forbidden' });
    } else if (status === 404) {
      // Redirect to 404 page
      router.push({ name: 'not-found' });
    }
    //  else if (status === 500) {
    //   // Redirect to 500 page
    //   router.push({ name: 'internal-server-error' });
    // }

    return Promise.reject(error);
  }
);

export default axios;


