<template>
  <div v-if="store.errorMessage" class="error-message">
    {{ store.errorMessage }}
    <button @click="clearErrorMessage">Close</button>
  </div>
</template>

<script>
import { store } from "../../Store";

export default {
  name: "ErrorMessage",
  computed: {
    store() {
      console.log("Accessing store.errorMessage:", store.errorMessage); // Debugging
      return store;
    }
  },
  methods: {
    clearErrorMessage() {
      store.clearErrorMessage();
    }
  }
};
</script>

<style scoped>
.error-message {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  /* Ensure it's visible */
  top: 20px;
  right: 20px;
  z-index: 9999;
  /* Ensure it's on top of other elements */
}

button {
  background-color: #721c24;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}
</style>