<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Edit Doctor Appointment</h1>

            <!-- <span class="mainDescription"
              >Use this page to start from scratch and put your custom
              content</span
            > -->
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-appointmentDoctors' }">Back</router-link>
          </div>
          <!-- <ol class="breadcrumb">
            <li class="active">
              <span>Blank Page</span>
            </li>
          </ol> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="editAppointmentDoctor" enctype="multipart/form-data">
              <div class="row">
                <div class="form-group col-md-12 mb-2">
                  <label class="text-">Day</label>
                  <multiselect v-model="appointment_day" :options="appointmentDays" :searchable="true" label="name"
                    track-by="id" placeholder="Select a Day" :multiple="false"></multiselect>
                </div>

                <div class="form-group col-md-12 mb-2">
                  <label class="text-">Hour</label>
                  <multiselect v-model="appointment_hour" :options="multiselectOptions" :searchable="true" label="label"
                    track-by="value" placeholder="Select a hour" :multiple="false"></multiselect>
                </div>

                <!-- <div class="form-group">
                  <label class="text-">Day</label>
                  <select class="form-control" v-model="selectedAppointmentDay">
                    <option value="">Select Day</option>
                    <option v-for="day in appointmentDays" :key="day.id" :value="day">
                      {{ day.name }}
                    </option>
                  </select>
                </div> -->

                <!-- <div class="form-group">
                  <label class="text-">Hour</label>
                  <select class="form-control" v-model="selectedAppointmentHour">
                    <option value="">Select Hour</option>
                    <option v-for="hour in appointmentHours" :key="hour.id" :value="hour">
                      {{ hour.from }} - {{ hour.to }}
                    </option>
                  </select>
                </div> -->
              </div>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import axios from "../../Axios";
export default {
  name: "CreateAppointmentDoctor",
  components: {
    Multiselect,
  },
  data() {
    return {
      doctor_service_specialty_id: "",
      appointmentDays: [],
      appointment_day: null,
      appointmentHours: [],
      appointment_hour: null,
      multiselectOptions: [],
    };
  },
  async created() {


    await this.fetchAppointmentDays();
    await this.fetchAppointmentHours();
    await this.fetchAppointmentDoctor();
  },
  methods: {
    async fetchAppointmentDays() {
      try {
        const response = await axios.get("/admin/admin/appointment-day/all");
        this.appointmentDays = response.data.data;
        console.log("Appointment Days:", this.appointmentDays);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchAppointmentHours() {
      try {
        const response = await axios.get("/admin/admin/appointment-hour/all");
        this.appointmentHours = response.data.data;

        // Transform appointment hours for multiselect
        this.multiselectOptions = this.appointmentHours.map(hour => ({
          label: `${hour.from} - ${hour.to}`,
          value: hour.id,
        }));

        console.log("Appointment Hours:", this.appointmentHours);
        console.log("Multiselect Options:", this.multiselectOptions);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchAppointmentDoctor() {
      try {
        const response = await axios.get(`/admin/admin/appointment-doctor/get?id=${this.$route.params.id}`);
        const appointmentData = response.data.data;

        // if (Array.isArray(appointmentData) && appointmentData.length > 0) {
        const firstAppointment = appointmentData[0];
        this.doctor_service_specialty_id = firstAppointment.doctor_service_specialty.id;
        this.appointment_day = this.appointmentDays.find(
          day => day.id === firstAppointment.appointment_day.id
        );
        this.appointment_hour = this.multiselectOptions.find(
          hour => hour.value === firstAppointment.appointment_hour.id
        );
        // } else {
        //   console.error("No appointment data found.");
        // }
      } catch (error) {
        console.error("Error fetching appointment data:", error);
      }
    },
    async editAppointmentDoctor() {
      try {
        const formData = new FormData();

        formData.append("id", this.$route.params.id);
        formData.append("doctor_service_specialty_id", this.doctor_service_specialty_id);
        formData.append("appointment_hour_id", this.appointment_hour.value);
        formData.append("appointment_day_id", this.appointment_day.id);

        const response = await axios.post("admin/admin/appointment-doctor/update", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        console.log("Form Data:", formData);
        console.log("API Response:", response.data);
        this.$router.push({ name: "all-appointmentDoctors" });
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  },
};
</script>
<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  width: 100%;
  /* max-width: 500px; Optional: Max width for better responsiveness */
}

form>.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}
</style>
