<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">All Doctors</h1>
          </div>
          <div class="col-sm-2">
            <router-link v-if="hasPermission('doctor create')" style="margin-right: 10px" class="btn btn-primary"
              :to="{ name: 'create-doctor' }">
              Create
            </router-link>

            <router-link v-if="hasPermission('doctor getSoftDeleted')" class="btn btn-success"
              :to="{ name: 'deleted-doctors' }">
              Deleted Doctors
            </router-link>
            <!-- <router-link style="margin-right: 10px" class="btn btn-primary"
              :to="{ name: 'create-doctor' }">create</router-link>
            <router-link class="btn btn-success" :to="{ name: 'deleted-doctors' }">Deleted
              Doctors</router-link> -->
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">

            <div v-if="doctors.length > 0">
              <MyTable :columns="columns" :actions="true">
                <tr v-for="(doctor, index) in doctors" :key="index">
                  <th scope="row">{{ doctor.id }}</th>
                  <td>{{ doctor.name_en }}</td>
                  <td>{{ doctor.name_ar }}</td>
                  <td>{{ doctor.title_ar }}</td>
                  <td>{{ doctor.title_en }}</td>
                  <td>
                    <img :src="`${doctor.main_image}`" width="50" height="50" class="img-thumbnail" />
                  </td>
                  <td>
                    <router-link class="btn btn-info spacing" :to="{
                      name: 'create-appointmentDoctor',
                      params: { doctor_id: doctor.id }
                    }">
                      Create Appointments
                    </router-link>
                  </td>
                  <!-- <td>
                    <router-link class="btn btn-success spacing" :to="{
                      name: 'create-appointmentDoctor',
                    // this.$emit(" doctorCreated", doctorServiceSpecialtyIds); params: { doctor_service_specialty_id:
                      doctor.doctor_service_specialty_ids }">
                      Create Appointments
                    </router-link>
                  </td> -->
                  <!-- <router-link class="btn btn-primary spacing"
                    :to="{ name: 'show-doctorAppointments', params: { doctor_service_specialty_id: doctor.doctor_service_specialty_ids[0] } }">
                    Show Bookings
                  </router-link> -->
                  <!-- </td> -->
                  <td>
                    <button class="me-2 btn btn-danger" @click="deleteDoctor(doctor.id)">
                      Delete
                    </button>
                    <router-link class="btn btn-primary spacing"
                      :to="{ name: 'edit-doctor', params: { id: doctor.id } }">
                      Edit
                    </router-link>
                  </td>
                </tr>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No doctors found.
            </div>
          </div>

        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import { store } from "../../Store";
import $ from 'jquery'; // Import jQuery
import MyTable from "../MyTable//MyTable.vue"; // Import your MyTable component

export default {
  name: "AllDoctors",

  components: {
    MyTable,
    // Paginate,
  },

  data() {
    return {
      dataTable: [], // Store DataTable instance
      doctors: [],
      meta: {},
      searchTerm: "",
      columns: [
        { label: '#', key: 'id' },
        { label: 'Name Arabic', key: 'name_ar' },
        { label: 'Name English', key: 'name_en' },
        { label: 'title Arabic', key: 'title_ar' },
        { label: 'title English', key: 'title_en' },
        { label: 'Image', key: 'main_image' },
        { label: 'Create Appointments', key: 'doctor_service_specialty_id' },
      ],
    };
  },

  async created() {
    await this.fetchDoctors();
  },
  computed: {

    hasPermission() {
      return (permission) => store.hasPermission(permission);
    },

    filteredDoctors() {
      if (!this.searchTerm) {
        return this.doctors;
      }
      const term = this.searchTerm.toLowerCase();
      return this.doctors.filter(
        (doctor) =>
          doctor.name.toLowerCase().includes(term) ||
          doctor.postal_code.toLowerCase().includes(term)
      );
    },
  },

  methods: {
    // fetch doctors
    async fetchDoctors() {
      try {
        const params = {};
        if (store.userRole === 'Doctor') {
          params.user_id = store.user ? store.user.id : null;
        }

        const response = await axios.get('/admin/admin/doctor/all?web=1', {
          params: params,
        });
        this.meta = response.data.meta;
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
      } catch (error) {
        console.error("Error fetching doctors:", error);
      }
    },
    initializeDataTable() {
      this.$nextTick(() => {
        // Ensure the DataTable is initialized only after the DOM is updated
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },
    deleteDoctor(id) {
      console.log("delete doctor id: ", id);
      axios
        .delete(`/admin/admin/doctor/delete?id=${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchDoctors();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}
</style>
