import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyC6uzKXcJy7sLRbYOM_2gFGzWeTIS6bZfA",
    authDomain: "allodoctor-d4515.firebaseapp.com",
    databaseURL: "https://allodoctor-d4515-default-rtdb.firebaseio.com",
    projectId: "allodoctor-d4515",
    storageBucket: "allodoctor-d4515.firebasestorage.app",
    messagingSenderId: "936525366271",
    appId: "1:936525366271:web:75c48f3f85b101c2f41dca",
    measurementId: "G-4LYXQVRKF6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Get a reference to the Realtime Database
const database = getDatabase(app);

export default database;
