import $ from 'jquery';

export default {
  install(app) {
    // Set jQuery to a global property
    app.config.globalProperties.$jquery = $; // Use $jquery instead of $
    
    // Optional: Use mixin to call jqueryInit if defined in components
    app.mixin({
      mounted() {
        if (this.$options.jqueryInit) {
          this.$options.jqueryInit.call(this);
        }
      }
    });
  }
};
