<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Edit Operation</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-operations' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="editOperation">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">English Name</label>
                    <input type="text" class="form-control" placeholder="Enter English Name" v-model="name_en" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="specialityName">Arabic Name</label>
                    <input type="text" class="form-control" placeholder="Enter Arabic Name" v-model="name_ar" />
                  </div>
                </div>
                <!-- <div class="form-group">
                  <button class="btn btn-success add-variant mb-3" @click="addVariant" type="button">
                    Add Variant
                  </button>
                  <div class="row">
                    <div class="col-md-6" v-for="(variant, index) in variants" :key="index">
                      <div class="card mb-3">
                        <div class="card-body">
                          <h5 class="card-title">Variant {{ index + 1 }}</h5>
                          <div class="form-group col-md-12 mb-2">
                            <label class="text-">Infomation Service</label>
                            <multiselect v-model="variant.info_service_id" :options="infoServices" :searchable="true"
                              label="name" track-by="id" placeholder="Select a Infomation Service" :multiple="false">
                            </multiselect>
                          </div>
                          <div class="form-group col-md-3">
                            <label class="text-">price</label>
                            <input type="number" class="form-control" placeholder="Enter price"
                              v-model="variant.price" />
                          </div>
                          <button class="btn btn-danger mt-3" @click="removeVariant(index)">
                            Remove Variant
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import "vue-multiselect/dist/vue-multiselect.css";

export default {
  name: "EditOperation",

  data() {
    return {
      name_en: "",
      name_ar: "",
      service_id: 1,
      // price: "",
      // infoServices: [],
      // variants: [],
    };
  },

  created() {
    this.fetchOperation();
    // this.fetchInfoservices();
  },
  methods: {
    async fetchOperation() {
      try {
        const response = await axios.get(
          "/admin/admin/operation/get?id=" + this.$route.params.id
        );
        const operationData = response.data.data;
        this.name_en = operationData.name_en;
        this.name_ar = operationData.name_ar;
        // Extract info_service_id from each info_service object
        // this.variants = operationData.info_services.map(service => ({
        //   info_service_id: service.info_service, // Adjusted to use info_service.id
        //   price: service.price
        // }));

        console.log(operationData);
      } catch (error) {
        console.error(error);
      }
    },
    async editOperation() {
      try {
        // Prepare form data for upload
        const formData = new FormData();
        formData.append("id", this.$route.params.id);
        formData.append('name_en', this.name_en);
        formData.append('name_ar', this.name_ar);
        formData.append("service_id", this.service_id);
        // formData.append("price", Number(this.price)); // Ensure price is a number
        // this.variants.forEach((variant, index) => {
        //   formData.append(`info_services[${index}][info_service_id]`, variant.info_service_id.id);
        //   formData.append(`info_services[${index}][price]`, variant.price);
        // });
        const response = await axios.post(
          "admin/admin/operation/update",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response.data);
        console.log(formData);
        this.$router.push({ name: "all-operations" });
      } catch (error) {
        console.error(error);
      }
    },
    // addVariant() {
    //   this.variants.push({
    //     info_service_id: "",
    //     // info_service_id: [], // Ensure this is initialized as an array
    //     price: ""
    //   });
    // },

    // removeVariant(index) {
    //   this.variants.splice(index, 1);
    // },

    // async fetchInfoservices() {
    //   try {
    //     // const response = await axios.get(`/admin/admin/info-service/all?service_id=${this.service_id}`);
    //     const response = await axios.get(`/admin/admin/info-service/all`);
    //     this.infoServices = response.data.data;
    //     this.meta = response.data.meta;
    //   } catch (error) {
    //     console.error("Error fetching infoServices:", error);
    //   }
    // }
  }
};

</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  width: 100%;
}

form>.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}
</style>
