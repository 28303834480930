<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Day Care Booking Details</h1>

            <!-- <span class="mainDescription"
              >Use this page to start from scratch and put your custom
              content</span
            > -->
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-dayServiceBookings' }">Back</router-link>
          </div>
          <!-- <ol class="breadcrumb">
            <li class="active">
              <span>Blank Page</span>
            </li>
          </ol> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <div class="booking-details">
              <!-- <h1>Booking Details</h1> -->
              <div class="booking-info">
                <div class="section">
                  <h2>Patient Information</h2>
                  <br>
                  <p><strong>Name:</strong> {{ name || 'N/A' }}</p>
                  <p><strong>Phone:</strong> {{ phone || 'N/A' }}</p>
                </div>

                <div class="section">
                  <h2>Appointment Details</h2>
                  <br>
                  <p><strong>Day Care:</strong> {{ day_service || 'N/A' }}</p>
                  <p><strong>Hospital:</strong> {{ info_service || 'N/A' }}</p>

                  <p><strong>Date:</strong> {{ date || 'N/A' }}</p>
                  <!-- <p><strong>Day:</strong> {{ appointment_day || 'N/A' }}</p> -->
                  <p><strong>Hour:</strong> {{ from }} - {{ to }}</p>
                  <p><strong>Price:</strong> {{ price }} EGP</p>

                </div>
                <div class="section status-section">
                  <h2>Status</h2>
                  <div class="form-group">
                    <br>
                    <multiselect v-model="selectedStatus" :options="statusOptions" :multiple="false"
                      placeholder="Select status" label="name" track-by="value"></multiselect>
                  </div>
                  <button @click="updateStatus" class="btn btn-primary mt-3 status-btn">
                    Update Status
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import Multiselect from "vue-multiselect";
export default {
  name: "ShowDayServiceBooking",
  components: {
    Multiselect
  },
  data() {
    return {
      name: "",
      phone: "",
      date: "",
      user: [],

      day_service: "",
      info_service: "",
      from: "",
      to: "",
      price: "",

      selectedStatus: null,
      statusOptions: [
        { name: "Pending", value: "Pending" },
        { name: "Confirmed", value: "Confirmed" },
        { name: "Cancelled", value: "Canceled" },
        { name: "Done", value: "Done" },
      ],
    };
  },
  created() {
    this.fetchBooking();
  },
  methods: {

    async fetchBooking() {
      try {
        const response = await axios.get(`/admin/admin/day-service/get-booking?id=${this.$route.params.id}`);
        const bookingData = response.data.data;
        console.log(bookingData)
        this.name = bookingData.name ?? null;
        this.phone = bookingData.phone ?? null;
        this.date = bookingData.date ?? null;

        this.day_service = bookingData.info_day_service.day_service.name_en ?? null;
        this.info_service = bookingData.info_day_service.info_service.name ?? null;
        this.from = bookingData.info_day_service.from ?? null;
        this.to = bookingData.info_day_service.to ?? null;
        this.price = bookingData.info_day_service.price ?? null;
        this.selectedStatus = this.statusOptions.find(
          (option) => option.value === bookingData.status
        );

      } catch (error) {
        console.error("Error fetching bookingData:", error);
      }
    },

    async updateStatus() {
      try {
        const formData = new FormData();
        formData.append("id", this.$route.params.id);
        if (this.selectedStatus) {
          formData.append("status", this.selectedStatus.value);
        }
        console.log(formData)
        const response = await axios.post(`/admin/admin/day-service/update-booking-status`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },

        });
        // this.booking.status = this.selectedStatus; // Update local state
        console.log(formData);
        console.log(response.data);
        this.$router.push({ name: "all-dayServiceBookings" });
      } catch (error) {
        console.error(error);
      }
    }
  },
};
</script>
<style scoped>
.booking-details {
  padding: 20px;
}

.booking-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.section {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
}

.document {
  margin-bottom: 10px;
}

.document-image {
  max-width: 100%;
  max-height: 100px;
}

h2 {
  margin-bottom: 10px;
}

.documents-container {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}

p {
  font-size: 1.1em
}

/* Button Styling */
.status-btn {
  display: block;
  margin-top: 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 10px 15px;
  transition: background-color 0.3s ease;
}

.status-btn:hover {
  background-color: #0056b3;
}
</style>
