<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Create Coupon</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-coupons' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="createCoupon" enctype="multipart/form-data">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">Name</label>
                    <input type="text" class="form-control" placeholder="Enter Name" v-model="name" />
                    <span class="error text-small block" v-if="errors.name">{{
                      errors.name }}</span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="specialityName">Code</label>
                    <input type="text" class="form-control" placeholder="Enter Code" v-model="code" />
                    <span class="error text-small block" v-if="errors.code">{{
                      errors.code }}</span>
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label class="text-">Discount</label>
                  <input type="number" min="0" max="100" class="form-control" placeholder="Enter Discount"
                    v-model="discount" />
                  <span class="error text-small block" v-if="errors.discount">{{
                    errors.discount }}</span>
                </div>
                <div class="checkbox clip-check check-primary">
                  <input type="checkbox" v-model="available" id="service1">
                  <label for="service1">
                    Availability
                  </label>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Notes</label>
                    <textarea rows="2" class="form-control" placeholder="Enter Notes" v-model="notes"></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Expiry Date:</label>
                    <input v-model="expires_at" type="date" />
                    <span class="error text-small block" v-if="errors.expires_at">{{
                      errors.expires_at }}</span>
                  </div>
                </div>


                <!-- <div v-if="Object.keys(errors).length > 0" class="error text-small block">
                  <ul>
                    <li v-for="(error, key) in errors" :key="key">
                      {{ error }}
                    </li>
                  </ul>
                </div> -->

              </div>
              <!-- <button class="btn btn-primary" :disabled="codeError">Submit</button> -->
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
export default {
  name: "CreateCoupon",
  data() {
    return {
      name: "",
      code: "",
      discount: "",
      available: false,
      notes: "",
      expires_at: "",
      codeError: "",
      errors: {},
    };
  },

  methods: {
    today() {
      const now = new Date();
      return now.toISOString().split("T")[0]; // Formats as 'YYYY-MM-DD'
    },
    isDateValid(date) {
      const selectedDate = new Date(date);
      const today = new Date(this.today());
      return selectedDate >= today;
    },
    validateForm() {
      this.errors = {};
      if (!this.name) {
        this.errors.name = "['Name is required.']";
      }
      if (!this.code) {
        this.errors.code = "['Code is required.']";
      }
      if (!this.discount) {
        this.errors.discount = "['Discount is required.']";
      }

      if (!this.expires_at) {
        this.errors.expires_at = "['Expiry Date is required.']";
      } else if (!this.isDateValid(this.expires_at)) {
        this.errors.expires_at = "['Expiry Date must be today or later.']";
      }

      return Object.keys(this.errors).length === 0;
    },
    async createCoupon() {
      try {

        if (!this.validateForm()) {
          return;
        }
        const formData = new FormData();
        formData.append('name', this.name);
        formData.append('code', this.code);
        formData.append("discount", Number(this.discount));
        formData.append("available", this.available ? 1 : 0);
        formData.append("notes", this.notes);
        formData.append("expires_at", this.expires_at);

        const response = await axios.post(
          "admin/admin/coupon/create",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(response.data.data);
        this.$router.push({ name: "all-coupons" });
      } catch (error) {
        console.error(error);
        if (error.response && error.response.status === 422) {
          // Handle validation errors
          this.errors = error.response.data.errors;
          console.log("Validation errors:", this.errors);
        } else {
          console.error("Error creating doctor:", error);
        }
      }
    },
  },
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
}

form>.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}

.error {
  color: #a94442;
  font-size: 0.875em;
}

.is-invalid {
  border-color: #dc3545;
}

.invalid-feedback {
  color: #dc3545;
}
</style>