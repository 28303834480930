<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">All Doctor Appointments</h1>
          </div>
          <div class="col-sm-2">
            <router-link style="margin-right: 10px" class="btn btn-primary"
              :to="{ name: 'create-appointmentDoctor' }">create</router-link>
            <router-link class="btn btn-success" :to="{ name: 'deleted-appointmentDoctors' }">Deleted
              Appointments</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <h5 class="over-title margin-bottom-15">
              <!-- Basic <span class="text-bold">Data Table</span> -->
            </h5>
            <p>

            </p>
            <div v-if="appointmentDoctors.length > 0">
              <MyTable :columns="columns" :actions="true">
                <tr v-for="(appointment, index) in appointmentDoctors" :key="index">
                  <th scope="row">{{ appointment.id }}</th>
                  <td>{{ appointment.doctor_service_specialty.doctor_name ?? '' }}</td>
                  <td>{{ appointment.doctor_service_specialty.service_name ?? '' }}</td>
                  <td>{{ appointment.doctor_service_specialty.specialty_name ?? '' }}</td>
                  <td>{{ appointment.appointment_day ? appointment.appointment_day.name_en : '' }}</td>
                  <td>{{ appointment.appointment_hour ? appointment.appointment_hour.from + ' - ' +
                    appointment.appointment_hour.to : '' }}</td>

                  <td>
                    <button class="me-2 btn btn-success" @click="restoreAppointment(appointment.id)">
                      Restore
                    </button>
                  </td>
                </tr>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No Appointments found.
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";
import $ from 'jquery'; // Import jQuery
import MyTable from "../MyTable//MyTable.vue"; // Import your MyTable component

export default {
  name: "AllAppointmentDoctors",
  components: {
    MyTable,
    // Paginate,
  },
  data() {
    return {
      dataTable: [], // Store DataTable instance
      appointmentDoctors: [],
      meta: {},
      searchTerm: "",
      columns: [
        { label: '#', key: 'id' },
        { label: 'Doctor Name', key: 'doctor_service_specialty.doctor_name' },
        { label: 'Speciality', key: 'doctor_service_specialty.speciality_name' },
        { label: 'Main Service', key: 'doctor_service_specialty.service_name' },
        { label: 'Day', key: 'appointment.appointment_day' },
        { label: 'Hour', key: 'appointment.appointment_hour' },
      ],
    };
  },

  async created() {
    this.fetchAppointment();
  },

  computed: {
    filteredAppointments() {
      if (!this.searchTerm) {
        return this.appointmentDoctors;
      }
      const term = this.searchTerm.toLowerCase();
      return this.appointmentDoctors.filter(
        (doctor) =>
          doctor.name.toLowerCase().includes(term)
      );
    },
  },

  methods: {

    async fetchAppointment() {
      try {
        const response = await axios.get(
          `/admin/admin/appointment-doctor/get-delete-soft`
        );
        this.appointmentDoctors = response.data.data;
        console.log(" fetching appointment-doctors:", this.appointmentDoctors);
        this.meta = response.data.meta;
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
      } catch (error) {
        console.error("Error fetching appointment-doctors:", error);
      }
    },
    initializeDataTable() {
      this.$nextTick(() => {
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },
    restoreAppointment(id) {
      console.log("restore appointmentDoctors id: ", id);
      axios
        .delete(`/admin/admin/appointment-doctor/restore?id=${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchAppointment();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

};
</script>
<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}
</style>