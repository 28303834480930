<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Create Doctor Appointment</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-doctors' }">Back</router-link>
          </div>
        </div>
      </section>
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form @submit.prevent="createAppointmentDoctor" enctype="multipart/form-data">
              <div class="row">

                <div class="form-group col-md-12 mb-2">
                  <label>Select services</label>
                  <select name="service_id" v-model="service_id" class="form-control"
                    @change="handleServiceChange(service_id); errors.service_id = ''">
                    <option value="">Select Service</option>
                    <option value=" 1">Hospitals</option>
                    <option value="2">Clinics</option>
                  </select>
                  <span v-if="errors.service_id" class="error">{{ errors.service_id }}</span>
                </div>

                <div class="form-group col-md-12 mb-2">
                  <label class="text-">Sub service</label>
                  <multiselect v-model="subService_id" :options="selectSubService" label="name" track-by="id"
                    placeholder="Select a Sub service"></multiselect>
                  <span v-if="errors.subService_id" class="error">{{ errors.subService_id }}</span>
                </div>

                <div class="form-group col-md-12 mb-2">
                  <label class="text-">Speciality</label>
                  <multiselect v-model="speciality_id" :options="specialities" label="name" track-by="id"
                    placeholder="Select a Speciality"></multiselect>
                  <span v-if="errors.speciality_id" class="error">{{ errors.speciality_id }}</span>
                </div>

                <div class="form-group col-md-12 mb-2">
                  <label class="text-">Hospitals/Clinics</label>
                  <multiselect v-model="info_service_id" :options="infoServices" label="name" track-by="id"
                    placeholder="Select a Hospital/Clinic"></multiselect>
                  <!-- @select="handleInfoServiceChange(info_service_id.id)"> -->
                  <span v-if="errors.info_service_id" class="error">{{ errors.info_service_id }}</span>
                </div>

                <div class="form-group col-md-12 mb-2">
                  <label class="text-">Day</label>
                  <multiselect v-model="appointment_day" :options="appointmentDays" label="name" track-by="id"
                    placeholder="Select a Day"></multiselect>
                  <span v-if="errors.appointment_day" class="error">{{ errors.appointment_day }}</span>
                </div>
                <div class="form-group col-md-12 mb-2">
                  <label class="text-">Hour</label>
                  <multiselect v-model="appointment_hour" :options="multiselectOptions" label="label" track-by="value"
                    placeholder="Select a hour"></multiselect>
                  <span v-if="errors.appointment_hour" class="error">{{ errors.appointment_hour }}</span>
                </div>

                <div v-if="errors.general" class="form-group col-md-12 mb-2">
                  <span class="error">{{ errors.general }}</span>
                </div>


                <br>
              </div>
              <button type="submit" class="btn btn-primary mb-2">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "../../Axios";
import Multiselect from "vue-multiselect";

export default {
  name: "CreateAppointmentDoctor",
  components: {
    Multiselect,
  },
  data() {
    return {
      errors: {}, // Store validation errors here
      selectSubService: [],
      subService_id: "",
      specialities: [],
      speciality_id: "",
      services: [],
      service_id: "",
      infoServices: [],
      info_service_id: "",
      appointmentDays: [],
      appointment_day: null,
      appointmentHours: [],
      appointment_hour: null,
      multiselectOptions: [],
    };
  },
  created() {
    this.fetchSpecialities();
    this.fetchAppointmentDays();
    this.fetchAppointmentHours();
  },
  methods: {
    validateForm() {
      this.errors = {}; // Reset errors

      if (!this.service_id) {
        this.errors.service_id = "Service is required.";
      }
      if (!this.subService_id && this.service_id == 1) {
        this.errors.subService_id = "Sub service is required.";
      }
      if (!this.speciality_id) {
        this.errors.speciality_id = "Speciality is required.";
      }
      if (!this.info_service_id) {
        this.errors.info_service_id = "Hospital/Clinic is required.";
      }
      if (!this.appointment_day) {
        this.errors.appointment_day = "Day is required.";
      }
      if (!this.appointment_hour) {
        this.errors.appointment_hour = "Hour is required.";
      }

      // Return true if there are no errors
      return Object.keys(this.errors).length === 0;
    },
    async fetchSpecialities() {
      try {
        const response = await axios.get("/admin/admin/speciality/all");
        this.specialities = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchInfoServices(serviceId) {
      try {
        const response = await axios.get(`/admin/admin/info-service/all?service_id=${serviceId}`);
        this.infoServices = response.data.data;
      } catch (error) {
        console.error("Error fetching infoServices:", error);
      }
    },
    async fetchAppointmentDays() {
      try {
        const response = await axios.get("/admin/admin/appointment-day/all");
        this.appointmentDays = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchAppointmentHours() {
      try {
        const response = await axios.get("/admin/admin/appointment-hour/all");
        this.appointmentHours = response.data.data;
        this.multiselectOptions = this.appointmentHours.map(hour => ({
          label: `${hour.from} - ${hour.to}`,
          value: hour.id,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    handleServiceChange(selectedService) {
      console.log("Selected service:", selectedService);
      this.subService_id = ""; // Clear subService selection
      this.info_service_id = ""; // Clear info service selection
      // Clear info service selection
      if (selectedService) {
        this.fetchInfoServices(selectedService); // Pass the variant
        if (selectedService == 1) {
          this.selectSubService = [{ id: 4, name: "OutPatient Clinics" }];
          console.log("Selected selectSubService:", this.selectSubService)
        } else {
          this.selectSubService = [];
        }
      } else {
        this.infoServices = [];
        this.selectSubService = [];
      }
    },

    async createAppointmentDoctor() {
      if (!this.validateForm()) {
        return; // Stop if the form is invalid
      }

      try {
        const formData = new FormData();
        if (this.subService_id.id) {
          formData.append("service_id", this.subService_id.id);
        } else {
          formData.append("service_id", this.service_id.id);
        }
        formData.append("doctor_id", this.$route.params.doctor_id);
        formData.append("speciality_id", this.speciality_id.id);
        formData.append("info_service_id", this.info_service_id.id);
        formData.append("appointment_hour_id", this.appointment_hour.value);
        formData.append("appointment_day_id", this.appointment_day.id);

        const response = await axios.post("admin/admin/appointment-doctor/create", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        console.log("Form Data:", formData);
        console.log("API Response:", response.data);
        this.$router.push({ name: "all-appointmentDoctors" });
      } catch (error) {
        console.error("Error submitting form:", error);

        // Reset errors object
        this.errors = {};
        // Handle backend validation errors
        if (error.response && error.response.data && error.response.data.errors) {
          this.errors = error.response.data.errors;
        } else if (error.response && error.response.data && error.response.data.message) {
          // Handle general backend errors (e.g., 500 errors)
          this.errors.general = error.response.data.message;
          this.errors.general = "Invalid Input";
        } else {
          // Handle other errors (e.g., network errors)
          this.errors.general = "An error occurred. Please try again.";
        }
      }
    },
  },
};
</script>


<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
}

form>.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}

.error {
  color: #a94442;
  font-size: 12px;
}
</style>