<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Create Operation Service</h1>
            <!-- <span class="mainDescription"
              >Use this page to start from scratch and put your custom
              content</span
            > -->
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-operationServices' }">Back</router-link>
          </div>
          <!-- <ol class="breadcrumb">
            <li class="active">
              <span>Blank Page</span>
            </li>
          </ol> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="createOperation" enctype="multipart/form-data">
              <div class="row">

                <div class="form-group col-md-12 mb-2">
                  <label class="text-">Hospital</label>
                  <multiselect v-model="info_service_id" :options="infoServices" :searchable="true" label="name"
                    track-by="id" placeholder="Select a Hospital/Clinic" :multiple="false">
                  </multiselect>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Select operation</label>
                    <multiselect v-model="operation_id" :options="operations" :multiple="false" :searchable="true"
                      label="name_en" track-by="id" placeholder="Select operation"></multiselect>
                  </div>
                </div>
                <div class="form-group col-md-3">
                  <label class="text-">price</label>
                  <input type="number" class="form-control" placeholder="Enter price" v-model="price" />
                </div>
              </div>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>
<script>
import axios from "../../Axios";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
export default {
  name: "CreateOperationService",
  components: {
    Multiselect,
  },
  data() {
    return {
      operation_id: "",
      info_service_id: "",
      price: "",
      operations: [],
      infoServices: [],

    };
  },

  async created() {
    this.fetchInfoservices();
    this.fetchOperations();
  },
  methods: {
    async createOperation() {
      try {
        // Prepare form data for upload
        const formData = new FormData();
        formData.append('operation_id', this.operation_id.id);
        formData.append('info_service_id', this.info_service_id.id);
        formData.append("price", this.price);
        console.log(formData);

        const response = await axios.post("api/admin/operation-service/create", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log(response.data);
        this.$router.push({ name: "all-operationServices" });
      } catch (error) {
        console.error(error);
      }

    },
    async fetchInfoservices() {
      try {
        const response = await axios.get(`/admin/admin/info-service/all`);
        this.infoServices = response.data.data;
        this.meta = response.data.meta;
        console.log(this.infoServices)
      } catch (error) {
        console.error("Error fetching infoServices:", error);
      }
    },
    async fetchOperations() {
      try {
        const response = await axios.get(`/admin/admin/operation/all`);
        this.operations = response.data.data;
        console.log(this.operations)

        this.meta = response.data.meta;
      } catch (error) {
        console.error("Error fetching operation:", error);
      }
    }

  }
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  width: 100%;
  /* max-width: 500px; Optional: Max width for better responsiveness */
}

form>.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}
</style>
