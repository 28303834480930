<template>
  <div>
    <input type="text" id="searchmap" placeholder="Search for a place" v-model="searchText"
      @keydown.enter.prevent="searchPlace" style="margin-bottom: 10px" />
    <div ref="map" style="height: 400px"></div>
  </div>
</template>

<script>
export default {
  name: "GoogleMap",
  props: {
    coords: {
      type: Object,
      default: () => ({
        // lat: '', // Default to Cairo's latitude
        // lng: '', // Default to Cairo's longitude
        lat: 30.0444, // Cairo's latitude as a fallback
        lng: 31.2357, // Cairo's longitude as a fallback
      }),
    },
  },
  data() {
    return {
      searchText: "",
      marker: null, // To store the marker instance
      map: null, // To store the map instance
    };
  },
  watch: {
    coords: {
      immediate: true,
      handler(newCoords) {
        // Update the map and marker position if coords change
        if (this.map && newCoords.lat && newCoords.lng) {
          const position = new window.google.maps.LatLng(newCoords.lat, newCoords.lng);
          this.map.setCenter(position);
          this.updateMarker(position);
        }
      },
    },
  },
  mounted() {
    this.loadGoogleMaps();
  },
  methods: {
    loadGoogleMaps() {
      if (!window.google) {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC0Qn35GGmFwlcIHeAdTL2_gpweBdNo5YI&libraries=places`;
        script.async = true;
        script.defer = true;
        script.onload = this.initializeMap;
        document.head.appendChild(script);
      } else {
        this.initializeMap();
      }
    },
    initializeMap() {
      const mapOptions = {
        center: this.coords,
        zoom: 12,
      };
      this.map = new window.google.maps.Map(this.$refs.map, mapOptions);
      this.marker = new window.google.maps.Marker({
        position: this.coords,
        map: this.map,
        title: "Selected Place",
      });

      const input = document.getElementById("searchmap");
      const searchBox = new window.google.maps.places.SearchBox(input);

      this.map.addListener("bounds_changed", () => {
        searchBox.setBounds(this.map.getBounds());
      });

      searchBox.addListener("places_changed", () => {
        const places = searchBox.getPlaces();
        if (places.length === 0) {
          return;
        }
        const bounds = new window.google.maps.LatLngBounds();
        places.forEach((place) => {
          if (place.geometry && place.geometry.location) {
            bounds.extend(place.geometry.location);
            this.$emit("placeChanged", {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            });
            this.updateMarker(place.geometry.location);
          }
        });
        this.map.fitBounds(bounds);
      });

      // this.map.addListener("click", (event) => {
      //   this.updateMarker(event.latLng);
      // });
      // Listen for click events on the map to place a marker
      this.map.addListener("click", (event) => {
        const clickedLocation = event.latLng;
        this.updateMarker(clickedLocation);
        this.$emit("placeChanged", {
          lat: clickedLocation.lat(),
          lng: clickedLocation.lng(),
        });
      });


    },

    updateMarker(location) {
      // Remove existing marker if it exists
      if (this.marker) {
        this.marker.setMap(null);
      }

      // Create new marker
      this.marker = new window.google.maps.Marker({
        position: location,
        map: this.map,
        title: "Selected Place",
      });
    },

    searchPlace() {
      // Optional: Implement searching for places directly by code if needed.
      console.log("Search triggered: ", this.searchText);
    },
  },
};
</script>

<style scoped>
/* Add scoped styles here */
</style>