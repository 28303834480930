<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Edit Category</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-categories' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="editCategory">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">English Name</label>
                    <input type="text" class="form-control" placeholder="Enter English Name" v-model="name_en" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">Arabic Name</label>
                    <input type="text" class="form-control" placeholder="Enter Arabic Name" v-model="name_ar" />
                  </div>
                </div>
                <!-- <div class="form-group">
                  <label>Image Upload</label>
                  <div class="fileinput-preview fileinput-exists thumbnail">
                    <img ref="imagePreview" :src="imagePreviewUrl" alt="Image Preview" />
                  </div>
                  <div class="user-edit-image-buttons">
                    <span class="btn btn-azure btn-file">
                      <span class="fileinput-new"><i class="fa fa-picture"></i> Select image</span>
                      <span class="fileinput-exists"><i class="fa fa-picture"></i> Change</span>
                      <input type="file" name="image" @change="handleFileChange" />
                    </span>
                    <a href="#" class="btn fileinput-exists btn-red" @click.prevent="removeImage">
                      <i class="fa fa-times"></i> Remove
                    </a>
                  </div>
                </div> -->
                <div class="form-group">
                  <label>Image Upload</label>
                  <div class="fileinput fileinput-new" data-provides="fileinput">
                    <div class="fileinput-new thumbnail">
                      <img :src="mainImageUrl" alt="Main Image" />
                    </div>
                    <div class="fileinput-preview fileinput-exists thumbnail"></div>
                    <div class="user-edit-image-buttons">
                      <span class="btn btn-azure btn-file"><span class="fileinput-new"><i class="fa fa-picture"></i>
                          Select image</span><span class="fileinput-exists"><i class="fa fa-picture"></i> Change</span>
                        <input type="file" name="main_image" @change="handleMainImageChange" />
                      </span>
                      <a href="#" class="btn fileinput-exists btn-red" data-dismiss="fileinput">
                        <i class="fa fa-times" @click.prevent="removeImage"></i> Remove
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import axios from "../../Axios";

export default {
  name: "EditCategory",
  data() {
    return {
      name_en: "",
      name_ar: "",
      mainImageUrl: "",
      main_image: null,

      existingImage: "", // URL of the existing image
      removeImageFlag: false, // Flag to indicate if the image should be removed
    };
  },
  // computed: {
  //   imagePreviewUrl() {
  //     return this.main_image ? URL.createObjectURL(this.main_image) : this.existingImage;
  //   },
  // },
  created() {
    this.fetchCategory();
  },
  methods: {
    handleMainImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.main_image = file;
      }
    },
    removeImage(index) {
      this.imagePreviewUrls.splice(index, 1);
    },
    async fetchCategory() {
      try {
        const response = await axios.get(`/admin/admin/category/get?id=${this.$route.params.id}`);
        const categoryData = response.data.data;
        this.name_en = categoryData.name_en;
        this.name_ar = categoryData.name_ar;
        // this.existingImage = categoryData.image; // Assuming the response includes an image URL
        this.mainImageUrl = categoryData.main_image;
        console.log('categoryData', categoryData);
      } catch (error) {
        console.error("Error fetching category:", error);
      }
    },
    async editCategory() {
      try {
        const formData = new FormData();
        formData.append("id", this.$route.params.id);
        formData.append("name_en", this.name_en);
        formData.append("name_ar", this.name_ar);
        if (this.main_image) {
          formData.append("main_image", this.main_image);
        }
        if (this.removeImageFlag) {
          formData.append("remove_image", true);
        }
        const response = await axios.post("/admin/admin/category/update", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log(response.data);
        console.log('formData', formData);

        this.$router.push({ name: "all-categories" });
      } catch (error) {
        console.error("Error updating category:", error);
      }
    },
    // handleFileChange(event) {
    //   const file = event.target.files[0];
    //   if (file) {
    //     this.main_image = file;
    //     const reader = new FileReader();
    //     reader.onload = (e) => {
    //       this.$refs.imagePreview.src = e.target.result; // Preview the selected image
    //     };
    //     reader.readAsDataURL(file);
    //   }
    // },

    // removeMainImage(index) {
    //   // Remove the image from selectedImages and imagePreviews
    //   this.main_selectedImages.splice(index, 1);
    //   URL.revokeObjectURL(this.main_imagePreviews[index]); // Clean up object URL
    //   this.main_imagePreviews.splice(index, 1); // Remove the preview URL from the array
    // },
    // async removeImage() {
    //   try {
    //     await axios.post(`/admin/admin/category/delete-image`, {
    //       id: this.$route.params.id,
    //     });
    //     this.main_image = null;
    //     this.removeImageFlag = true;
    //     this.$refs.imagePreview.src = ""; // Clear the preview image
    //   } catch (error) {
    //     console.error("Error removing image:", error);
    //   }
    // },
  },
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
}

form>.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}

.fileinput-preview {
  max-width: 200px;
  max-height: 200px;
}

.user-edit-image-buttons {
  margin-top: 1rem;
}
</style>