<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Edit OutPatient Clinic Service</h1>

            <!-- <span class="mainDescription"
              >Use this page to start from scratch and put your custom
              content</span
            > -->
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-externalClinicServices' }">Back</router-link>
          </div>
          <!-- <ol class="breadcrumb">
            <li class="active">
              <span>Blank Page</span>
            </li>
          </ol> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="editExternalClinicService" enctype="multipart/form-data">
              <div class="row">

                <div class="form-group">
                  <label class="text-">Hospital</label>
                  <multiselect v-model="info_service_id" :options="infoServices" :searchable="true" label="name"
                    track-by="id" placeholder="Select a Hospital" :multiple="false">
                  </multiselect>
                </div>
                <div class="form-group">
                  <label class="text-">OutPatient Clinic</label>
                  <multiselect v-model="external_clinic_id" :options="externalClinics" :searchable="true"
                    label="name_en" track-by="id" placeholder="Select an OutPatient Clinic" :multiple="false">
                  </multiselect>
                </div>
                <div class="form-group">
                  <label class="text-">English Name</label>
                  <input type="text" class="form-control" placeholder="Enter English Name" v-model="name_en" />
                </div>
                <div class="form-group">
                  <label for="specialityName">Arabic Name</label>
                  <input type="text" class="form-control" placeholder="Enter Arabic Name" v-model="name_ar" />
                </div>
                <div class="form-group">
                  <label for="labName">English Description </label>
                  <textarea rows="3" class="form-control" placeholder="Enter English Description"
                    v-model="description_en"></textarea>
                </div>

                <div class="form-group">
                  <label for="labName">Arabic Description </label>
                  <textarea rows="3" class="form-control" placeholder="Enter Arabic Description"
                    v-model="description_ar"></textarea>
                </div>
                <div class="form-group">
                  <label class="text-">price</label>
                  <input type="number" class="form-control" placeholder="Enter price" v-model="price" />
                </div>
                <div class="form-group ">
                  <label class="text-">District</label>
                  <multiselect v-model="district_id" :options="districts" :searchable="true" label="name" track-by="id"
                    placeholder="Select a District" :multiple="false">
                  </multiselect>
                </div>
                <div class="form-group">
                  <label for="labName">Address</label>
                  <input type="text" class="form-control" placeholder="Enter address" v-model="address" />
                </div>
                <!-- <GoogleMap :coords="{ lat: variant.lat, lng: variant.long }"
                  @placeChanged="(coords) => handlePlaceChanged(coords, index)" /> -->
                <GoogleMap :coords="{ lat: lat, lng: long }" @placeChanged="handlePlaceChanged" />
                <!-- <GoogleMap :coords="{ lat: variant.lat, lng: variant.long }"
                            @placeChanged="handlePlaceChanged" /> -->
                <div class="form-group">
                  <label>Latitude</label>
                  <input type="text" class="form-control" placeholder="Enter Latitude" v-model="lat" />
                </div>

                <div class="form-group">
                  <label>Longitude</label>
                  <input type="text" class="form-control" placeholder="Enter Longitude" v-model="long" />
                </div>

                <div class="form-group">
                  <label>Image</label>
                  <div class="fileinput fileinput-new" data-provides="fileinput">
                    <div class="fileinput-new thumbnail">
                      <img :src="imageUrl" alt="Image" />
                    </div>
                    <div class="fileinput-preview fileinput-exists thumbnail"></div>
                    <div class="user-edit-image-buttons">
                      <span class="btn btn-azure btn-file"><span class="fileinput-new"><i class="fa fa-picture"></i>
                          Select image</span><span class="fileinput-exists"><i class="fa fa-picture"></i> Change</span>
                        <input type="file" name="image" @change="handleImageChange" />
                      </span>
                      <a href="#" class="btn fileinput-exists btn-red" data-dismiss="fileinput">
                        <i class="fa fa-times"></i> Remove
                      </a>
                    </div>
                  </div>
                </div>



              </div>
              <button class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>


<script>
import axios from "../../Axios";
// import { store } from "../../Store";
import GoogleMap from "../Component/GoogleMap.vue";
import Multiselect from "vue-multiselect";
export default {
  name: "EditExternalClinicService",
  components: {
    GoogleMap,
    Multiselect,
  },
  data() {
    return {
      name_en: "",
      name_ar: "",
      description_ar: "",
      description_en: "",
      price: "",
      lat: 30.0444, // Lat and long for input fields
      long: 31.2357, // long and long for input fields
      // image: "",
      image: null,
      imageUrl: "",
      address: "",
      district_id: "",
      info_service_id: "",
      external_clinic_id: "",
      externalClinics: [],
      infoServices: [],
      districts: [],
      // users: [],
    };
  },
  async created() {
    this.fetchInfoservices();
    this.fetchExternalClinics();
    this.fetchDistricts();
    this.fetchExternalClinicServices();
  },
  methods: {

    handlePlaceChanged({ lat, lng }) {
      this.lat = lat;
      this.long = lng;
    },
    handleImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.image = file; // Update with the new file
        // this.imageUrl = URL.createObjectURL(file); // Preview the new image
      }
    },
    // Extract the file name from a URL
    getFileNameFromUrl(url) {
      if (!url) return null;
      return url.substring(url.lastIndexOf("/") + 1); // Extract the file name from the URL
    },

    async fetchExternalClinicServices() {
      try {
        const response = await axios.get(
          "/admin/admin/external-clinic-service/get?id=" + this.$route.params.id
        );

        const ExternalClinic = response.data.data;
        console.log(ExternalClinic);
        this.name_en = ExternalClinic.name_en;
        this.name_ar = ExternalClinic.name_ar;
        this.description_en = ExternalClinic.description_en;
        this.description_ar = ExternalClinic.description_ar;
        this.price = ExternalClinic.price;
        this.address = ExternalClinic.address;
        this.lat = ExternalClinic.lat;
        this.long = ExternalClinic.long;
        this.district_id = ExternalClinic.district;
        this.external_clinic_id = ExternalClinic.external_clinic;
        this.info_service_id = ExternalClinic.info_service;
        this.imageUrl = ExternalClinic.image;
        // this.user_id = ExternalClinic.user_id;
        console.log('fetched', ExternalClinic);
      } catch (error) {
        console.error("Error fetching doctor:", error);
      }
    },
    async editExternalClinicService() {
      try {
        const formData = new FormData();
        formData.append("id", this.$route.params.id);
        formData.append('name_en', this.name_en);
        formData.append('name_ar', this.name_ar);
        formData.append('description_ar', this.description_ar);
        formData.append('description_en', this.description_en);
        formData.append('price', Number(this.price));
        formData.append('district_id', this.district_id.id);
        formData.append('address', this.address);
        formData.append('lat', this.lat);
        formData.append('long', this.long);
        // formData.append('image', this.imageUrl);
        if (this.image) {
          formData.append("image", this.image); // Append the file object
        }
        // else {
        //   const fileName = this.getFileNameFromUrl(this.imageUrl); // Extract the file name from the URL
        //   console.log(fileName);
        //   if (fileName) {
        //     formData.append("image", fileName); // Append the file name
        //   }
        // }
        formData.append('info_service_id', this.info_service_id.id);
        formData.append("external_clinic_id", this.external_clinic_id.id);
        // const user_id = store.user ? store.user.id : null;
        console.log(formData);
        const response = await axios.post("admin/admin/external-clinic-service/create", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log(response.data);
        this.$router.push({ name: "all-externalClinicServices" });
      } catch (error) {
        console.error(error);
      }
    },

    async fetchInfoservices() {
      try {
        const response = await axios.get(`/admin/admin/info-service/all?service_id=1`);
        this.infoServices = response.data.data;
        this.meta = response.data.meta;
      } catch (error) {
        console.error("Error fetching infoServices:", error);
      }
    },
    async fetchExternalClinics() {
      try {
        const response = await axios.get(`/admin/admin/external-clinic/all`);
        this.externalClinics = response.data.data;
        console.log(this.externalClinics);
        this.meta = response.data.meta;
      } catch (error) {
        console.error("Error fetching externalClinics:", error);
      }
    },
    async fetchDistricts() {
      try {
        const response = await axios.get(`/admin/admin/district/all`);
        this.districts = response.data.data;
        this.meta = response.data.meta;
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    },
  },
};
</script>

<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  width: 100%;
  /* max-width: 500px; Optional: Max width for better responsiveness */
}

form>.form-group {
  margin-bottom: 1.5rem;
}

button {
  width: 100%;
}
</style>
