import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from './Store';
// Styles
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import "bootstrap/dist/css/bootstrap.css";

// import 'datatables.net-dt/css/jquery.dataTables.css'; 

// Axios and jQuery
import "./Axios.js";
import $ from "jquery"; // Use this import statement for jQuery
import 'datatables.net';
import 'datatables.net-dt';
import jQueryPlugin from './plugins/jquery-plugin'; // Adjust the path as necessary
import DataTablesPlugin from './plugins/dataTablesPlugin'; // DataTables plugin
import dayjs from 'dayjs'; // Import dayjs
import relativeTime from 'dayjs/plugin/relativeTime'; // Optional: Import a plugin




// Use the plugin (if needed)
dayjs.extend(relativeTime);

// import 'https://maps.googleapis.com/maps/api/js?key=AIzaSyC0Qn35GGmFwlcIHeAdTL2_gpweBdNo5YI&libraries=places';
// import '../public/vendor/jquery.cookie';
// import '../public/vendor/perfect-scrollbar';
// import '../public/vendor/switchery';
// import '../public/vendor/chart.js';
// import '../public/vendor/jquery.sparkline';
// import '../public/vendor/maskedinput';
// import '../public/vendor/bootstrap-touchspin';
// import '../public/vendor/autosize';
// import '../public/vendor/select2';
// import '../public/vendor/bootstrap-datepicker';
// import '../public/vendor/bootstrap-timepicker';
// import '../public/vendor/bootstrap-fileinput';
// import '../public/vendor/selectFx'
// document.addEventListener('DOMContentLoaded', () => {
//     $('#sample_1').DataTable(); // Example for initializing DataTables
//   });
//   $(document).ready(function () {
//     Main.init();
//     Index.init();
//     TableData.init();
//   });
// import the package
// import VueAwesomePaginate from "vue-awesome-paginate";
// // import the necessary css file
// import "vue-awesome-paginate/dist/style.css";
// import Paginate from "vuejs-paginate-next";


const app = createApp(App);
// Error handling setup
// app.config.errorHandler = (err, vm, info) => {
//     console.error('Vue Error:', err);
//     console.error('Error Info:', info);
// };

// Create your Vue app

//
app.config.globalProperties.$ = $;
app.config.globalProperties.jQuery = $;

// app.appUrl = "https://localhost:8080";
app.use(jQueryPlugin); // Use the jQuery plugin
app.use(DataTablesPlugin); // Use DataTables plugin
app.use(router);
app.use(store);


// app.directive('perfect-scroll', {
//     mounted(el) {
//         new PerfectScrollbar(el);
//     }
// });


// // Router navigation guards 
// router.beforeEach((to, from, next) => {
//     // Clean up any existing DataTables instances
//     $('.dataTable').DataTable().destroy();
//     next();
// });

if (localStorage.getItem("access_token")) {
    const role = localStorage.getItem("userRole");
    const permissions = JSON.parse(localStorage.getItem("userPermissions"));

    // Set user role and permissions in the store
    store.setUserRole(role);
    store.setUserPermissions(permissions);
    store.setLoggedIn(true);
}

app.mount('#app');


// // Handle unhandled promise rejections
// window.addEventListener('unhandledrejection', event => {
//     console.error('Unhandled Promise Rejection:', event.reason);
// });

// // Clean up on page unload
// window.addEventListener('unload', () => {
//     try {
//         // Destroy all DataTables instances
//         $.fn.dataTable.tables().forEach(table => {
//             $(table).DataTable().destroy();
//         });
//     } catch (error) {
//         console.error('Error during cleanup:', error);
//     }
// });