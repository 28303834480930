<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Edit Medical Insurance</h1>

            <!-- <span class="mainDescription"
              >Use this page to start from scratch and put your custom
              content</span
            > -->
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-medicalInsurances' }">Back</router-link>
          </div>
          <!-- <ol class="breadcrumb">
            <li class="active">
              <span>Blank Page</span>
            </li>
          </ol> -->
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">
            <form class="text-start mx-auto" @submit.prevent="editInsurance" enctype="multipart/form-data">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">English Name</label>
                    <input type="text" class="form-control" placeholder="Enter English Name" v-model="name_en" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="labName">Arabic Name</label>
                    <input type="text" class="form-control" placeholder="Enter Arabic Name" v-model="name_ar" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">English Title</label>
                    <input type="text" class="form-control" placeholder="Enter English Title" v-model="title_en" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="labTitle">Arabic Title</label>
                    <input type="text" class="form-control" placeholder="Enter Arabic Title" v-model="title_ar" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="labName">Phone</label>
                    <input type="text" class="form-control" placeholder="Enter Phone" v-model="phone" />
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label class="text-">Email</label>
                    <input type="text" class="form-control" placeholder="Enter Email" v-model="email" />
                  </div>
                </div>
                <div class="form-group">
                  <label>Main Image</label>
                  <div class="fileinput fileinput-new" data-provides="fileinput">
                    <div class="fileinput-new thumbnail">
                      <img :src="main_imagePreviews" alt="Main Image" />
                    </div>
                    <div class="fileinput-preview fileinput-exists thumbnail"></div>
                    <div class="user-edit-image-buttons">
                      <span class="btn btn-azure btn-file"><span class="fileinput-new"><i class="fa fa-picture"></i>
                          Select image</span><span class="fileinput-exists"><i class="fa fa-picture"></i> Change</span>
                        <input type="file" name="main_image" @change="handleMainImageChange" />
                      </span>
                      <a href="#" class="btn fileinput-exists btn-red" data-dismiss="fileinput">
                        <i class="fa fa-times"></i> Remove
                      </a>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Background Image</label>
                  <div class="fileinput fileinput-new" data-provides="fileinput">
                    <div class="fileinput-new thumbnail">
                      <img :src="background_imagePreviews" alt="Background Image" />
                    </div>
                    <div class="fileinput-preview fileinput-exists thumbnail"></div>
                    <div class="user-edit-image-buttons">
                      <span class="btn btn-azure btn-file"><span class="fileinput-new"><i class="fa fa-picture"></i>
                          Select background_image</span><span class="fileinput-exists"><i class="fa fa-picture"></i>
                          Change</span>
                        <input type="file" name="background_image" @change="handleBackgroundImageChange" />
                      </span>
                      <a href="#" class="btn fileinput-exists btn-red" data-dismiss="fileinput">
                        <i class="fa fa-times"></i> Remove
                      </a>
                    </div>
                  </div>
                </div>

                <!-- <div class="form-group">
                  <label for="image" class="form-label">Image Upload</label>
                  <div class="fileinput-preview fileinput-exists thumbnail mt-2">
                    <img :src="main_imagePreviews" alt="Profile Preview" class="image-preview" />
                  </div>
                  <div class="upload-buttons">
                    <label class="btn btn-azure btn-file">
                      <span><i class="fa fa-picture"></i> Select Image</span>
                      <input type="file" accept="image/*" @change="handleMainImageChange" class="hidden" />
                    </label>
                    <button v-if="main_imagePreviews" @click.prevent="removeMainImage"
                      class="btn fileinput-exists btn-red">
                      <i class="fa fa-times"></i> Remove
                    </button>
                  </div>
                </div> -->

                <!-- <div class="form-group">
                  <label for="image" class="form-label">background Image Upload</label>
                  <div class="fileinput-preview fileinput-exists thumbnail mt-2">
                    <img :src="background_imagePreviews" alt="Profile Preview" class="image-preview" />
                  </div>
                  <div class="upload-buttons">
                    <label class="btn btn-azure btn-file">
                      <span><i class="fa fa-picture"></i> Select Image</span>
                      <input type="file" accept="image/*" @change="handleBackgroundImageChange" class="hidden" />
                    </label>
                    <button v-if="background_imagePreviews" @click.prevent="removeBackgroundImage"
                      class="btn fileinput-exists btn-red">
                      <i class="fa fa-times"></i> Remove
                    </button>
                  </div>
                </div> -->
              </div>
              <button class="btn btn-primary submit-button">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>
<script>
import axios from "../../Axios";

export default {
  name: "editMedicalInsurance",
  data() {
    return {
      name_en: "",
      name_ar: "",
      title_ar: "",
      title_en: "",
      email: "",
      phone: "",
      main_image: null,
      main_imagePreviews: [],
      background_image: null,

      background_imagePreviews: [],
    };
  },
  created() {
    this.fetchInsurance();
  },
  methods: {
    async fetchInsurance() {
      try {
        const response = await axios.get(
          "/admin/admin/medical-insurance/get?id=" + this.$route.params.id
        );
        const insuranceData = response.data.data;

        this.name_en = insuranceData.name_en;
        this.name_ar = insuranceData.name_ar;
        this.title_ar = insuranceData.title_ar;
        this.title_en = insuranceData.title_en;
        this.email = insuranceData.email;
        this.phone = insuranceData.phone;
        if (insuranceData.main_image) {
          this.main_imagePreviews = insuranceData.main_image;
        }
        const backgrount_image_url = `https://allodoctor-backend.developnetwork.net/storage/${insuranceData.background_image}`
        if (insuranceData.background_image) {
          // this.background_imagePreviews = insuranceData.background_image
          this.background_imagePreviews = backgrount_image_url
        }

        console.log('fetched', insuranceData);
      } catch (error) {
        console.error(error);
      }
    },
    handleMainImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.main_image = file;
        this.main_imagePreviews = URL.createObjectURL(file);
      }
      event.target.value = null;
    },
    removeMainImage() {
      this.main_image = null;
      this.main_imagePreviews = null;
    },
    handleBackgroundImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.background_image = file;
        this.background_imagePreviews = URL.createObjectURL(file);
      }
      event.target.value = null;
    },
    removeBackgroundImage() {
      this.background_image = null;
      this.background_imagePreviews = null;
    },
    async editInsurance() {
      try {
        const formData = new FormData();
        formData.append("id", this.$route.params.id);
        formData.append("name_en", this.name_en);
        formData.append("name_ar", this.name_ar);
        formData.append("title_ar", this.title_ar);
        formData.append("title_en", this.title_en);
        formData.append("phone", this.phone);
        formData.append("email", this.email);
        if (this.main_image) {
          formData.append("main_image", this.main_image); // Append the selected file
        }
        if (this.background_image) {
          formData.append("background_image", this.background_image); // Append the selected file
        }

        const response = await axios.post(
          "admin/admin/medical-insurance/update",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log(formData);
        console.log(response.data);
        this.$router.push({ name: "all-medicalInsurances" });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>


<style scoped>
form {
  padding: 1rem;
  border: 1px solid #ccc;
  background-color: white;
  /* Add a background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Add a shadow for better visibility */
  width: 100%;
  /* max-width: 500px; Optional: Max width for better responsiveness */
}

form>.form-group {
  margin-bottom: 1.5rem;
}

.submit-button {
  width: 100%;
}

.hidden {
  display: none;
}

.upload-buttons {
  display: flex;
  gap: 1rem;
}

.btn {
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}
</style>
