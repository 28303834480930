<template>
  <div class="main-content">
    <div class="wrap-content container" id="container">
      <!-- start: PAGE TITLE -->
      <section id="page-title" style="background-color: #d1d1d1;">
        <div class="row">
          <div class="col-sm-10">
            <h1 class="mainTitle">Deleted Medical Insurances</h1>
          </div>
          <div class="col-sm-2">
            <router-link class="btn btn-primary" :to="{ name: 'all-medicalInsurances' }">Back</router-link>
          </div>
        </div>
      </section>
      <!-- end: PAGE TITLE -->
      <!-- start: DYNAMIC TABLE -->
      <div class="container-fluid container-fullw bg-white">
        <div class="row">
          <div class="col-md-12">

            <div v-if="insurances.length > 0">
              <MyTable :columns="columns" :actions="true">
                <tr v-for="(insurance, index) in insurances" :key="index">
                  <th scope="row">{{ insurance.id }}</th>
                  <td>{{ insurance.name_ar }}</td>
                  <td>{{ insurance.name_en }}</td>
                  <td>
                    <img :src="`${insurance.main_image}`" width="50" height="50" class="img-thumbnail" />
                  </td>
                  <!-- <td><img :src="`${assets}pharmacy.image`"></td> -->
                  <td>
                    <button class="me-2 btn btn-success" @click="restoreInsurance(insurance.id)">
                      Restore
                    </button>

                  </td>
                </tr>
              </MyTable>
            </div>
            <div v-else class="alert alert-info">
              No deleted medical insurances found.
            </div>
          </div>
        </div>
      </div>
      <!-- end: DYNAMIC TABLE -->
    </div>
  </div>
</template>

<script>
import $ from 'jquery'; // Import jQuery
import MyTable from "../MyTable//MyTable.vue"; // Import your MyTable component

import axios from "../../Axios";
export default {
  name: "DeletedMedicalInsurances",
  components: {
    MyTable,
    // Paginate,
  },
  data() {
    return {
      dataTable: [],
      insurances: [],
      meta: {},
      columns: [
        { label: '#', key: 'id' },
        { label: 'Name Arabic', key: 'name_en' },
        { label: 'Name English', key: 'name_ar' },
        { label: 'Image', key: 'main_image' },
      ],
      searchTerm: "",
    };
  },

  async created() {
    this.fetchInsurances();
  },
  methods: {
    // fetch pharmacies
    async fetchInsurances() {
      try {
        const response = await axios.get(
          `/admin/admin/medical-insurance/get-delete-soft`
        );

        this.insurances = response.data.data;
        // this.service_id= response.data.data.service.id;
        this.meta = response.data.meta;
        this.$nextTick(() => {
          this.initializeDataTable(); // Initialize DataTable after fetching users
        });
      } catch (error) {
        console.error("Error fetching insurances:", error);
      }
    },
    initializeDataTable() {
      this.$nextTick(() => {
        // Ensure the DataTable is initialized only after the DOM is updated
        $('.sample_1').DataTable(); // Initialize DataTable
      });
    },
    restoreInsurance(id) {
      console.log("restore Insurance id: ", id);
      axios
        .post(`/admin/admin/medical-insurance/restore?id=${id}`)
        .then((response) => {
          console.log(response.data);
          this.fetchInsurances();
        })
        .catch((error) => {
          console.error(error);
        });
    },

  },

};

</script>
<style scoped>
.table {
  margin-top: 10px;
}

.spacing {
  margin-left: 10px !important;
}
</style>